import React from 'react'

import { TextField } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { InputDropdown } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { formatNumber } from '@clain/core/utils/format'

import { FieldProps, Amount } from './types'

const getPrintValueLabel = (fromValue?: string, toValue?: string): string => {
  const from = fromValue && formatNumber(fromValue, 0)
  const to = toValue && formatNumber(toValue, 0)

  return from && to ? `${from} — ${to}` : from || to
}

const AmountFilter = ({
  label,
  value,
  onChange,
}: FieldProps<Amount> & { label: string }) => {
  const from = value.from || ''
  const to = value.to || ''

  const ref = React.useRef(null)

  // TODO: подумать надо открытием на фокус при добавлении
  // React.useEffect(() => {
  //   ref.current.focus()
  // })

  const handleInputClear = () => onChange({ from: null, to: null })
  const handleChangeAmountFrom = (v) => onChange({ ...value, from: v })
  const handleChangeAmountTo = (v) => onChange({ ...value, to: v })

  return (
    <InputDropdown
      clearable
      ref={ref}
      padded={false}
      variant="outline"
      placeholder="Any"
      startAddon={{ label }}
      value={getPrintValueLabel(from, to)}
      onClear={handleInputClear}
    >
      <Container gap={[1.5, 1, 2, 1]}>
        <MagicGrid gap={1}>
          <Label content="From">
            <TextField
              variant="outline"
              startAddon={{ label: '≥ BTC:' }}
              value={from}
              onChange={handleChangeAmountFrom}
              mask="number"
            />
          </Label>
          <Label content="To">
            <TextField
              variant="outline"
              startAddon={{ label: '≤ BTC:' }}
              value={to}
              onChange={handleChangeAmountTo}
              mask="number"
            />
          </Label>
        </MagicGrid>
      </Container>
    </InputDropdown>
  )
}

export default AmountFilter

import * as S from '@compliance/components/Widgets/AIChatWidget/AIChatWidget.styles'
import { StatusBlock } from '@clainio/web-platform'
import React from 'react'

export const EmptyChat = () => {
  return (
    <S.ChatMessageFullHeightContainer>
      <StatusBlock gap={'md'}>
        <StatusBlock.Image type={'message'} width={'56px'} height={'56px'} />
        <StatusBlock.Title variant={'heading200Normal'}>
          Get More Insights
        </StatusBlock.Title>
        <StatusBlock.Subtitle variant={'body100Normal'}>
          Ask questions here to get relevant insights.
          <br /> Learn more about a specific transaction, account history, or
          unusual activity.
        </StatusBlock.Subtitle>
      </StatusBlock>
    </S.ChatMessageFullHeightContainer>
  )
}

import React, { memo } from 'react'
import { ChatMessageHistoryProps } from '@compliance/components/Widgets/AIChatWidget/AIChatWidget.types'
import { ChatMessageCardSystem } from '@clainio/web-platform'
import { ChatMessageCardUserSection } from '@compliance/components/Widgets/AIChatWidget/ChatMessageCardUserSection'

export const ChatMessagesHistory = memo(
  ({
    chatMessages,
    isUseMessageInReportDisabled,
    onUseMessageInReport,
    chatMessagesUsedInSummary,
    showUseInReportButton,
    timeZone,
  }: ChatMessageHistoryProps) => {
    return (
      <>
        {chatMessages.map(
          (
            { user, createdAt, content, role, usedInReport, id, files },
            index
          ) => {
            if (role === 'assistant') {
              const showUsedInReportLabel =
                usedInReport || chatMessagesUsedInSummary.includes(id)
              const showUseInReportActionButton =
                showUseInReportButton && !showUsedInReportLabel
              return (
                <ChatMessageCardSystem
                  timeZone={timeZone}
                  key={`${createdAt}-${index}`}
                  text={content}
                  date={createdAt}
                >
                  {showUsedInReportLabel && (
                    <ChatMessageCardSystem.ActionTitle />
                  )}
                  {showUseInReportActionButton && (
                    <ChatMessageCardSystem.ActionButton
                      onClick={() => onUseMessageInReport(id)}
                      isDisabled={isUseMessageInReportDisabled}
                    />
                  )}
                </ChatMessageCardSystem>
              )
            }
            return (
              <ChatMessageCardUserSection
                timeZone={timeZone}
                key={`${createdAt}-${index}`}
                userName={user?.name}
                userAvatar={user?.avatar}
                text={content}
                createdAt={createdAt}
                files={files}
              />
            )
          }
        )}
      </>
    )
  }
)

ChatMessagesHistory.displayName = 'ChatMessagesHistory'

import React from 'react'
import { getScoreColor } from '@clain/core/utils/getScoreColor'
import { roundToPrecision } from '@clain/core/utils/math'
import { formatNumber } from '@clain/core/utils/format'

import Exposure from '@clain/core/Exposure/Exposure'
import { ExposureItem, ExposureMode } from '@clain/core/Exposure/Exposure.types'
import { ClientExposure as ClientExposureType } from '../../apiServices/Client/ClientService.types'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

interface ClientExposureProps {
  className?: string
  data: SnakeToCamelCaseObject<{
    in: ClientExposureType
    out: ClientExposureType
  }>
  mode?: ExposureMode
}

const transformExposure = (items = []) => {
  const totalAmount = items.reduce(
    (total, category) =>
      total +
        category.counterparties?.reduce((accum, value) => {
          return accum + value.amount
        }, 0) || 0,
    0
  )

  return items.map((item) => {
    const categoryAmount = item.counterparties.reduce(
      (accum, value) => accum + value.amount,
      0
    )
    const categoryFraction = categoryAmount / totalAmount
    const categoryScore = Math.floor(
      roundToPrecision(
        item.counterparties.reduce(
          (accum, value) =>
            accum + value.score * (value.amount / categoryAmount),
          0
        ),
        10
      )
    )

    return {
      name: item.name,
      amount: Number(item.category_amount),
      value: categoryFraction,
      color: getScoreColor(categoryScore),
      score: categoryScore,
      children: item.counterparties.map((cp) => ({
        name: `${cp.entity?.entity || item.name} ${formatNumber(
          (cp.amount * 100) / categoryAmount,
          1
        )}%`,
        value: cp.amount / totalAmount,
        color: getScoreColor(cp.score),
        score: cp.score,
      })),
    }
  })
}

const ClientExposure: React.FC<ClientExposureProps> = ({
  className,
  data = {},
  mode,
}) => {
  const preparedData = React.useMemo(() => {
    const { in: source, out: destination } = data

    return [
      transformExposure(source?.exposure),
      transformExposure(destination?.exposure),
    ]
  }, [data]) as [Array<ExposureItem>, Array<ExposureItem>]

  if (!data.in?.exposure?.length && !data.out?.exposure?.length) return null

  return <Exposure mode={mode} className={className} data={preparedData} />
}

export default ClientExposure

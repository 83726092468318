import React from 'react'

import Chart from '@clain/core/Chart2'
import { formatMoney, formatNumberShort } from '@clain/core/utils/format'

interface SeverityDonutData {
  high: number
  medium: number
  low: number
  total: number
}

interface SeverityDonutOptions {
  data: SeverityDonutData
}

function getOptions({ data }: SeverityDonutOptions) {
  const TITLE = 'Total'

  const legendValues = {
    High: data.high,
    Medium: data.medium,
    Low: data.low,
  }

  const options = {
    tooltip: {
      show: false,
    },
    title: [
      {
        text: `$${formatNumberShort({ value: data.total })}`,
        subtext: TITLE,

        x: 'center',
        y: 115,

        itemGap: 8,

        textStyle: {
          fontSize: 24,
          fontWeight: 'normal',
        },
        subtextStyle: {
          fontSize: 16,
        },
      },
    ],
    legend: {
      show: true,
      bottom: 32,
      selectedMode: false,
      data: [
        legendValues.High && {
          name: 'High',
          icon: 'circle',
        },
        legendValues.Medium && {
          name: 'Medium',
          icon: 'circle',
        },
        legendValues.Low && {
          name: 'Low',
          icon: 'circle',
        },
      ].filter(Boolean),
      x: 'center',
      y: 'bottom',
      itemGap: 24,
      padding: [24, 0, 24, 0],
      orient: 'horizontal',
      selected: {},
      formatter: function (name) {
        const value = formatMoney({
          value: legendValues[name],
          currency: 'usd',
          precision: 0,
          code: '',
        })

        return `${value} ${name}`
      },
      textStyle: {
        fontSize: 14,
      },
    },
    series: [
      {
        name: TITLE,
        type: 'pie',
        radius: ['45%', '70%'], // 70 * 0.65 ~ 45% // 0.65 — отношение внетруннего круга к внешнему
        center: ['50%', '42%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 4,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        silent: true,
        data: [
          data.total !== 0 && {
            value: data.high,
            name: 'High',
            itemStyle: {
              color: '#D4114A',
            },
          },
          data.total !== 0 && {
            value: data.medium,
            name: 'Medium',
            itemStyle: {
              color: '#FFA800',
            },
          },
          data.total !== 0 && {
            value: data.low,
            name: 'Low',
            itemStyle: {
              color: '#2173FF',
            },
          },
          data.total === 0 && {
            value: 0,
            name: 'Empty',
            itemStyle: {
              color: '#F1F3F9',
            },
          },
        ].filter(Boolean),
      },
    ],
  }

  return options
}

interface SeverityDonutProps extends SeverityDonutOptions {
  className?: string
  loading?: boolean
}

const SeverityDonut = React.memo(
  ({ className, loading, data }: SeverityDonutProps) => {
    return (
      <Chart
        stub={!data}
        loading={loading}
        className={className}
        option={getOptions({ data })}
        style={{
          height: 330,
        }}
      />
    )
  }
)
SeverityDonut.displayName = 'SeverityDonut'
export default SeverityDonut

import React from 'react'
import classnames from 'classnames/bind'

import { Text } from '@clain/core/ui-kit'
import { InfoCard, InfoCardStub } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { CopyIcon } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { formatMoney } from '@clain/core/utils/format'
import { MagicGrid } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { AssetsListDetailed } from '@clain/core/ui-kit'

import styles from './index.scss'
import { BlockchainType } from '../../types/coin'
import { PopoverBaseContainer } from '@clainio/web-platform'
import { ClientDetails } from '../../apiServices/Client/ClientService.types'

const cx = classnames.bind(styles)

interface ActivitySummaryInfoCardProps {
  className?: string
  client?: ClientDetails
  loading?: boolean
}

const ActivitySummaryInfoCard: React.FC<ActivitySummaryInfoCardProps> = ({
  client,
  loading,
  className,
}) => {
  const assets = React.useMemo(
    () =>
      client?.client_tokens.map(({ withdrawals, deposited, token }) => ({
        id: token.id,
        name: token.name,
        blockchain: token.blockchain.toUpperCase() as BlockchainType,
        receivedUsd: withdrawals,
        sentUsd: deposited,
      })),
    [client]
  )

  if (loading) {
    return <InfoCardStub className={className} rows={5} />
  }

  const incoming = client?.transaction_distribution.find(
    (data) => data.type === 'incoming'
  )
  const outgoing = client?.transaction_distribution.find(
    (data) => data.type === 'outgoing'
  )

  return (
    <InfoCard
      className={className}
      details={[
        {
          label: (
            <Text>
              Transfers{' '}
              <Typography variant="caption1">
                ({client?.transactions ?? 0})
              </Typography>
            </Text>
          ),
          value: (
            <RowDeprecated gap={0.5}>
              <Text>
                {formatMoney({
                  value: client?.volume ?? 0,
                  currency: 'usd',
                  precision: 0,
                  code: '',
                })}
              </Text>
              <CopyIcon value={client?.volume ?? 0} />
            </RowDeprecated>
          ),
        },
        {
          label: (
            <Text>
              Total received{' '}
              <Typography variant="caption1">
                ({incoming?.total ?? 0})
              </Typography>
            </Text>
          ),
          value: (
            <RowDeprecated gap={0.5}>
              <Text>
                {formatMoney({
                  value: incoming?.total_usd ?? 0,
                  currency: 'usd',
                  precision: 0,
                  code: '',
                })}
              </Text>
              <CopyIcon value={incoming?.total_usd ?? 0} />
            </RowDeprecated>
          ),
        },
        {
          label: (
            <Text>
              Total sent{' '}
              <Typography variant="caption1">
                ({outgoing?.total ?? 0})
              </Typography>
            </Text>
          ),
          value: (
            <RowDeprecated gap={0.5}>
              <Text>
                {formatMoney({
                  value: outgoing?.total_usd ?? 0,
                  currency: 'usd',
                  precision: 0,
                  code: '',
                })}
              </Text>
              <CopyIcon value={outgoing?.total_usd ?? 0} />
            </RowDeprecated>
          ),
        },
        {
          label: 'Assets',
          value: (
            <>
              <Typography
                className={cx('CounterpartyTokens', {
                  active: client?.client_tokens?.length,
                })}
                variant="body2"
                color="grey1"
              >
                {client?.client_tokens?.length ?? 0}
              </Typography>
              {client?.client_tokens?.length > 0 && (
                <PopoverBaseContainer
                  className={cx('AssetsDropDown')}
                  content={
                    <MagicGrid>
                      <Container gap={[1, 1, 0, 1]}>
                        <Typography variant="body2" color="grey1">
                          The client has {client.client_tokens.length} assets
                        </Typography>
                      </Container>
                      <AssetsListDetailed
                        assets={assets}
                        className={cx('CounterpartyAssetsList')}
                      />
                    </MagicGrid>
                  }
                  anchor={<div className={cx('AssetsDropdownInner')} />}
                />
              )}
            </>
          ),
        },
      ]}
    />
  )
}

export default ActivitySummaryInfoCard

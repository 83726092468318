import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'
import { ReactComponent as HistoryIcon } from '@clain/core/assets/history.svg'
import { ReactComponent as HistoryBlockIcon } from '@clain/core/assets/history_block.svg'
import { ReactComponent as BlockIcon } from '@clain/core/assets/block.svg'

import { FieldProps, CommonSettings as CommonSettingsType } from './types'

import styles from './index.scss'

const cx = classnames.bind(styles)

const CommonSettings = ({
  value,
  onChange,
}: FieldProps<CommonSettingsType>) => {
  const autoBlock = value?.autoBlock
  const retrospectivelyBlock = value?.retrospectivelyBlock
  const retrospectivelyApply = value?.retrospectivelyApply

  const handleChangeAutoBlock = () =>
    onChange({ ...value, autoBlock: !value.autoBlock })
  const handleChangeRetrospectivelyBlock = () =>
    onChange({ ...value, retrospectivelyBlock: !value.retrospectivelyBlock })
  const handleChangeRetrospectivelyApply = () =>
    onChange({ ...value, retrospectivelyApply: !value.retrospectivelyApply })

  return (
    <RowDeprecated gap={2} className={cx('CommonSettings')}>
      <Checkbox
        value={autoBlock}
        onChange={handleChangeAutoBlock}
        label={
          <RowDeprecated gap={0.25}>
            <Typography variant="body1" color="black">
              Autoblock client
            </Typography>
            <BlockIcon className={cx('BlockIcon')} />
          </RowDeprecated>
        }
      />
      <Checkbox
        value={retrospectivelyApply}
        onChange={handleChangeRetrospectivelyApply}
        label={
          <RowDeprecated gap={0.25}>
            <Typography variant="body1" color="black">
              Applied in retrospect
            </Typography>
            <HistoryIcon className={cx('HistoryIcon')} />
          </RowDeprecated>
        }
      />
      <Checkbox
        value={retrospectivelyBlock}
        onChange={handleChangeRetrospectivelyBlock}
        label={
          <RowDeprecated gap={0.25}>
            <Typography variant="body1" color="black">
              Autoblock in retrospect
            </Typography>
            <HistoryBlockIcon className={cx('HistoryBlockIcon')} />
          </RowDeprecated>
        }
      />
    </RowDeprecated>
  )
}

export default CommonSettings

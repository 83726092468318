import React from 'react'

import { ReactComponent as IncidentsIcon } from '@clain/core/assets/incidents_new.svg'

import BaseSearchCard from './BaseSearchCard'
import SeverityTag from '../../../../SeverityTag'
import { IncidentDataResponse } from '../../../../../apiServices'

interface IncidentSearchCardProps {
  incident: IncidentDataResponse
  selected: boolean
}

const IncidentSearchCard: React.FC<IncidentSearchCardProps> = ({
  incident,
  selected,
}) => {
  const severity = incident.rule.severity

  const severityTag = severity && (
    <SeverityTag value={severity}>{severity}</SeverityTag>
  )

  return (
    <BaseSearchCard
      icon={<IncidentsIcon />}
      id={incident?.incident?.id}
      href={`/incidents/${incident?.incident?.id}`}
      selected={selected}
    >
      {severityTag}
    </BaseSearchCard>
  )
}

export default IncidentSearchCard

import React from 'react'
import classnames from 'classnames/bind'
import { subWeeks, subMonths } from 'date-fns'
import { Select } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

const allOptions = [
  {
    label: 'All time',
    value: 'all',
  },
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Month',
    value: 'month',
  },
]

const compareOptions = [
  {
    label: 'a week ago',
    value: 'week',
  },
  {
    label: 'a month ago',
    value: 'month',
  },
]

export type PeriodValue = 'week' | 'month'
export type PeriodAllValue = 'all' | 'week' | 'month'

const subByPeriod = {
  week: subWeeks,
  month: subMonths,
}

export const getDateFromPeriod = (period: PeriodValue) =>
  subByPeriod[period](new Date(), 1)

interface PeriodBaseProps {
  label?: string
}

interface PeriodCompareProps extends PeriodBaseProps {
  preset?: 'compare'
  value: PeriodValue
  onChange: (value: PeriodValue) => void
}

interface PeriodAllProps extends PeriodBaseProps {
  preset?: 'all'
  value: PeriodAllValue
  onChange: (value: PeriodAllValue) => void
}

const Period: React.FC<PeriodCompareProps | PeriodAllProps> = ({
  label,
  preset = 'compare',
  value,
  onChange,
}) => {
  return (
    <RowDeprecated gap={0}>
      {label && <Typography color="grey3">{label}</Typography>}
      <Select
        sameWidth={false}
        value={value}
        className={cx('Period')}
        placement="bottom-end"
        options={preset === 'compare' ? compareOptions : allOptions}
        onChange={onChange}
      />
    </RowDeprecated>
  )
}

export default Period

import React, { useEffect } from 'react'
import Incidents from '../components/Incidents'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageIncidents = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Incidents', fixedHeader: true })
  }, [])
  return <Incidents />
}

import React from 'react'
import classnames from 'classnames/bind'

import { ScoreRangeSlider } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface RuleScoreFilterProps {
  value: string
  onChange: (value: string) => unknown
  disabled: boolean
  error?: boolean
}

const RuleScoreFilter: React.FC<RuleScoreFilterProps> = ({
  value,
  onChange,
  disabled,
  error,
}) => {
  React.useEffect(() => {
    if (disabled) {
      onChange('')
    }
  }, [disabled])

  return (
    <MagicGrid
      onClick={(e) => {
        e.preventDefault()
      }}
    >
      <ScoreRangeSlider
        from={1.0}
        value={[1.0, Number(value)]}
        onChange={([, to]) => onChange(String(to))}
        disabled={disabled}
      />
      <Typography className={cx('ScoreValue')}>
        <Typography color={disabled ? 'grey5' : 'grey1'}>1.0</Typography>
        <Typography color={disabled ? 'grey5' : 'grey1'}>-</Typography>
        {
          <TextField
            value={value}
            onChange={(value) => onChange(value)}
            variant="outline"
            className={cx('ScoreValueInput')}
            disabled={disabled}
            error={error}
          />
        }
      </Typography>
    </MagicGrid>
  )
}

export default RuleScoreFilter

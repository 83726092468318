import React from 'react'
import classnames from 'classnames/bind'

import Portlet from '@clain/core/Portlet'
import { RowDeprecated } from '@clain/core/ui-kit'
import { AddFilter } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { CalendarInput } from '@clain/core/ui-kit'
import Form, { Field } from '@clain/core/Form'

import { FiltersProps, FIELDS, FieldTypes, FieldOptions } from './types'
import SearchInput from './SearchInput'
import VolumeInput from './VolumeInput'
import ScoreInput from './ScoreInput'

import styles from './index.scss'

const cx = classnames.bind(styles)

const TransferVolume = (p) => <VolumeInput label="Transfer volume:" {...p} />
const IncomingVolume = (p) => <VolumeInput label="Incoming volume:" {...p} />
const OutgoingVolume = (p) => <VolumeInput label="Outgoing volume:" {...p} />

const filterType: FieldTypes = {
  score: ScoreInput,
  transfers: TransferVolume,
  incoming: IncomingVolume,
  outgoing: OutgoingVolume,
}

const clientFitlers: FieldOptions = [
  {
    label: 'Transfers volume',
    value: FIELDS.transfers,
  },
  {
    label: 'Incoming volume',
    value: FIELDS.incoming,
  },
  {
    label: 'Outgoing volume',
    value: FIELDS.outgoing,
  },
  {
    label: 'Client score',
    value: FIELDS.score,
  },
]

const CaseFilters = ({
  defaultFiltersState,
  initialValues,
  onChange,
}: FiltersProps) => {
  const formRef = React.useRef(null)

  React.useEffect(() => {
    const unsubscribe = formRef.current?.form.subscribe(
      ({ values: { selected } }) => {
        if (selected) {
          clientFitlers
            .filter(({ value }) => !selected.includes(value))
            .forEach(({ value }) => {
              formRef.current?.form.change(value, defaultFiltersState[value])
            })
        }
      },
      { values: true }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Portlet>
      {{
        content: (
          <Form initialValues={initialValues} onChange={onChange} ref={formRef}>
            {({ form }) => {
              const formState = form.getState()
              const selected = formState.values.selected || []

              return (
                <MagicGrid>
                  <RowDeprecated>
                    <Field
                      as={AddFilter}
                      options={clientFitlers}
                      name={FIELDS.selected}
                    />
                    <Field
                      clearable
                      as={CalendarInput}
                      variant="outline"
                      name={FIELDS.created}
                      label="Created:"
                      placeholder="Select date"
                    />
                    <Field as={SearchInput} name={FIELDS.clientId} />
                  </RowDeprecated>
                  {selected.length > 0 && (
                    <RowDeprecated wrap className={cx('Filters')}>
                      {selected.map((field) => (
                        <Field
                          key={field}
                          name={field}
                          as={filterType[field]}
                        />
                      ))}
                    </RowDeprecated>
                  )}
                </MagicGrid>
              )
            }}
          </Form>
        ),
      }}
    </Portlet>
  )
}

export default CaseFilters
export type { FiltersState } from './types'

import React from 'react'

import { Header } from '@clain/core/Layout'

import { IncidentActions } from '../../IncidentActions'
import { TransactionHighlightedLink } from '../../TransactionHighlightedLink'
import { IncidentActionsProvider } from '../../IncidentActions/IncidentActions.context'
import { IncidentsDataItemNormalized } from '@compliance/types/Incident'
import { incidentViewModelFacade } from '@compliance/di/containers/IncidentViewModelFacade.container'

interface IncidentHeaderProps {
  data: IncidentsDataItemNormalized
}

export const IncidentHeader = ({ data }: IncidentHeaderProps) => {
  const actions = (
    <IncidentActionsProvider
      viewModel={incidentViewModelFacade.incidentActionsController}
    >
      <IncidentActions incident={data} variant="page" />
    </IncidentActionsProvider>
  )

  return (
    <Header
      title={'High-Risk Transfer'}
      subTitle={
        data?.transaction ? (
          <TransactionHighlightedLink
            currency={data?.transaction?.currency}
            hash={data?.transaction?.hash}
          />
        ) : (
          ''
        )
      }
      actions={actions}
    />
  )
}

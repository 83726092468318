import React from 'react'
import classnames from 'classnames/bind'

import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'

import RuleOptionsList, { RuleOptions } from './RuleOptionsList'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface CreateRuleConfirmationButtonProps {
  values: RuleOptions
  valid: boolean
  submit: () => void
  error?: string
  onModalOpen: (isOpen: boolean) => void
}

const CreateRuleConfirmationButton: React.FC<
  CreateRuleConfirmationButtonProps
> = ({ values, valid, submit, onModalOpen }) => {
  const target: ModalSlot = ({ show }) => {
    const onClick = () => {
      if (valid) {
        onModalOpen?.(true)
        show()
      } else {
        submit()
      }
    }

    return (
      <Button
        type="button"
        variant="solid"
        color="primary"
        size="lg"
        onClick={onClick}
      >
        set rule
      </Button>
    )
  }

  const body: ModalSlot = ({ hide }) => {
    const handleSubmit = () => {
      submit()
      hide()
      onModalOpen?.(true)
    }

    return (
      <Container gap={0.5}>
        <MagicGrid gap={2}>
          <Typography variant="heading5" color="black">
            Set rule
          </Typography>
          <MagicGrid>
            <Typography variant="body1" color="black">
              Press Activate to put into action the following rule:
            </Typography>
            <RuleOptionsList options={values} />
            <RowDeprecated className={cx('RuleConfirmationButtons')}>
              <Button
                variant="outline"
                color="secondary"
                size="lg"
                onClick={hide}
              >
                cancel
              </Button>
              <Button
                variant="solid"
                color="primary"
                size="lg"
                type="submit"
                onClick={handleSubmit}
              >
                activate
              </Button>
            </RowDeprecated>
          </MagicGrid>
        </MagicGrid>
      </Container>
    )
  }

  return (
    <Modal closable target={target}>
      {body}
    </Modal>
  )
}

export default CreateRuleConfirmationButton

import styled, { css } from 'styled-components'
import { createTokenKey, selectToken } from '@clainio/web-platform'

export const AiWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Hide any overflow to prevent unexpected scroll behavior */
  height: 100%;
  max-height: 100%; /* Ensure it doesn't exceed the parent container's height */

  ${({ theme }) => {
    return css({
      gap: selectToken(theme, createTokenKey(['gap', 'xl'])),
      marginBottom: selectToken(theme, createTokenKey(['padding', 'xl'])),
    })
  }}
`

export const ChatWrapper = styled.div`
  flex-grow: 1; /* Allow the ChatWrapper to grow and fill available space */
  overflow-y: auto;
  word-break: break-word;

  &::-webkit-scrollbar {
    width: 7px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`

export const ChatMessageTextAreaWrapper = styled.div`
  display: flex;
`
export const ChatMessageFullHeightContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

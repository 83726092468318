import { Container } from 'inversify'
import { IClientService } from '@compliance/apiServices/Client/ClientService.types'
import { ClientService } from '@compliance/apiServices/Client/ClientService'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'

export * from './ClientService.types'

const container = new Container({ defaultScope: 'Singleton' })

container.bind<IClientService>(DY_TYPES.ClientService).to(ClientService)

export const clientService = container.get<IClientService>(
  DY_TYPES.ClientService
)

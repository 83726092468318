import styled, { css } from 'styled-components'
import { createTokenKey, selectToken } from '@clainio/web-platform'

export const TimelineWidgetWrapper = styled.div`
  display: flex;
  flex-flow: column;
  overflow: hidden;
  flex: 1;
  overflow-y: auto;
  ${({ theme }) => {
    return css({
      marginBottom: selectToken(theme, createTokenKey(['padding', 'xl'])),
    })
  }}
`

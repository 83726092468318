import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'

import { Container } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../hooks'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface CaseActivityCardProps {
  className?: string
  icon?: React.ReactElement
  title?: string
  main?: React.ReactNode
  date?: Date
}

const CaseActivityCard: React.FC<PropsWithChildren<CaseActivityCardProps>> = ({
  className,
  icon,
  title,
  main,
  date,
  children,
}) => {
  const formatDate = useFormatDate()
  return (
    <Container className={cx('CaseActivityCard', className)}>
      <MagicGrid className={cx('CaseActivityCardBody')}>
        <div className={cx('TopBlock')}>
          <RowDeprecated className={cx('TitleBlock')} gap={0.5}>
            {React.cloneElement(icon, {
              className: cx('Icon'),
            })}
            <Typography color="purple">{title}</Typography>
          </RowDeprecated>
          {main}
          <Typography className={cx('Date')} color="grey4">
            {formatDate(date, 'time')}
          </Typography>
        </div>
        {children}
      </MagicGrid>
    </Container>
  )
}

export default observer(CaseActivityCard)

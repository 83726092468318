import React from 'react'
import { useAppCtx } from '../../ctx'

export const useSettings = () => {
  const appCtx = useAppCtx()
  const settingsController = React.useMemo(() => {
    return {
      ...appCtx.settingsController,
      ...appCtx.settingsState.settingsProfile,
      ...appCtx.settingsState.settings,
    }
  }, [appCtx])

  return settingsController
}

import {
  createTokenKey,
  selectToken,
  SurfaceContainer,
} from '@clainio/web-platform'
import styled from 'styled-components'
import { Link } from '@clain/core'

export const GraphThumbnailWidgetWrapperContainer = styled(SurfaceContainer)`
  width: 100%;
  height: 100%;
  background: transparent;
`

export const GraphThumbnailWidgetWrapper = styled.div`
  position: relative;
  border-radius: inherit;
  height: 100%;
`

export const ThumbnailImageContainer = styled.div<{ $imageUrl: string }>`
  border-radius: inherit;
  width: 100%;
  height: 100%;
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
  background-position: center;
  &:hover .overlay {
    opacity: 0.7;
    cursor: pointer;
  }
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) =>
    selectToken(theme, createTokenKey(['ocean700']))};
  opacity: 0;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
`

export const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`

import React from 'react'
import classnames from 'classnames/bind'

import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'
import Pagination from '@clain/core/Pagination'

import { CaseTable } from '../../Cases'
import VoidText from '../../VoidText'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'
import { CasesApiParams, CasesDataResponse } from '../../../apiServices/Case'

const cx = classnames.bind(styles)

interface ClientProps {
  clientId?: number | string
}

const Client: React.FC<ClientProps> = ({ clientId }) => {
  const [page, setCasesPage] = React.useState(1)
  const [sortBy, setSortBy] = React.useState('')
  const [order, setOrder] = React.useState<'asc' | 'desc' | ''>('')

  const apiParams: CasesApiParams = {
    client_id: clientId,
    page,
    sort_by: sortBy,
    sort_order: order,
  }

  const { data: casesData, isLoading } = useHttp<CasesDataResponse>(
    buildUrl`${getConfig()?.API}/api/cases?${apiParams}`
  )

  const handleChangeSort = (sortBy, order) => {
    setSortBy(sortBy)
    setOrder(order)
  }

  return (
    <>
      <CaseTable
        items={casesData?.cases}
        loading={isLoading}
        showClientId={false}
        onChangeSort={handleChangeSort}
      />
      {casesData?.cases.length === 0 && (
        <VoidText>No relevant cases for the client</VoidText>
      )}
      <Pagination
        className={cx('Pagination')}
        totalPages={casesData?.total_pages}
        value={page}
        onChange={setCasesPage}
      />
    </>
  )
}

export default Client

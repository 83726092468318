import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as InfoIcon } from '@clain/core/assets/info_outline.svg'

import { Container } from '@clain/core/ui-kit'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { Select } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { CheckboxSwitch } from '@clain/core/ui-kit'
import Form, { Field, FormActionsVertical } from '@clain/core/Form'

import SettingsHeader from '../SettingsHeader'
import styles from './index.scss'
import http from '@clain/core/http'
import { notify } from '@clain/core/ui-kit'
import { useSettings } from '../../../hooks/useSettings/useSettings'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'

const cx = classnames.bind(styles)

export const NotificationsRow = ({
  name,
  label,
  help = '',
  children = null,
  oneField = false,
}) => (
  <div className={cx('NotificationsRow')}>
    <div className={cx('SwitchRow')}>
      <Typography variant="subheading2" color="grey2">
        {label}
      </Typography>
      <RowDeprecated gap={1.5}>
        {oneField ? (
          <Field
            rtl
            as={CheckboxSwitch}
            className={cx('CheckboxSwitch')}
            name={`${name}`}
          />
        ) : (
          <>
            <Field
              rtl
              as={CheckboxSwitch}
              className={cx('CheckboxSwitch')}
              name={`${name}.inApp`}
              label={<Typography color="grey2">In-app</Typography>}
            />
            <Field
              rtl
              as={CheckboxSwitch}
              className={cx('CheckboxSwitch')}
              name={`${name}.email`}
              label={<Typography color="grey2">Email</Typography>}
            />
          </>
        )}
      </RowDeprecated>
    </div>
    {help && (
      <Typography className={cx('Help')} variant="caption1" color="grey3">
        {help}
      </Typography>
    )}
    {children && <div className={cx('Content')}>{children}</div>}
  </div>
)

const NotificationsSection = ({ title, children }) => {
  return (
    <MagicGrid gap={2.5}>
      <Typography variant="heading3" color="grey1">
        {title}
      </Typography>
      {children}
    </MagicGrid>
  )
}

const NotificationsSettings: React.FC = () => {
  const formRef = React.useRef(undefined)

  const handleSubmit = async (data) => {
    try {
      const response = await http.post(
        buildUrl`${getConfig()?.API}/api/settings/users/notifications`,
        data
      )

      if (response.data.status) {
        notify('Notification settings successfully saved', { type: 'success' })
        formRef.current?.form.restart(data)
      } else {
        throw new Error(response.data.msg || '')
      }
    } catch (e) {
      notify('An error occured while saving changes', { type: 'fail' })
    }
  }

  HeaderSlot.useContent(() => <SettingsHeader />)

  const repeatOptions = [
    {
      label: 'Every day',
      value: true,
    },
    {
      label: 'Never',
      value: false,
    },
  ]

  const { user } = useSettings()
  const crmNotifications = user.crmNotifications
  const initialValues = React.useMemo(
    () => ({
      incidents: {
        // daysLimit: crmNotifications?.incidents?.daysLimit,
        // repeat: crmNotifications?.incidents?.repeat,
        // reviewLimit: {
        //   email: crmNotifications?.incidents?.reviewLimit?.email,
        //   inApp: crmNotifications?.incidents?.reviewLimit?.inApp,
        // },
        new: {
          email: crmNotifications?.incidents?.new?.email,
          inApp: crmNotifications?.incidents?.new?.inApp,
        },
      },
      case: {
        // newLimits: {
        //   limit: crmNotifications?.case?.newLimits?.limit,
        //   repeat: crmNotifications?.case?.newLimits?.repeat,
        //   enabled: {
        //     email: crmNotifications?.case?.newLimits?.enabled?.email,
        //     inApp: crmNotifications?.case?.newLimits?.enabled?.inApp,
        //   },
        // },
        daysLeft: {
          limit: crmNotifications?.case?.daysLeft?.limit,
          repeat: crmNotifications?.case?.daysLeft?.repeat,
          enabled: {
            inApp: crmNotifications?.case?.daysLeft?.enabled?.inApp,
            email: crmNotifications?.case?.daysLeft?.enabled?.email,
          },
        },
        // statusChange: {
        //   email: crmNotifications?.case?.statusChange?.email,
        //   inApp: crmNotifications?.case?.statusChange?.inApp,
        // },
        assignment: {
          inApp: crmNotifications?.case?.assignment?.inApp,
          email: crmNotifications?.case?.assignment?.email,
        },
        overdue: {
          inApp: crmNotifications?.case?.overdue?.inApp,
          email: crmNotifications?.case?.overdue?.email,
        },
      },
      mentions: {
        inApp: crmNotifications?.mentions?.inApp,
        email: crmNotifications?.mentions?.email,
      },
    }),
    [crmNotifications]
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      className={cx('Form')}
      ref={formRef}
    >
      {({ form }) => {
        const isChanged = form.getState().dirty

        return (
          <MagicGrid gap={3.25}>
            <Typography variant="heading2" color="black">
              Notifications
            </Typography>

            <NotificationsRow label="Mentions" name="mentions" />

            <NotificationsSection title="Incidents">
              <NotificationsRow label="New incident" name="incidents.new" />
              {/* <NotificationsRow
                label='Time limit for an incident review'
                name='incidents.reviewLimit'
                help='Setup limit of time for initiating a case or dismissing an incident that will be reflected in the system.'
              >
                <RowDeprecated gap={2}>
                  <Label content='Days limit'>
                    <Field
                      as={TextField}
                      name='incidents.daysLimit'
                      variant='outline'
                      type='number'
                      min={1}
                      defaultValue='1'
                      className={cx('Input')}
                    />
                  </Label>
                  <Label content='Repeat notification'>
                    <Field
                      as={Select2}
                      name='incidents.repeat'
                      variant='outline'
                      defaultValue={true}
                      className={cx('Select')}
                      options={repeatOptions}
                    />
                  </Label>
                </RowDeprecated>
              </NotificationsRow> */}
            </NotificationsSection>

            <NotificationsSection title="Cases">
              <NotificationsRow
                label="Case overdue"
                name="case.overdue"
                help="When the status of your case is changed."
              />
              {/* <NotificationsRow
                label='Not initiated investigation'
                name='case.newLimits.enabled'
                help='Limit of time when the case was open, but Due Date and Assignee are still not set. After expiration this time you will be notified.'
              >
                <RowDeprecated gap={2}>
                  <Label content='Days limit'>
                    <Field
                      as={TextField}
                      name='case.newLimits.limit'
                      variant='outline'
                      type='number'
                      min={1}
                      defaultValue='1'
                      className={cx('Input')}
                    />
                  </Label>
                  <Label content='Repeat notification'>
                    <Field
                      as={Select2}
                      name='case.newLimits.repeat'
                      variant='outline'
                      defaultValue={true}
                      className={cx('Select')}
                      options={repeatOptions}
                    />
                  </Label>
                </RowDeprecated>
              </NotificationsRow> */}
              <NotificationsRow
                label="New case assignment"
                name="case.assignment"
                help="Receive notifications for the cases assigned to you"
              />
              {/* <NotificationsRow
                label='Case status change'
                name='case.statusChange'
                help='When the status of your case is changed.'
              /> */}
              <NotificationsRow
                label="Days left for the case processing"
                name="case.daysLeft.enabled"
                help="Set a number of days before the case deadline, after expiration which you will be notified. "
              >
                <RowDeprecated gap={2}>
                  <Label content="Days left">
                    <Field
                      as={TextField}
                      name="case.daysLeft.limit"
                      variant="outline"
                      type="number"
                      min={1}
                      defaultValue="14"
                      className={cx('Input')}
                    />
                  </Label>
                  <Label content="Repeat notification">
                    <Field
                      as={Select}
                      name="case.daysLeft.repeat"
                      variant="outline"
                      defaultValue={true}
                      className={cx('Select')}
                      options={repeatOptions}
                    />
                  </Label>
                </RowDeprecated>
              </NotificationsRow>
            </NotificationsSection>

            <div className={cx('FormActions')}>
              <FormActionsVertical>
                <InfoPanel visible={isChanged} />
                <Button type="submit" variant="solid" color="primary" size="lg">
                  Save settings
                </Button>
              </FormActionsVertical>
            </div>
          </MagicGrid>
        )
      }}
    </Form>
  )
}

export default NotificationsSettings

const InfoPanel = ({ visible }) => (
  <Container className={cx('InfoPanel', { visible })} gap={0.5}>
    <RowDeprecated gap={0.75}>
      <InfoIcon />
      <Typography variant="body3">
        You have some usaved changes on this page.
      </Typography>
    </RowDeprecated>
  </Container>
)

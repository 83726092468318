import { Group } from 'pts'
import { IController, IView } from '../../types'

const DEFAULT_FONT_FAMILY = 'Inter'
const DEFAULT_FONT_SIZE = '14'
const DEFAULT_FONT_WEIGHT = '400'
const DEFAULT_COLOR = '#0F131B'
const DEFAULT_ALIGN = 'center'
const DEFAULT_VERTICAL_ALIGN = 'bottom'

const DEFAULT_TEXT_ITEM = {
  value: '',
  fontSize: DEFAULT_FONT_SIZE,
  fontFamily: DEFAULT_FONT_FAMILY,
  fontWeight: DEFAULT_FONT_WEIGHT,
  color: DEFAULT_COLOR,
}

interface TextItem {
  value: string
  fontSize?: string // FontSize
  fontFamily?: string // FontFamily
  fontWeight?: string
  color?: string // Color
}

export interface TypographyProps {
  topLeft: [number, number]
  width: number
  height: number
  text: Array<TextItem>
  align?: 'left' | 'center' | 'right'
  verticalAlign?: 'top' | 'middle' | 'bottom'
}

class Typography implements IView {
  public ctrl: IController

  public props: TypographyProps

  public textWidth: Array<number>
  public textTotalWidth: number
  public textHeight: Array<number>
  public textMaxHeight: number
  public layout: Group

  get form() {
    return this.ctrl.form
  }

  get space() {
    return this.ctrl.space
  }

  constructor(ctrl: IController) {
    this.ctrl = ctrl
  }

  init(props: TypographyProps) {
    this.setPropsWithDefaults(props)
    this.calculateTextWidthAndHeight()
  }

  render() {
    let currentWidth = 0

    this.props.text.forEach((textItem, index) => {
      const [x0, y0] = this.getX0Y0(this.textHeight[index])

      this.form
        .fill(textItem.color)
        .font(Number(textItem.fontSize), textItem.fontWeight)
        .text([x0 + currentWidth, y0], textItem.value)

      currentWidth += this.textWidth[index]
    })
  }

  protected calculateTextWidthAndHeight() {
    this.textWidth = []
    this.textHeight = []
    this.props.text.forEach((textItem) => {
      const [width, height] = this.calculateTextItemWidthAndHeight(textItem)
      this.textWidth.push(width)
      this.textHeight.push(height)
    })
    this.textTotalWidth = this.textWidth.reduce((total, width) => total + width)
    this.textMaxHeight = Math.max(...this.textHeight)
  }

  protected calculateTextItemWidthAndHeight(text: TextItem) {
    const element = document.createElement('span')

    element.style.fontFamily = text.fontFamily
    element.style.fontSize = `${text.fontSize}px`
    element.style.fontWeight = `${text.fontWeight}px`

    element.style.position = 'absolute'
    element.style.left = '-10000px'
    element.style.whiteSpace = 'nowrap'
    element.style.lineHeight = '100%'

    document.body.appendChild(element)

    element.innerHTML = text.value.replaceAll(' ', '&nbsp;')

    const result = [
      element.getBoundingClientRect().width,
      element.getBoundingClientRect().height,
    ]

    document.body.removeChild(element)

    return result
  }

  protected getX0Y0(textItemHeight: number) {
    let x0 = this.props.topLeft[0]
    let y0 = this.props.topLeft[1]

    if (this.props.align === 'right') {
      x0 = x0 + this.props.width - this.textTotalWidth
    }

    if (this.props.align === 'center') {
      x0 = x0 + (this.props.width - this.textTotalWidth) / 2
    }

    if (this.props.verticalAlign === 'bottom') {
      y0 = y0 + this.props.height - textItemHeight
    }

    if (this.props.verticalAlign === 'middle') {
      y0 = y0 + (this.props.height - textItemHeight) / 2
    }

    return [x0, y0]
  }

  protected setPropsWithDefaults(props: TypographyProps) {
    const { text, ...otherProps } = props
    this.props = {
      align: DEFAULT_ALIGN,
      verticalAlign: DEFAULT_VERTICAL_ALIGN,
      ...otherProps,
      text: text.map((textItem) => ({
        ...DEFAULT_TEXT_ITEM,
        ...textItem,
      })),
    }
  }
}

export default Typography

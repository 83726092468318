import React from 'react'
import classnames from 'classnames/bind'

import { UnixTimestamp } from '@clain/core/types'
import { Typography } from '@clain/core/ui-kit'

import ScoreHistoryChart from '../Chart/scoreHistory'

import styles from './index.scss'
import { useSettings } from '../../hooks'
import { ScoreHistory } from '../../apiServices/Client/ClientService.types'
import { observer } from 'mobx-react-lite'

const cx = classnames.bind(styles)

interface ClientScoreTimelineProps {
  data: ScoreHistory | undefined
  className?: string
}

const reduceScoreData = (data: ScoreHistory, threshold = 0.5) => {
  return data.reduce((acc, item) => {
    if (!acc.length) {
      return [...acc, item]
    }

    const prev = acc[acc.length - 1]

    if (Math.abs(prev[1] - item[1]) >= threshold) {
      return [...acc, item]
    }

    return acc
  }, [])
}

const insertCurrentScorePoint = (data) =>
  data?.length ? [...data, [Date.now(), data[data.length - 1][1]]] : []

const ClientScoreTimeline: React.FC<ClientScoreTimelineProps> = ({
  className,
  data,
}) => {
  const { settings } = useSettings()

  // тут "особенные" данные и стандартные функции нормализации не подходят
  const normalizedData = React.useMemo(
    (): Array<[UnixTimestamp, number]> =>
      data?.length ? data.map(([score, time]) => [time * 1000, score]) : [],
    [data]
  )

  let view = 'loading'

  if (data?.length === 0) {
    view = 'empty'
  }

  if (data?.length) {
    view = 'chart'
  }

  let treshold
  const parsedtreshold = parseFloat(settings.threshold)
  if (isNaN(parsedtreshold)) {
    treshold = 0.5
  } else {
    treshold = parsedtreshold
  }

  return (
    <div className={cx('ClientScoreTimeline', className)}>
      <Typography variant="subheading2" color="grey3">
        Score timeline
      </Typography>
      {view === 'empty' && (
        <Typography variant="heading2" color="grey4" className={cx('Stub')}>
          There is no score data yet
        </Typography>
      )}
      {view === 'chart' && (
        <ScoreHistoryChart
          data={insertCurrentScorePoint(
            reduceScoreData(normalizedData, treshold)
          )}
        />
      )}
    </div>
  )
}

export default observer(ClientScoreTimeline)

import React from 'react'
import classnames from 'classnames/bind'

import http from '@clain/core/http'
import { Modal, ModalSlot, RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import Editor from '@clain/core/Editor'
import { Alert } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import Form, {
  FORM_ERROR,
  Field,
  FieldError,
  FormActionsVertical,
} from '@clain/core/Form'

import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'
import { ReactComponent as BlockIcon } from '@clain/core/assets/block.svg'

import styles from './index.scss'
import { BlockClientResponse } from './BlockClient.types'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'

const cx = classnames.bind(styles)

const blockClient = async (id, data) => {
  return http.post(buildUrl`${getConfig()?.API}/api/clients/${id}/block`, data)
}

const BlockClientButton = ({ onClick }: { onClick: () => void }) => (
  <RowDeprecated className={cx('BlockClientButton')} gap={0.5} onClick={onClick}>
    <Typography transform="uppercase" variant="caption1" color="magenta">
      block client
    </Typography>
    <CloseIcon />
  </RowDeprecated>
)

interface BlockClientFormProps {
  id: string
  onSubmitSuccess: (data: BlockClientResponse) => void
  onCancel: () => void
}

const BlockClientForm: React.FC<BlockClientFormProps> = ({
  id,
  onSubmitSuccess,
  onCancel,
}) => {
  const handleSubmit = async (data) => {
    const response = await blockClient(id, data)

    if (!response.data.status) {
      return {
        [FORM_ERROR]: response.data.msg,
      }
    }

    onSubmitSuccess(response.data)
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({ form }) => {
        const { submitting, submitError } = form.getState()

        return (
          <>
            <MagicGrid gap={2}>
              <Typography variant="heading5">Block client</Typography>

              <MagicGrid>
                <Typography>
                  The client #{id} will be blocked and will not able to produce
                  any transactions via all related addresses.
                </Typography>

                <Label
                  size="lg"
                  content={
                    <Alert>
                      Currently it is not possible to unblock client via CRM.
                    </Alert>
                  }
                >
                  <Field as={Editor} name="comment" variant="outline" />
                  <FieldError name="comment" />
                </Label>
              </MagicGrid>

              <FormActionsVertical>
                {submitError && (
                  <Typography color="magenta">{submitError}</Typography>
                )}
                <RowDeprecated gap={0.5}>
                  <Button
                    size="lg"
                    type="button"
                    variant="outline"
                    color="secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="lg"
                    // pristine докидываем, чтобы не моргало при первом рендере
                    // т.к. знаем что форму полюбому надо заполнить
                    disabled={submitting}
                    endCustomIcon={BlockIcon}
                    variant="solid"
                    color="critical"
                  >
                    Block client
                  </Button>
                </RowDeprecated>
              </FormActionsVertical>
            </MagicGrid>
          </>
        )
      }}
    </Form>
  )
}

interface BlockClientProps {
  id: string
  onSubmitSuccess: (data: BlockClientResponse) => void
}

const BlockClient = ({ id, onSubmitSuccess }: BlockClientProps) => {
  const target: ModalSlot = ({ show }) => {
    return <BlockClientButton onClick={show} />
  }

  const body: ModalSlot = ({ hide }) => (
    <BlockClientForm
      id={id}
      onSubmitSuccess={onSubmitSuccess}
      onCancel={hide}
    />
  )

  return (
    <Modal closable target={target}>
      {body}
    </Modal>
  )
}

export default BlockClient

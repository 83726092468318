import React from 'react'

import useHttp from '@clain/core/useHttp'

import { Rule, RulesData } from '../../../types/Rule'
import { CommonSettings } from './Filters/types'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'

const URL = buildUrl`${getConfig()?.API}/api/rules`

const severityWeight = {
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
}

const directionWeight = {
  out: 1,
  in: 2,
  both: 3,
}

export interface RequestParams {
  groupBy: string
  severity: Array<string>
  amountFrom: number
  amountTo: number
  direction: 'in' | 'out' | 'both'
  ruleType: Array<string>
  commonSettings: CommonSettings
  isArchivedRules: boolean
}

const useRules = ({
  groupBy,
  severity,
  amountFrom,
  amountTo,
  direction,
  ruleType,
  commonSettings,
  isArchivedRules,
}: Partial<RequestParams>) => {
  const { data, mutate, isLoading } = useHttp<RulesData>(URL)

  const groupedData = React.useMemo(() => {
    if (groupBy === 'type') {
      const items = data?.rules ? Array.from(data.rules) : undefined
      return items?.sort(({ type: a }, { type: b }) => {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      })
    }

    if (groupBy === 'severity') {
      const items = data?.rules ? Array.from(data.rules) : undefined
      return items?.sort(
        ({ severity: a }, { severity: b }) =>
          severityWeight[b] - severityWeight[a]
      )
    }

    if (groupBy === 'direction') {
      const items = data?.rules ? Array.from(data.rules) : undefined
      return items?.sort(
        ({ direction: a }, { direction: b }) =>
          directionWeight[b] - directionWeight[a]
      )
    }

    return data?.rules
  }, [data, groupBy])

  const filteredBySeverityData = React.useMemo(() => {
    if (severity?.length) {
      return groupedData?.filter(({ severity: s }) =>
        severity.includes(s.toLowerCase())
      )
    }

    return groupedData
  }, [groupedData, severity])

  const filteredByRiskAmountData = React.useMemo(() => {
    if (!amountFrom && !amountTo) return filteredBySeverityData
    const from = amountFrom || 0
    const to = amountTo || Infinity

    return filteredBySeverityData?.filter(
      ({ usd_amount }) => usd_amount >= from && usd_amount < to
    )
  }, [filteredBySeverityData, amountFrom, amountTo])

  const filteredByDirectionData = React.useMemo(() => {
    if (direction) {
      return filteredByRiskAmountData?.filter(
        ({ direction: d }) => direction === d
      )
    }
    return filteredByRiskAmountData
  }, [filteredByRiskAmountData, direction])

  const filteredByRuleTypeData = React.useMemo(() => {
    if (ruleType?.length) {
      return filteredByDirectionData?.filter(({ type: t }) =>
        ruleType.includes(t)
      )
    }
    return filteredByDirectionData
  }, [filteredByDirectionData, ruleType])

  const filteredByCommonSettingsData = React.useMemo(() => {
    const commonSettingsValues = Object.values(commonSettings)

    if (commonSettingsValues.some((v) => v)) {
      return filteredByRuleTypeData?.filter(
        ({ block_client, block_old_clients, run_retrospectivly }) => {
          let result = true

          if (commonSettings.autoBlock) {
            result = result && block_client
          }

          if (commonSettings.retrospectivelyApply) {
            result = result && run_retrospectivly
          }

          if (commonSettings.retrospectivelyBlock) {
            result = result && block_old_clients
          }

          return result
        }
      )
    }
    return filteredByRuleTypeData
  }, [filteredByRuleTypeData, commonSettings])

  const filteredByHiddenData = React.useMemo(() => {
    if (isArchivedRules) {
      return filteredByCommonSettingsData?.filter(({ hidden }) => hidden)
    } else {
      return filteredByCommonSettingsData?.filter(({ hidden }) => !hidden)
    }
  }, [filteredByCommonSettingsData, isArchivedRules])

  const mutateRules = (newRules: Array<Rule>) => {
    mutate({ rules: newRules })
  }

  return {
    rules: filteredByHiddenData,
    mutateRules,
    isLoading,
  }
}

export default useRules

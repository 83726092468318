import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as PlusIcon } from '@clain/core/assets/plus.svg'

import useHttp from '@clain/core/useHttp'
import { Typography } from '@clain/core/ui-kit'
import { AvatarMarkup } from '@clain/core/ui-kit'
import { Avatar } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { Tooltip } from '@clain/core/ui-kit'

import { CaseAssignee } from '../../types/Case'

import styles from './index.scss'
import { Color } from '@clain/core/styles/colors'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'
import { PopoverBaseContainer, Stack } from '@clainio/web-platform'

const cx = classnames.bind(styles)

type UserItemProps = {
  name: string
  text?: string
  avatar: string
  bordered?: boolean
  borderColor?: Color
  selected: boolean
  onClick?: () => void
}

export const UserItem: React.FC<UserItemProps> = ({
  name,
  text,
  avatar,
  bordered,
  borderColor,
  selected,
  onClick,
}) => {
  return (
    <div className={cx('UserItem', { selected })} onClick={onClick}>
      <Avatar
        src={avatar}
        bordered={bordered}
        borderColor={borderColor}
        name={name}
      />
      <Typography className={cx('Label')}>{name}</Typography>
      {text && (
        <Typography className={cx('Label')} color="grey5">
          {text}
        </Typography>
      )}
    </div>
  )
}

interface CaseAssigneeFilterProps {
  value?: number
  label?: string
  onChange?: (value: number | null) => void
  className?: string
  disabled?: boolean
  tooltip?: string
}

const CaseAssigneeFilter = ({
  value,
  label,
  className,
  disabled,
  tooltip,
  onChange,
}: CaseAssigneeFilterProps) => {
  const { data: users = [] } = useHttp<Array<CaseAssignee>>(
    buildUrl`${getConfig()?.API}/api/cases/assignees`
  )

  const selectedUser = users.find((u) => u.id == value)

  const content = (
    <Stack
      direction="column"
      gap={'xl'}
      fullWidth={false}
      padding={['xl', 'none']}
    >
      <Stack padding={['none', 'xl']} justify="center">
        <Button
          variant="outline"
          color="secondary"
          size="lg"
          disabled={!selectedUser}
          onClick={() => onChange(null)}
        >
          clear filter
        </Button>
      </Stack>
      <div>
        {users.map((u) => (
          <UserItem
            key={u.id}
            avatar={u.avatar}
            name={u.name}
            selected={value == u.id}
            onClick={() => value !== u.id && onChange(u.id)}
          />
        ))}
      </div>
    </Stack>
  )

  const target = (
    <div className={cx('CaseAssigneeFilter', { disabled }, className)}>
      {label && <Typography className={cx('Label')}>{label}</Typography>}
      {selectedUser ? (
        <Avatar
          disabled={disabled}
          src={selectedUser.avatar}
          name={selectedUser.name}
        />
      ) : (
        <AvatarMarkup dashed disabled={disabled}>
          <PlusIcon />
        </AvatarMarkup>
      )}
    </div>
  )

  if (disabled) {
    return <Tooltip content={tooltip}>{target}</Tooltip>
  }

  return (
    <PopoverBaseContainer
      content={content}
      placement="bottom-end"
      anchor={target}
      maxHeight={440}
    />
  )
}

export default CaseAssigneeFilter

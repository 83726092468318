import React from 'react'
import { Timezone } from '@clain/core/Timezone'
interface Timezone {
  name: string
  utcOffset: number
  utcOffsetStr: string
}

type Timezones = Array<Timezone>

interface SystemTimeDropdownProps {
  value: string // timezone name
  onChange: (value: string) => void
  timezones: Timezones
  disabled?: boolean
}

const SystemTimeDropdown: React.FC<SystemTimeDropdownProps> = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <Timezone timezone={value} updateTimezone={onChange} disabled={disabled} />
  )
}

export default SystemTimeDropdown

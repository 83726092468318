import React from 'react'

import { HeaderSlot } from '@clain/core/Layout/slots'
import { generatePath, useNavigate } from '@clain/core/Router/router'
import {
  FEED_ACTIVITY_TYPE,
  FeedActivity,
  FeedStatusChangedActivity,
} from '../../types/Case'
import CaseStatusAlert from '../CaseStatusAlert'
import { CaseHeader } from './CaseHeader'
import { ButtonLink, Stack, SurfaceContainer } from '@clainio/web-platform'
import * as S from '../Layout/CasePageLayout.styles'
import { TransactionInfoWidget } from '../Widgets/TransactionInfoWidget'
import { GraphThumbnailWidget } from '../Widgets/GraphThumbnailWidget'
import { RulesTriggeredWidget } from '../Widgets/RulesTriggeredWidget'
import { CaseSidebar } from './CaseSidebar'
import { TransactionHighlightedLink } from '../TransactionHighlightedLink'
import { CaseDetailsTabs } from './CaseDetailsTabs'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import {
  SnakeToCamelCaseMapping,
  SnakeToCamelCaseObject,
} from '@clain/core/utilsTypes'
import { CaseActionsProvider } from './CaseActions.context'
import { caseViewModelFacade } from '@compliance/di/containers/CaseViewModelFacade.container'

const Case = observer(({ id }: { id: number }) => {
  const {
    init,
    clear,
    isInitialized,
    caseData: mobxData,
    isFirstLoading: isCaseFirstLoading,
    incidentData: mobxIncidentData,
    assignees: mobxAssigneesData,
    activityData: mobxActivityDataData,
    caseActionsController,
    rules,
  } = caseViewModelFacade
  const data = toJS(mobxData)
  const incident = toJS(mobxIncidentData)
  const assignees = toJS(mobxAssigneesData)
  const activityData = toJS(mobxActivityDataData)

  React.useEffect(() => {
    if (id != null) {
      init(id)
      return () => {
        clear()
      }
    }
  }, [id])
  const navigate = useNavigate()

  React.useEffect(() => {
    if (data?.status === 'new') {
      navigate(generatePath('/cases/:id/start', { id: id?.toString() }))
    }
  }, [data, id])

  HeaderSlot.useContent(
    () => (
      <CaseActionsProvider viewModel={caseActionsController}>
        <CaseHeader
          id={id}
          data={data}
          loading={isCaseFirstLoading}
          assignee={data?.assignee}
          assignees={assignees}
        />
      </CaseActionsProvider>
    ),
    [id, data, assignees, isCaseFirstLoading, caseActionsController]
  )

  if (!isInitialized) {
    return null
  }

  const reviewComment = activityData?.feed?.find(
    (
      a: SnakeToCamelCaseMapping<FeedActivity>
    ): a is SnakeToCamelCaseObject<FeedStatusChangedActivity> =>
      a.type === FEED_ACTIVITY_TYPE.TO_PENDING
  )?.activity.comment

  return (
    <S.PageContainer $top={142}>
      <S.GridContainer>
        <S.MainContent gap={'xl'} direction={'column'}>
          {data?.status === 'pending' && reviewComment && (
            <CaseStatusAlert
              name={data?.assignee.name}
              status={data?.status}
              recommendation={data?.recommendCloseAs}
              comment={reviewComment}
            />
          )}
          <SurfaceContainer borderRadius={'md'} spaces={['xl', 'xxl']}>
            <Stack align={'center'} justify={'space-between'} gap={'lg'}>
              {incident?.transaction ? (
                <TransactionHighlightedLink
                  currency={incident?.transaction?.currency}
                  hash={incident?.transaction?.hash}
                  typographyVariant={'body100NormalCode'}
                />
              ) : (
                ''
              )}
              <ButtonLink
                target={'_blank'}
                variant={'text'}
                size={'md'}
                color={'secondary'}
                endIcon={'BoltIncident'}
                to={`/incidents/${incident?.id}`}
              >
                transfer page
              </ButtonLink>
            </Stack>
          </SurfaceContainer>
          <Stack gap={'xxl'}>
            <S.Section>
              <TransactionInfoWidget
                isLoading={isCaseFirstLoading}
                token={incident?.transaction?.token}
                type={
                  incident?.transaction?.isDeposit ? 'incoming' : 'outgoing'
                }
                amountUsd={incident?.transaction?.amountUsd}
                amount={incident?.transaction?.amount}
                time={incident?.transaction?.time}
                rule={{
                  name: incident?.rule?.name,
                  severity: incident?.rule?.severity,
                  type: incident?.rule?.type,
                  scoreAmount: incident?.rule?.scoreAmount,
                  percentage: incident?.rule?.percentage,
                }}
              />
            </S.Section>
            <S.Section>
              <GraphThumbnailWidget probeId={data?.probeId} />
            </S.Section>
          </Stack>
          <RulesTriggeredWidget isLoading={isCaseFirstLoading} rules={rules} />
          <CaseDetailsTabs />
        </S.MainContent>
        <CaseSidebar assignees={assignees} feed={activityData?.feed} />
      </S.GridContainer>
    </S.PageContainer>
  )
})

export default Case

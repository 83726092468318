import { action, makeObservable, computed, observable } from 'mobx'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import { SettingsProfileCRMRaw, SettingsRaw } from '@clain/api/endpoint'

export class SettingsState {
  @observable
  public settingsProfile: SnakeToCamelCaseObject<SettingsProfileCRMRaw> = {
    user: {},
  } as SnakeToCamelCaseObject<SettingsProfileCRMRaw>

  @observable
  public settings: SnakeToCamelCaseObject<SettingsRaw> =
    {} as SnakeToCamelCaseObject<SettingsRaw>

  public static instance: SettingsState

  public constructor() {
    makeObservable(this)
  }

  public static getInstance(): SettingsState {
    if (!SettingsState.instance) {
      SettingsState.instance = new SettingsState()
    }
    return SettingsState.instance
  }

  @computed public get getSettingsProfile() {
    return this.settingsProfile
  }

  @computed public get getSettings() {
    return this.settings
  }

  @action.bound
  public setSettingsProfile = (data: typeof this.settingsProfile) => {
    this.settingsProfile = data
  }

  @action.bound
  public setSettings = (data: typeof this.settings) => {
    this.settings = data
  }
}

export const settingsState = SettingsState.getInstance()

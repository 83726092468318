import classnames from 'classnames/bind'
import styles from './ClientWidget.scss'
import { useFormatDate, useSettings } from '../../../hooks'
import { fromUnixTime } from 'date-fns'
import { Stack } from '@clainio/web-platform'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
import {
  ActivityPeriod,
  ColDeprecated,
  Container,
  CopyIcon,
  MagicGrid,
  RowDeprecated,
  Score,
  Typography,
} from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import ClientInfoCard from '../../InfoCard/ClientInfoCard'
import ClientActions from '../../ClientActions'
import ClientScoreTimeline from '../../ClientScoreTimeline'
import ClientExposure from '../../Exposure/ClientExposure'
import React, { FC } from 'react'
import { ClientWidgetProps } from './ClientWidget.types'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

export const ClientWidget: FC<ClientWidgetProps> = observer(
  ({
    data,
    isFirstLoading,
    relatedIncidentsCount,
    relatedCasesCount,
    onRelatedIncidentsClick,
    onRelatedCasesClick,
  }) => {
    const { firstTransaction: firstTransactionSettings, settings } =
      useSettings()

    const firstTransaction = firstTransactionSettings
      ? fromUnixTime(firstTransactionSettings)
      : null
    const formatDate = useFormatDate()

    return (
      <Stack direction={'column'} gap={'xl'}>
        <ErrorBoundaryContainer>
          <div className={cx('ClientTab')}>
            <Container gap={1.25} className={cx('ClientTabItem')}>
              <ColDeprecated gap={2}>
                <RowDeprecated>
                  <Typography variant="heading3" color="grey1">
                    ID
                  </Typography>
                  <RowDeprecated>
                    <RowDeprecated gap={0.25}>
                      <Link
                        className={cx('ClientId')}
                        to={`/clients/${data?.client?.id}`}
                      >
                        <Typography variant="heading4">
                          #{data?.client.id}
                        </Typography>
                      </Link>
                      <CopyIcon value={data?.client?.id} />
                    </RowDeprecated>
                    <Score value={data?.client?.score} />
                  </RowDeprecated>
                </RowDeprecated>
                {data?.client && (
                  <ActivityPeriod
                    timeZone={settings?.timeZone}
                    today={formatDate(new Date(), 'date')}
                    activityPeriodStart={firstTransaction}
                    activityPeriod={
                      [
                        fromUnixTime(data?.client.firstSeen),
                        fromUnixTime(data?.client.lastSeen),
                      ] as [Date, Date]
                    }
                  />
                )}
                <ClientInfoCard
                  loading={isFirstLoading}
                  client={data?.client}
                  relatedIncidentsCount={relatedIncidentsCount}
                  relatedCasesCount={relatedCasesCount}
                  onRelatedIncidentsClick={onRelatedIncidentsClick}
                  onRelatedCasesClick={onRelatedCasesClick}
                />
              </ColDeprecated>
            </Container>
            <Container gap={1.25} className={cx('ClientTabItem')}>
              <MagicGrid gap={0.5}>
                {data?.client && (
                  <ClientActions
                    showStatus
                    id={data.client.id}
                    status={data.client.blocked ? 'blocked' : 'active'}
                  />
                )}
                <ClientScoreTimeline data={data?.scoreHistory} />
              </MagicGrid>
            </Container>
          </div>
        </ErrorBoundaryContainer>
        <ErrorBoundaryContainer>
          <ClientExposure mode="compact" data={data?.exposure} />
        </ErrorBoundaryContainer>
      </Stack>
    )
  }
)

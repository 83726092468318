import React from 'react'

import { TextField } from '@clain/core/ui-kit'

import { FieldProps } from './types'

const SearchInput = ({ value, onChange }: FieldProps<string>) => {
  return (
    <TextField
      fullWidth
      clearable
      value={value}
      autoComplete="off"
      variant="outline"
      name="search"
      placeholder="Search by Transaction hash"
      startAddon={{ iconVariant: 'Search' }}
      onChange={onChange}
    />
  )
}

export default SearchInput

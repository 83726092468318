import React from 'react'
import classnames from 'classnames/bind'
import download from 'downloadjs'
import { formatDate, fromUnixTime } from 'date-fns'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import http from '@clain/core/http'

import { ReactComponent as UsersIcon } from '@clain/core/assets/users_alt.svg'
import { ReactComponent as CSVIcon } from '@clain/core/assets/csv.svg'

import {
  useNavigate,
  useObjectFormattedSearchParams,
  generatePath,
} from '@clain/core/Router/router'
import evolve from '@clain/core/utils/evolve'
import useHttp from '@clain/core/useHttp'
import buildUrl, { clearParams } from '@clain/core/utils/buildUrl'
import { useFormatDate, useTranslateDate } from '../../hooks'
import { formatMoney, formatNumber } from '@clain/core/utils/format'
import { Score } from '@clain/core/ui-kit'
import StatusDot from '../StatusDot'
import { MagicGrid } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import Portlet from '@clain/core/Portlet'
import Pagination from '@clain/core/Pagination'
import PaginationSize from '../PaginationSize'
import { Tooltip } from '@clain/core/ui-kit'
import { CounterTabs, CounterTabPropsOption } from '@clain/core/ui-kit'
import { HeaderSlot } from '@clain/core/Layout/slots'
import Header from '@clain/core/Layout/Header'
import { formatDurationLifetime } from '@clain/core/utils/formatDuration'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { useDebounceValue } from '@clain/core/useDebounce'
import {
  Table2 as Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  createColumns,
} from '@clain/core/ui-kit'
import type { ScoreRange } from '@clain/core/types/ScoreRange'

import { Volume } from './Filters/types'
import { ClientsData, ClientsApiParams } from '../../types/Client'
import Filters, { FiltersState } from './Filters'
import VoidText from '../VoidText'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

const DEFAULT_PAGINATION_SIZE = 10
const DEBOUNCE_IN_MS = 500

const tabStub = {
  all: 'No clients imported to CRM.',
  active: 'No active clients.',
  blocked: 'No blocked clients.',
}

interface Client {
  external_id: string
  created_at: number | null
  first_seen: number | null
  last_seen: number | null
  score: number
  volume: number
  blocked: boolean
  transactions: number
  total_received: string
  total_sent: string
}

interface ClientsTableProps {
  loading?: boolean
  items?: Client[]
  sortBy: string
  order: 'asc' | 'desc'
  onChangeSort: (sortBy: string, order: 'asc' | 'desc') => void
}

const ClientsTable = observer(
  ({ loading, items, sortBy, order, onChangeSort }: ClientsTableProps) => {
    const nav = useNavigate()

    const formatDate = useFormatDate()

    const { columns, columnsData } = createColumns<Client>(
      items,
      [
        {
          title: 'Client',
          field: 'client_id',
          sortable: false,
          render(item) {
            return (
              <RowDeprecated gap={0.5}>
                <StatusDot status={item.blocked ? 'blocked' : 'active'} />
                <Link
                  className={cx('ClientIdLink')}
                  to={`/clients/${item.external_id}`}
                >
                  {item.external_id}
                </Link>
              </RowDeprecated>
            )
          },
        },
        {
          title: 'Client score',
          field: 'score',
          sortable: true,
          render(item) {
            return item.score ? <Score value={item.score} /> : null
          },
        },
        {
          title: 'Transfers volume',
          field: 'volume',
          type: 'number',
          sortable: true,
          render(item) {
            const volume = item.volume
              ? formatMoney({
                  value: item.volume || 0,
                  currency: 'usd',
                  code: '',
                })
              : '0'

            return <Typography>{volume}</Typography>
          },
        },
        {
          title: 'Transfers count',
          field: 'transfers_count',
          type: 'number',
          sortable: true,
          render(item) {
            return formatNumber(item.transactions, 0)
          },
        },
        {
          title: 'Total received',
          field: 'total_received',
          type: 'number',
          sortable: true,
          render(item) {
            return formatMoney({
              value: item.total_received || 0,
              currency: 'usd',
              code: '',
            })
          },
        },
        {
          title: 'Total sent',
          field: 'total_sent',
          type: 'number',
          sortable: true,
          render(item) {
            return formatMoney({
              value: item.total_sent || 0,
              currency: 'usd',
              code: '',
            })
          },
        },
        {
          title: 'Created',
          field: 'first_seen',
          type: 'number',
          sortable: true,
          render(item) {
            return item.created_at
              ? formatDate(fromUnixTime(item.created_at))
              : 'N/A'
          },
        },
        {
          title: 'Last activity',
          field: 'last_seen',
          type: 'number',
          sortable: true,
          render(item) {
            return item.last_seen
              ? formatDate(fromUnixTime(item.last_seen))
              : ''
          },
        },
        {
          title: 'Activity period',
          field: 'activity_period',
          type: 'number',
          sortable: true,
          render(item) {
            if (!item.first_seen || !item.last_seen) {
              return 'N/A'
            }

            const firstSeen = new Date(item.first_seen * 1000)
            const lastSeen = new Date(item.last_seen * 1000)

            const duration = formatDurationLifetime(
              lastSeen.getTime() - firstSeen.getTime()
            )
            const period = `${formatDate(firstSeen, {
              year: 'numeric',
              month: 'short',
            })} — ${formatDate(lastSeen, {
              year: 'numeric',
              month: 'short',
            })}`

            if (!duration) {
              return '1 day'
            }

            return (
              <Tooltip content={period}>
                <Typography
                  className={cx('ActivityPeriod', { withTooltip: true })}
                >
                  {duration}
                </Typography>
              </Tooltip>
            )
          },
        },
      ],
      {
        stubHeight: 55.5,
        stubAmount: DEFAULT_PAGINATION_SIZE,
      }
    )

    return (
      <>
        <Table loading={loading}>
          <Thead>
            <Tr>
              {columns.map(({ title, field, type, sortable = false }) => (
                <Th
                  key={field}
                  type={type}
                  className={cx(field)}
                  sortable={sortable}
                  order={order}
                  name={field}
                  sortBy={sortBy}
                  onChangeSort={onChangeSort}
                >
                  {title}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {columnsData.map((item, index) => {
              const navigate = (e) => {
                if (
                  e.target?.tagName === 'A' ||
                  window.getSelection().type === 'Range'
                )
                  return

                nav(generatePath('/clients/:id', { id: item.external_id }))
              }

              return (
                <Tr
                  key={index}
                  className={cx('ClickableRow')}
                  onClick={navigate}
                >
                  {columns.map(({ field, type, render }) => (
                    <Td key={field} type={type} className={cx(field)}>
                      {render(item)}
                    </Td>
                  ))}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </>
    )
  }
)

const unpackQuery = evolve({
  created: (created) => created.map((c) => new Date(c)),
  score: (score) => score.map((c) => Number(c)),
})

const compactQuery = evolve({
  created: (created) => created.map((c) => c && String(c)),
  score: (score) => (isEqual(score, [1, 10]) ? [] : score),
  page: (page) => (page !== 1 ? page : undefined),
})

const defaultFiltersState: FiltersState = {
  created: [],
  clientId: '',
  score: [1, 10],
  transfers: {},
  incoming: {},
  outgoing: {},
  selected: [],
}

type ClientsQuery = {
  page: string
  sortBy: string
  order: 'desc' | 'asc'
  status?: 'all' | 'active' | 'blocked'
  selected: Array<string>
  created: [] | [string, string]
  clientId: string
  score: [string, string]
  transfers: Volume
  incoming: Volume
  outgoing: Volume
}

type ClientsUnpackedQuery = ClientsQuery & {
  created: [] | [Date, Date]
  score: ScoreRange
}

type ClientStatus = 'all' | 'active' | 'blocked'

const Clients = observer(() => {
  const translateDate = useTranslateDate()
  const [query, setQuery] = useObjectFormattedSearchParams<ClientsQuery>()
  const uppackedQuery = React.useMemo(
    () => unpackQuery(query),
    [query]
  ) as ClientsUnpackedQuery

  const [counters, setCounters] = React.useState({
    all: undefined,
    active: undefined,
    blocked: undefined,
    inactive: undefined,
    idle: undefined,
  })

  const statusOptions = React.useMemo(
    (): CounterTabPropsOption[] => [
      {
        children: 'All',
        value: 'all',
        counterValue: counters.all,
        isShowZeroValue: true,
      },
      {
        children: 'Active',
        value: 'active',
        counterValue: counters.active,
        isShowZeroValue: true,
      },
      {
        children: 'Inactive',
        value: 'inactive',
        counterValue: counters.inactive,
        isShowZeroValue: true,
      },
      {
        children: 'Idle',
        value: 'idle',
        counterValue: counters.idle,
        isShowZeroValue: true,
      },
      {
        children: 'Blocked',
        value: 'blocked',
        counterValue: counters.blocked,
        isShowZeroValue: true,
      },
    ],
    [counters]
  )

  const [page, setPage] = React.useState(Number(uppackedQuery.page) || 1)
  const [sortBy, setSortBy] = React.useState(uppackedQuery.sortBy || '')
  const [order, setOrder] = React.useState<'asc' | 'desc'>(uppackedQuery.order)
  const [status, setStatus] = React.useState<ClientStatus>(
    uppackedQuery.status || 'all'
  )
  const [filters, setFilters] = React.useState<FiltersState>({
    ...defaultFiltersState,
    selected: uppackedQuery.selected || defaultFiltersState.selected,
    created: uppackedQuery.created || defaultFiltersState.created,
    clientId: uppackedQuery.clientId || defaultFiltersState.clientId,
    score: uppackedQuery.score || defaultFiltersState.score,
    transfers: uppackedQuery.transfers || defaultFiltersState.transfers,
    incoming: uppackedQuery.incoming || defaultFiltersState.incoming,
    outgoing: uppackedQuery.outgoing || defaultFiltersState.outgoing,
  })

  const handleChangeFilters = (newFilters: FiltersState) => {
    setPage(1)
    setFilters(newFilters)
  }
  const handleChangeSort = (sortBy, order) => {
    setSortBy(sortBy)
    setOrder(order)
  }

  const debouncedClientId = useDebounceValue(filters.clientId, DEBOUNCE_IN_MS)
  const debouncedIncoming = useDebounceValue(filters.incoming, DEBOUNCE_IN_MS)
  const debouncedOutgoing = useDebounceValue(filters.outgoing, DEBOUNCE_IN_MS)
  const debouncedTransfers = useDebounceValue(filters.transfers, DEBOUNCE_IN_MS)
  const debouncedScore = useDebounceValue(filters.score, DEBOUNCE_IN_MS)

  // дефолтный скор не отправляем, иначе Idle клиенты фильтруются
  const score = isEqual(debouncedScore, [1, 10]) ? [] : debouncedScore

  const apiParams = React.useMemo(
    (): ClientsApiParams => ({
      page: String(page),
      sort_by: sortBy,
      status: status === 'all' ? undefined : status,
      sort_order: order,
      client_id: debouncedClientId,
      incoming_from: debouncedIncoming?.from,
      incoming_to: debouncedIncoming?.to,
      outgoing_from: debouncedOutgoing?.from,
      outgoing_to: debouncedOutgoing?.to,
      transfers_from: debouncedTransfers?.from,
      transfers_to: debouncedTransfers?.to,
      score_from: score?.[0],
      score_to: score?.[1],
      created_from: filters.created?.[0]
        ? translateDate(filters.created[0]).toISOString()
        : null,
      created_to: filters.created?.[1]
        ? translateDate(filters.created[1]).toISOString()
        : null,
    }),
    [
      filters,
      page,
      sortBy,
      order,
      status,
      debouncedClientId,
      debouncedIncoming,
      debouncedOutgoing,
      debouncedTransfers,
      debouncedScore,
    ]
  )

  React.useEffect(() => {
    const debouncedUpdateQuery = debounce(() => {
      setQuery(
        compactQuery(
          clearParams({
            ...filters,
            page,
            sortBy,
            order,
            status,
          })
        ) as ClientsQuery
      )
    }, DEBOUNCE_IN_MS)

    debouncedUpdateQuery()

    return () => {
      debouncedUpdateQuery.cancel()
    }
  }, [filters, page, sortBy, order, status, setQuery])

  const { data, isLoading, error } = useHttp<ClientsData>(
    buildUrl`${getConfig()?.API}/api/clients?${apiParams}`
  )

  React.useEffect(() => {
    if (data) {
      const active = data.counters?.Active || 0
      const inactive = data.counters?.Inactive || 0
      const blocked = data.counters?.Blocked || 0
      const idle = data.counters?.Idle || 0

      const all = active + inactive + blocked + idle

      setCounters({ all, active, inactive, blocked, idle })
    }
  }, [data])

  if (error) {
    // TODO: handle error
  }

  const [paginationSize, setPaginationSize] = React.useState(0)

  React.useEffect(() => {
    if (data?.total_entries) {
      setPaginationSize(
        DEFAULT_PAGINATION_SIZE < data.total_entries
          ? DEFAULT_PAGINATION_SIZE
          : data.total_entries
      )
    }
  }, [data])

  const withFilters = React.useMemo(() => {
    return Boolean(
      filters.created?.length ||
        debouncedClientId ||
        debouncedIncoming ||
        debouncedOutgoing ||
        debouncedTransfers ||
        debouncedScore
    )
  }, [
    filters,
    debouncedClientId,
    debouncedIncoming,
    debouncedOutgoing,
    debouncedTransfers,
    debouncedScore,
  ])

  const downloadClients = () => {
    http
      .get(
        buildUrl`${getConfig()?.API}/api/clients/csv?${{
          ...apiParams,
          page: undefined,
        }}`
      )
      .then((data) => {
        if (data?.data) {
          download(
            data.data,
            `clients - ${formatDate(new Date(), 'yyyy-MM-dd-HH-mm')}.csv`,
            'text/csv'
          )
        }
      })
  }

  HeaderSlot.useContent(
    () => (
      <Header
        title="Clients"
        icon={<UsersIcon />}
        actions={
          <RowDeprecated
            className={cx('ExportAction')}
            onClick={downloadClients}
          >
            <Typography>Export data</Typography>
            <Typography color="grey3">
              <CSVIcon />
            </Typography>
          </RowDeprecated>
        }
      />
    ),
    [data]
  )

  return (
    <MagicGrid gap={2}>
      <Filters
        defaultFiltersState={defaultFiltersState}
        initialValues={filters}
        onChange={handleChangeFilters}
      />
      <div>
        <div className={cx('TableHead')}>
          <CounterTabs
            className={cx('UserStatusTabs')}
            options={statusOptions}
            value={status}
            onChange={(status: 'all' | 'active' | 'blocked') =>
              setStatus(status)
            }
          />
          <PaginationSize
            total={data?.total_entries}
            value={String(paginationSize)}
            onChange={setPaginationSize}
            disabled
          />
        </div>
        <Portlet variant="card" className={styles.card}>
          {{
            body: (
              <ClientsTable
                loading={isLoading}
                items={data?.clients}
                sortBy={sortBy}
                order={order}
                onChangeSort={handleChangeSort}
              />
            ),
          }}
        </Portlet>
        {data?.clients?.length === 0 && !isLoading && (
          <VoidText>
            {tabStub[status]}
            <br />
            {withFilters &&
              'Try to change filters and/or time range restrictions'}
          </VoidText>
        )}
        <Pagination
          className={cx('Pagination')}
          totalPages={data?.total_pages}
          value={page}
          onChange={setPage}
        />
      </div>
    </MagicGrid>
  )
})

export default Clients

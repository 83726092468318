import React from 'react'

import { TextField } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { InputDropdown } from '@clain/core/ui-kit'
import { formatNumber } from '@clain/core/utils/format'

export interface Amount {
  from?: string
  to?: string
}

interface AmountInputProps {
  label?: string
  value: Amount | null
  onChange: (value: Amount | null) => void
}

const getPrintValueLabel = (fromValue?: string, toValue?: string): string => {
  const from = fromValue && formatNumber(fromValue, 0)
  const to = toValue && formatNumber(toValue, 0)

  return from && to ? `${from} — ${to}` : from || to
}

const AmountFilter = ({ label, value, onChange }: AmountInputProps) => {
  const from = value.from || ''
  const to = value.to || ''

  const ref = React.useRef(null)

  // TODO: подумать надо открытием на фокус при добавлении
  // React.useEffect(() => {
  //   ref.current.focus()
  // })

  const handleInputClear = () => onChange({ from: null, to: null })
  const handleChangeAmountFrom = (v) => onChange({ ...value, from: v })
  const handleChangeAmountTo = (v) => onChange({ ...value, to: v })

  return (
    <InputDropdown
      clearable
      ref={ref}
      variant="outline"
      startAddon={{ label }}
      placeholder="Any"
      value={getPrintValueLabel(from, to)}
      onClear={handleInputClear}
    >
      <MagicGrid gap={1.5}>
        <Label content="From">
          <TextField
            mask="number"
            variant="outline"
            startAddon={{ label: '$' }}
            value={from}
            onChange={handleChangeAmountFrom}
          />
        </Label>
        <Label content="To">
          <TextField
            mask="number"
            variant="outline"
            startAddon={{ label: '$' }}
            value={to}
            onChange={handleChangeAmountTo}
          />
        </Label>
      </MagicGrid>
    </InputDropdown>
  )
}

export default AmountFilter

import http, { HttpResponse } from '@clain/core/http'
import { API_ENDPOINT } from '../utils'
import {
  IIncidentApiService,
  IncidentApiParams,
  IncidentDataOpenCaseResponse,
  IncidentDataResponse,
  IncidentsApiParams,
  IncidentsDataResponse,
  PostChatFilesDataResponse,
} from './IncidentService.types'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import buildUrl from '@clain/core/utils/buildUrl'
import { injectable } from 'inversify'

@injectable()
export class IncidentRestApiService implements IIncidentApiService {
  private apiEndpoint = `${API_ENDPOINT}/incidents`

  public getIncidentById = ({ id }: IncidentApiParams) => {
    return new Promise<SnakeToCamelCaseObject<IncidentDataResponse>>(
      (resolve, reject) => {
        http
          .get<HttpResponse<IncidentDataResponse>>(`${this.apiEndpoint}/${id}`)
          .then((result) => {
            resolve(normalizeSnakeToCamelCase(result.data.data))
          })
          .catch(reject)
      }
    )
  }

  public getIncidents = (params: IncidentsApiParams) => {
    return new Promise<SnakeToCamelCaseObject<IncidentsDataResponse>>(
      (resolve, reject) => {
        http
          .get<HttpResponse<IncidentsDataResponse>>(
            buildUrl`${this.apiEndpoint}?${params}`
          )
          .then((result) => {
            resolve(normalizeSnakeToCamelCase(result.data.data))
          })
          .catch(reject)
      }
    )
  }

  public postChatFilesAttach = (
    id: number,
    data: {
      files: File[]
    }
  ) => {
    return new Promise<SnakeToCamelCaseObject<PostChatFilesDataResponse>>(
      (resolve, reject) => {
        const formData = new FormData()
        data.files.forEach((file) => {
          formData.append('files[]', file)
        })

        http
          .post<
            typeof data,
            HttpResponse<
              {
                status: boolean
                msg?: string
              } & PostChatFilesDataResponse
            >
          >(buildUrl`${this.apiEndpoint}/${id}/attach`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .then((result) => {
            if (result.data.status) {
              resolve(normalizeSnakeToCamelCase(result.data))
            } else {
              reject(new Error(result.data.msg))
            }
          })
          .catch(reject)
      }
    )
  }

  public postCaseToIncident = (
    id: number,
    data: {
      tag: string
    }
  ) => {
    return new Promise<SnakeToCamelCaseObject<IncidentDataOpenCaseResponse>>(
      (resolve, reject) => {
        http
          .post<
            typeof data,
            HttpResponse<{
              data: IncidentDataOpenCaseResponse
              status: boolean
              msg?: string
            }>
          >(buildUrl`${this.apiEndpoint}/${id}/case`, data)
          .then((result) => {
            if (result.data.status) {
              resolve(normalizeSnakeToCamelCase(result.data.data))
            } else {
              reject(new Error(result.data.msg))
            }
          })
          .catch(reject)
      }
    )
  }

  public postIgnoreToIncident = (
    id: number,
    data: {
      comment: string
    }
  ) => {
    return new Promise<void>((resolve, reject) => {
      http
        .post<typeof data, HttpResponse<{ status: boolean; msg?: string }>>(
          buildUrl`${this.apiEndpoint}/${id}/ignore`,
          data
        )
        .then((result) => {
          if (result.data.status) {
            resolve()
          } else {
            reject(new Error(result.data.msg))
          }
        })
        .catch(reject)
    })
  }
}

import { injectable, inject } from 'inversify'
import { action, computed } from 'mobx'
import { sort } from 'ramda'
import { IStateViewModel, StateViewModel } from '@clain/core/utils/mobxUtils'
import {
  IAiChartReportData,
  IChatTemporaryData,
} from '@compliance/modules/IncidentCaseChatStore/IncidentCaseChatStore.types'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'

@injectable()
export class IncidentCaseChatStore<
  TSocketDataResponse extends IAiChartReportData,
  TChatUserMessage
> {
  private aiChatReportData: IStateViewModel<TSocketDataResponse>
  private temporaryStreamChatData: IStateViewModel<
    IChatTemporaryData<TChatUserMessage>
  >

  constructor(
    @inject(DY_TYPES.AiChartReportData)
    initialSocketData: TSocketDataResponse,
    @inject(DY_TYPES.ChatTemporaryData)
    initialTemporaryData: IChatTemporaryData<TChatUserMessage> = {
      fileIds: [],
      filesInProcessing: [],
      filePreviews: [],
      chatSystemMessageResponse: [],
      chatUserMessage: null,
      chatMessagesUsedInSummary: [],
      error: null,
    }
  ) {
    this.aiChatReportData = new StateViewModel<TSocketDataResponse>(
      initialSocketData
    )
    this.temporaryStreamChatData = new StateViewModel(initialTemporaryData)
  }

  @computed
  private get chatMessages() {
    return sort(
      (a, b) => a.createdAt - b.createdAt,
      this.aiChatReportData.state.chatMessages
    )
  }

  @computed
  private get chatSystemMessageResponse() {
    return this.temporaryStreamChatData.state.chatSystemMessageResponse.join('')
  }

  @computed
  private get chatMessagesUsedInSummary() {
    return this.temporaryStreamChatData.state.chatMessagesUsedInSummary.slice()
  }

  @computed
  public get data() {
    return {
      chatMessages: this.chatMessages,
      chatStatus: this.aiChatReportData.state.chatStatus,
      aiSummary: this.aiChatReportData.state.aiSummary,
      aiSummaryStatus: this.aiChatReportData.state.aiStatus,
      chatSystemMessageResponse: this.chatSystemMessageResponse,
      chatSystemMessageResponseArray:
        this.temporaryStreamChatData.state.chatSystemMessageResponse,
      chatUserMessage: this.temporaryStreamChatData.state.chatUserMessage,
      chatMessagesUsedInSummary: this.chatMessagesUsedInSummary,
      filesInProcessing: this.temporaryStreamChatData.state.filesInProcessing,
      fileIds: this.temporaryStreamChatData.state.fileIds,
      filePreviews: this.temporaryStreamChatData.state.filePreviews,
      error: this.temporaryStreamChatData.state.error,
    }
  }

  @computed
  public get actions() {
    return {
      resetTemporaryData: this.temporaryStreamChatData.resetState,
      updateTemporaryData: this.temporaryStreamChatData.updateState,
      initTemporaryData: this.temporaryStreamChatData.initState,

      resetData: this.aiChatReportData.resetState,
      updateData: this.aiChatReportData.updateState,
      initData: this.aiChatReportData.initState,
    }
  }

  @action
  public clear = () => {
    this.aiChatReportData.clearState()
    this.temporaryStreamChatData.clearState()
  }
}

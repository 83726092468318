import React from 'react'
import { Button } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import Editor from '@clain/core/Editor'
import { Label } from '@clain/core/ui-kit'
import Form, {
  FORM_ERROR,
  Field,
  FormActionsVertical,
  validators,
} from '@clain/core/Form'
import { Typography } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { useIncidentActions } from '../IncidentActions.context'

export default function IncidentDismissForm({
  incidentId,
  onSubmitSuccess,
  onCancel,
}) {
  const commentRef = React.useRef(null)
  const { ignoreIncident } = useIncidentActions()
  const handleSubmit = async (data: { comment: string }) => {
    try {
      await ignoreIncident(incidentId, data)
      onSubmitSuccess()
    } catch (e) {
      return {
        [FORM_ERROR]: e?.message,
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({ form }) => {
        const { validating, invalid, pristine, submitting, submitError } =
          form.getState()

        return (
          <Container gap={0.5}>
            <MagicGrid gap={2}>
              <Typography variant="heading5" color="black">
                Dismiss the incident
              </Typography>

              <div>
                <Label
                  content={
                    <Typography>
                      It will not be possible to cancel this decision later.
                      <br />
                      Leave the reasoning below and CONFIRM if you are going to
                      dismiss this incident.
                    </Typography>
                  }
                  size="lg"
                />
                <div style={{ marginTop: 16 }}>
                  <Field
                    ref={commentRef}
                    fullWidth
                    as={Editor}
                    name="comment"
                    type="mini"
                    validate={validators.required()}
                  />
                </div>
              </div>

              <FormActionsVertical>
                {submitError && (
                  <Typography color="magenta">{submitError}</Typography>
                )}
                <MagicGrid cols={2} gap={1 / 2}>
                  <Button
                    type="button"
                    variant="outline"
                    color="secondary"
                    onClick={onCancel}
                    size="lg"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    // pristine докидываем, чтобы не моргало при первом рендере
                    // т.к. знаем что форму полюбому надо заполнить
                    disabled={pristine || validating || invalid || submitting}
                    variant="solid"
                    color="primary"
                    size="lg"
                  >
                    Confirm
                  </Button>
                </MagicGrid>
              </FormActionsVertical>
            </MagicGrid>
          </Container>
        )
      }}
    </Form>
  )
}

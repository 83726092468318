const evolve = (transformations) => (obj) =>
  Object.entries(obj).reduce((result, [key, val]) => {
    const transform = transformations[key]

    result[key] = transform ? transform(val) : val

    return result
  }, {})

export default evolve

import { useTimezones } from '@clain/core/hooks'
import {
  InputDropdown,
  Stack,
  TypographyNew,
  TextField,
  MagicGrid,
  Container,
  StatusBlock,
} from '@clain/core/ui-kit'
import { useState, useMemo, useDeferredValue } from 'react'

import * as S from './Timezone.styles'

interface Timezone {
  name: string
  utcOffset: number
  utcOffsetStr: string
}

const getPrintValue = (timezone?: Timezone) => {
  return timezone ? `(UTC${timezone.utcOffsetStr}) ${timezone.name}` : ''
}

export const Timezone = ({
  timezone,
  updateTimezone,
  disabled,
}: {
  timezone?: string
  updateTimezone: (timezone: string) => void
  disabled?: boolean
}) => {
  const timezones = useTimezones()

  const [inputValue, setInputValue] = useState('')

  const deferredInputValue = useDeferredValue(inputValue)
  const [isOpen, setIsOpen] = useState(false)

  const printValue = disabled
    ? timezone
    : getPrintValue(timezones?.find((item) => item.name === timezone))

  const sortedTimezones = useMemo(() => {
    if (!timezones) return []
    return Array.from(timezones).sort(
      ({ utcOffset: a }, { utcOffset: b }) => a - b
    )
  }, [timezones])

  const filteredTimezones = useMemo(() => {
    if (!deferredInputValue) return sortedTimezones

    const normalizedQuery = deferredInputValue.toLowerCase()
    return sortedTimezones.filter((tz) =>
      tz.name.toLowerCase().includes(normalizedQuery)
    )
  }, [sortedTimezones, deferredInputValue])

  const options = useMemo(() => {
    return filteredTimezones.map((tz) => ({
      value: tz.name,
      label: (
        <Stack gap="md" align="center">
          <TypographyNew variant="body100Normal" color="onBackgroundVariant2">
            (UTC{tz.utcOffsetStr})
          </TypographyNew>
          <TypographyNew variant="body100Normal" color="onBackgroundBase">
            {tz.name}
          </TypographyNew>
        </Stack>
      ),
    }))
  }, [filteredTimezones])

  return (
    <Stack direction="column" gap="md">
      <TypographyNew variant="heading300Accent" color="onBackgroundVariant1">
        System time
      </TypographyNew>
      <InputDropdown
        disabled={disabled}
        open={isOpen}
        variant="outline"
        endAddon={{ iconVariant: isOpen ? 'Up' : 'Down' }}
        sameWidth
        padded={false}
        value={printValue}
        onOpenChange={setIsOpen}
      >
        <Container gap={[1, 0]}>
          <MagicGrid>
            <Container gap={[0, 1]}>
              <TextField
                autoFocus
                fullWidth
                variant="outline"
                value={inputValue}
                onChange={setInputValue}
                startAddon={{ iconVariant: 'Search' }}
              />
            </Container>
            <S.MenuContainer isCentered={!options?.length}>
              {options?.length ? (
                <S.MenuContent
                  padded={false}
                  onChange={(val) => {
                    setIsOpen(false)
                    setInputValue('')
                    updateTimezone(val)
                  }}
                  options={options}
                />
              ) : (
                <StatusBlock>
                  <StatusBlock.Image type={'search'} />
                  <StatusBlock.Title>No results found</StatusBlock.Title>
                </StatusBlock>
              )}
            </S.MenuContainer>
          </MagicGrid>
        </Container>
      </InputDropdown>
    </Stack>
  )
}

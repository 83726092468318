import React from 'react'
import classnames from 'classnames/bind'
import { useObjectFormattedSearchParams } from '@clain/core/Router/router'
import download from 'downloadjs'
import { formatDate } from 'date-fns'
import debounce from 'lodash/debounce'
import http from '@clain/core/http'
import { getConfig } from '@clain/core/useConfig'

import { ReactComponent as BoltIcon } from '@clain/core/assets/bolt.svg'
import { ReactComponent as CSVIcon } from '@clain/core/assets/csv.svg'

import useHttp from '@clain/core/useHttp'
import buildUrl, { clearParams } from '@clain/core/utils/buildUrl'
import evolve from '@clain/core/utils/evolve'
import { HeaderSlot } from '@clain/core/Layout/slots'
import Header from '@clain/core/Layout/Header'
import { CounterTabs, CounterTabOption } from '@clain/core/ui-kit'
import Portlet from '@clain/core/Portlet'
import Pagination from '@clain/core/Pagination'
import PaginationSize from '../PaginationSize'
import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { useDebounceValue } from '@clain/core/useDebounce'
import { useTranslateDate } from '../../hooks'

import { Amount } from '../AmountInput'
import IncidentFilters, { FiltersState } from './Filters'
import IncidentTable from './Table'
import VoidText from '../VoidText'

import styles from './index.scss'
import { IncidentsApiParams, IncidentsDataResponse } from '../../apiServices'
import { IncidentActionsProvider } from '../IncidentActions/IncidentActions.context'
import { IncidentActionsController } from '../IncidentActions/IncidentActionsController'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

const DEFAULT_PAGINATION_SIZE = 10
const DEBOUNCE_IN_MS = 500

const tabStub = {
  all: 'No incidents found.',
  new: 'No new incidents.',
  case: 'No incidents with linked cases.',
  ignored: 'No dismissed incidents.',
}

const defaultFiltersState: FiltersState = {
  selected: [],
  created: [],
  someSearch: null,
  severity: null,
  riskAmount: null,
  rule: null,
}

const unpackQuery = evolve({
  created: (created) => created.map((c) => new Date(c)),
})

const compactQuery = evolve({
  created: (created) => created.map((c) => c && String(c)),
  page: (page) => (page !== 1 ? page : undefined),
  statusTab: (statusTab: StatusTab) =>
    statusTab !== 'new' ? statusTab : undefined,
})

type StatusTab = 'all' | 'new' | 'case' | 'ignored'

type IncidentQuery = {
  page: string
  sortBy: string
  order: 'asc' | 'desc'
  statusTab: StatusTab
  created: [] | [string, string]
  tag: string
  rule: string
  severity: Array<string>
  riskAmount: Amount
  selected: Array<string>
  someSearch: string
}

type IncidentUnpackedQuery = IncidentQuery & {
  created: [] | [Date, Date]
}

interface IncidentsProps {
  title?: string
  clientId?: string | number
}

const Incidents = ({ title, clientId }: IncidentsProps) => {
  const translateDate = useTranslateDate()
  const [query, setQuery] = useObjectFormattedSearchParams<IncidentQuery>()
  const unpackedQuery = React.useMemo(
    () => unpackQuery(query),
    [query]
  ) as IncidentUnpackedQuery

  const [page, setPage] = React.useState(Number(unpackedQuery.page) || 1)
  const [sortBy, setSortBy] = React.useState(unpackedQuery.sortBy || '')
  const [order, setOrder] = React.useState<'asc' | 'desc'>(unpackedQuery.order)
  const [statusTab, setStatusTab] = React.useState<StatusTab>(
    unpackedQuery.statusTab || 'new'
  )
  const [filters, setFilters] = React.useState<FiltersState>({
    ...defaultFiltersState,
    selected: unpackedQuery.selected || defaultFiltersState.selected,
    created: unpackedQuery.created || defaultFiltersState.created,
    someSearch: unpackedQuery.someSearch || defaultFiltersState.someSearch,
    severity: unpackedQuery.severity || defaultFiltersState.severity,
    riskAmount: unpackedQuery.riskAmount || defaultFiltersState.riskAmount,
    rule: unpackedQuery.rule || defaultFiltersState.rule,
  })

  const handleChangeFilters = (newFilters: FiltersState) => {
    setPage(1)
    setFilters(newFilters)
  }
  const handleChangeStatusTab = (statusTab: StatusTab) => {
    setPage(1)
    setStatusTab(statusTab)
  }
  const handleChangeSort = (sortBy, order) => {
    setSortBy(sortBy)
    setOrder(order)
  }

  const debouncedSomeSearch = useDebounceValue(
    filters.someSearch,
    DEBOUNCE_IN_MS
  )
  const debouncedRiskAmount = useDebounceValue(
    filters.riskAmount,
    DEBOUNCE_IN_MS
  )

  const apiParams = React.useMemo(
    (): IncidentsApiParams => ({
      page: String(page),
      sort_by: sortBy,
      sort_order: order,
      client_id: clientId,
      status: statusTab,
      created_from: filters.created[0]
        ? translateDate(filters.created[0]).toISOString()
        : null,
      created_to: filters.created[1]
        ? translateDate(filters.created[1]).toISOString()
        : null,
      hash: debouncedSomeSearch,
      rule: filters.rule,
      usd_amount_from: debouncedRiskAmount?.from,
      usd_amount_to: debouncedRiskAmount?.to,
      severity: filters.severity,
    }),
    [
      page,
      sortBy,
      order,
      clientId,
      statusTab,
      filters?.created,
      filters?.rule,
      filters?.severity,
      translateDate,
      debouncedSomeSearch,
      debouncedRiskAmount,
    ]
  )

  React.useEffect(() => {
    const debouncedUpdateQuery = debounce(() => {
      setQuery(
        compactQuery(
          clearParams({
            ...filters,
            page,
            sortBy,
            order,
            statusTab,
          })
        ) as IncidentQuery
      )
    }, DEBOUNCE_IN_MS)

    debouncedUpdateQuery()

    return () => {
      debouncedUpdateQuery.cancel()
    }
  }, [filters, page, sortBy, order, statusTab, setQuery])

  const { data, isLoading, error, revalidate } = useHttp<IncidentsDataResponse>(
    buildUrl`${getConfig()?.API}/api/incidents?${apiParams}`
  )

  const statusOptions: Array<CounterTabOption<StatusTab>> = [
    {
      value: 'all',
      children: 'all',
      isShowZeroValue: true,
      counterValue: data?.counters
        ? Object.values(data?.counters).reduce(
            (a: number, v: number) => a + v,
            0
          )
        : 0,
    },
    {
      value: 'new',
      children: 'new',
      counterValue: data?.counters?.new ?? 0,
      counterVariant: data?.counters?.new > 0 ? 'important' : 'default',
      isShowZeroValue: true,
    },
    {
      value: 'case',
      children: 'case',
      counterValue: data?.counters?.case ?? 0,
      isShowZeroValue: true,
    },
    {
      value: 'ignored',
      children: 'dismissed',
      counterValue: data?.counters?.ignored ?? 0,
      isShowZeroValue: true,
    },
  ]

  const [paginationSize, setPaginationSize] = React.useState(0)

  React.useEffect(() => {
    if (data?.total_entries) {
      setPaginationSize(
        DEFAULT_PAGINATION_SIZE < data.total_entries
          ? DEFAULT_PAGINATION_SIZE
          : data.total_entries
      )
    }
  }, [data])

  let items

  if (data) {
    items = data.data // .filter(i => i.incoming === incoming)
  }

  const withFilters = React.useMemo(() => {
    return Boolean(
      filters.created?.length ||
        filters.rule?.length ||
        filters.severity ||
        debouncedSomeSearch ||
        debouncedRiskAmount
    )
  }, [filters, debouncedSomeSearch, debouncedRiskAmount])

  const downloadIncidents = () => {
    http
      .get(
        buildUrl`${getConfig()?.API}/api/incidents/csv?${{
          ...apiParams,
          page: undefined,
        }}`
      )
      .then((data) => {
        if (data?.data) {
          download(
            data.data,
            `incidents - ${formatDate(new Date(), 'yyyy-MM-dd-HH-mm')}.csv`,
            'text/csv'
          )
        }
      })
  }

  HeaderSlot.useContent(
    () => (
      <Header
        icon={<BoltIcon />}
        title="Incidents"
        actions={
          <RowDeprecated
            className={cx('ExportAction')}
            onClick={downloadIncidents}
          >
            <Typography>Export data</Typography>
            <Typography color="grey3">
              <CSVIcon />
            </Typography>
          </RowDeprecated>
        }
      />
    ),
    [items]
  )

  if (error) {
    // body = <NetworkError onRetry={revalidate} loading={isLoading} />
  }

  return (
    <IncidentActionsProvider
      viewModel={
        new IncidentActionsController({
          ignoreIncidentCallback: revalidate,
        })
      }
    >
      <MagicGrid gap={2}>
        <IncidentFilters
          defaultFiltersState={defaultFiltersState}
          initialValues={filters}
          onChange={handleChangeFilters}
        />
        <div>
          <div className={cx('TableHead')}>
            <CounterTabs
              className={cx('StatusTabs')}
              options={statusOptions}
              value={statusTab}
              onChange={handleChangeStatusTab}
            />
            <PaginationSize
              total={data?.total_entries}
              value={String(paginationSize)}
              onChange={setPaginationSize}
              disabled
            />
          </div>
          <Portlet variant="card">
            {{
              title,
              body: (
                <IncidentTable
                  showClientId={!clientId}
                  items={items}
                  loading={isLoading}
                  onIgnoreSuccess={revalidate}
                  order={order}
                  sortBy={sortBy}
                  onChangeSort={handleChangeSort}
                  showStatuses={statusTab === 'all'}
                />
              ),
            }}
          </Portlet>
          {data?.data.length === 0 && !isLoading && (
            <VoidText>
              {tabStub[statusTab]}
              <br />
              {withFilters &&
                'Try to change filters and/or time range restrictions'}
            </VoidText>
          )}
          <Pagination
            className={cx('Pagination')}
            totalPages={data?.total_pages}
            value={page}
            onChange={setPage}
          />
        </div>
      </MagicGrid>
    </IncidentActionsProvider>
  )
}

export default observer(Incidents)
export { IncidentTable }

import { AlertCaution, Spinner } from '@clainio/web-platform'
import React from 'react'

export const OtherUserMessageInProcessInfoBlock = () => (
  <AlertCaution
    variant={'info'}
    leftSlotProps={{
      children: <Spinner color={'grey2'} size={'xxs'} />,
      align: 'start',
    }}
  >
    {/* eslint-disable-next-line react/no-unescaped-entities */}
    Messaging paused — another user's query is in progress. Please hold on
    briefly.
  </AlertCaution>
)

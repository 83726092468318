import React from 'react'
import classnames from 'classnames/bind'
import isEmpty from 'lodash/isEmpty'
import { startOfDay } from 'date-fns'

import { formatNumberProgressive } from '@clain/core/utils/format'
import { useTranslateDate } from '../../../hooks'
import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'
import Portlet from '@clain/core/Portlet'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'
import AutoCounter from '../AutoCounter'
import { PercentageGrowth } from '@clain/core/ui-kit'

import Period, { getDateFromPeriod, PeriodValue } from '../Filters/Period'
import { observer } from 'mobx-react-lite'
import {
  DashboardClientsData,
  DashboardClientsApiParams,
} from '../../../types/Dashboard'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'

const cx = classnames.bind(styles)

const defaultCounterState = {
  total: {
    value: 0,
    persentage: 0,
  },
  active: {
    value: 0,
    persentage: 0,
  },
  inactive: {
    value: 0,
    persentage: 0,
  },
  blocked: {
    value: 0,
    persentage: 0,
  },
  idle: {
    value: 0,
    persentage: 0,
  },
}

interface ClientsBlockProps {
  className?: string
}

const calcGrowthRate = (previous, current) => {
  if (!previous || !current) {
    return null
  }

  return (current * 100) / previous - 100
}

// Idle -- новый юзер который не сделал депозит
// Active -- активный юзер
// Inactive -- юзер у которого посл 6 месяцев не было транз
// Blocked -- заблокированный

const ClientsBlock: React.FC<ClientsBlockProps> = ({ className }) => {
  const [period, setPeriod] = React.useState<PeriodValue>('week')

  const translateDate = useTranslateDate()

  const prevApiParams = React.useMemo(
    (): DashboardClientsApiParams => ({
      date: translateDate(startOfDay(getDateFromPeriod(period))).toISOString(),
    }),
    [period]
  )

  const { data: previousData, isFirstLoading: previousIsLoading } =
    useHttp<DashboardClientsData>(
      buildUrl`${getConfig()?.API}/api/dash/clients?${prevApiParams}`
    )
  const { data: currentData, isFirstLoading: currentIsLoading } =
    useHttp<DashboardClientsData>(
      buildUrl`${getConfig()?.API}/api/dash/clients`
    )

  const isFirstLoading = previousIsLoading || currentIsLoading

  const data = React.useMemo(() => {
    if (!isEmpty(currentData)) {
      const totalPrevious = !isEmpty(previousData)
        ? Object.values(previousData).reduce((a, b) => a + b, 0)
        : null
      const totalCurrent = Object.values(currentData).reduce((a, b) => a + b, 0)

      return {
        total: {
          value: totalCurrent,
          persentage: calcGrowthRate(totalPrevious, totalCurrent),
        },
        active: {
          value: currentData.Active ?? 0,
          persentage: calcGrowthRate(previousData?.Active, currentData.Active),
        },
        inactive: {
          value: currentData.Inactive ?? 0,
          persentage: calcGrowthRate(
            previousData?.Inactive,
            currentData.Inactive
          ),
        },
        blocked: {
          value: currentData.Blocked ?? 0,
          persentage: calcGrowthRate(
            previousData?.Blocked,
            currentData.Blocked
          ),
        },
        idle: {
          value: currentData.Idle ?? 0,
          persentage: calcGrowthRate(previousData?.Idle, currentData.Idle),
        },
      }
    }

    return defaultCounterState
  }, [previousData, currentData])

  return (
    <Portlet variant="card" className={className}>
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Clients
          </Typography>
        ),
        toolbar: (
          <Period label="current vs" value={period} onChange={setPeriod} />
        ),
        body: isFirstLoading ? (
          <Stub className={cx('ClientsPortletStub')} isActive={true} />
        ) : (
          <Container gap={2} className={cx('CountersContainer')}>
            <div className={cx('CountersFirstGroupContainer')}>
              <ColDeprecated gap={1.5} className={cx('Counter')}>
                <Typography variant="heading4" color="grey3">
                  Total
                </Typography>
                <ColDeprecated className={cx('CounterValue')}>
                  <AutoCounter
                    value={data.total.value}
                    format={formatNumberProgressive}
                  />
                  <PercentageGrowth value={data.total.persentage} />
                </ColDeprecated>
              </ColDeprecated>
              <ColDeprecated gap={1.5} className={cx('Counter')}>
                <Typography variant="heading4" color="grey3">
                  Active
                </Typography>
                <ColDeprecated className={cx('CounterValue')}>
                  <AutoCounter
                    value={data.active.value}
                    format={formatNumberProgressive}
                  />
                  <PercentageGrowth value={data.active.persentage} />
                </ColDeprecated>
              </ColDeprecated>
            </div>
            <div className={cx('Delimiter')} />
            <div className={cx('CountersSecondGroupContainer')}>
              <ColDeprecated gap={1.5} className={cx('Counter')}>
                <Typography variant="heading4" color="grey3">
                  Idle
                </Typography>
                <ColDeprecated className={cx('CounterValue')}>
                  <AutoCounter
                    value={data.idle.value}
                    format={formatNumberProgressive}
                  />
                  <PercentageGrowth
                    value={data.idle.persentage}
                    increasePositive={false}
                  />
                </ColDeprecated>
              </ColDeprecated>
              <ColDeprecated gap={1.5} className={cx('Counter')}>
                <Typography variant="heading4" color="grey3">
                  Blocked
                </Typography>
                <ColDeprecated className={cx('CounterValue')}>
                  <AutoCounter
                    value={data.blocked.value}
                    format={formatNumberProgressive}
                  />
                  <PercentageGrowth
                    value={data.blocked.persentage}
                    increasePositive={false}
                  />
                </ColDeprecated>
              </ColDeprecated>
              <ColDeprecated gap={1.5} className={cx('Counter')}>
                <Typography variant="heading4" color="grey3">
                  Inactive
                </Typography>
                <ColDeprecated className={cx('CounterValue')}>
                  <AutoCounter
                    value={data.inactive.value}
                    format={formatNumberProgressive}
                  />
                  <PercentageGrowth
                    value={data.inactive.persentage}
                    increasePositive={false}
                  />
                </ColDeprecated>
              </ColDeprecated>
            </div>
          </Container>
        ),
      }}
    </Portlet>
  )
}

export default observer(ClientsBlock)

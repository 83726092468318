import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as AlertCircleIcon } from '@clain/core/assets/alert_circle.svg'
import { TextField } from '@clain/core/ui-kit'
import { Menu } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import useHttp from '@clain/core/useHttp'
import useFuse from '@clain/core/useFuse'

import styles from './index.scss'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'
import { RULES_RIGHT_OVERLAP_SIDEBAR_ID } from './RulesRightOverlapSidebar.constants'
import { PopoverBaseContainer, usePopoverContext } from '@clainio/web-platform'

const cx = classnames.bind(styles)

interface RuleCategoryFilterProps {
  value: string
  onChange: (value: string) => unknown
  error?: boolean
}

interface RulesCategoriesResponseData {
  categories: string[]
}

const DropdownContent = ({
  data,
  inputValue,
  handleChange,
}: {
  data: RulesCategoriesResponseData
  inputValue: string
  handleChange: (category: string) => void
}) => {
  const items = React.useMemo(() => {
    return data?.categories || []
  }, [data])
  const { setOpen } = usePopoverContext()

  const getCategories = useFuse(items)

  const onChange = (category: string) => {
    setOpen(false)
    handleChange(category)
  }

  const resultCategories = getCategories(inputValue)

  if (resultCategories && !resultCategories?.length) {
    return (
      <Container>
        <RowDeprecated gap={0.5}>
          <AlertCircleIcon className={cx('AlertCircleIcon')} />
          <Typography color="grey3">
            There are no matching categories.
            <br />
            Try another name.
          </Typography>
        </RowDeprecated>
      </Container>
    )
  }

  if (resultCategories.length) {
    return (
      <Menu
        options={resultCategories.map((category) => ({
          value: category,
          children: <Typography color="grey1">{category}</Typography>,
        }))}
        onChange={onChange}
      />
    )
  }
  return null
}

const RuleCategoryFilter: React.FC<RuleCategoryFilterProps> = ({
  onChange,
  error,
}) => {
  const [inputValue, setInputValue] = React.useState('')

  const handleInputClear = () => {
    setInputValue('')
    onChange('')
  }

  const { data } = useHttp<RulesCategoriesResponseData>(
    buildUrl`${getConfig()?.API}/api/rules/categories`
  )

  const handleChange = (category: string) => {
    onChange(category)
    setInputValue(category)
  }

  return (
    <PopoverBaseContainer
      id={RULES_RIGHT_OVERLAP_SIDEBAR_ID}
      content={
        <DropdownContent
          data={data}
          inputValue={inputValue}
          handleChange={handleChange}
        />
      }
      sameWidth
      maxHeight={440}
      anchor={
        <TextField
          clearable
          variant="outline"
          placeholder="Find Category"
          value={inputValue}
          onChange={setInputValue}
          onClear={handleInputClear}
          startAddon={{ iconVariant: 'Search' }}
          error={error}
        />
      }
    />
  )
}

export default RuleCategoryFilter

import React from 'react'
import classnames from 'classnames/bind'
import { getConfig } from '@clain/core/useConfig'

import buildUrl from '@clain/core/utils/buildUrl'
import Portlet from '@clain/core/Portlet'
import useHttp from '@clain/core/useHttp'
import { Typography } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'

import SeverityDonut from '../../Chart/severityDonut'

import {
  TriggeredTransactionsAmountData,
  TriggeredTransactionsAmountApiParams,
} from '../../../types/Dashboard'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface TriggeredTransactionsAmountBlockProps {
  className?: string
  assignee?: number
}

const TriggeredTransactionsAmountBlock: React.FC<
  TriggeredTransactionsAmountBlockProps
> = ({ className, assignee }) => {
  const apiParams = React.useMemo(
    (): TriggeredTransactionsAmountApiParams => ({
      assignee_id: assignee,
    }),
    [assignee]
  )

  const { data, isFirstLoading } = useHttp<TriggeredTransactionsAmountData>(
    buildUrl`${getConfig()?.API}/api/dash/sum_by_risk?${apiParams}`
  )

  const donutData = React.useMemo(() => {
    const high = parseInt(data?.HIGH || '0')
    const medium = parseInt(data?.MEDIUM || '0')
    const low = parseInt(data?.LOW || '0')

    return {
      high,
      medium,
      low,
      total: high + medium + low,
    }
  }, [data])

  return (
    <Portlet variant="card" className={className}>
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Risk amount
          </Typography>
        ),
        body: isFirstLoading ? (
          <Stub
            className={cx('TriggeredTransactionsAmountPortletStub')}
            isActive={true}
          />
        ) : (
          <SeverityDonut data={donutData} />
        ),
      }}
    </Portlet>
  )
}

export default TriggeredTransactionsAmountBlock

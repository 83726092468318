import React from 'react'
import * as S from './AIChatWidget.styles'
import {
  ChatMessageCardSystem,
  ChatMessageTextArea,
  Stack,
} from '@clainio/web-platform'
import { useAutoScroll } from '@clain/core/hooks'
import { ChatMessageCardUserSection } from '@compliance/components/Widgets/AIChatWidget/ChatMessageCardUserSection'
import { AIChatWidgetProps } from '@compliance/components/Widgets/AIChatWidget/AIChatWidget.types'
import { ChatMessagesHistory } from '@compliance/components/Widgets/AIChatWidget/ChatMessagesHistory'
import { OtherUserMessageInProcessInfoBlock } from '@compliance/components/Widgets/AIChatWidget/OtherUserMessageInProcessInfoBlock'
import { ReportToProcessInfoBlock } from '@compliance/components/Widgets/AIChatWidget/ReportToProcessInfoBlock'
import { EmptyChat } from '@compliance/components/Widgets/AIChatWidget/EmptyChat'
import { ErrorInfoBlock } from '@compliance/components/Widgets/AIChatWidget/ErrorInfoBlock'
import { observer } from 'mobx-react-lite'
import { useTimezone } from '@compliance/hooks'

export const AIChatWidget = observer(
  ({
    attachFilesToChatMessage,
    removeChatFile,
    chatFilesInProcessing,
    onSubmitMessage,
    chatMessages,
    chatMessage,
    userMessage,
    chatStatus,
    onUseMessageInReport,
    chatMessagesUsedInSummary,
    isChatDisabled,
    isSummaryReportCantBeModified,
    showToProcessInfoBlock,
    userId,
    showUseInReportButton = true,
    isSummaryReportProcessing,
    error,
    retrySendAiChatMessage,
  }: AIChatWidgetProps) => {
    const timezone = useTimezone()

    const containerRef = useAutoScroll([
      chatMessage,
      userMessage,
      chatMessages.length,
    ])
    const isChatEmpty = chatMessages.length === 0 && chatStatus === 'idle'
    const isOtherUserMessageInProcess =
      chatStatus !== 'idle' &&
      !userMessage?.content &&
      !chatMessage &&
      chatMessages[chatMessages.length - 1]?.user?.id !== userId

    const isChatMessageOrReportGenerating =
      chatStatus !== 'idle' ||
      isSummaryReportProcessing ||
      isOtherUserMessageInProcess

    return (
      <S.AiWidgetWrapper>
        <S.ChatWrapper ref={containerRef}>
          {isChatEmpty ? (
            <EmptyChat />
          ) : (
            <Stack direction={'column'} gap={'md'}>
              <ChatMessagesHistory
                timeZone={timezone}
                showUseInReportButton={showUseInReportButton}
                chatMessagesUsedInSummary={chatMessagesUsedInSummary}
                onUseMessageInReport={onUseMessageInReport}
                chatMessages={chatMessages}
                isUseMessageInReportDisabled={
                  isChatMessageOrReportGenerating ||
                  isChatDisabled ||
                  isSummaryReportCantBeModified
                }
              />
              {userMessage && (
                <ChatMessageCardUserSection
                  timeZone={timezone}
                  userName={userMessage.user?.name}
                  userAvatar={userMessage.user?.avatar}
                  text={userMessage.content}
                  createdAt={userMessage.createdAt}
                  files={userMessage.files}
                />
              )}
              {chatMessage ? (
                <ChatMessageCardSystem timeZone={timezone} text={chatMessage} />
              ) : null}
            </Stack>
          )}
        </S.ChatWrapper>
        {showToProcessInfoBlock && <ReportToProcessInfoBlock />}
        {isOtherUserMessageInProcess && <OtherUserMessageInProcessInfoBlock />}
        {error && <ErrorInfoBlock onRetry={retrySendAiChatMessage} />}
        <S.ChatMessageTextAreaWrapper>
          <ChatMessageTextArea
            isDisabled={isChatDisabled}
            attachFiles={attachFilesToChatMessage}
            filesInProcessing={chatFilesInProcessing}
            removeFile={removeChatFile}
            isLoading={isChatMessageOrReportGenerating}
            rows={1}
            onSubmit={onSubmitMessage}
          />
        </S.ChatMessageTextAreaWrapper>
      </S.AiWidgetWrapper>
    )
  }
)

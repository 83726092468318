import React from 'react'
import classnames from 'classnames/bind'

import useHttp from '@clain/core/useHttp'
import { HeaderSlot } from '@clain/core/Layout/slots'

import BlockClientForm from './BlockClientForm'
import SettingsHeader from '../SettingsHeader'

import { SettingsApiIntergrationData } from '../../../types/Settings'

import styles from './index.scss'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'

const cx = classnames.bind(styles)

const ApiSettings = () => {
  const { data, mutate } = useHttp<SettingsApiIntergrationData>(
    buildUrl`${getConfig()?.API}/api/settings/api_data`
  )

  const handleUpdateApiIntegrationData = (
    newData: Partial<SettingsApiIntergrationData>
  ) => {
    mutate({ ...data, ...newData })
  }

  const keys = React.useMemo(
    () => ({
      public: data?.public_key,
      private: data?.private_key,
    }),
    [data]
  )

  HeaderSlot.useContent(() => <SettingsHeader />)

  return (
    <div className={cx('Container')}>
      <BlockClientForm
        blockUrl={data?.block_url}
        whiteList={data?.white_list}
        keys={keys}
        onUpdate={handleUpdateApiIntegrationData}
      />
    </div>
  )
}

export default ApiSettings

import React from 'react'

import { Typography, TypographyProps } from '@clain/core/ui-kit'

interface TransactionHashProps extends TypographyProps {
  className?: string
  value: string
}

const TransactionHash = ({ value, className }: TransactionHashProps) => {
  return (
    <Typography variant="code1" className={className}>
      {value.substring(0, 10)}
    </Typography>
  )
}

export default TransactionHash

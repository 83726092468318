import {
  createTokenKey,
  selectToken,
  SurfaceContainer,
} from '@clainio/web-platform'
import styled, { css } from 'styled-components'

export const HEADER_HEIGHT = 80

export const Header = styled(SurfaceContainer)`
  position: relative;
  display: flex;
  min-height: ${HEADER_HEIGHT}px;
  white-space: nowrap;
  align-items: center;
  ${({ theme }) => {
    return css({
      borderBottom: `1px solid ${selectToken(
        theme,
        createTokenKey(['grey400'])
      )}`,
    })
  }}
`

export const HeaderActions = styled.div`
  margin-left: auto;
`

export const HeaderIcon = styled.div`
  width: ${({ theme }) => selectToken(theme, 'iconLgSize')};
  height: ${({ theme }) => selectToken(theme, 'iconLgSize')};
  flex-shrink: 0;
  margin-right: ${({ theme }) => `${selectToken(theme, 'spaceX8')}px`};
  color: ${({ theme }) => selectToken(theme, 'iconOnBackgroundVariant1Color')};
`

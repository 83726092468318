import React from 'react'

import useHttp from '@clain/core/useHttp'
import { getConfig } from '@clain/core/useConfig'

import { SearchResults } from '../types'
import buildUrl from '@clain/core/utils/buildUrl'
import { IncidentDataResponse } from '../../../apiServices'
import { CaseDataResponse, CasesDataResponse } from '../../../apiServices/Case'
import { ClientDataResponse } from '../../../apiServices/Client/ClientService.types'

const NOOP = () => {}

const useSearch = (
  search: string
): {
  data: SearchResults
  isLoading: boolean
} => {
  const {
    data: incidentData,
    isLoading: isIncidentLoading,
    error: isIncidentError,
  } = useHttp<IncidentDataResponse>(
    search ? buildUrl`${getConfig()?.API}/api/incidents/${search}` : null,
    {
      onErrorRetry: NOOP,
    }
  )

  const {
    data: caseData,
    isLoading: isCaseLoading,
    error: isCaseError,
  } = useHttp<CaseDataResponse>(
    search ? buildUrl`${getConfig()?.API}/api/cases/${search}` : null,
    {
      onErrorRetry: NOOP,
    }
  )

  const {
    data: clientData,
    isLoading: isClientDataLoading,
    error: isClientError,
  } = useHttp<ClientDataResponse>(
    search ? buildUrl`${getConfig()?.API}/api/clients/${search}` : null,
    {
      onErrorRetry: NOOP,
    }
  )

  const {
    data: casesData,
    isLoading: isCasesLoading,
    error: isCasesError,
  } = useHttp<CasesDataResponse>(
    search ? buildUrl`${getConfig()?.API}/api/cases?tag=${search}` : null,
    {
      onErrorRetry: NOOP,
    }
  )

  const isLoading = React.useMemo(() => {
    const isSomeLoading =
      isIncidentLoading ||
      isCaseLoading ||
      isClientDataLoading ||
      isCasesLoading

    return Boolean(isSomeLoading && search)
  }, [
    search,
    isIncidentLoading,
    isCaseLoading,
    isClientDataLoading,
    isCasesLoading,
  ])

  const data = React.useMemo(() => {
    if (!search) return undefined

    let result: SearchResults = []

    if (clientData?.client && !isClientError) {
      result.push(clientData.client)
    }

    if (incidentData?.incident?.id && !isIncidentError) {
      result.push(incidentData)
    }

    if (caseData?.id && !isCaseError) {
      result.push(caseData)
    }

    if (casesData?.cases && !isCasesError) {
      result = [...result, ...casesData.cases]
    }

    return result
  }, [
    search,
    clientData?.client,
    isClientError,
    incidentData,
    isIncidentError,
    caseData,
    isCaseError,
    casesData?.cases,
    isCasesError,
  ])

  return {
    data,
    isLoading,
  }
}

export default useSearch

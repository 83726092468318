import React from 'react'
import classnames from 'classnames/bind'

import Portlet from '@clain/core/Portlet'
import { RowDeprecated } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import Form, { Field } from '@clain/core/Form'

import Severity from './SeverityInput'
import AmountInput from './AmountInput'
import TransactionDirectionInput from './TransactionDirectionInput'
import RuleTypeInput from './RuleTypeInput'
import CommonSettings from './CommonSettings'
import { FiltersProps, FIELDS } from './types'

import styles from './index.scss'

const cx = classnames.bind(styles)

const RiskAmount = (p) => <AmountInput label="Risk amount:" {...p} />

const IncidentFilters = ({ initialValues, onChange }: FiltersProps) => {
  return (
    <Portlet>
      {{
        content: (
          <Form initialValues={initialValues} onChange={onChange}>
            {() => {
              return (
                <MagicGrid gap={1.5}>
                  <RowDeprecated gap={0.5} className={cx('FiltersRow')} wrap>
                    <Field as={Severity} name={FIELDS.severity} />
                    <Field as={RiskAmount} name={FIELDS.riskAmount} />
                    <Field
                      as={TransactionDirectionInput}
                      name={FIELDS.direction}
                    />
                    <Field as={RuleTypeInput} name={FIELDS.ruleType} />
                  </RowDeprecated>
                  <Field as={CommonSettings} name={FIELDS.commonSettings} />
                </MagicGrid>
              )
            }}
          </Form>
        ),
      }}
    </Portlet>
  )
}

export default IncidentFilters
export type { FiltersState } from './types'

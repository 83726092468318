import React from 'react'
import classnames from 'classnames/bind'

import { Modal, ModalSlot } from '@clain/core/ui-kit'

import GlobalSearchTarget from './GlobalSearchTarget'
import GlobalSearchBody from './GlobalSearchBody'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface GlobalSearchProps {
  className?: string
  textClassName?: string
  iconClassName?: string
}

const GlobalSearch = React.forwardRef<HTMLDivElement, GlobalSearchProps>(
  ({ className, textClassName, iconClassName }, ref) => {
    const target: ModalSlot = ({ show }) => {
      return (
        <GlobalSearchTarget
          ref={ref}
          className={className}
          textClassName={textClassName}
          iconClassName={iconClassName}
          show={show}
        />
      )
    }

    const body: ModalSlot = ({ hide }) => <GlobalSearchBody hideModal={hide} />

    return (
      <Modal
        target={target}
        className={cx('GlobalSearch')}
        overlayClassName={cx('GlobalSearchOverlay')}
      >
        {body}
      </Modal>
    )
  }
)
GlobalSearch.displayName = 'GlobalSearch'
export default GlobalSearch

import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ReactComponent as LabelIcon } from '@clain/core/assets/label.svg'

import CaseActivityCard from './index'
import { FeedLabelUpdateActivity } from '../../../../types/Case'

import styles from './index.scss'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

const cx = classnames.bind(styles)

interface CaseLabelUpdateActivityCardProps {
  activity: SnakeToCamelCaseObject<FeedLabelUpdateActivity>
  className?: string
}

const CaseLabelUpdateActivityCard: React.FC<
  CaseLabelUpdateActivityCardProps
> = ({ activity, className }) => {
  return (
    <CaseActivityCard
      className={className}
      icon={<LabelIcon />}
      title="Label"
      main={
        <Typography variant="body3" color="grey2">
          <RowDeprecated gap={0.5}>
            {activity.user.name}
            <Typography color="grey2">changed the label</Typography>
          </RowDeprecated>
        </Typography>
      }
      date={new Date(activity.insertedAt)}
    >
      <Typography color="grey2" className={cx('LabelUpdatePanel')}>
        <RowDeprecated gap={0.5}>
          from
          <Typography variant="body3" color="grey2" className={cx('OldLabel')}>
            {activity.activity.old}
          </Typography>
          to
          <Typography variant="body3" color="grey2" className={cx('NewLabel')}>
            {activity.activity.new}
          </Typography>
        </RowDeprecated>
      </Typography>
    </CaseActivityCard>
  )
}

export default CaseLabelUpdateActivityCard

import React, { memo } from 'react'
import classnames from 'classnames/bind'
import debounce from 'lodash/debounce'
import { getConfig } from '@clain/core/useConfig'

import { HeaderSlot, RightOverlapSidebarSlot } from '@clain/core/Layout/slots'
import buildUrl, { clearParams } from '@clain/core/utils/buildUrl'
import { useObjectFormattedSearchParams } from '@clain/core/Router/router'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Portlet from '@clain/core/Portlet'
import http from '@clain/core/http'
import Pagination from '@clain/core/Pagination'
import { Button, RadioButtonGroup } from '@clain/core/ui-kit'
import { formatMoney } from '@clain/core/utils/format'
import { RowDeprecated } from '@clain/core/ui-kit'
import { CheckboxSwitchDouble } from '@clain/core/ui-kit'
import { useDebounceValue } from '@clain/core/useDebounce'
import { Container } from '@clain/core/ui-kit'
import { ReactComponent as HistoryIcon } from '@clain/core/assets/history.svg'
import { ReactComponent as HistoryBlockIcon } from '@clain/core/assets/history_block.svg'
import { ReactComponent as BlockIcon } from '@clain/core/assets/block.svg'

import {
  Table2 as Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  createColumns,
} from '@clain/core/ui-kit'
import { useRightOverlapSidebarStateSetter } from '@clain/core/state'

import { Rule } from '../../../types/Rule'
import SeverityTag from '../../SeverityTag'
import SettingsHeader from '../SettingsHeader'
import useRules, { RequestParams } from './useRules'
import Filters, { FiltersState } from './Filters'
import RulesRightOverlapSidebar from './RulesRightOverlapSidebar'
import { Amount } from './Filters/types'
import RemoveRuleConfirmationButton from './RemoveRuleConfirmationButton'
import VoidText from '../../VoidText'

import styles from './index.scss'

const cx = classnames.bind(styles)

const MAX_RULES_PER_PAGE = 100
const DEBOUNCE_IN_MS = 500

const tabStub = {
  current: 'No rules.',
  archived: 'No archived rules.',
}

const defaultFiltersState: FiltersState = {
  selected: [],
  severity: null,
  riskAmount: null,
  direction: null,
  ruleType: null,
  commonSettings: {},
}

const directionLabel = {
  in: 'In',
  out: 'Out',
  both: 'In/ Out',
}

const groupByOptions = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'type',
    label: 'Rule type',
  },
  {
    value: 'severity',
    label: 'Severity',
  },
  {
    value: 'direction',
    label: 'Direction',
  },
]

type RuleQueryParams = {
  selected: Array<string>
  severity: Array<string>
  riskAmount: Amount
  direction: 'both' | 'in' | 'out'
  ruleType: Array<string>
}

interface RuleTableProps {
  items?: Array<Rule>
  onRemove: (id: string) => void
}

const RuleTable: React.FC<RuleTableProps> = ({ items, onRemove }) => {
  const { columns, columnsData } = createColumns<Rule>(
    items,
    [
      {
        title: 'severity',
        field: 'severity',
        sortable: false,
        render: ({ severity }) => (
          <SeverityTag value={severity}>{severity}</SeverityTag>
        ),
      },
      {
        title: 'rule type',
        field: 'rule_type',
        sortable: false,
        render: ({ type }) => (
          <Typography transform="capitalize">{type}</Typography>
        ),
      },
      {
        title: 'name',
        field: 'name',
        sortable: false,
        render: ({ name }) => <Typography variant="body2">{name}</Typography>,
      },
      {
        title: 'exposure (%)',
        field: 'exposure',
        sortable: false,
        render: ({ percentage, type }) => {
          const color = type === 'exposure' ? undefined : 'grey3'
          const value =
            type === 'exposure'
              ? percentage
                ? `≥ ${percentage}%`
                : 'Any'
              : 'n/a'

          return <Typography color={color}>{value}</Typography>
        },
      },
      {
        title: 'score',
        field: 'score',
        sortable: false,
        render: ({ score, score_amount }) => {
          const color = score ? undefined : 'grey3'
          const value = score ? `≤ ${score_amount}` : 'n/a'

          return <Typography color={color}>{value}</Typography>
        },
      },
      {
        title: 'risk amount',
        field: 'risk_amount',
        sortable: false,
        render: ({ usd, usd_amount }) => {
          const value = usd
            ? `≥ ${formatMoney({
                value: usd_amount,
                currency: 'usd',
                precision: 0,
                code: '',
              })}`
            : 'Any'

          return <Typography>{value}</Typography>
        },
      },
      {
        title: 'direction',
        field: 'direction',
        sortable: false,
        render: ({ direction }) => {
          return <Typography>{directionLabel[direction]}</Typography>
        },
      },
      {
        title: 'common settings',
        field: 'common_settings',
        sortable: false,
        type: 'label',
        render: ({ block_client, run_retrospectivly, block_old_clients }) => {
          return (
            <Container className={cx('CommonSettings')}>
              {run_retrospectivly && (
                <HistoryIcon className={cx('HistoryIcon')} />
              )}
              {block_old_clients && (
                <HistoryBlockIcon className={cx('HistoryBlockIcon')} />
              )}
              {block_client && <BlockIcon className={cx('BlockIcon')} />}
            </Container>
          )
        },
      },
      {
        title: '',
        field: 'remove_action',
        sortable: false,
        render: (rule) => {
          return (
            <RemoveRuleConfirmationButton
              className={cx('RemoveAction')}
              submit={() => onRemove(rule.id)}
              rule={rule}
            />
          )
        },
      },
    ],
    {
      stubHeight: 58,
      stubAmount: 20,
    }
  )

  return (
    <Table className={cx('RuleTable')}>
      <Thead>
        <Tr>
          {columns.map(({ title, field, type }) => (
            <Th key={field} className={cx(field)} type={type}>
              {title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {columnsData.map((item, index) => {
          return (
            <Tr
              key={item?.id || index}
              className={cx('Row', { new: item?.new })}
            >
              {columns.map(({ field, render, type }) => (
                <Td key={field} className={cx(field)} type={type}>
                  {render(item)}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

const RulesSettings: React.FC = memo(() => {
  const [query, setQuery] =
    useObjectFormattedSearchParams<Partial<RuleQueryParams>>()

  const [isArchivedRules, setIsArchivedRules] = React.useState<true | false>(
    false
  )

  const [page, setPage] = React.useState(1)
  const [groupBy, setGroupBy] = React.useState('none')
  const [filters, setFilters] = React.useState<FiltersState>({
    ...defaultFiltersState,
    selected: query.selected || defaultFiltersState.selected,
    riskAmount: query.riskAmount || defaultFiltersState.riskAmount,
    direction: query.direction || defaultFiltersState.direction,
    ruleType: query.ruleType || defaultFiltersState.ruleType,
  })

  const handleChangeFilters = (newFilters: FiltersState) => {
    setPage(1)
    setFilters(newFilters)
  }

  const handleChangeGroupBy = (groupBy: string) => {
    setPage(1)
    setGroupBy(groupBy)
  }

  const setIsRightOverlapSidebarOpen = useRightOverlapSidebarStateSetter()
  const openOverlapSidebar = () => {
    setIsRightOverlapSidebarOpen(true)
  }

  const debouncedRiskAmount = useDebounceValue(
    filters.riskAmount,
    DEBOUNCE_IN_MS
  )

  const apiParams = React.useMemo(
    (): RequestParams => ({
      groupBy,
      severity: filters.severity,
      amountFrom: Number(debouncedRiskAmount?.from),
      amountTo: Number(debouncedRiskAmount?.to),
      direction: filters.direction,
      ruleType: filters.ruleType,
      commonSettings: filters.commonSettings,
      isArchivedRules,
    }),
    [filters, groupBy, isArchivedRules, debouncedRiskAmount]
  )

  const { rules, mutateRules, isLoading } = useRules(apiParams)

  const withFilters = React.useMemo(() => {
    return Boolean(
      filters.severity?.length ||
        filters.direction ||
        filters.ruleType ||
        filters.commonSettings?.autoBlock ||
        filters.commonSettings?.retrospectivelyApply ||
        filters.commonSettings?.retrospectivelyBlock ||
        debouncedRiskAmount
    )
  }, [filters, debouncedRiskAmount])

  React.useEffect(() => {
    const debouncedUpdateQuery = debounce(() => {
      setQuery(clearParams(filters))
    }, DEBOUNCE_IN_MS)
    debouncedUpdateQuery()
    return () => {
      debouncedUpdateQuery.cancel()
    }
  }, [filters, setQuery])

  const totalPages = React.useMemo(() => {
    if (rules) {
      return Math.ceil(rules.length / MAX_RULES_PER_PAGE)
    }
  }, [rules])

  const items = React.useMemo(() => {
    if (rules) {
      const start = MAX_RULES_PER_PAGE * (page - 1)
      const end = start + MAX_RULES_PER_PAGE
      return rules?.slice(start, end)
    }
  }, [rules, page])

  const onRemoveRule = async (id: string) => {
    await http.post(buildUrl`${getConfig()?.API}/api/rules/${id}/remove`)
    mutateRules(rules.filter((rule) => rule.id !== id))
  }

  const handleAddRule = React.useCallback(
    (newRule: Rule) => {
      mutateRules([{ ...newRule, new: true }, ...rules])
    },
    [rules]
  )

  HeaderSlot.useContent(() => <SettingsHeader />)
  RightOverlapSidebarSlot.useContent(
    ({ toggle, isOpen }) => (
      <RulesRightOverlapSidebar
        toggle={toggle}
        isOpen={isOpen}
        onCreateRuleSuccess={handleAddRule}
      />
    ),
    [rules]
  )

  return (
    <>
      <div className={cx('Head')}>
        <Typography variant="heading2" color="black">
          AML/ CFT Rules management
        </Typography>
        <Button
          variant="solid"
          color="primary"
          size="lg"
          onClick={openOverlapSidebar}
        >
          add rule
        </Button>
      </div>
      <CheckboxSwitchDouble
        value={isArchivedRules}
        onChange={setIsArchivedRules}
        leftLabel="Current rules"
        rightLabel="Archieved rules"
        leftValue={false}
        rightValue={true}
      />
      <RowDeprecated gap={3} className={cx('GroupByBlock')}>
        <Typography variant="body3" color="grey2">
          Group by
        </Typography>
        <RadioButtonGroup
          value={groupBy}
          onChange={handleChangeGroupBy}
          options={groupByOptions}
          direction="row"
        />
      </RowDeprecated>
      <Filters initialValues={filters} onChange={handleChangeFilters} />
      <MagicGrid className={cx('TableBlock')}>
        <Portlet>
          {{
            body: <RuleTable items={items} onRemove={onRemoveRule} />,
          }}
        </Portlet>
        {rules?.length === 0 && !isLoading && (
          <VoidText>
            {tabStub[isArchivedRules ? 'archived' : 'current']}
            <br />
            {withFilters &&
              'Try to change filters and/or time range restrictions'}
          </VoidText>
        )}
        <Pagination
          className={cx('Pagination')}
          totalPages={totalPages}
          value={page}
          onChange={setPage}
        />
      </MagicGrid>
    </>
  )
})

RulesSettings.displayName = 'RulesSettings'

export default RulesSettings

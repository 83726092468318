import React from 'react'
import classnames from 'classnames/bind'

import { Notification } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'

import { NewCaseNotification as N } from '../../apiServices'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface NewCaseNotificationProps {
  notification: N
  closeToast: () => void
}

export const NewCaseNotification: React.FC<NewCaseNotificationProps> = ({
  notification: { id },
  closeToast,
}) => (
  <Notification.Body closeToast={closeToast} type="attention">
    <Typography variant="body1" color="grey1">
      <Link to="/cases/:id" className={cx('Bold')} params={{ id }}>
        Case #{id}:{' '}
      </Link>
      New case created
    </Typography>
  </Notification.Body>
)

import React from 'react'
import {
  IconVariant,
  RoundSolidIcon,
  Stack,
  Stub,
  SurfaceContainer,
  Tooltip,
  TypographyNew,
} from '@clainio/web-platform'
import { SeverityTag } from '../../SeverityTag'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
import * as S from './TransactionInfoWidget.styles'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'
import { TransactionAmount } from './TransactionAmount'
import { TransactionInfoWidgetProps } from './TransactionInfoWidget.types'
import { TransactionRuleType } from './TransactionRuleType'
import { fromUnixTime } from 'date-fns'
import { useFormatDate } from '../../../hooks'
import { observer } from 'mobx-react-lite'
const transferTypeMap: Record<
  TransactionInfoWidgetProps['type'],
  Extract<IconVariant, 'FlowOut' | 'FlowIn'>
> = {
  incoming: 'FlowIn',
  outgoing: 'FlowOut',
}

export const TransactionInfoWidget = observer(
  ({
    time,
    rule,
    amountUsd,
    amount,
    type,
    token,
    isLoading,
  }: TransactionInfoWidgetProps) => {
    const formatDate = useFormatDate()
    return (
      <SurfaceContainer borderRadius={'md'} spaces={['xl', 'xxl']}>
        <ErrorBoundaryContainer>
          <S.BigStack>
            <Stack direction={'column'} gap={'md'}>
              {isLoading ? (
                <Stub isActive type={'grey'} height={17} width={100} />
              ) : (
                <TypographyNew
                  color={'onBackgroundBase'}
                  variant={'body100Normal'}
                >
                  {time ? formatDate(fromUnixTime(time)) : ''}
                </TypographyNew>
              )}
              <Stack gap={'lg'} align={'center'}>
                {isLoading ? (
                  <Stub
                    isActive
                    type={'grey'}
                    height={24}
                    width={140}
                    isFullWidth
                  />
                ) : (
                  <>
                    <SeverityTag value={rule.severity} width={'auto'}>
                      {rule.severity} SEVERITY
                    </SeverityTag>
                    <TransactionRuleType
                      name={rule.name}
                      type={rule.type}
                      percentage={rule.percentage}
                    />
                  </>
                )}
              </Stack>
            </Stack>
            <Stack gap={'lg'} align={'center'}>
              <Stub isActive={isLoading}>
                <Tooltip content={`${capitalizeFirstLetter(type)} transfer`}>
                  <div>
                    <RoundSolidIcon variant={transferTypeMap[type]} />
                  </div>
                </Tooltip>
              </Stub>
              <TransactionAmount
                isLoading={isLoading}
                amountUSD={amountUsd}
                amount={amount}
                token={token}
              />
            </Stack>
          </S.BigStack>
        </ErrorBoundaryContainer>
      </SurfaceContainer>
    )
  }
)

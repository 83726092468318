import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as ProfileIcon } from '../../assets/profile.svg'
import { Typography } from '@clain/core/ui-kit'
import { BaseLink } from '../../Link'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface ProfileItemProps {
  className?: string
  textClassName?: string
  iconClassName?: string
  to?: string
  profileName: string
  profileEmail: string
}

const ProfileItem = React.forwardRef<HTMLAnchorElement, ProfileItemProps>(
  (
    { className, textClassName, iconClassName, to, profileName, profileEmail },
    ref
  ) => {
    return (
      <BaseLink ref={ref} to={to} className={cx('ProfileItem', className)}>
        <ProfileIcon className={iconClassName} />
        <Typography color="grey4" className={textClassName}>
          {profileName || profileEmail}
        </Typography>
      </BaseLink>
    )
  }
)

export default ProfileItem

import React, { useEffect } from 'react'
import Dashboard from '../components/Dashboard'
import { useAppLayoutResetter } from '@clain/core/state'

export const PageDashboard = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])
  return <Dashboard />
}

import {
  IAiChartReportData,
  IChatTemporaryData,
  IncidentCaseChatStore,
} from '@compliance/modules/IncidentCaseChatStore'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import { ChatUserMessage } from '@compliance/apiServices/shared/Chat.types'
import { ContainerModule, interfaces } from 'inversify'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'

export const incidentCaseChatStoreModule = (
  aiChatReportData: IAiChartReportData,
  aiTemporaryData: IChatTemporaryData<
    SnakeToCamelCaseObject<ChatUserMessage>
  > = {
    fileIds: [],
    filesInProcessing: [],
    filePreviews: [],
    chatSystemMessageResponse: [],
    chatUserMessage: null,
    chatMessagesUsedInSummary: [],
    error: null,
  }
) =>
  new ContainerModule((bind: interfaces.Bind) => {
    bind<IAiChartReportData>(DY_TYPES.AiChartReportData).toConstantValue(
      aiChatReportData
    )

    bind<IChatTemporaryData<SnakeToCamelCaseObject<ChatUserMessage>>>(
      DY_TYPES.ChatTemporaryData
    ).toConstantValue(aiTemporaryData)

    bind<
      IncidentCaseChatStore<
        IAiChartReportData,
        SnakeToCamelCaseObject<ChatUserMessage>
      >
    >(DY_TYPES.IncidentCaseChatStore)
      .to(IncidentCaseChatStore)
      .inSingletonScope()
  })

import humanizeDuration, { Options } from 'humanize-duration'

const humanizer = humanizeDuration.humanizer({
  language: 'en',
})

const defaultOpts: Options = {
  units: ['d', 'h', 'm', 's'],
  round: true,
  delimiter: ' ',
}

export default function formatDuration(v: number, opts?: Options) {
  return v ? humanizer(v, { ...defaultOpts, ...opts }) : ''
}

export function formatDays(v: number) {
  return formatDuration(v, { units: ['d'] })
}

export function formatDurationLifetime(v: number, opts?: Options) {
  return formatDuration(v, {
    units: ['y', 'mo', 'd', 'h'],
    largest: 2,
    ...opts,
  })
}

import React from 'react'

import { DateISOString } from '@clain/core/types'
import Link from '@clain/core/Link'
import { Typography } from '@clain/core/ui-kit'
import { formatMoney } from '@clain/core/utils/format'
import { useFormatDate } from '../../hooks'

import SeverityTag from '../SeverityTag'
import RelatedItemContainer from '../RelatedItemContainer'
import { IncidentStatus } from '../IncidentActions/components/IncidentStatus'
import { observer } from 'mobx-react-lite'
interface RelatedExposureRuleIncidentCardProps {
  id: number
  severity: 'LOW' | 'MEDIUM' | 'HIGH'
  category: string
  amount: string
  riskAmount: number | null
  percentage: number
  cases?: Array<{ id: number; tag: string }>
  status?: 'ignored' | 'case' | 'new'
  createdAt?: DateISOString
}

const RelatedExposureRuleIncidentCard: React.FC<
  RelatedExposureRuleIncidentCardProps
> = ({
  id,
  severity,
  category,
  amount,
  riskAmount,
  percentage,
  cases,
  status,
  createdAt,
}) => {
  const formatDate = useFormatDate()
  return (
    <RelatedItemContainer
      items={[
        {
          name: 'ID',
          value: (
            <Link to="/incidents/:id" params={{ id: id }}>
              {id}
            </Link>
          ),
        },
        {
          name: 'Severity',
          value: <SeverityTag value={severity}>{severity}</SeverityTag>,
        },
        {
          name: 'Exposure',
          value: (
            <Typography variant="body2" color="grey1">
              {percentage ? `${category} ≥ ${percentage}%` : 'Any'}
            </Typography>
          ),
        },
        {
          name: 'Risk amount',
          value: riskAmount
            ? formatMoney({ value: riskAmount, currency: 'usd', code: '' })
            : 'Any',
        },
        {
          name: 'Amount',
          value: formatMoney({ value: amount, currency: 'usd', code: '' }),
        },
        {
          name: 'Status',
          value: (
            <IncidentStatus variant="inline" cases={cases} status={status} />
          ),
        },
        {
          name: 'Created',
          value: createdAt ? formatDate(new Date(createdAt), 'date') : null,
        },
      ]}
    />
  )
}

export default observer(RelatedExposureRuleIncidentCard)

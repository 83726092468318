import React from 'react'
import classnames from 'classnames/bind'

import {
  Table2 as Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  createColumns,
  HandleChangeSort,
  capitalizeFirstLetter,
  Icon,
} from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { CopyIcon } from '@clain/core/ui-kit'
import useHttp from '@clain/core/useHttp'
import Pagination from '@clain/core/Pagination'
import { ColDeprecated } from '@clain/core/ui-kit'
import buildUrl from '@clain/core/utils/buildUrl'
import TransactionHash from '../../TransactionHash'
import { observer } from 'mobx-react-lite'
import {
  WithdrawalData,
  WithdrawalDataItem,
  WithdrawalApiParams,
} from '../../../types/Client'
import VoidText from '../../VoidText'

import styles from './index.scss'
import { useFormatDate } from '../../../hooks'
import { getConfig } from '@clain/core/useConfig'

const cx = classnames.bind(styles)

const DEFAULT_PAGINATION_SIZE = 10

interface WithdrawalAddressTableProps {
  items: Array<WithdrawalDataItem>
  loading: boolean
  sortBy: string
  order: 'asc' | 'desc' | ''
  onChangeSort: HandleChangeSort
}

const WithdrawalAddressTable = observer(
  ({
    items,
    loading,
    sortBy,
    order,
    onChangeSort,
  }: WithdrawalAddressTableProps) => {
    const formatDate = useFormatDate()

    const { columns, columnsData } = createColumns<WithdrawalDataItem>(
      items,
      [
        {
          title: 'score',
          field: 'score',
          sortable: false,
          render: ({ counterparty }) => {
            return <Score value={counterparty?.score} />
          },
        },
        {
          title: 'counterparty',
          field: 'counterparty',
          sortable: false,
          render: ({ counterparty, currency }) => {
            return (
              <RowDeprecated className={cx('Cluster')}>
                <MagicGrid gap={0.25} className={cx('ClusterName')}>
                  <Link
                    to="%PLATFORM_URL%/:coin/cluster/:id"
                    params={{ coin: currency, id: counterparty?.root_id }}
                    className={cx('ClusterNameLink')}
                  >
                    {counterparty?.name}
                  </Link>
                  {counterparty?.category && (
                    <Typography variant="caption1" color="grey4">
                      {counterparty?.category}
                    </Typography>
                  )}
                </MagicGrid>
                <CopyIcon value={counterparty?.category} />
              </RowDeprecated>
            )
          },
        },
        {
          title: 'transaction',
          field: 'transaction',
          sortable: true,
          render: ({ address, transaction, currency }) => {
            return (
              <RowDeprecated>
                <Icon variant={capitalizeFirstLetter(currency)} />
                <Link
                  to="%PLATFORM_URL%/:coin/explorer/transaction/:id"
                  params={{ coin: currency, id: transaction }}
                >
                  <TransactionHash value={transaction} />
                </Link>
                <CopyIcon value={address} />
              </RowDeprecated>
            )
          },
        },
        {
          title: 'address',
          field: 'address',
          sortable: true,
          render: ({ address, currency }) => {
            return (
              <RowDeprecated>
                <Link
                  to="%PLATFORM_URL%/:coin/address/:id"
                  params={{ coin: currency, id: address }}
                  className={cx('Address')}
                >
                  <Typography variant="code1">{address}</Typography>
                </Link>
                <CopyIcon value={address} />
              </RowDeprecated>
            )
          },
        },
        {
          title: 'created at',
          field: 'inserted_at',
          sortable: true,
          type: 'number',
          render: ({ inserted_at }) => {
            const date = formatDate(new Date(inserted_at), 'date')
            const time = formatDate(new Date(inserted_at), 'time')

            return (
              <ColDeprecated gap={0} align="right">
                <Typography>{date}</Typography>
                <Typography variant="caption1" color="grey4">
                  {time}
                </Typography>
              </ColDeprecated>
            )
          },
        },
      ],
      {
        stubHeight: 70,
        stubAmount: DEFAULT_PAGINATION_SIZE,
      }
    )

    return (
      <Table loading={loading}>
        <Thead>
          <Tr>
            {columns.map(({ title, field, type, sortable }) => (
              <Th
                key={field}
                className={cx(field)}
                type={type}
                sortable={sortable}
                name={field}
                sortBy={sortBy}
                order={order}
                onChangeSort={onChangeSort}
              >
                {title}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {columnsData.map((item, index) => {
            return (
              <Tr key={`columnsData_${index}`}>
                {columns.map(({ field, render, type }) => (
                  <Td key={field} className={cx(field)} type={type}>
                    {render(item)}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    )
  }
)

interface WithdrawalAddressProps {
  clientId?: string | number
}

const WithdrawalAddress = ({ clientId }: WithdrawalAddressProps) => {
  const [page, setPage] = React.useState(1)
  const [sortBy, setSortBy] = React.useState('')
  const [order, setOrder] = React.useState<'asc' | 'desc' | ''>('')

  const apiParams = React.useMemo(
    (): WithdrawalApiParams => ({
      page,
      sort_by: sortBy,
      order,
    }),
    [page, sortBy, order]
  )

  const handleChangeSort = (sortBy, order) => {
    setSortBy(sortBy)
    setOrder(order)
  }

  const { data, isLoading } = useHttp<WithdrawalData>(
    buildUrl`${
      getConfig()?.API
    }/api/clients/${clientId}/withdrawals?${apiParams}`
  )

  return (
    <>
      <WithdrawalAddressTable
        items={data?.withdrawals}
        loading={isLoading}
        sortBy={sortBy}
        order={order}
        onChangeSort={handleChangeSort}
      />
      {data?.withdrawals.length === 0 && (
        <VoidText>No deposits or withdrawals yet</VoidText>
      )}
      <Pagination
        className={cx('Pagination')}
        totalPages={data?.total_pages}
        value={page}
        onChange={setPage}
      />
    </>
  )
}

export default WithdrawalAddress

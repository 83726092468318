import 'reflect-metadata'
import http from '@clain/core/http'
import { ctx } from './ctx'
import React, { PropsWithChildren } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { useAuth, useHttpInterceptorEffect } from '@clain/core/Auth'
import { useSettings } from './hooks'
import { ThemeProvider } from 'styled-components'
import { enterprise } from '@clain/core/ui-kit'
import App, { RootContent } from '@clain/core/App'
import { navItems } from './navItems'
import UserDropdown from '@clain/core/UserDropdown'
import { getConfig } from '@clain/core/useConfig'

const wss = getConfig()?.COMPLIANCE_WS
const refreshApiToken = async (token: string) => {
  http.config({ token })
  await ctx.reconnectWithNewToken(token)
}
const initApiToken = async (
  token: string,
  refreshToken: () => Promise<string>
) => {
  http.config({ token })
  await ctx.init({ wss, token, refreshToken })
}
const AppComponent: React.FC<PropsWithChildren> = withAuthenticationRequired(
  ({ children }) => {
    const { isTokenProvided, logout, refreshToken } = useAuth({
      initApiToken,
      refreshApiToken,
    })
    useHttpInterceptorEffect({
      refreshToken,
      logout,
    })
    const { user } = useSettings()
    return (
      isTokenProvided && (
        <ThemeProvider theme={enterprise['light']}>
          <App
            navItems={navItems}
            logout={logout}
            slots={{
              userDropdown: (
                <UserDropdown logout={logout} email={user?.email} />
              ),
            }}
          >
            {children}
          </App>
        </ThemeProvider>
      )
    )
  }
)
export default function RootApp() {
  return <RootContent AppComponent={AppComponent} />
}

import React from 'react'
import { Stack } from '@clainio/web-platform'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
import CounterpartyInfoCard from '../../InfoCard/CounterpartyInfoCard'
import CounterpartyExposure from '../../Exposure/CounterpartyExposure'
import { CounterpartyWidgetProps } from './CounterpartyWidget.types'

export const CounterpartyWidget = ({
  loading,
  view,
  counterparty,
  currency,
  exposure,
  exposureSnapshot,
}: CounterpartyWidgetProps) => {
  return (
    <Stack direction={'column'} gap={'xl'}>
      <ErrorBoundaryContainer>
        <CounterpartyInfoCard
          loading={loading}
          counterparty={counterparty}
          currency={currency}
        />
      </ErrorBoundaryContainer>
      <ErrorBoundaryContainer>
        <CounterpartyExposure
          mode="compact"
          data={view === 'snapshot' ? exposureSnapshot : exposure}
        />
      </ErrorBoundaryContainer>
    </Stack>
  )
}

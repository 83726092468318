export const DY_TYPES = {
  // incident api services
  IncidentApiService: Symbol.for('IncidentApiService'),
  IncidentSocketService: Symbol.for('IncidentSocketService'),
  IncidentService: Symbol.for('IncidentService'),
  // client api services
  ClientService: Symbol.for('ClientService'),
  // case api services
  CaseApiService: Symbol.for('CaseApiService'),
  CaseSocketService: Symbol.for('CaseSocketService'),
  CaseService: Symbol.for('CaseService'),
  // incident case chat store
  AiChartReportData: Symbol.for('AiChartReportData'),
  ChatTemporaryData: Symbol.for('ChatTemporaryData'),
  IncidentCaseChatStore: Symbol.for('IncidentCaseChatStore'),
  // incident store
  IncidentStore: Symbol.for('IncidentStore'),
  IncidentViewModel: Symbol.for('IncidentViewModel'),
  IncidentViewModelFacade: Symbol.for('IncidentViewModelFacade'),
  // case store
  CaseStore: Symbol.for('CaseStore'),
  CaseViewModel: Symbol.for('CaseViewModel'),
  CaseViewModelFacade: Symbol.for('CaseViewModelFacade'),
}

import React from 'react'
import { EllipsisTypography } from '@clainio/web-platform'
import { RuleType } from '../../../types/Rule'

interface TransactionRuleTypeProps {
  type: RuleType
  percentage: number
  name: string
}

const getTransactionRuleTypeText = ({
  type,
  percentage,
  name,
}: TransactionRuleTypeProps) => {
  switch (type) {
    case 'category': {
      return `100% ${name}`
    }
    case 'score':
    case 'entity': {
      return name
    }
    case 'exposure': {
      return `${percentage}% ${name}`
    }
    default: {
      return ''
    }
  }
}

export const TransactionRuleType = (params: TransactionRuleTypeProps) => {
  return (
    <EllipsisTypography variant={'body100Accent'} color={'onBackgroundBase'}>
      {getTransactionRuleTypeText(params)}
    </EllipsisTypography>
  )
}

import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as PlusIcon } from '@clain/core/assets/plus.svg'
import { ReactComponent as InfoIcon } from '@clain/core/assets/info_outline.svg'

import { Container } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../hooks'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ConfirmButton } from '@clain/core/ui-kit'
import { Avatar } from '@clain/core/ui-kit'
import { CheckboxSwitchDouble } from '@clain/core/ui-kit'
import Form from '@clain/core/Form'
import { Battery } from '@clain/core/ui-kit'
import useHttp from '@clain/core/useHttp'
import { TagDeprecated } from '@clain/core/ui-kit'
import { useSettings } from '../../../hooks'
import { notify } from '@clain/core/ui-kit'

import SettingsHeader from '../SettingsHeader'
import styles from './index.scss'
import { Modal, ModalSlot } from '@clain/core/ui-kit'
import AddNewOfficerForm from './AddNewOfficerForm'
import http from '@clain/core/http'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

interface MemberUser {
  id: number
  role?: string
  crm_access: boolean
  avatar: null | string
  email: string
  inserted_at: string
  name: string
  position: null | string
}

interface MemberCardProps {
  user: MemberUser
  showRemoveMemberButton: boolean
  showAddMemberButton: boolean
  allowAddMember: boolean
  addMember: (id: number) => void
  removeMember: (id: number) => void
}

const MemberCard = observer(
  ({
    user,
    showRemoveMemberButton,
    showAddMemberButton,
    allowAddMember,
    addMember,
    removeMember,
  }: MemberCardProps) => {
    const formatDate = useFormatDate()

    const showAddToCRMButton =
      showAddMemberButton && !user.crm_access && allowAddMember
    const showCRMUserTag = showAddMemberButton && user.crm_access
    return (
      <div
        className={cx('MemberCard', {
          supervisor: user.position === 'supervisor',
          disabled: showAddMemberButton && user.crm_access,
        })}
      >
        <Avatar name={user.name} className={cx('Avatar')} />
        <div className={cx('Info')}>
          <div className={cx('Role')}>{user.role || 'Officer'}</div>
          <div className={cx('Name')}>{user.name}</div>
          <div className={cx('Position')}>{user.position}</div>
          <div className={cx('Date')}>
            {formatDate(new Date(user.inserted_at), 'date')}
          </div>
        </div>
        <div className={cx('Email')}>{user.email}</div>
        <div className={cx('Actions')}>
          {showAddToCRMButton && (
            <ConfirmButton
              onClick={() => addMember(user.id)}
              className={cx('MemberCardButton')}
              title={
                <Typography variant="heading5" color="black">
                  Add officer to CRM
                </Typography>
              }
              acceptText="add officer"
              content={
                <>
                  <Typography variant="body3">{user.name}’s</Typography> will
                  will be provided with access to CRM.
                </>
              }
            >
              add to CRM
            </ConfirmButton>
          )}
          {showCRMUserTag && (
            <TagDeprecated variant="alternate">crm user</TagDeprecated>
          )}
          {showRemoveMemberButton && (
            <ConfirmButton
              onClick={() => removeMember(user.id)}
              className={cx('MemberCardButton')}
              title={
                <Typography variant="heading5" color="black">
                  Remove from CRM
                </Typography>
              }
              acceptText="Remove"
              content={
                <>
                  <Typography variant="body3">{user.name}’s</Typography> access
                  to CRM will be disabled.
                </>
              }
            >
              Remove from CRM
            </ConfirmButton>
          )}
        </div>
      </div>
    )
  }
)

const MemberCardPlus = ({ disabled = false }) => {
  const target: ModalSlot = ({ show }) => {
    return (
      <div
        className={cx('MemberCardPlus', { disabled })}
        onClick={() => !disabled && show()}
      >
        <RowDeprecated gap={0.75}>
          <PlusIcon className={cx('PlusIcon')} />
          <Typography
            className={cx('NewOfficerLabel')}
            variant="body3"
            color="grey3"
          >
            New Officer
          </Typography>
        </RowDeprecated>
      </div>
    )
  }

  const body: ModalSlot = ({ hide }) => (
    <AddNewOfficerForm onSubmitSuccess={hide} onCancel={hide} />
  )

  return (
    <Modal
      target={target}
      closable
      title={
        <Typography variant="heading5" color="black">
          Add new officer to CRM
        </Typography>
      }
    >
      {body}
    </Modal>
  )
}

interface MembersListProps {
  users: Array<MemberUser>
  showAddMember: boolean
  showRemoveMemberButton: boolean
  showAddMemberButton: boolean
  allowAddMember: boolean
  addMember: (id: number) => void
  removeMember: (id: number) => void
}

const MembersList = ({
  users,
  showAddMember,
  allowAddMember,
  showAddMemberButton,
  showRemoveMemberButton,
  addMember,
  removeMember,
}: MembersListProps) => {
  return (
    <div className={`${cx('MembersList')} sentry-block sentry-mask`}>
      {showAddMember && <MemberCardPlus disabled={!allowAddMember} />}
      {users.map((u) => (
        <MemberCard
          key={u.id}
          user={u}
          allowAddMember={allowAddMember}
          showRemoveMemberButton={showRemoveMemberButton}
          showAddMemberButton={showAddMemberButton}
          addMember={addMember}
          removeMember={removeMember}
        />
      ))}
    </div>
  )
}

const ReachedLimitPanel = () => (
  <Container className={cx('ReachedLimitPanel')}>
    <RowDeprecated gap={0.75}>
      <InfoIcon />
      <Typography variant="body3">
        You reached the limit of team slots for you subscription plan.
        <br />
        Please, reach out our sales.
      </Typography>
    </RowDeprecated>
  </Container>
)

const TeamSettings: React.FC = () => {
  const handleSubmit = (data) => {
    console.log('save', data)
  }

  const [status, setStatus] = React.useState<'current' | 'all'>('current')

  const { data, mutate } = useHttp<{ users: Array<MemberUser> }>(
    buildUrl`${getConfig()?.API}/api/settings/users/all`
  )

  const { settings } = useSettings()

  const filteredUsers = React.useMemo(() => {
    if (!data?.users) return []
    if (status === 'all') return data.users
    if (status === 'current')
      return data.users.filter((user) => user.crm_access)
  }, [data, status])

  const activeUsers = data?.users?.filter((u) => u.crm_access)
  const isLimitReached = settings.maxUsers <= activeUsers?.length

  HeaderSlot.useContent(() => <SettingsHeader />)

  const reactivateUser = (id: number) => {
    http
      .post(buildUrl`${getConfig()?.API}/api/settings/users/${id}/reactivate`)
      .then((response) => {
        if (response.data.status) {
          mutate({
            users: data.users.map((user) => {
              if (user.id == id) {
                return {
                  ...user,
                  crm_access: true,
                }
              }
              return user
            }),
          })
        } else {
          throw new Error('Bad request')
        }
      })
      .catch((e) => {
        console.log(e)
        notify('An error occured while reactivating the user', { type: 'fail' })
      })
  }

  const deactivateUser = (id: number) => {
    http
      .post(buildUrl`${getConfig()?.API}/api/settings/users/${id}/deactivate`)
      .then((response) => {
        if (response.data.status) {
          mutate({
            users: data.users.map((user) => {
              if (user.id == id) {
                return {
                  ...user,
                  crm_access: false,
                }
              }
              return user
            }),
          })
        } else {
          throw new Error('Bad request')
        }
      })
      .catch((e) => {
        console.log(e)
        notify('An error occured while deactivating the user', { type: 'fail' })
      })
  }

  return (
    <Form className={cx('Form')} onSubmit={handleSubmit}>
      {() => {
        return (
          <MagicGrid gap={4}>
            <MagicGrid gap={3}>
              <Typography variant="heading2" color="black">
                Team setup
              </Typography>
              <RowDeprecated gap={1.2}>
                <RowDeprecated gap={2.5} fullWidth>
                  <Typography
                    variant={'body1'}
                    color={'grey2'}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Team members
                  </Typography>
                  <Battery
                    capacity={settings.maxUsers}
                    level={activeUsers?.length}
                  />
                </RowDeprecated>
                {settings.maxUsers ? (
                  <Typography
                    variant="body1"
                    color={'black'}
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    {activeUsers?.length} of {settings.maxUsers}
                  </Typography>
                ) : null}
              </RowDeprecated>
            </MagicGrid>

            <MagicGrid gap={2}>
              <CheckboxSwitchDouble
                leftLabel="Current CRM users"
                rightLabel="Other users"
                leftValue="current"
                rightValue="all"
                value={status}
                onChange={setStatus}
              />

              {isLimitReached && <ReachedLimitPanel />}

              <MembersList
                users={filteredUsers}
                showAddMember={status === 'current'}
                showRemoveMemberButton={status === 'current'}
                showAddMemberButton={status === 'all'}
                allowAddMember={!isLimitReached}
                addMember={reactivateUser}
                removeMember={deactivateUser}
              />
            </MagicGrid>
          </MagicGrid>
        )
      }}
    </Form>
  )
}

export default TeamSettings

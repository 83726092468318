import React from 'react'
import ProfileItem from '@clain/core/Layout/NavMenu/ProfileItem'
import { useSettings } from '../../hooks'

export interface ProfileItemContainerProps {
  className?: string
  textClassName?: string
  iconClassName?: string
  to?: string
}

export const ProfileItemContainer = (props: ProfileItemContainerProps) => {
  const { user } = useSettings()
  return (
    <ProfileItem
      {...props}
      profileName={user?.name}
      profileEmail={user?.email}
    />
  )
}

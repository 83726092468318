import { Navigate } from '@clain/core/Router/router'

import NoAccess from './pages/NoAccess'
import { getConfig } from '@clain/core/useConfig'
import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import ErrorPage from '@clain/core/Router/ErrorPage'
import { captureException, wrapCreateBrowserRouter } from '@sentry/react'
import Layout from '@clain/core/Layout'
import NotFound from '@clain/core/NotFound'
import Root from './app'
import { PageDashboard } from './pages/Dashboard'
import Profile from './pages/Profile'
import { PageIncidents } from './pages/Incidents'
import { PageIncident } from './pages/Incident'
import { PageCases } from './pages/Cases'
import { PageCase } from './pages/Case'
import { PageStartCase } from './pages/StartCase'
import { PageClients } from './pages/Clients'
import { PageClient } from './pages/Client'
import { PageTransactions } from './pages/Transactions'
import PageRulesSettings from './pages/RulesSettings'
import PageGlobalSettings from './pages/GlobalSettings'
import PageProfileSettings from './pages/ProfileSettings'
import PageApiSettings from './pages/ApiSettings'
import PageTeamSettings from './pages/TeamSettings'
import PageNotificationsSettings from './pages/NotificationsSettings'
import PageFullReset from './pages/FullReset'

const config = getConfig()
const env = config?.ENV
if (env !== 'development') {
  Sentry.init({
    dsn: 'https://458ce858fdc44590af66aa29b1d071fb@o252637.ingest.us.sentry.io/5413542',
    environment: `CRM-${env}`,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.httpClientIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracePropagationTargets: [getConfig()?.COMPLIANCE_URL],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const errorPage = <ErrorPage captureException={captureException} />

const sentryWrapper = wrapCreateBrowserRouter(
  createBrowserRouter
) as typeof createBrowserRouter

const routes = sentryWrapper([
  {
    path: '/',
    element: <Root />,
    errorElement: errorPage,
    children: [
      {
        element: <Layout />,
        errorElement: errorPage,
        children: [
          {
            path: '*',
            element: <NotFound />,
          },
          {
            index: true,
            element: <Navigate to={'dashboard'} replace />,
          },
          {
            path: 'dashboard',
            element: <PageDashboard />,
          },
          {
            path: 'dashboard/:tab',
            element: <PageDashboard />,
          },
          {
            path: 'profile',
            element: <Profile />,
          },
          {
            path: 'incidents',
            element: <PageIncidents />,
          },
          {
            path: 'incidents/:id',
            element: <PageIncident />,
          },
          {
            path: 'cases',
            element: <PageCases />,
          },
          {
            path: 'cases/:id',
            element: <PageCase />,
          },
          {
            path: 'cases/:id/start',
            element: <PageStartCase />,
          },
          {
            path: 'clients',
            element: <PageClients />,
          },
          {
            path: 'clients/:id',
            element: <PageClient />,
          },
          {
            path: 'clients/:id/:tab',
            element: <PageClient />,
          },
          {
            path: 'transfers',
            element: <PageTransactions />,
          },
          {
            path: 'settings',
            children: [
              {
                index: true,
                element: <Navigate to={'rules'} replace />,
              },
              {
                path: 'rules',
                element: <PageRulesSettings />,
              },
              {
                path: 'global',
                element: <PageGlobalSettings />,
              },
              {
                path: 'profile',
                element: <PageProfileSettings />,
              },
              {
                path: 'api',
                element: <PageApiSettings />,
              },
              {
                path: 'team',
                element: <PageTeamSettings />,
              },
              {
                path: 'notifications',
                element: <PageNotificationsSettings />,
              },
              getConfig().ENV === 'production'
                ? {}
                : {
                    path: 'reset',
                    element: <PageFullReset />,
                  },
            ],
          },
          {
            path: 'noaccess',
            element: <NoAccess />,
          },
        ],
      },
    ],
  },
])

export default routes

import React from 'react'

import { HeaderSlot } from '@clain/core/Layout/slots'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ConfirmButton } from '@clain/core/ui-kit'
import http from '@clain/core/http'
import { useNavigate } from '@clain/core/Router/router'

import SettingsHeader from '../SettingsHeader'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'

const FullReset: React.FC = () => {
  const nav = useNavigate()

  HeaderSlot.useContent(() => <SettingsHeader />)

  const reset = async () => {
    await http.post(buildUrl`${getConfig()?.API}/api/settings/reset`)
    nav('/dashboard')
  }

  return (
    <ColDeprecated align="left">
      <Typography variant="subheading1">
        All data will be permanently deleted
      </Typography>
      <ConfirmButton
        onClick={reset}
        acceptText="yes"
        cancelText="cancel"
        title={
          <>
            Are you sure you want to permanently delete all data?
            <br />
            Recovery will not be possible
          </>
        }
        variant="solid"
        color="critical"
      >
        reset
      </ConfirmButton>
    </ColDeprecated>
  )
}

export default FullReset

export default [
  {
    id: -1,
    name: 'Bitcoin',
    symbol: 'BTC',
    blockchain: 'BTC',
    icon: null,
  },
  {
    id: -2,
    name: 'Ethereum',
    symbol: 'ETH',
    blockchain: 'ETH',
    icon: null,
  },
]

import { Font } from 'pts'
import { IController } from '../types'

class StyleConroller implements IController {
  public ctrl: IController

  get form() {
    return this.ctrl.form
  }

  get space() {
    return this.ctrl.space
  }

  constructor(ctrl: IController) {
    this.ctrl = ctrl
  }

  init() {
    this.applyInterFont()
  }

  private applyInterFont() {
    const InterFont = new Font(14, 'Inter', '400')
    this.form.font(InterFont)
  }
}

export default StyleConroller

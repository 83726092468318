import React, { PropsWithChildren } from 'react'
import classnames from 'classnames/bind'

import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { EnterKey } from '@clain/core/ui-kit'
import { BaseLink } from '@clain/core/Link'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface BaseSearchCardProps {
  icon: React.ReactElement
  id: string | number
  href: string
  selected: boolean
}

const BaseSearchCard: React.FC<PropsWithChildren<BaseSearchCardProps>> = ({
  icon,
  id,
  href,
  selected,
  children,
}) => {
  const styledIcon = React.cloneElement(icon, {
    className: cx('Icon'),
  })

  return (
    <BaseLink to={href} className={cx('BaseSearchCard', { selected })}>
      <Container gap={[0.5, 1]}>
        <RowDeprecated gap={0.5}>
          {styledIcon}
          <Typography variant="body2" color="black">
            {id}
          </Typography>
          {children}
        </RowDeprecated>
      </Container>
      <EnterKey className={cx('EnterBlock')} />
    </BaseLink>
  )
}

export default BaseSearchCard

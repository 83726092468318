import {
  ApiServicesStateReactionFacade,
  APIServiceStateFacade,
  APIServiceStateViewModel,
  StateViewModel,
} from '@clain/core/utils/mobxUtils'
import {
  CaseActivityApiParams,
  CaseActivityDataResponse,
  CaseApiParams,
  CaseDataResponse,
  CasesAssigneesDataResponse,
  IIncidentService,
  IncidentsApiParams,
  IncidentsDataResponse,
} from '@compliance/apiServices'
import {
  ClientApiParams,
  ClientDataResponse,
  IClientService,
} from '@compliance/apiServices/Client'
import {
  SnakeToCamelCaseMapping,
  SnakeToCamelCaseObject,
} from '@clain/core/utilsTypes'
import {
  CasesApiParams,
  CasesDataResponse,
  ICaseApiService,
} from '@compliance/apiServices/Case'
import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable } from 'mobx'
import { ChatUserMessage } from '@compliance/apiServices/shared/Chat.types'
import {
  IAiChartReportData,
  IncidentCaseChatStore,
} from '@compliance/modules/IncidentCaseChatStore'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'

const apiServicesStateFacade = new ApiServicesStateReactionFacade({
  apiServiceStates: {
    case: new APIServiceStateFacade<
      Parameters<ICaseApiService['getCaseById']>,
      SnakeToCamelCaseObject<CaseDataResponse>
    >(new APIServiceStateViewModel()),
    client: new APIServiceStateFacade<
      Parameters<IClientService['getClientById']>,
      SnakeToCamelCaseObject<ClientDataResponse>
    >(new APIServiceStateViewModel()),
    cases: new APIServiceStateFacade<
      Parameters<ICaseApiService['getCases']>,
      SnakeToCamelCaseObject<CasesDataResponse>
    >(new APIServiceStateViewModel()),
    assignees: new APIServiceStateFacade<
      Parameters<ICaseApiService['getAssignees']>,
      SnakeToCamelCaseMapping<CasesAssigneesDataResponse>
    >(new APIServiceStateViewModel()),
    activity: new APIServiceStateFacade<
      Parameters<ICaseApiService['getCaseActivity']>,
      SnakeToCamelCaseObject<CaseActivityDataResponse>
    >(new APIServiceStateViewModel()),
    incidents: new APIServiceStateFacade<
      Parameters<IIncidentService['rest']['getIncidents']>,
      SnakeToCamelCaseObject<IncidentsDataResponse>
    >(new APIServiceStateViewModel()),
  },
  apiParams: {
    case: new StateViewModel<CaseApiParams>(),
    client: new StateViewModel<ClientApiParams>(),
    cases: new StateViewModel<CasesApiParams>(),
    assignees: new StateViewModel(),
    activity: new StateViewModel<CaseActivityApiParams>(),
    incidents: new StateViewModel<IncidentsApiParams>(),
  },
})

const commonData = new StateViewModel<{
  caseId: number
}>({
  caseId: null,
})

@injectable()
export class CaseStore {
  public apiServicesStateFacade = apiServicesStateFacade
  public commonData = commonData

  @observable public isInitialized = false

  constructor(
    @inject(DY_TYPES.IncidentCaseChatStore)
    public chatDataStore: IncidentCaseChatStore<
      IAiChartReportData,
      SnakeToCamelCaseObject<ChatUserMessage>
    >
  ) {
    makeObservable(this)
  }

  @action
  public setInitialized = (isInitialized: boolean) => {
    this.isInitialized = isInitialized
  }

  @computed
  public get isFirstLoading() {
    return this.apiServicesStateFacade.getIsFirstLoadingByService('case')
  }

  @computed
  public get data() {
    return this.apiServicesStateFacade.getStateByService('case')
  }

  @computed
  public get clientId() {
    return this.data?.client?.id
  }

  @computed
  public get assignees() {
    return this.apiServicesStateFacade.getStateByService('assignees')
  }

  @computed
  public get activityData() {
    return this.apiServicesStateFacade.getStateByService('activity')
  }

  @computed
  public get incident() {
    return this.data?.incidents?.[0]
  }

  @computed
  public get clientData() {
    return this.apiServicesStateFacade.getStateByService('client')
  }
  @computed
  public get isClientDataFirstLoading() {
    return this.apiServicesStateFacade.getIsFirstLoadingByService('client')
  }

  @computed
  public get relatedIncidents() {
    const relatedIncidentsData =
      this.apiServicesStateFacade.getStateByService('incidents')
    return relatedIncidentsData?.data
      ? relatedIncidentsData.data.filter(
          (related) => related.incident?.id != this.incident?.id
        )
      : []
  }

  @computed
  public get rules() {
    if (!this.incident) {
      return [] as unknown as (typeof this.incident.rule)[]
    }
    return [this.incident.rule, ...this.incident.otherRules]
  }

  @computed
  public get relatedCases() {
    const relatedCasesData =
      this.apiServicesStateFacade.getStateByService('cases')
    return relatedCasesData?.cases
      ? relatedCasesData.cases.filter(
          (related) => related.id != this.commonData.state.caseId
        )
      : []
  }

  @action
  public clear = () => {
    this.commonData.clearState()
    this.chatDataStore.clear()
    this.apiServicesStateFacade.clear()
    this.isInitialized = false
  }
}

import React from 'react'
import classnames from 'classnames/bind'

import { InputDropdown } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

const paginationSizes = [10, 20, 50, 100]

interface PaginationSizeProps {
  total: number
  value: string
  onChange?: (value: number) => void
  disabled?: boolean
}

const PaginationSize: React.FC<PaginationSizeProps> = ({
  total,
  value,
  onChange,
  disabled,
}) => {
  const onValueChange = React.useCallback((value) => {
    onChange?.(value)
  }, [])

  const sizeOptions = React.useMemo(() => {
    const sizes: Array<number> = paginationSizes.filter((size) => size < total)

    if (total <= 100) {
      sizes.push(total)
    }

    return sizes
  }, [total])

  return (
    <Typography color="grey3" className={cx('PaginationSize')}>
      Show
      <InputDropdown
        className={cx('Input')}
        variant="outline"
        value={value}
        onChange={onValueChange}
        placement="bottom"
        dropdownClassName={cx('InputDropdown')}
        disabled={disabled}
      >
        <MagicGrid gap={0.5} className={cx('SizeContainer')}>
          {sizeOptions.map((size) => (
            <Typography
              key={size}
              className={cx('SizeOption')}
              variant="body3"
              color="grey3"
              onClick={() => onChange(size)}
            >
              {size}
            </Typography>
          ))}
        </MagicGrid>
      </InputDropdown>
      of {total ?? 'unknown'}
    </Typography>
  )
}

export default PaginationSize

import React from 'react'

import { RangeSlider, RangeValue, From, To } from '@clain/core/ui-kit'

interface ExposureRangeSliderProps {
  value: RangeValue
  onChange: (value: RangeValue) => unknown
  disabled?: boolean
  from?: From
  to?: To
}

const ExposureRangeSlider: React.FC<ExposureRangeSliderProps> = ({
  value,
  onChange,
  from,
  to,
  disabled,
}) => (
  <RangeSlider
    min={0}
    max={100}
    dots={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
    step={10}
    value={value}
    onChange={onChange}
    from={from}
    to={to}
    disabled={disabled}
  />
)

export default ExposureRangeSlider

import React from 'react'
import { ReportWidgetProps } from './ReportWidget.types'
import { Markdown, Stack, StatusBlock, Stub } from '@clainio/web-platform'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
import * as S from './ReportWidget.styles'

export const ReportWidget = ({ data, status }: ReportWidgetProps) => {
  const renderContent = () => {
    switch (status) {
      case 'new': {
        return (
          <S.ReportWidgetFullHeightContainer>
            <Stack padding={'xxl'}>
              <StatusBlock gap={'md'}>
                <StatusBlock.Image
                  type={'report'}
                  width={'56px'}
                  height={'56px'}
                />
                <StatusBlock.Title variant={'heading200Normal'}>
                  Report Was Not Generated
                </StatusBlock.Title>
              </StatusBlock>
            </Stack>
          </S.ReportWidgetFullHeightContainer>
        )
      }
      case 'to_process':
        return (
          <S.ReportWidgetFullHeightContainer>
            <Stack padding={'xxl'}>
              <StatusBlock>
                <StatusBlock.Spinner
                  color={'grey2'}
                  size={'lg'}
                  borderWidth={5}
                />
                <StatusBlock.Title variant={'body100Normal'}>
                  Generating your report. It’ll be ready soon.
                </StatusBlock.Title>
              </StatusBlock>
            </Stack>
          </S.ReportWidgetFullHeightContainer>
        )
      case 'in_process':
      case 'done':
      default:
        return (
          <Stub isActive={status === 'in_process'}>
            <Stack padding={'xxl'}>
              <Markdown>{data}</Markdown>
            </Stack>
          </Stub>
        )
    }
  }
  return <ErrorBoundaryContainer>{renderContent()}</ErrorBoundaryContainer>
}

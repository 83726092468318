import React from 'react'
import classnames from 'classnames/bind'

import { TagDeprecated, TagVariantDeprecated } from '@clain/core/ui-kit'

import { CASE_STATUS } from '../../types/Case'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface CaseStatusTagProps {
  size?: 'md' | 'lg'
  value: CASE_STATUS
  closedAs: boolean
  recommendAs: boolean
  className?: string
}

const CaseStatusTag = ({
  value,
  closedAs,
  recommendAs,
  className,
  size,
}: CaseStatusTagProps) => {
  let variant: TagVariantDeprecated = 'default'

  let label: string = value

  if (value === 'new') {
    variant = 'alternate'
  }

  if (value === 'pending') {
    label = 'review'
  }

  let subStatus

  if (typeof closedAs === 'boolean') {
    subStatus = closedAs ? 'TP' : 'FP'
  }

  if (typeof recommendAs === 'boolean') {
    subStatus = recommendAs ? 'TP' : 'FP'
  }

  return (
    <span
      className={cx('CaseStatusTag', { withSubStatus: subStatus }, className)}
    >
      <TagDeprecated
        variant={variant}
        size={size}
        className={cx('Status', subStatus)}
      >
        {label}
      </TagDeprecated>
      {subStatus && (
        <TagDeprecated
          variant={variant}
          size={size}
          className={cx('Substatus', subStatus)}
        >
          {subStatus}
        </TagDeprecated>
      )}
    </span>
  )
}

export default CaseStatusTag

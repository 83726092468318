import React from 'react'

import { TextField } from '@clain/core/ui-kit'
interface CaseSearchInputProps {
  value?: string
  onChange?: (value?: string) => void
}

const SearchInput = ({ value, onChange: onChange }: CaseSearchInputProps) => {
  const [inputValue, setInputValue] = React.useState(value || '')

  const handleChange = (e) => {
    setInputValue(e)
    onChange?.(e)
  }

  const handleClear = () => {
    setInputValue('')
    onChange?.('')
  }

  return (
    <TextField
      fullWidth
      clearable
      value={inputValue}
      // label={inputLabel}
      autoComplete="off"
      variant="outline"
      name="search"
      placeholder="Search by Label"
      startAddon={{ iconVariant: 'Search' }}
      onChange={handleChange}
      onClear={handleClear}
    />
  )
}

export default SearchInput

import React from 'react'
import classnames from 'classnames/bind'

import { RightOverlapSidebar } from '@clain/core/Layout/RightOverlapSidebar'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'
import { ReactComponent as LightningIcon } from '@clain/core/assets/incidents_new.svg'
import { ReactComponent as CasesIcon } from '@clain/core/assets/cases_new.svg'

import ClientIncidentsList from './ClientIncidentsList'
import ClientCasesList from './ClientCasesList'

import { IncidentsDataItem } from '../../types/Incident'
import { CasesDataItem } from '../../types/Case'

import styles from './index.scss'
import { SnakeToCamelCaseArray } from '@clain/core/utilsTypes'

const cx = classnames.bind(styles)

export type OverlapSidebarVariant = 'incidents' | 'cases'

interface OverlapSidebarProps {
  toggle: () => void
  isOpen: boolean
  variant: OverlapSidebarVariant
  clientIncidents?: SnakeToCamelCaseArray<IncidentsDataItem>
  clientCases?: SnakeToCamelCaseArray<CasesDataItem>
}

const OverlapSidebar: React.FC<OverlapSidebarProps> = ({
  toggle,
  isOpen,
  variant,
  clientIncidents,
  clientCases,
}) => {
  const sidebarTitle = React.useMemo(() => {
    if (variant === 'incidents') {
      return (
        <div className={cx('TitleBlock')}>
          <RowDeprecated gap={0.5}>
            <LightningIcon className={cx('TitleIcon')} />
            <Typography variant="heading3" color="grey1">
              Client incidents
            </Typography>
          </RowDeprecated>
          <Typography variant="heading4" color="grey1">
            ({clientIncidents?.length ?? '?'})
          </Typography>
        </div>
      )
    }

    if (variant === 'cases') {
      return (
        <div className={cx('TitleBlock')}>
          <RowDeprecated gap={0.5}>
            <CasesIcon className={cx('TitleIcon')} />
            <Typography variant="heading3" color="grey1">
              Client cases
            </Typography>
          </RowDeprecated>
          <Typography variant="heading4" color="grey1">
            ({clientCases?.length ?? '?'})
          </Typography>
        </div>
      )
    }
  }, [clientCases?.length, clientIncidents?.length, variant])

  const sidebarContent = React.useMemo(() => {
    if (variant === 'incidents') {
      return <ClientIncidentsList incidents={clientIncidents} />
    }

    if (variant === 'cases') {
      return <ClientCasesList cases={clientCases} />
    }
  }, [variant, clientIncidents, clientCases])

  const deps = React.useMemo(() => {
    return [variant, clientIncidents, clientCases]
  }, [variant, clientIncidents, clientCases])

  return (
    <RightOverlapSidebar toggle={toggle} isOpen={isOpen} deps={deps}>
      <Container gap={[1.25, 1.5]}>
        <MagicGrid gap={3}>
          <MagicGrid gap={2}>
            <CloseIcon onClick={toggle} className={cx('CloseIcon')} />
            {sidebarTitle}
          </MagicGrid>
          {sidebarContent}
        </MagicGrid>
      </Container>
    </RightOverlapSidebar>
  )
}

export default OverlapSidebar

import {
  caseService,
  CaseSummaryStatus,
  clientService,
  ICaseService,
  IClientService,
  IIncidentService,
  incidentService,
} from '@compliance/apiServices'
import { IAiChartReportData } from '@compliance/modules/IncidentCaseChatStore'
import { Container } from 'inversify'
import { incidentCaseChatStoreModule } from '@compliance/di/modules/IncidentCaseChatStore'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'
import { caseStoreModule } from '@compliance/di/modules/CaseStore'
import { CaseViewModelFacade } from '@compliance/modules/CaseViewModelFacade'
import { caseViewModelModule } from '@compliance/di/modules/CaseViewModel'

const aiChatReportData = {
  aiStatus: CaseSummaryStatus.Done,
  aiSummary: '',
  chatMessages: [],
  chatStatus: 'idle',
} satisfies IAiChartReportData

const container = new Container({ defaultScope: 'Singleton' })

container.load(incidentCaseChatStoreModule(aiChatReportData))
container.load(caseStoreModule())
container.load(caseViewModelModule())

// Bind services
container
  .bind<IIncidentService>(DY_TYPES.IncidentService)
  .toConstantValue(incidentService)
container
  .bind<IClientService>(DY_TYPES.ClientService)
  .toConstantValue(clientService)
container.bind<ICaseService>(DY_TYPES.CaseService).toConstantValue(caseService)
// Bind store and view models
container
  .bind<CaseViewModelFacade>(DY_TYPES.CaseViewModelFacade)
  .to(CaseViewModelFacade)

export const caseViewModelFacade = container.get<CaseViewModelFacade>(
  DY_TYPES.CaseViewModelFacade
)

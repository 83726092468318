import React from 'react'
import classnames from 'classnames/bind'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface SeverityDotProps {
  value?: 'high' | 'medium' | 'low'
}

const SeverityDot: React.FC<SeverityDotProps> = ({ value }) => (
  <div className={cx('SeverityDot', value)} />
)

export default SeverityDot

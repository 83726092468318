import React from 'react'

import { ButtonSelect } from '@clain/core/ui-kit'

interface RuleSeverityFilterProps {
  value: 'HIGH' | 'MEDIUM' | 'LOW'
  onChange: (value: 'HIGH' | 'MEDIUM' | 'LOW') => unknown
}

const RuleSeverityFilter: React.FC<RuleSeverityFilterProps> = ({
  value,
  onChange,
}) => {
  return (
    <ButtonSelect
      fullWidth
      options={[
        { value: 'HIGH', label: 'high', color: 'magenta' },
        { value: 'MEDIUM', label: 'medium', color: 'yellow' },
        { value: 'LOW', label: 'low', color: 'blue' },
      ]}
      value={value}
      onChange={onChange}
      fadeless
    />
  )
}

export default RuleSeverityFilter

import React, { useEffect } from 'react'
import NotificationsSettings from '../components/Settings/NotificationsSettings'
import { useAppLayoutResetter } from '@clain/core/state'

export const PageNotificationsSettings = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])
  return <NotificationsSettings />
}

export default PageNotificationsSettings

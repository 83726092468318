import React from 'react'

import { Typography } from '@clain/core/ui-kit'
import useCountUp from '@clain/core/useCountUp'

const COUNTING_DURATION = 500 // ms
const MAX_VIEWPORT_WIDTH = 2_240
const MIN_VIEWPORT_WIDTH = 900

interface AutoCounterProps {
  // иногда надо передать стаб в виде строки 🤷‍♂️
  value: number | string
  minFontSize?: number
  maxFontSize?: number
  className?: string
  format?: (value: number) => React.ReactNode
}

const AutoCounter: React.FC<AutoCounterProps> = ({
  value,
  minFontSize = 24,
  maxFontSize = 40,
  className,
  format,
}) => {
  const isMock = React.useMemo(() => typeof value !== 'number', [])

  const countingValue = isMock
    ? value
    : useCountUp(value as number, COUNTING_DURATION)

  const clamp = React.useMemo(() => {
    const slope =
      (maxFontSize - minFontSize) / (MAX_VIEWPORT_WIDTH - MIN_VIEWPORT_WIDTH)
    const yAxisIntersection = -MIN_VIEWPORT_WIDTH * slope + minFontSize
    const preferredClampValue = `${yAxisIntersection.toFixed(4)}px + ${(
      slope * 100
    ).toFixed(4)}vw`

    return `clamp(${minFontSize}px, ${preferredClampValue}, ${maxFontSize}px)`
  }, [minFontSize, maxFontSize])

  const printValue = React.useMemo(
    () =>
      isMock ? countingValue : format?.(Number(countingValue)) ?? countingValue,
    [countingValue, format]
  )

  return (
    <Typography
      className={className}
      variant="heading1"
      color="grey2"
      style={{ fontSize: clamp }}
    >
      {printValue}
    </Typography>
  )
}

export default AutoCounter

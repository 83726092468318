import React from 'react'

import { ReactComponent as CasesIcon } from '@clain/core/assets/cases_new.svg'
import { Typography } from '@clain/core/ui-kit'

import BaseSearchCard from './BaseSearchCard'
import { CasesDataItem } from '../../../../../types/Case'
import SeverityTag from '../../../../SeverityTag'
import { CaseDataResponse } from '../../../../../apiServices/Case'

interface CaseSearchCardProps {
  caseItem: CaseDataResponse | CasesDataItem
  selected: boolean
}

const CaseSearchCard: React.FC<CaseSearchCardProps> = ({
  caseItem,
  selected,
}) => {
  const severity = (caseItem as CasesDataItem).severity
  console.log(caseItem)
  const severityTag = severity && (
    <SeverityTag value={severity}>{severity}</SeverityTag>
  )

  return (
    <BaseSearchCard
      icon={<CasesIcon />}
      id={caseItem.id}
      href={`/cases/${caseItem.id}`}
      selected={selected}
    >
      <Typography variant="body2" color="black">
        {caseItem.tag}
      </Typography>
      {severityTag}
    </BaseSearchCard>
  )
}

export default CaseSearchCard

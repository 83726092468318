import { IController } from '../types'

class DevUtilsController implements IController {
  public ctrl: IController

  get form() {
    return this.ctrl.form
  }

  get space() {
    return this.ctrl.space
  }

  private isDev = false

  constructor(ctrl: IController) {
    this.ctrl = ctrl
  }

  init() {
    if (process.env.ENV !== 'production') {
      this.isDev = true
    }
  }

  render(time: number, ftime: number) {
    if (this.isDev) {
      this.showFPS(ftime)
    }
  }

  private showFPS(ftime: number) {
    this.form.log('FPS:' + Math.floor(1000 / ftime))
  }
}

export default DevUtilsController

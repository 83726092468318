import React from 'react'
import classnames from 'classnames/bind'

import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'
import Pagination from '@clain/core/Pagination'

import { IncidentTable } from '../../Incidents'
import VoidText from '../../VoidText'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'
import { IncidentsApiParams, IncidentsDataResponse } from '../../../apiServices'
import { IncidentActionsProvider } from '../../IncidentActions/IncidentActions.context'
import { defaultIncidentActionsController } from '../../IncidentActions/IncidentActionsController'

const cx = classnames.bind(styles)

interface IncidentTabProps {
  clientId?: number | string
}

const IncidentTab: React.FC<IncidentTabProps> = ({ clientId }) => {
  const [page, setPage] = React.useState(1)
  const [sortBy, setSortBy] = React.useState('')
  const [order, setOrder] = React.useState<'asc' | 'desc'>()

  const apiParams: IncidentsApiParams = {
    client_id: clientId,
    page,
    status: 'all',
    sort_by: sortBy,
    sort_order: order,
  }

  const { data: incidentsData, isLoading: incidentsIsLoading } =
    useHttp<IncidentsDataResponse>(
      buildUrl`${getConfig()?.API}/api/incidents?${apiParams}`
    )

  const handleChangeSort = (sortBy, order) => {
    setSortBy(sortBy)
    setOrder(order)
  }

  return (
    <>
      <IncidentActionsProvider viewModel={defaultIncidentActionsController}>
        <IncidentTable
          items={incidentsData?.data}
          loading={incidentsIsLoading}
          showClientId={false}
          showActions={false}
          showStatuses
          onChangeSort={handleChangeSort}
          sortBy={sortBy}
          order={order}
        />
      </IncidentActionsProvider>
      {incidentsData?.data.length === 0 && (
        <VoidText>No relevant incidents for the client’s transactions</VoidText>
      )}
      <Pagination
        className={cx('Pagination')}
        totalPages={incidentsData?.total_pages}
        value={page}
        onChange={setPage}
      />
    </>
  )
}

export default IncidentTab

import React, { useEffect, useMemo, useState } from 'react'
import { addDays } from 'date-fns'
import { CalendarInput } from '@clainio/web-platform'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'

export const CalendarPopup = ({
  date,
  children,
  onSubmit,
}: {
  date: Date
  children: React.ReactNode
  onSubmit: (date: Date) => Promise<void>
}) => {
  const formattedDate = useMemo(
    () =>
      date
        ? ([addDays(new Date(date), -30), new Date(date)] as [Date, Date])
        : ([] as unknown as [Date, Date]),
    [date]
  )

  const [localDate, setLocalDate] = useState(formattedDate)

  useEffect(() => {
    setLocalDate(formattedDate)
  }, [formattedDate])

  const onSubmitCalendar = async (hide: () => void | undefined) => {
    await onSubmit(localDate[1])
    hide?.()
  }

  return (
    <CalendarInput
      ErrorBoundaryWrapper={ErrorBoundaryContainer}
      disableDaysBeforeSelected
      freezeFrom
      size={'sm'}
      variant={'outline'}
      value={formattedDate}
      onChange={(value) => setLocalDate(value as [Date, Date])}
      onSubmit={onSubmitCalendar}
    >
      {/*  @ts-expect-error */}
      {children}
    </CalendarInput>
  )
}

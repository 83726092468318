import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as SearchIcon } from '@clain/core/assets/search_new.svg'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface GlobalSearchTargetProps {
  className: string
  textClassName: string
  iconClassName: string
  show: () => void
}

const GlobalSearchTarget = React.forwardRef<
  HTMLDivElement,
  GlobalSearchTargetProps
>(({ className, textClassName, iconClassName, show }, ref) => (
  <div ref={ref} className={cx('GlobalSearchTarget', className)} onClick={show}>
    <SearchIcon className={iconClassName} />
    <Typography className={textClassName}>Search</Typography>
  </div>
))
GlobalSearchTarget.displayName = 'GlobalSearchTarget'
export default GlobalSearchTarget

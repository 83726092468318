import React from 'react'
import classnames from 'classnames/bind'

import { Notification } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'

import { ClientBlockedNotification as N } from '../../apiServices'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface ClientBlockedNotificationProps {
  notification: N
  closeToast: () => void
}

export const ClientBlockedNotification: React.FC<
  ClientBlockedNotificationProps
> = ({ notification: { id, client_id }, closeToast }) => (
  <Notification.Body closeToast={closeToast} type="warning">
    <Typography variant="body1" color="grey1">
      <Link to="/clients/:id" className={cx('Bold')} params={{ id: client_id }}>
        Client #{client_id}:
      </Link>{' '}
      Automatically blocked due to Incident{' '}
      <Link to="/incidents/:id" params={{ id }}>
        #{id}
      </Link>
    </Typography>
  </Notification.Body>
)

import React from 'react'

import Link from '@clain/core/Link'
import { Typography } from '@clain/core/ui-kit'
import { formatMoney } from '@clain/core/utils/format'
import { useFormatDate } from '../../hooks'

import RelatedItemContainer from '../RelatedItemContainer'
import SeverityTag from '../SeverityTag'
import { DateISOString } from '@clain/core/types'
import { IncidentStatus } from '../IncidentActions/components/IncidentStatus'
import { observer } from 'mobx-react-lite'
interface RelatedEntityRuleIncidentCardProps {
  id: number
  severity: 'LOW' | 'MEDIUM' | 'HIGH'
  entity: string
  amount: string
  cases?: Array<{ id: number; tag: string }>
  status?: 'ignored' | 'case' | 'new'
  createdAt?: DateISOString
}

const RelatedEntityRuleIncidentCard: React.FC<
  RelatedEntityRuleIncidentCardProps
> = ({ id, severity, entity, amount, cases, status, createdAt }) => {
  const formatDate = useFormatDate()

  return (
    <RelatedItemContainer
      items={[
        {
          name: 'ID',
          value: (
            <Link to="/incidents/:id" params={{ id }}>
              {id}
            </Link>
          ),
        },
        {
          name: 'Severity',
          value: <SeverityTag value={severity}>{severity}</SeverityTag>,
        },
        {
          name: 'Entity',
          value: (
            <Typography variant="body2" color="grey1">
              {entity}
            </Typography>
          ),
        },
        {
          name: 'Amount',
          value: formatMoney({ value: amount, currency: 'usd', code: '' }),
        },
        {
          name: 'Status',
          value: (
            <IncidentStatus variant="inline" cases={cases} status={status} />
          ),
        },
        {
          name: 'Created',
          value: createdAt ? formatDate(new Date(createdAt), 'date') : null,
        },
      ]}
    />
  )
}

export default observer(RelatedEntityRuleIncidentCard)

export default function normalizeSeriesDate<
  RowData extends [number | string, number, ...any[]],
>(data: RowData[]): RowData[] {
  return data?.length
    ? data.map(
        ([x, y, ...rest]) =>
          [
            typeof x === 'string' ? new Date(x).getTime() : x * 1000,
            y,
            ...rest,
          ] as RowData
      )
    : []
}

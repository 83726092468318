import { createTokenKey, selectToken } from '@clainio/web-platform'
import styled, { css } from 'styled-components'

export const BigStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) =>
    `${selectToken(theme, createTokenKey(['space', 'x20']))}px`};
`

export const HiddenAmountBlock = styled.div`
  overflow: hidden;
  width: fit-content;
`
export const FoggyWrapperAmountStyled = styled.div<{ $isActive: boolean }>`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 14px;
    height: 100%;
    z-index: 1;
  }

  ${({ $isActive }) => {
    if ($isActive) {
      return css({
        '&::after': {
          right: 0,
          background:
            'linear-gradient(90deg, rgba(241, 243, 249, 0) 0%, white 100%)',
        },
      })
    }
  }}
`
export const AmountTickerStyled = styled.div`
  overflow: hidden;
  max-width: 58px;
`

import React from 'react'
import { endOfDay, isToday, isTomorrow } from 'date-fns'

import formatDuration from '@clain/core/utils/formatDuration'
import { Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../hooks'
import { observer } from 'mobx-react-lite'
import { CASE_STATUS } from '../../types/Case'

const ONE_WEEK_MS = 1000 * 60 * 60 * 24 * 7
const ONE_MONTH_MS = ONE_WEEK_MS * 4

interface DueDateLeftDaysProps {
  className?: string
  date: Date | null
  fallback?: React.ComponentType<{ className?: string; date: Date }>
}

export const DueDateLeftDays = ({
  className,
  date,
  fallback: Fallback,
}: DueDateLeftDaysProps) => {
  if (!date) {
    return null
  }

  if (isToday(date)) {
    return (
      <Typography className={className} variant="body2" color="magenta">
        Today
      </Typography>
    )
  }

  if (isTomorrow(date)) {
    return (
      <Typography className={className} variant="body2" color="magenta">
        Tomorrow
      </Typography>
    )
  }

  const durationInMs = date.getTime() - endOfDay(new Date()).getTime()
  const expired = durationInMs <= 0

  // TODO: когда привяжем настройку времени исполения кейсов,
  // то будем подсвечить в зависимости от нее
  const errorExpiresSoon = false // durationInMs <= daysLeftSetting
  const expiresSoon = durationInMs <= ONE_WEEK_MS
  const showDaysToExpire = durationInMs < ONE_MONTH_MS

  const days = formatDuration(Math.abs(durationInMs), {
    largest: 1,
    units: ['d', 'h'],
  })
  const daysText = `${days} left`

  if (expired) {
    return (
      <Typography className={className} variant="body2" color="magenta">
        Overdue ({days})
      </Typography>
    )
  }

  if (expiresSoon) {
    return (
      <Typography
        className={className}
        variant="body2"
        color={errorExpiresSoon ? 'magenta' : null}
      >
        {daysText}
      </Typography>
    )
  }

  if (showDaysToExpire) {
    return (
      <Typography className={className} variant="body2">
        {daysText}
      </Typography>
    )
  }

  if (Fallback) {
    return <Fallback className={className} date={date} />
  }

  return null
}

const SimpleDateText = observer(
  ({ className, date }: { className?: string; date: Date }) => {
    const formatDate = useFormatDate()

    return (
      <Typography className={className} variant="body2">
        {formatDate(date, 'date')}
      </Typography>
    )
  }
)

interface DueDateProps {
  className?: string
  date: Date | null
  status: CASE_STATUS
}

const DueDate = ({ className, date, status }: DueDateProps) => {
  const formatDate = useFormatDate()

  if (!date) {
    return (
      <Typography className={className} variant="body2" color="magenta">
        Set deadline
      </Typography>
    )
  }

  if (status === 'closed') {
    return (
      <Typography className={className} variant="body2">
        {formatDate(date, 'date')}
      </Typography>
    )
  }

  return (
    <DueDateLeftDays
      className={className}
      date={date}
      fallback={SimpleDateText}
    />
  )
}

export default observer(DueDate)

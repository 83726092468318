import React from 'react'
import classnames from 'classnames/bind'
import { endOfDay } from 'date-fns'

import { Notification } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import formatDuration from '@clain/core/utils/formatDuration'

import { CaseOverdueNotification as N } from '../../apiServices'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface CaseOverdueNotificationProps {
  notification: N
  closeToast: () => void
}

export const CaseOverdueNotification: React.FC<
  CaseOverdueNotificationProps
> = ({ notification: { id, due_date }, closeToast }) => {
  const date = new Date(due_date)

  const durationInMs = date.getTime() - endOfDay(new Date()).getTime()
  const expired = durationInMs <= 0

  const units = formatDuration(Math.abs(durationInMs), {
    largest: 1,
    units: ['d', 'h'],
  })

  return (
    <Notification.Body closeToast={closeToast} type="attention">
      <Typography variant="body1" color="grey1">
        <Link to="/cases/:id" className={cx('Bold')} params={{ id }}>
          Case #{id}:
        </Link>
        {expired ? (
          <>
            {' '}
            Overdue{' '}
            <Typography color="magenta2" variant="body3">
              {units}
            </Typography>
          </>
        ) : (
          <>
            {' '}
            it is{' '}
            <Typography color="magenta2" variant="body3">
              {units}
            </Typography>{' '}
            left for the case processing completion
          </>
        )}
      </Typography>
    </Notification.Body>
  )
}

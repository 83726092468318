import React from 'react'
import classnames from 'classnames/bind'

import { TextField } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import useHttp from '@clain/core/useHttp'
import { Typography } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { InputDropdown } from '@clain/core/ui-kit'
import { formatNumber } from '@clain/core/utils/format'
import useFuse from '@clain/core/useFuse'

import { AssetsListShortForSuggest } from '@clain/core/ui-kit'

import { FieldProps, AssetAmount } from '../../Transactions/Filters/types'
import baseAssets from '../../Assets/AssetsSuggest/baseAssets'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'
import { isDefaultTokenId } from '@clain/core/utils/defaultCurrency'

const cx = classnames.bind(styles)

const getPrintValueLabel = (
  symbol?: string,
  name?: string,
  fromValue?: string,
  toValue?: string
): string => {
  if (symbol) {
    const from = fromValue && formatNumber(fromValue, 0)
    const to = toValue && formatNumber(toValue, 0)

    if (from && to) return `${symbol} ${from} — ${to}`
    if (from) return `${symbol} ≥ ${from}`
    if (to) return `${symbol} ≤ ${to}`

    return name
  }

  return ''
}

const AssetAmountFilter = ({
  value,
  onChange,
}: { label: string } & FieldProps<AssetAmount>) => {
  const from = value.from || ''
  const to = value.to || ''
  const asset_id = value.asset_id || ''
  const blockchain = value.blockchain || ''

  const searchInputRef = React.useRef(null)

  const [assetFilter, setAssetFilter] = React.useState('')

  const handleInputClear = () => onChange({})
  const handleChangeAmountFrom = (v) => onChange({ ...value, from: v })
  const handleChangeAmountTo = (v) => onChange({ ...value, to: v })

  const handleAssetFilterClear = () => {
    setAssetFilter('')
    handleInputClear()
  }

  const { data: assetsData = [], isLoading } = useHttp(
    `${getConfig()?.API}/api/settings/assets`
  )

  const assets = React.useMemo(() => {
    return [...baseAssets, ...assetsData].map((asset) => ({
      ...asset,
      blockchain: asset.blockchain.toLowerCase(),
    }))
  }, [assetsData])

  const getAssets = useFuse(assets, {
    keys: ['name', 'symbol'],
    threshold: 0.3,
  })

  const filteredAssets = React.useMemo(() => {
    return getAssets(assetFilter)
  }, [getAssets, assetFilter])

  const activeAsset = React.useMemo(() => {
    if ((asset_id || blockchain) && !isLoading) {
      return (
        assets.find((asset) => asset.id === Number(asset_id)) ||
        assets.find((asset) => asset.blockchain.toUpperCase() === blockchain)
      )
    }
  }, [asset_id, blockchain, isLoading, assets])

  React.useEffect(() => {
    if (activeAsset) {
      setAssetFilter(activeAsset.name)
    }
  }, [activeAsset])

  const handleAssetChange = (id, blockchain) => {
    if (id >= 0) {
      onChange({ ...value, asset_id: id, blockchain: blockchain.toUpperCase() })
    } else if (blockchain) {
      onChange({
        ...value,
        asset_id: undefined,
        blockchain: blockchain.toUpperCase(),
      })
    }
  }

  return (
    <InputDropdown
      clearable
      sameWidth
      variant="outline"
      startAddon={{ label: 'Asset / Amount:' }}
      placeholder="Any"
      value={getPrintValueLabel(
        activeAsset?.symbol,
        activeAsset?.name,
        from,
        to
      )}
      onClear={handleInputClear}
      dropdownClassName={cx('AssetAmountFilter')}
      padded={false}
    >
      <Container gap={[1.5, 0]}>
        <MagicGrid>
          <Container gap={[0, 1]}>
            <MagicGrid>
              <Label content="From">
                <TextField
                  mask="number"
                  variant="outline"
                  startAddon={{ label: activeAsset?.symbol }}
                  value={from}
                  onChange={handleChangeAmountFrom}
                  disabled={!blockchain}
                />
              </Label>
              <Label content="To">
                <TextField
                  mask="number"
                  variant="outline"
                  startAddon={{ label: activeAsset?.symbol }}
                  value={to}
                  onChange={handleChangeAmountTo}
                  disabled={!blockchain}
                />
                {!activeAsset && (
                  <Typography variant="caption1" color="grey3">
                    Select asset first, then input amount
                  </Typography>
                )}
              </Label>
              <Label content="Asset">
                <TextField
                  ref={searchInputRef}
                  clearable
                  variant="outline"
                  value={assetFilter}
                  startAddon={{ iconVariant: 'Search' }}
                  onChange={setAssetFilter}
                  onClear={handleAssetFilterClear}
                />
              </Label>
            </MagicGrid>
          </Container>
          <AssetsListShortForSuggest
            className={cx('AssetsList')}
            assets={filteredAssets}
            onChange={handleAssetChange}
            isDefaultTokenId={isDefaultTokenId}
          />
        </MagicGrid>
      </Container>
    </InputDropdown>
  )
}

export default AssetAmountFilter

import React from 'react'
import classnames from 'classnames/bind'

import ExposureRangeSlider from '../ExposureRangeSlider'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface RuleExposureProps {
  value: number
  onChange: (value: number) => unknown
  disabled: boolean
  error?: boolean
}

const RuleExposure: React.FC<RuleExposureProps> = ({
  value,
  onChange,
  disabled,
}) => {
  React.useEffect(() => {
    if (disabled) {
      onChange(undefined)
    }
  }, [disabled])

  return (
    <MagicGrid
      onClick={(e) => {
        e.preventDefault()
      }}
    >
      <ExposureRangeSlider
        to={100}
        value={[Number(value), 100]}
        onChange={([from]) => onChange(from)}
        disabled={disabled}
      />
      <Typography className={cx('ExposureValue')}>
        <Typography color={disabled ? 'grey5' : 'grey1'}>{value}%</Typography>
        <Typography color={disabled ? 'grey5' : 'grey1'}>-</Typography>
        <Typography color={disabled ? 'grey5' : 'grey1'}>100%</Typography>
      </Typography>
    </MagicGrid>
  )
}

export default RuleExposure

import { Button, Modal, ModalSlot } from '@clain/core/ui-kit'
import DissmissForm from './DissmissForm'
import React from 'react'

interface IgnoreIncidentProps {
  incidentId: number
  size?: 'md' | 'lg'
  onSubmitSuccess: (incidentId: number) => void
}

export const IgnoreIncidentButton = ({
  incidentId,
  size = 'md',
  onSubmitSuccess,
}: IgnoreIncidentProps) => {
  const target: ModalSlot = ({ show }) => {
    const onClick = (e) => {
      e.stopPropagation()
      show()
    }
    return (
      <Button size={size} variant="outline" color="secondary" onClick={onClick}>
        Dismiss
      </Button>
    )
  }

  const body: ModalSlot = ({ hide }) => {
    const handleSubmitSuccess = () => {
      onSubmitSuccess(incidentId)
      hide()
    }

    return (
      <DissmissForm
        incidentId={incidentId}
        onSubmitSuccess={handleSubmitSuccess}
        onCancel={hide}
      />
    )
  }

  return (
    <Modal closable target={target}>
      {body}
    </Modal>
  )
}

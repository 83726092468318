import React, { useEffect } from 'react'
import Clients from '../components/Clients'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageClients = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Clients', fixedHeader: true })
  }, [])
  return <Clients />
}

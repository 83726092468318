import React from 'react'
import classnames from 'classnames/bind'

import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'

import styles from './index.scss'

const cx = classnames.bind(styles)

const BlockClientAPIExample = ({ url }) => (
  <MagicGrid className={cx('BlockClientAPIExample')} gap={1.5}>
    <Typography variant="subheading2" color="grey1">
      Example
    </Typography>
    <Container gap={[0.5, 1.5]} className={cx('CodeBox')}>
      <Typography variant="code1" color="grey6">
        POST {url || 'http://yourcompany.com/api/clients/block_client'}
      </Typography>
    </Container>
    <Container gap={[0.5, 1.5]} className={cx('CodeBox')}>
      <Typography variant="code1" color="grey6">
        {'['}
        <Container gap={[0, 1]}>
          {'{'}
          <Container gap={[0, 1]}>
            <MagicGrid gap={0}>
              <Typography variant="code1" color="magenta4">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                "user_id":
                <Typography variant="code1" color="yellow02">
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  "xxxx"
                </Typography>
              </Typography>
              <Typography variant="code1" color="magenta4">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                "block":
                <Typography variant="code1" color="purple02">
                  true
                </Typography>
              </Typography>
            </MagicGrid>
          </Container>
          {'}'}
        </Container>
        {']'}
      </Typography>
    </Container>
    <Typography variant="body1" color="black">
      For more information{' '}
      <Link to="https://doc-crm.clain.io/reference/authentication">
        see documentation
      </Link>
    </Typography>
  </MagicGrid>
)

export default BlockClientAPIExample

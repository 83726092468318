import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { Avatar } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../hooks'

import { FeedStartCaseActivity } from '../../../../types/Case'

import styles from './index.scss'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

interface CaseStartActivityCardProps {
  activity: SnakeToCamelCaseObject<FeedStartCaseActivity>
  className?: string
}

const CaseStartActivityCard: React.FC<CaseStartActivityCardProps> = ({
  activity,
  className,
}) => {
  const formatDate = useFormatDate()
  const time = formatDate(new Date(activity.insertedAt), 'time')
  const name = activity.user.name
  const avatar = activity.user.avatar
  const tag = activity.activity.tag

  return (
    <MagicGrid className={cx('CaseStartActivityCard', className)}>
      <MagicGrid gap={0.5} className={cx('CaseStartActivityCardUser')}>
        <Avatar size="lg" name={name} src={avatar} />
        <Typography variant="body3" color="grey2">
          {name}
        </Typography>
      </MagicGrid>
      <Typography variant="body3" color="grey2">
        Opened case
        <Typography variant="body1" color="grey2">
          {' '}
          labeled as{' '}
        </Typography>
        {tag}
        <Typography variant="body1" color="grey2">
          {' '}
          at {time}
        </Typography>
      </Typography>
    </MagicGrid>
  )
}
export default observer(CaseStartActivityCard)

import http, { HttpResponse } from '@clain/core/http'
import { API_ENDPOINT } from '../utils'
import {
  CaseActivityApiParams,
  CaseActivityDataResponse,
  CaseApiParams,
  CaseAssignApiParamsData,
  CaseDataResponse,
  CaseDueDateApiParamsData,
  CasesApiParams,
  CasesAssigneesDataResponse,
  CasesDataResponse,
  CaseStartApiParamsData,
  CaseTagApiParamsData,
  ICaseApiService,
} from './CaseService.types'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import {
  SnakeToCamelCaseMapping,
  SnakeToCamelCaseObject,
} from '@clain/core/utilsTypes'
import buildUrl from '@clain/core/utils/buildUrl'
import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import { SubmitCaseForReviewParams } from '../../components/Case/CaseActions/ReopenForm.types'
import { injectable } from 'inversify'
import { PostChatFilesDataResponse } from '@compliance/apiServices'

@injectable()
export class CaseApiService implements ICaseApiService {
  private apiEndpoint = `${API_ENDPOINT}/cases`

  public getCases = (params: CasesApiParams) => {
    return new Promise<SnakeToCamelCaseObject<CasesDataResponse>>(
      (resolve, reject) => {
        http
          .get<HttpResponse<CasesDataResponse>>(
            buildUrl`${this.apiEndpoint}?${params}`
          )
          .then((result) => {
            resolve(normalizeSnakeToCamelCase(result.data.data))
          })
          .catch(reject)
      }
    )
  }

  public getCaseById = ({ caseId }: CaseApiParams) => {
    return new Promise<SnakeToCamelCaseObject<CaseDataResponse>>(
      (resolve, reject) => {
        http
          .get<HttpResponse<CaseDataResponse>>(`${this.apiEndpoint}/${caseId}`)
          .then((result) => {
            resolve(normalizeSnakeToCamelCase(result.data.data))
          })
          .catch(reject)
      }
    )
  }

  public getCaseActivity = ({ caseId }: CaseActivityApiParams) => {
    return new Promise<SnakeToCamelCaseObject<CaseActivityDataResponse>>(
      (resolve, reject) => {
        http
          .get<HttpResponse<CaseActivityDataResponse>>(
            `${this.apiEndpoint}/${caseId}/activity`
          )
          .then((result) => {
            resolve(normalizeSnakeToCamelCase(result.data.data))
          })
          .catch(reject)
      }
    )
  }

  public getAssignees = () => {
    return new Promise<SnakeToCamelCaseMapping<CasesAssigneesDataResponse>>(
      (resolve, reject) => {
        http
          .get<HttpResponse<CasesAssigneesDataResponse>>(
            `${this.apiEndpoint}/assignees`
          )
          .then((result) => {
            resolve(normalizeSnakeToCamelCase(result.data.data))
          })
          .catch(reject)
      }
    )
  }

  public postCaseDueDate = (caseId: number, data: CaseDueDateApiParamsData) => {
    return new Promise<void>((resolve, reject) => {
      http
        .post<typeof data, HttpResponse<{ status: boolean; msg?: string }>>(
          buildUrl`${this.apiEndpoint}/${caseId}/due_date`,
          normalizeCamelToSnakeCase(data)
        )
        .then((result) => {
          if (result.data.status) {
            resolve()
          } else {
            reject(new Error(result.data.msg))
          }
        })
        .catch(reject)
    })
  }

  public postCaseAssignee = (caseId: number, data: CaseAssignApiParamsData) => {
    return new Promise<void>((resolve, reject) => {
      http
        .post<typeof data, HttpResponse<{ status: boolean; msg?: string }>>(
          buildUrl`${this.apiEndpoint}/${caseId}/assign`,
          normalizeCamelToSnakeCase(data)
        )
        .then((result) => {
          if (result.data.status) {
            resolve()
          } else {
            reject(new Error(result.data.msg))
          }
        })
        .catch(reject)
    })
  }

  public postCaseTag = (caseId: number, data: CaseTagApiParamsData) => {
    return new Promise<void>((resolve, reject) => {
      http
        .post<typeof data, HttpResponse<{ status: boolean; msg?: string }>>(
          buildUrl`${this.apiEndpoint}/${caseId}/tag`,
          normalizeCamelToSnakeCase(data)
        )
        .then((result) => {
          if (result.data.status) {
            resolve()
          } else {
            reject(new Error(result.data.msg))
          }
        })
        .catch(reject)
    })
  }

  public postCaseStart = (caseId: number, data: CaseStartApiParamsData) => {
    return new Promise<void>((resolve, reject) => {
      http
        .post<typeof data, HttpResponse<{ status: boolean; msg?: string }>>(
          buildUrl`${this.apiEndpoint}/${caseId}/start`,
          normalizeCamelToSnakeCase(data)
        )
        .then((result) => {
          if (result.data.status) {
            resolve()
          } else {
            reject(new Error(result.data.msg))
          }
        })
        .catch(reject)
    })
  }

  public postCaseReOpen = (caseId: number, data: SubmitCaseForReviewParams) => {
    return new Promise<void>((resolve, reject) => {
      http
        .post<typeof data, HttpResponse<{ status: boolean; msg?: string }>>(
          buildUrl`${this.apiEndpoint}/${caseId}/re_open`,
          normalizeCamelToSnakeCase(data)
        )
        .then((result) => {
          if (result.data.status) {
            resolve()
          } else {
            reject(new Error(result.data.msg))
          }
        })
        .catch(reject)
    })
  }

  public postCaseToPending = (
    caseId: number,
    data: SubmitCaseForReviewParams
  ) => {
    return new Promise<void>((resolve, reject) => {
      http
        .post<typeof data, HttpResponse<{ status: boolean; msg?: string }>>(
          buildUrl`${this.apiEndpoint}/${caseId}/to_pending`,
          normalizeCamelToSnakeCase(data)
        )
        .then((result) => {
          if (result.data.status) {
            resolve()
          } else {
            reject(new Error(result.data.msg))
          }
        })
        .catch(reject)
    })
  }

  public postCaseToClose = (
    caseId: number,
    data: SubmitCaseForReviewParams
  ) => {
    return new Promise<void>((resolve, reject) => {
      http
        .post<typeof data, HttpResponse<{ status: boolean; msg?: string }>>(
          buildUrl`${this.apiEndpoint}/${caseId}/to_close`,
          normalizeCamelToSnakeCase(data)
        )
        .then((result) => {
          if (result.data.status) {
            resolve()
          } else {
            reject(new Error(result.data.msg))
          }
        })
        .catch(reject)
    })
  }
  public postChatFilesAttach = (
    id: number,
    data: {
      files: File[]
    }
  ) => {
    return new Promise<SnakeToCamelCaseObject<PostChatFilesDataResponse>>(
      (resolve, reject) => {
        const formData = new FormData()
        data.files.forEach((file) => {
          formData.append('files[]', file)
        })

        http
          .post<
            typeof data,
            HttpResponse<
              {
                status: boolean
                msg?: string
              } & PostChatFilesDataResponse
            >
          >(buildUrl`${this.apiEndpoint}/${id}/attach`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
            },
          })
          .then((result) => {
            if (result.data.status) {
              resolve(normalizeSnakeToCamelCase(result.data))
            } else {
              reject(new Error(result.data.msg))
            }
          })
          .catch(reject)
      }
    )
  }
}

import React from 'react'
import classnames from 'classnames/bind'
import { getConfig } from '@clain/core/useConfig'

import { ReactComponent as AlertCircleIcon } from '@clain/core/assets/alert_circle.svg'
import { TextField } from '@clain/core/ui-kit'
import { Menu } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { Spinner } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { useDebounceValue } from '@clain/core/useDebounce'
import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'

import styles from './index.scss'
import { PopoverBaseContainer, usePopoverContext } from '@clainio/web-platform'
import { RULES_RIGHT_OVERLAP_SIDEBAR_ID } from './RulesRightOverlapSidebar.constants'

const DEBOUNCE_TIMEOUT = 300

const cx = classnames.bind(styles)

interface Entity {
  currency: number
  identity_id: number
  name: string
  score: number
  type: string
}

interface RuleEntityItemProps {
  entity: Entity
}

const RuleEntityItem: React.FC<RuleEntityItemProps> = ({
  entity: { score, name },
}) => (
  <RowDeprecated>
    <Score value={score} />
    <Typography color="grey1">{name}</Typography>
  </RowDeprecated>
)

interface RuleEntityFilterProps {
  value: string
  onChange: (value: string) => unknown
  error?: boolean
}

//TODO define types for data and items
const DropDownContent = ({
  data,
  handleChange,
  isLoading,
  isReadyToSearch,
  items,
}: {
  data: any
  isReadyToSearch: boolean
  isLoading: boolean
  items: any[]
  handleChange: (entityId: number) => void
}) => {
  const { setOpen } = usePopoverContext()

  const onChange = (entityId: number) => {
    handleChange(entityId)
    setOpen(false)
  }

  if (data?.result && !data?.results?.length) {
    return (
      <Container>
        <RowDeprecated gap={0.5}>
          <AlertCircleIcon className={cx('AlertCircleIcon')} />
          <Typography color="grey3">
            There are no matching rules.
            <br />
            Try another name.
          </Typography>
        </RowDeprecated>
      </Container>
    )
  }

  if (!isReadyToSearch) {
    return (
      <Container>
        <RowDeprecated gap={0.5}>
          <AlertCircleIcon className={cx('AlertCircleIcon')} />
          <Typography color="grey3">
            At least 4 symbols required to begin search.
          </Typography>
        </RowDeprecated>
      </Container>
    )
  }

  if (isLoading && isReadyToSearch) {
    return (
      <Container>
        <Spinner center />
      </Container>
    )
  }

  if (data?.results?.length) {
    return (
      <Menu
        options={items?.map((entity) => ({
          value: entity.identity_id,
          children: <RuleEntityItem entity={entity} />,
        }))}
        onChange={onChange}
      />
    )
  }
  return null
}

const RuleEntityFilter: React.FC<RuleEntityFilterProps> = ({
  value,
  onChange,
  error,
}) => {
  const [inputValue, setInputValue] = React.useState('')
  // типа сбрасываем стейт
  React.useEffect(() => {
    if (!value) {
      setInputValue('')
    }
  }, [value])

  const handleInputClear = () => {
    setInputValue('')
    onChange('')
  }

  const debouncedInputValue = useDebounceValue(
    inputValue.trim(),
    DEBOUNCE_TIMEOUT
  )

  const isReadyToSearch = debouncedInputValue?.length >= 4

  const { data, isLoading } = useHttp(
    isReadyToSearch
      ? buildUrl`${getConfig()?.API}/api/rules/entity_search?${{
          term: debouncedInputValue,
        }}`
      : null
  )

  const items = React.useMemo(() => {
    return data?.results?.slice(0, 20) || []
  }, [data])

  const handleChange = (entityId: number) => {
    onChange(String(entityId))
    setInputValue(items.find((item) => item.identity_id === entityId).name)
  }

  return (
    <PopoverBaseContainer
      id={RULES_RIGHT_OVERLAP_SIDEBAR_ID}
      content={
        <DropDownContent
          data={data}
          isLoading={isLoading}
          handleChange={handleChange}
          isReadyToSearch={isReadyToSearch}
          items={items}
        />
      }
      sameWidth
      maxHeight={440}
      anchor={
        <TextField
          clearable
          variant="outline"
          placeholder="Find Entity"
          value={inputValue}
          onChange={setInputValue}
          onClear={handleInputClear}
          startAddon={{ iconVariant: 'Search' }}
          error={error}
        />
      }
    />
  )
}

export default RuleEntityFilter

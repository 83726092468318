import React from 'react'
import classnames from 'classnames/bind'
import { observer } from 'mobx-react-lite'

import { Typography } from '@clain/core/ui-kit'
import { Avatar } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../hooks'
import { EditorView } from '@clain/core/Editor'

import { FeedStatusClosedActivity } from '../../../../types/Case'

import styles from './index.scss'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

const cx = classnames.bind(styles)

interface CaseCloseActivityCardProps {
  activity: SnakeToCamelCaseObject<FeedStatusClosedActivity>
  className?: string
}

const CaseCloseActivityCard: React.FC<CaseCloseActivityCardProps> = ({
  activity,
  className,
}) => {
  const formatDate = useFormatDate()
  const resolutionColor =
    activity.activity.closedAs === 'true' ? 'magenta' : 'green'
  const resolution =
    activity.activity.closedAs === 'true' ? 'True positive' : 'False positive'
  const time = formatDate(new Date(activity.insertedAt), 'time')
  const name = activity.user.name
  const avatar = activity.user.avatar
  const comment = activity.activity.comment

  return (
    <MagicGrid className={cx('CaseCloseActivityCard', className)}>
      <MagicGrid gap={0.5} className={cx('CaseCloseActivityCardUser')}>
        <Avatar size="lg" name={name} src={avatar} />
        <Typography variant="body3" color="grey2">
          {name}
        </Typography>
      </MagicGrid>
      <Typography variant="body3" color="grey2">
        Case closed as{' '}
        <Typography variant="body3" color={resolutionColor}>
          {resolution}
        </Typography>{' '}
        at {time}
      </Typography>
      {comment && (
        <div className={cx('Comment')}>
          <EditorView value={comment} />
        </div>
      )}
    </MagicGrid>
  )
}

export default observer(CaseCloseActivityCard)

import React, { PropsWithChildren } from 'react'
import { Icon, IconVariant } from '@clain/core/ui-kit'
import * as S from './Header.styles'
import { Stack, TypographyNew } from '@clainio/web-platform'

interface HeaderProps {
  icon?: React.ReactElement
  iconVariant?: IconVariant
  title?: React.ReactNode
  subTitle?: React.ReactNode
  actions?: React.ReactNode
  footer?: React.ReactNode
}

const Header: React.FC<PropsWithChildren<HeaderProps>> = (props) => {
  const icon = props.icon ? (
    <S.HeaderIcon as={(arg: any) => React.cloneElement(props.icon, arg)} />
  ) : props.iconVariant ? (
    <S.HeaderIcon>
      <Icon
        variant={props.iconVariant}
        size="lg"
        color="onBackgroundVariant1"
      />
    </S.HeaderIcon>
  ) : null

  const title = props.title ? (
    <TypographyNew variant="heading100Accent" color="onBackgroundBase">
      {props.title}
    </TypographyNew>
  ) : null

  const subTitle = props.subTitle || null

  const actions = props.actions ? (
    <S.HeaderActions>{props.actions}</S.HeaderActions>
  ) : null

  return (
    <S.Header spaces={['xl', 'xxxxl']}>
      <Stack direction={'column'}>
        <Stack align={'center'} gap={'xl'}>
          {icon}
          <Stack fullWidth={false} align={'center'} gap={'xxxxl'}>
            {title}
            {subTitle}
          </Stack>
          {props.children}
          {actions}
        </Stack>
        {props.footer}
      </Stack>
    </S.Header>
  )
}

export default Header

import React from 'react'
import classnames from 'classnames/bind'

import Portlet from '@clain/core/Portlet'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import Link, { BaseLink } from '@clain/core/Link'
import { ColDeprecated } from '@clain/core/ui-kit'
import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'
import { ReactComponent as FireIcon } from '@clain/core/assets/fire.svg'

import SeverityTag from '../../SeverityTag'
import CaseProgressBars from '../../Chart/caseProgressBars'
import CaseDueDate from '../../CaseDueDate'
import { CasesDataItem } from '../../../types/Case'
import {
  CasesByDueDateData,
  CasesByDueDateApiParams,
} from '../../../types/Dashboard'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'
import { CasesApiParams, CasesDataResponse } from '../../../apiServices/Case'

const cx = classnames.bind(styles)

interface TopCasesTableProps {
  cases: Array<CasesDataItem>
}

const TopCasesTable: React.FC<TopCasesTableProps> = ({ cases }) => {
  return (
    <ColDeprecated gap={1.5}>
      <Container gap={0}>
        {cases?.map(({ id, severity, tag, status, due_date }) => (
          <BaseLink
            key={id}
            className={cx('TopCaseItem')}
            to={due_date ? '/cases/:id' : '/cases/:id/start'}
            params={{ id }}
          >
            <Link className={cx('TopCaseId')}>{id}</Link>
            <SeverityTag value={severity}>{severity}</SeverityTag>
            <Typography
              variant="body1"
              color="black"
              className={cx('TopCaseTag')}
            >
              {tag}
            </Typography>
            <CaseDueDate
              className={cx('TopCaseDueDate')}
              status={status}
              date={due_date ? new Date(due_date) : null}
            />
          </BaseLink>
        ))}
      </Container>
      <Link
        to="/cases"
        query={{ sortBy: 'due_date', order: 'asc' }}
        className={cx('SeeAllCasesLink')}
      >
        See all cases
      </Link>
    </ColDeprecated>
  )
}

interface CasesToDueDateBlockProps {
  assignee?: number
}

const CasesToDueDateBlock: React.FC<CasesToDueDateBlockProps> = ({
  assignee,
}) => {
  const apiParams = React.useMemo(
    (): CasesByDueDateApiParams => ({
      assignee_id: assignee,
    }),
    [assignee]
  )

  const {
    data: progressStatusData,
    isFirstLoading: isProgressStatusFirstLoading,
  } = useHttp<CasesByDueDateData>(
    buildUrl`${getConfig()?.API}/api/dash/cases_by_due_date?${apiParams}`
  )

  const topCasesApiParams = React.useMemo(
    (): CasesApiParams => ({
      sort_by: 'due_date',
      sort_order: 'asc',
      assignee,
    }),
    [assignee]
  )

  const { data: topCasesData, isFirstLoading: isTopCasesFirstLoading } =
    useHttp<CasesDataResponse>(
      buildUrl`${getConfig()?.API}/api/cases?${topCasesApiParams}`
    )

  return (
    <Portlet variant="card">
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Deadlines
          </Typography>
        ),
        body:
          isProgressStatusFirstLoading || isTopCasesFirstLoading ? (
            <Stub className={cx('CasesToDueDatePortletStub')} isActive={true} />
          ) : (
            <div className={cx('CasesToDueDateBlock')}>
              <Container gap={2} className={cx('CasesToDueDateContainer')}>
                <ColDeprecated gap={2}>
                  <Typography variant="heading4" color="grey3">
                    Open cases due dates
                  </Typography>
                  <CaseProgressBars data={progressStatusData} />
                </ColDeprecated>
              </Container>
              <Container gap={2} className={cx('CasesToDueDateContainer')}>
                <ColDeprecated gap={2}>
                  <RowDeprecated gap={0.5}>
                    <FireIcon className={cx('FireIcon')} />
                    <Typography variant="heading4" color="grey3">
                      Top cases to work on
                    </Typography>
                  </RowDeprecated>
                  <TopCasesTable cases={topCasesData?.cases?.slice(0, 5)} />
                </ColDeprecated>
              </Container>
            </div>
          ),
      }}
    </Portlet>
  )
}

export default CasesToDueDateBlock

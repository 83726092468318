import { startOfDay, endOfDay } from 'date-fns'
const getSeriesExtremum = <T extends any[]>(
  data: Array<T>,
  index = 0
): [Date, Date] | [] => {
  if (!data || !data.length) {
    return []
  }

  const min = data.reduce(
    (result, row) => Math.min(result, row[index]),
    Infinity
  )
  const max = data.reduce((result, row) => Math.max(result, row[index]), 0)

  return [startOfDay(new Date(min)), endOfDay(new Date(max))]
}

export default getSeriesExtremum

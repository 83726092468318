import { CaseAssignee } from '../../../types/Case'
import { useSettings } from '../../../hooks'
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemContent,
  PopoverBaseContainer,
  RowDeprecated,
  Stack,
  SurfaceContainer,
} from '@clainio/web-platform'
import {
  EllipsisTypography,
  ListItemAtom,
  TypographyNew,
  usePopoverState,
} from '@clain/core/ui-kit'
import sortBy from 'lodash/sortBy'
import React, { useState } from 'react'
import { CaseDataAssignee } from '../../../apiServices/Case'

export const Assignee = ({
  assignees,
  assignee,
  onChangeAssignee,
}: {
  assignees: CaseAssignee[]
  assignee: CaseDataAssignee
  onChangeAssignee: (userId: number) => Promise<void>
}) => {
  return (
    <RowDeprecated gap={0.25}>
      <TypographyNew variant={'body100Normal'} color={'onBackgroundVariant1'}>
        Assignee
      </TypographyNew>
      <PopoverBaseContainer
        maxHeight={178}
        anchor={
          <SurfaceContainer
            interactive
            spaces={['xs', 'xs']}
            borderRadius={'md'}
          >
            <Avatar size={'xxs'} src={assignee?.avatar} name={assignee?.name} />
          </SurfaceContainer>
        }
        content={
          <AssigneePopupContent
            assignees={assignees}
            assignee={assignee}
            onChangeAssignee={onChangeAssignee}
          />
        }
      />
    </RowDeprecated>
  )
}

const AssigneePopupContent = ({
  assignees,
  assignee,
  onChangeAssignee,
}: {
  assignees: CaseAssignee[]
  assignee: CaseDataAssignee
  onChangeAssignee: (userId: number) => Promise<void>
}) => {
  const { user } = useSettings()
  const { setOpen } = usePopoverState()
  const [isLoadingAssignee, setIsLoadingAssignee] = useState<number | null>(
    null
  )
  return (
    <div style={{ minWidth: 256 }}>
      <Stack direction={'column'} padding={['md', 'none']}>
        {sortBy(assignees, 'name').map((el) => {
          return (
            <ListItem
              onClick={async () => {
                setIsLoadingAssignee(el.id)
                await onChangeAssignee(el.id)
                setIsLoadingAssignee(null)
                setOpen(false)
              }}
              isSelected={el.id === assignee?.id}
              key={el.id}
              size={['lg', 'md']}
              density={'lg'}
              content={(params) => (
                <ListItemContent
                  {...params}
                  slots={[
                    {
                      type: 'iconText',
                      component: ({ density, variant: [body] }) => (
                        <ListItemAtom gap={density}>
                          {isLoadingAssignee === el.id ? (
                            <IconButton isLoading={true} size={'sm'} />
                          ) : (
                            <Avatar
                              size={'xxs'}
                              src={el?.avatar}
                              name={el?.name}
                            />
                          )}

                          <Stack gap={'xs'} align={'center'}>
                            <EllipsisTypography
                              variant={body}
                              color={'onBackgroundBase'}
                            >
                              {el.name}
                            </EllipsisTypography>
                            {user.id === el.id ? (
                              <TypographyNew
                                variant={body}
                                color={'onBackgroundVariant1'}
                              >
                                - You
                              </TypographyNew>
                            ) : null}
                          </Stack>
                        </ListItemAtom>
                      ),
                    },
                  ]}
                />
              )}
            />
          )
        })}
      </Stack>
    </div>
  )
}

import React from 'react'

import { MagicGrid } from '@clain/core/ui-kit'

import { CasesDataItem } from '../../types/Case'
import RelatedCaseCard from '../RelatedCaseCard'
import { SnakeToCamelCaseArray } from '@clain/core/utilsTypes'

interface ClientCasesListProps {
  cases?: SnakeToCamelCaseArray<CasesDataItem>
}

const ClientCasesList: React.FC<ClientCasesListProps> = ({ cases }) => (
  <MagicGrid>
    {cases?.map((caseItem) => (
      <RelatedCaseCard key={caseItem.id} case={caseItem} />
    ))}
  </MagicGrid>
)

export default ClientCasesList

import React from 'react'
import classnames from 'classnames/bind'
import { getConfig } from '@clain/core/useConfig'

import buildUrl from '@clain/core/utils/buildUrl'
import Portlet from '@clain/core/Portlet'
import useHttp from '@clain/core/useHttp'
import { Typography } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'

import {
  CasesProcessedData,
  CasesProcessedApiParams,
} from '../../../types/Dashboard'

import SeverityDuoDonuts from '../../Chart/severityDuoDonuts'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface TotalCasesProcessedBlockProps {
  className?: string
  assignee?: number
}

interface TotalCasesProcessed {
  high: number
  medium: number
  low: number
  total: number
  title: string
}

type TotalCasesProcessedNormalized = [TotalCasesProcessed, TotalCasesProcessed]

const TotalCasesProcessedBlock: React.FC<TotalCasesProcessedBlockProps> = ({
  className,
  assignee,
}) => {
  const apiParams = React.useMemo(
    (): CasesProcessedApiParams => ({
      assignee_id: assignee,
    }),
    [assignee]
  )
  const { data: source, isFirstLoading } = useHttp<CasesProcessedData>(
    buildUrl`${getConfig()?.API}/api/dash/cases_by_severity?${apiParams}`
  )

  const totalTrueClosed = source
    ? Object.values(source?.cases_true_closed).reduce((a, b) => a + b, 0)
    : 0
  const totalFalseClosed = source
    ? Object.values(source?.cases_false_closed).reduce((a, b) => a + b, 0)
    : 0

  const data = React.useMemo(
    (): TotalCasesProcessedNormalized => [
      {
        high: source?.cases_true_closed.HIGH || 0,
        medium: source?.cases_true_closed.MEDIUM || 0,
        low: source?.cases_true_closed.LOW || 0,
        total: totalTrueClosed,
        title: 'True positive',
      },
      {
        high: source?.cases_false_closed.HIGH || 0,
        medium: source?.cases_false_closed.MEDIUM || 0,
        low: source?.cases_false_closed.LOW || 0,
        total: totalFalseClosed,
        title: 'False positive',
      },
    ],
    [
      source?.cases_false_closed?.HIGH,
      source?.cases_false_closed?.LOW,
      source?.cases_false_closed?.MEDIUM,
      source?.cases_true_closed?.HIGH,
      source?.cases_true_closed?.LOW,
      source?.cases_true_closed?.MEDIUM,
      totalFalseClosed,
      totalTrueClosed,
    ]
  )

  return (
    <Portlet variant="card" className={className}>
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Total cases processed
          </Typography>
        ),
        body: isFirstLoading ? (
          <Stub
            className={cx('TotalCasesProcessedPortletStub')}
            isActive={true}
          />
        ) : (
          <SeverityDuoDonuts data={data} />
        ),
      }}
    </Portlet>
  )
}

export default TotalCasesProcessedBlock

import React from 'react'
import classnames from 'classnames/bind'

import { RangeValue } from '@clain/core/ui-kit'
import { ScoreRangeSlider } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { InputDropdown } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import { FieldProps } from './types'

import styles from './index.scss'

const cx = classnames.bind(styles)

const ScoreFilters = ({ value, onChange }: FieldProps<RangeValue>) => {
  // всегда задаем скор по умолчанию, потому что иначе придется показывать пустоту
  const [min = 1, max = 10] = value

  const printValue = min === 1 && max === 10 ? '' : `${min} — ${max}`

  const handleInputClear = () => {
    onChange([1, 10])
  }

  return (
    <InputDropdown
      clearable
      sameWidth
      value={printValue}
      variant="outline"
      placeholder="Any"
      startAddon={{ label: 'Score:' }}
      onClear={handleInputClear}
      padded={false}
    >
      <Container gap={[1.5, 1]}>
        <MagicGrid gap={0.5}>
          <Typography>Score range</Typography>
          <MagicGrid gap={2}>
            <ScoreRangeSlider value={[min, max]} onChange={onChange} />
            <div className={cx('ScoreRangeInputsContainer')}>
              <TextField
                value={min}
                variant="outline"
                className={cx('ScoreRangeInput')}
                readOnly
              />
              <TextField
                value={max}
                variant="outline"
                className={cx('ScoreRangeInput')}
                readOnly
              />
            </div>
          </MagicGrid>
        </MagicGrid>
      </Container>
    </InputDropdown>
  )
}

export default ScoreFilters

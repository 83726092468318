import React from 'react'
import { Header } from '@clain/core/Layout'
import { Stub } from '@clain/core/ui-kit'
import CaseActions from '../CaseActions'
import { Divider, Stack } from '@clainio/web-platform'
import { FooterProgress } from './CaseHeader.styles'
import { CaseHeaderProps } from './CaseHeader.types'
import { CaseStatusProgressBar } from './CaseStatusProgressBar'
import { CaseActionsComponent } from './CaseActionsComponent'
import { CaseHeaderSubtitle } from './CaseHeaderSubtitle'
import { useCaseActions } from '../CaseActions.context'

export const CaseHeader = ({
  id,
  data,
  onTitleChange,
  loading,
  assignees,
  assignee,
}: CaseHeaderProps) => {
  const { tagCase } = useCaseActions()
  const actions = !loading ? (
    <CaseActions id={id} status={data?.status} assignees={assignees} />
  ) : (
    <Stub type={'grey'} width={134} height={40} isActive={true} />
  )

  const handleTitleChange = async (tag: string) => {
    await tagCase(id, { tag })
    if (onTitleChange) {
      await onTitleChange(tag)
    }
  }

  return (
    <Header
      title={'Case'}
      subTitle={
        !loading ? (
          <CaseHeaderSubtitle
            onChangeSubmit={handleTitleChange}
            text={data?.tag || ''}
          />
        ) : (
          <Stub type={'grey'} width={243} height={24} isActive={true} />
        )
      }
      actions={
        data?.status === 'new' || !assignees ? null : (
          <CaseActionsComponent
            id={id}
            isLoading={loading}
            status={data?.status}
            dueDate={data?.dueDate ? new Date(data?.dueDate) : null}
            assignees={assignees}
            assignee={assignee}
          />
        )
      }
      footer={
        data?.status === 'new' || !assignees ? null : (
          <>
            <Divider type={'line'} spaces={['xl', 'none']} color={'high'} />
            <Stack align={'center'} justify={'space-between'} gap={'xl'}>
              <FooterProgress>
                <CaseStatusProgressBar
                  status={data?.status}
                  loading={loading}
                  reviewDays={data?.reviewDays}
                  investigationDays={data?.investigationDays}
                />
              </FooterProgress>
              {actions}
            </Stack>
          </>
        )
      }
    />
  )
}

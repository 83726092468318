import React from 'react'
import classnames from 'classnames/bind'

import { generatePath, useMatch, useNavigate } from '@clain/core/Router/router'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { MagicGrid } from '@clain/core/ui-kit'
import NotFound from '@clain/core/NotFound'

import DashboardHeader, { DashboardTabType } from './DashboardHeader'
import ClientsBlock from './ClientsBlock'
import ClientsScoreBlock from './ClientsScoreBlock'
import TransfersIncidentsCasesBlock from './FunnelBlock'
import TransfersVolumeBlock from './TransfersVolumeBlock'
import IncidentsBlock from './IncidentsBlock'
import ClientsBlockedBlock from './ClientsBlockedBlock'
import CasesToDueDateBlock from './CasesToDueDateBlock'
import AverageTimeToProcessCaseBlock from './AverageTimeToProcessCaseBlock'
import MyOngoingCasesBlock from './OngoingCasesBlock'
import TotalCasesProcessedBlock from './TotalCasesProcessedBlock'
import TriggeredTransactionsAmountBlock from './TriggeredTransactionsAmountBlock'

import styles from './index.scss'

const cx = classnames.bind(styles)

const ClientsClientsScoreBlock: React.FC = () => (
  <div className={cx('ClientsClientsScoreBlock')}>
    <ClientsBlock className={cx('ClientsBlock')} />
    <ClientsScoreBlock className={cx('ClientsScoreBlock')} />
  </div>
)

const IncidentsClientsBlockedBlock: React.FC = () => (
  <div className={cx('IncidentsClientsBlockedBlock')}>
    <IncidentsBlock className={cx('IncidentsBlock')} />
    <ClientsBlockedBlock className={cx('ClientsBlockedBlock')} />
  </div>
)

const AverageTimeToProcessCaseMyOngoingCasesBlock: React.FC<{
  assignee: number
}> = ({ assignee }) => (
  <div className={cx('AverageTimeToProcessCaseMyOngoingCasesBlock')}>
    <AverageTimeToProcessCaseBlock
      className={cx('AverageTimeToProcessCaseBlock')}
      assignee={assignee}
    />
    <MyOngoingCasesBlock
      className={cx('MyOngoingCasesBlock')}
      assignee={assignee}
    />
  </div>
)

const TotalCasesProcessedTriggeredTransactionsAmountBlock: React.FC<{
  assignee: number
}> = ({ assignee }) => (
  <div className={cx('TotalCasesProcessedTriggeredTransactionsAmountBlock')}>
    <TotalCasesProcessedBlock
      className={cx('TotalCasesProcessedBlock')}
      assignee={assignee}
    />
    <TriggeredTransactionsAmountBlock
      className={cx('TriggeredTransactionsAmountBlock')}
      assignee={assignee}
    />
  </div>
)

const Dashboard: React.FC = () => {
  const nav = useNavigate()
  const match = useMatch('/dashboard/:tab')

  const tab = match ? (match.params.tab as DashboardTabType) : 'enterprise'
  const handleUpdateTab = (tab) => {
    nav(generatePath('/dashboard/:tab', { tab }))
  }

  const [assignee, setAssignee] = React.useState<number>()

  HeaderSlot.useContent(
    () => (
      <DashboardHeader
        tab={tab}
        onTabChange={handleUpdateTab}
        assignee={assignee}
        onAssigneeChange={setAssignee}
      />
    ),
    [tab, assignee]
  )

  if (tab === 'enterprise') {
    return (
      <MagicGrid gap={2}>
        <ClientsClientsScoreBlock />
        <TransfersIncidentsCasesBlock />
        <TransfersVolumeBlock />
      </MagicGrid>
    )
  }

  if (tab === 'officer') {
    return (
      <MagicGrid gap={2}>
        <IncidentsClientsBlockedBlock />
        <CasesToDueDateBlock assignee={assignee} />
        <AverageTimeToProcessCaseMyOngoingCasesBlock assignee={assignee} />
        <TotalCasesProcessedTriggeredTransactionsAmountBlock
          assignee={assignee}
        />
      </MagicGrid>
    )
  }

  return <NotFound />
}

export default Dashboard

import { inject, injectable } from 'inversify'
import { IReactionDisposer, reaction } from 'mobx'
import {
  IIncidentService,
  IClientService,
  ICaseService,
} from '@compliance/apiServices'
import * as Sentry from '@sentry/react'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { REJECT_REASON } from '@clain/core/utils/WebSocket'
import { Notification } from '@clain/core/ui-kit'
import { notificationToastOptions } from '@compliance/utils/Notifications'
import { settingsController } from '@compliance/controllers/SettingsController'
import {
  convertToUnixTimestamp,
  getCurrentTimezone,
} from '@clain/core/utils/date'
import { convertFileToChatFile } from '@compliance/utils/convertFileToChatFile'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'
import {
  CaseActionsController,
  ICaseActionsController,
} from '@compliance/components/Case/CaseActionsController'
import { CaseStore } from '@compliance/modules/CaseStore'
import { prop, uniqBy } from 'ramda'

@injectable()
export class CaseViewModel {
  private reactionDisposers: Array<IReactionDisposer> = []
  public caseActionsController: ICaseActionsController

  constructor(
    @inject(DY_TYPES.CaseStore) private caseStore: CaseStore,
    @inject(DY_TYPES.CaseService)
    private caseApiService: ICaseService,
    @inject(DY_TYPES.ClientService)
    private clientApiService: IClientService,
    @inject(DY_TYPES.IncidentService)
    private incidentApiService: IIncidentService
  ) {
    this.caseActionsController = new CaseActionsController({
      caseAPIService: this.caseApiService.rest,
      callbacks: {
        tagCaseCallback: this.revalidateData,
        assignCaseCallback: this.revalidateData,
        caseDueDateCallback: this.revalidateData,
        reopenCaseCallback: this.revalidateData,
        setCaseToPendingCallback: this.revalidateData,
        setCaseToCloseCallback: this.revalidateData,
      },
    })
  }

  public get store() {
    return this.caseStore
  }

  public init = async (caseId: number) => {
    try {
      this.initReactions()
      this.store.commonData.initState({ caseId })
      this.fetchData()
      await this.initSocketChannel(caseId)
      this.store.setInitialized(true)
    } catch (error) {
      this.handleError(error, 'Failed to initialize case page')
    }
  }

  public attachFilesToChatMessage = async (files: File[]) => {
    let processingFiles: number[] = []
    try {
      if (files?.length) {
        const { filesInProcessing, fileIds } = this.store.chatDataStore.data
        const startIndex = fileIds.length
        const newIndexes = Array.from(
          { length: files.length },
          (_, i) => startIndex + i
        )

        this.store.chatDataStore.actions.updateTemporaryData({
          filesInProcessing: [...filesInProcessing, ...newIndexes],
          filePreviews: files,
        })
        processingFiles = [...filesInProcessing, ...newIndexes]

        const caseId = this.store.commonData.state.caseId
        const data = await this.caseApiService.rest.postChatFilesAttach(
          caseId,
          { files }
        )

        this.store.chatDataStore.actions.updateTemporaryData({
          fileIds: data.fileIds,
        })
      }
    } catch (e) {
      this.store.chatDataStore.actions.updateTemporaryData({
        fileIds: [],
      })
      this.handleError(e, 'Failed to attach files')
    } finally {
      const { filesInProcessing } = this.store.chatDataStore.data
      this.store.chatDataStore.actions.updateTemporaryData({
        filesInProcessing: filesInProcessing.filter(
          (index) => !processingFiles.includes(index)
        ),
      })
    }
  }

  public removeChatFile = (fileIndex: number) => {
    const { fileIds } = this.store.chatDataStore.data

    this.store.chatDataStore.actions.updateTemporaryData({
      fileIds: fileIds.filter((_, index) => index !== fileIndex),
    })
  }

  public sendAiChatMessage = async ({ message }: { message: string }) => {
    try {
      this.store.chatDataStore.actions.updateTemporaryData({
        error: null,
      })
      const user = settingsController.getUser()
      const createdAt = convertToUnixTimestamp(new Date(), getCurrentTimezone())
      this.store.chatDataStore.actions.updateTemporaryData({
        chatUserMessage: normalizeSnakeToCamelCase({
          role: 'user',
          id: createdAt.toString(),
          used_in_report: false,
          files: this.store.chatDataStore.data?.filePreviews?.map(
            convertFileToChatFile
          ),
          content: message,
          created_at: createdAt,
          user: {
            id: user.id,
            name: user.name,
            avatar: user.avatar,
          },
        }),
      })
      await this.caseApiService.socket.sendAiChatMessage({
        message,
        fileIds: this.store.chatDataStore.data.fileIds,
      })
    } catch (e) {
      this.handleError(e, 'Failed to send chat message')
    }
  }

  public retrySendAiChatMessage = () => {
    try {
      this.store.chatDataStore.actions.updateTemporaryData({
        error: null,
      })
      this.caseApiService.socket.retrySendAiChatMessage()
    } catch (e) {
      this.handleError(e, 'Failed to retry send chat message')
    }
  }

  public regenerateReport = async () => {
    try {
      await this.caseApiService.socket.regenerateReport()
    } catch (e) {
      this.showErrorNotification(e?.reason || 'Failed to regenerate report')
      this.handleError(e, 'Failed to regenerate report')
    }
  }

  public useMessageInReport = async ({ messageId }: { messageId: string }) => {
    try {
      await this.caseApiService.socket.useMessageInReport({
        messageId,
      })
    } catch (e) {
      this.showErrorNotification(e?.reason || 'Failed to use chat message')
      this.handleError(e, 'Failed to use chat message in report')
    }
  }

  public clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.store.clear()
    this.caseApiService.socket.closeSocketChannel()
  }

  private initSocketChannel = async (caseId: number) => {
    try {
      const aiChatResult =
        await this.caseApiService.socket.initializeSocketChannel({
          id: caseId,
        })
      this.store.chatDataStore.actions.initData(aiChatResult)
      this.subscribeToAIChatMessageStream()
    } catch (error) {
      this.handleError(error, 'Failed to initialize socket channel')
    }
  }

  private subscribeToAIChatMessageStream = () => {
    this.caseApiService.socket.subscribeToStreamSocketChannel((res) => {
      if ('data' in res) {
        this.store.chatDataStore.data.chatSystemMessageResponseArray.push(
          res.data
        )
      }
      if ('chat_status' in res) {
        this.store.chatDataStore.actions.updateData({
          chatStatus: res.chat_status,
        })
      }
      if ('error' in res) {
        this.handleError(res?.error, 'Failed to send chat message')
        this.store.chatDataStore.actions.updateTemporaryData({
          error: res.error,
        })
      }
    })

    this.caseApiService.socket.subscribeToSummarySocketChannel((res) => {
      if ('error' in res) {
        this.showErrorNotification(res.error)
      } else {
        this.store.chatDataStore.actions.updateData({
          aiSummary: res.summary,
        })
      }
    })

    this.caseApiService.socket.subscribeToCaseStatusSocketChannel((res) => {
      if ('error' in res) {
        this.showErrorNotification(res.error)
      } else {
        this.store.chatDataStore.actions.updateData({
          aiStatus: res.ai_status,
        })
      }
    })

    this.caseApiService.socket.subscribeToMessageUsedInSummarySocketChannel(
      (res) => {
        if ('error' in res) {
          this.showErrorNotification(res.error)
        } else {
          this.store.chatDataStore.actions.updateTemporaryData((prevState) => ({
            ...prevState,
            chatMessagesUsedInSummary: [
              ...prevState.chatMessagesUsedInSummary,
              res.message_id,
            ],
          }))
        }
      }
    )

    this.caseApiService.socket.subscribeToThreadMessageSocketChannel((res) => {
      if ('error' in res) {
        this.showErrorNotification(res.error)
      } else {
        this.store.chatDataStore.actions.resetTemporaryData()
        this.store.chatDataStore.actions.updateData((prevState) => ({
          ...prevState,
          chatMessages: uniqBy(prop('id'), [
            ...prevState.chatMessages,
            ...normalizeSnakeToCamelCase(res.messages),
          ]),
        }))
      }
    })
  }

  private initReactions = () => {
    this.reactionDisposers.push(
      reaction(
        () => this.store.clientId,
        (clientId) => {
          if (clientId != null) {
            this.fetchClient()
            this.fetchClientCases()
            this.fetchClientIncidents()
          }
        }
      )
    )
  }

  private fetchData = () => {
    this.fetchCase()
    this.fetchActivity()
    this.store.apiServicesStateFacade.initApiParamsStateByService('assignees')(
      {}
    )
    this.store.apiServicesStateFacade.injectRequestMethodByService('assignees')(
      this.caseApiService.rest.getAssignees
    )

    this.store.apiServicesStateFacade.initDataLoadingReaction('assignees')
  }

  private fetchActivity = () => {
    const caseId = this.store.commonData.state.caseId
    this.store.apiServicesStateFacade.initApiParamsStateByService('activity')({
      caseId,
    })
    this.store.apiServicesStateFacade.injectRequestMethodByService('activity')(
      this.caseApiService.rest.getCaseActivity
    )

    this.store.apiServicesStateFacade.initDataLoadingReaction('activity')
  }

  private fetchCase = () => {
    const caseId = this.store.commonData.state.caseId
    this.store.apiServicesStateFacade.initApiParamsStateByService('case')({
      caseId,
    })
    this.store.apiServicesStateFacade.injectRequestMethodByService('case')(
      this.caseApiService.rest.getCaseById
    )

    this.store.apiServicesStateFacade.initDataLoadingReaction('case')
  }

  private fetchClient = () => {
    this.store.apiServicesStateFacade.initApiParamsStateByService('client')({
      clientId: `${this.store.data.client.id}`,
    })
    this.store.apiServicesStateFacade.injectRequestMethodByService('client')(
      this.clientApiService.getClientById
    )

    this.store.apiServicesStateFacade.initDataLoadingReaction('client')
  }

  private fetchClientCases = () => {
    this.store.apiServicesStateFacade.initApiParamsStateByService('cases')({
      client_id: this.store.data.client.id,
    })
    this.store.apiServicesStateFacade.injectRequestMethodByService('cases')(
      this.caseApiService.rest.getCases
    )

    this.store.apiServicesStateFacade.initDataLoadingReaction('cases')
  }

  private fetchClientIncidents = () => {
    this.store.apiServicesStateFacade.initApiParamsStateByService('incidents')({
      client_id: this.store.data.client.id,
      status: 'all',
    })
    this.store.apiServicesStateFacade.injectRequestMethodByService('incidents')(
      this.incidentApiService.rest.getIncidents
    )

    this.store.apiServicesStateFacade.initDataLoadingReaction('incidents')
  }

  private revalidateData = async () => {
    await Promise.all([
      this.store.apiServicesStateFacade.requestService('case'),
      this.store.apiServicesStateFacade.requestService('activity'),
    ])
  }

  private handleError = (error: any, message: string) => {
    if (error?.message === REJECT_REASON.offline) {
      return
    }
    Sentry.captureException(error, { extra: { message } })
    console.error(message, error)
  }

  private showErrorNotification = (error: string) => {
    Notification.notify(error, { type: 'warning' }, notificationToastOptions)
  }
}

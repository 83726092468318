import React from 'react'
import castArray from 'lodash/castArray'
import { endOfDay } from 'date-fns'

import { UnixTimestamp, DateInterval } from '@clain/core/types'
import { useFormatDate } from '../../hooks'
import { formatMoney } from '@clain/core/utils/format'
import Chart from '@clain/core/Chart2'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'
import { observer } from 'mobx-react-lite'
type Row = [UnixTimestamp, number]

type TransfersType = 'count' | 'volume'

const TransfersFlowTooltip = ({ type, points: p, formatDate }) => {
  const points = castArray(p)

  if (!points || points.length === 0) {
    return null
  }

  points.forEach((point) => {
    point.y = point.value[1]
  })

  const time = points[0].value[0]

  // сначала определяем какая точка к нам пришла,
  // положительная или отрицательная

  let positiveY
  let negativeY

  if (points.length === 2) {
    positiveY = points[0].y
    negativeY = points[1].y
  }

  if (points.length === 1 && points[0].y > 0) {
    positiveY = points[0].y
  }

  if (points.length === 1 && points[0].y < 0) {
    negativeY = points[0].y
  }

  // а потом смотрим какого типа она была
  // и показывать будем только его

  let received
  let send

  let deposits
  let withdrawals

  if (type === 'volume') {
    received = positiveY
    send = negativeY
  }

  if (type === 'count') {
    deposits = positiveY
    withdrawals = negativeY
  }

  const receivedMoney = received
    ? formatMoney({
        value: received,
        currency: 'usd',
        precision: 2,
        code: '',
      })
    : ''

  const sendMoney = send
    ? formatMoney({
        value: Math.abs(send),
        currency: 'usd',
        precision: 2,
        code: '',
      })
    : ''

  return (
    <Container>
      <ColDeprecated>
        <Typography color="grey2">
          {formatDate(new Date(time), 'date')}
        </Typography>
        {type === 'count' && (
          <ColDeprecated gap={0}>
            {deposits ? (
              <Typography color="black">
                Deposits: <Typography variant="body2">{deposits}</Typography>
              </Typography>
            ) : null}
            {withdrawals ? (
              <Typography color="black">
                Withdrawals:{' '}
                <Typography variant="body2">{withdrawals}</Typography>
              </Typography>
            ) : null}
          </ColDeprecated>
        )}
        {type === 'volume' && (
          <ColDeprecated gap={0}>
            {receivedMoney ? (
              <Typography color="black">
                Received:{' '}
                <Typography variant="body3" color="grey1">
                  {receivedMoney}
                </Typography>
              </Typography>
            ) : null}
            {sendMoney ? (
              <Typography color="black">
                Sent:{' '}
                <Typography variant="body3" color="grey1">
                  {sendMoney}
                </Typography>
              </Typography>
            ) : null}
          </ColDeprecated>
        )}
      </ColDeprecated>
    </Container>
  )
}

interface TransfersFlowData {
  inflow?: Row[]
  outflow?: Row[]
}
interface FlowOptions {
  type: TransfersType
  data: TransfersFlowData
  zoom?: DateInterval
}

function getOptions({
  data,
  zoom,
  type,
  formatDate,
}: FlowOptions & { formatDate: ReturnType<typeof useFormatDate> }) {
  const options = {
    tooltip: {
      trigger: 'axis',
      padding: 0,
      showDelay: 0.001,
      hideDelay: 0,
      borderRadius: 8,
      transitionDuration: 0,
      // TODO: https://github.com/apache/echarts/blob/master/src/component/tooltip/TooltipView.ts#L777
      formatter: createTooltipFormatter(
        (points) => ({ points }),
        TransfersFlowTooltip,
        { type, formatDate }
      ),
      backgroundColor: 'rgba(255, 255, 255, 1)',
      extraCssText:
        'box-shadow: 0px 4px 40px rgba(0, 17, 158, 0.25); width: 300px; z-index: 0; width: 220px; border: none',
      appendToBody: true,
    },

    xAxis: [
      {
        type: 'time',
        axisPointer: {
          type: 'none',
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: '#8D9EC1',
          },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
          onZero: false,
        },
      },
    ],

    dataZoom: [
      {
        type: 'slider',
        show: true,
        brushSelect: false,
        zoomLock: true,
        xAxisIndex: [0],
        // TODO: это вообще что и зачем?
        // filterMode: 'weekFilter',
        startValue: zoom.start,
        endValue: zoom.end,

        // отключаем лейблы слева и справа
        showDetail: false,
        // TODO: посмотреть как менее глючно работает
        // чтобы график не дергался обновляем его после того как отпустили
        realtime: false,
        // throttle: 2000,
      },
    ],

    // настройка отступов
    grid: {
      top: 50,
      left: 20,
      // справа почему-то всегда меньше отступ получается — компенсируем
      right: 25,
      bottom: 50,
      containLabel: true,
    },

    series: [
      {
        color: '#2173FF',
        name: 'In',
        data: data.inflow.map((value) => ({
          value,
        })),
        type: 'bar',
        stack: 'volume',

        barGap: 8,
        barMinWidth: 1,
        barMaxWidth: 40,
        sampling: 'sum',
        itemStyle: {
          color: '#808DFF',
          borderRadius: 4,
        },
      },
      {
        color: '#FF2751',
        name: 'Out',
        data: data.outflow.map((value) => ({
          value,
        })),
        type: 'bar',
        stack: 'volume',

        barGap: 8,
        barMinWidth: 1,
        barMaxWidth: 40,
        sampling: 'sum',
        itemStyle: {
          color: '#BDC4FF',
          borderRadius: 4,
        },
      },
    ],
  }

  return options
}

const chartStyle = {
  height: 430,
}

interface FlowChartProps extends FlowOptions {
  loading?: boolean
  onZoomChange?: (interval: DateInterval) => void
}

const TransfersFlow = ({
  data,
  loading,
  onZoomChange,
  ...props
}: FlowChartProps) => {
  const formatDate = useFormatDate()

  const handleZoom = (event, instance) => {
    const zoomValue = instance.getOption().dataZoom[0] as {
      startValue: number
      endValue: number
    } // in ms

    // чтобы ширина интервала не менялась от приведения времени
    const end = endOfDay(zoomValue.endValue)
    const diff = zoomValue.endValue - zoomValue.startValue
    const start = new Date(end.getTime() - diff)

    onZoomChange?.({
      start,
      end,
    })
  }

  const onEvents = React.useMemo(() => {
    return {
      datazoom: handleZoom,
    }
  }, [])

  return (
    <Chart
      loading={loading}
      option={getOptions({ ...props, data, formatDate })}
      style={chartStyle}
      onEvents={onEvents}
    />
  )
}

export default observer(TransfersFlow)

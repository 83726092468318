import { Group } from 'pts'
import CoreController from './CoreController'
import Stage from '../views/Stage'
import Conversion from '../views/Conversion'
import StageLabel from '../views/StageLabel'
import StageIndicator from '../views/StageIndicator'
import { IController } from '../../core/types'
import Typography from '../../core/views/Typography'

const STAGE_TO_CONVERSION_HEIGHT = 1.5
const FUNNEL_TO_CANVAS_WIDTH = 0.7
const STAGE_LABEL_TO_CANVAS_WIDTH = 0.15
const STAGE_INDICATOR_PADDING_TO_CANVAS_WIDTH = 0.04

class ViewController implements IController {
  public ctrl: CoreController

  get form() {
    return this.ctrl.form
  }

  get space() {
    return this.ctrl.space
  }

  public stages: Array<Stage> = []
  public conversions: Array<Conversion> = []
  public stageLabels: Array<StageLabel> = []
  public stageIndicators: Array<StageIndicator> = []
  public conversionLabels: Array<Typography> = []

  public pivot: number = 0
  public stageHeight: number = 0
  public conversionHeight: number = 0

  constructor(ctrl: CoreController) {
    this.ctrl = ctrl
  }

  private resizedBoundX: number
  private resizedBoundY: number

  get boundX() {
    return this.space?.innerBound?.size?.x || this.resizedBoundX
  }

  get boundY() {
    return this.space?.innerBound?.size?.y || this.resizedBoundY
  }

  public init() {
    this.stages = []
    this.conversions = []
    this.stageLabels = []
    this.stageIndicators = []
    this.conversionLabels = []

    this.pivot = 0
    this.stageHeight = 0
    this.conversionHeight = 0

    this.createViews()
    this.calculateParams()
    this.initViews()
  }

  public render() {
    this.stages.forEach((stage) => {
      stage.render()
    })
    this.conversions.forEach((stage) => {
      stage.render()
    })
    this.stageLabels.forEach((stageLabel) => {
      stageLabel.render()
    })
    this.stageIndicators.forEach((stageIndicator) => {
      stageIndicator.render()
    })
    this.conversionLabels.forEach((stageIndicator) => {
      stageIndicator.render()
    })
  }

  public handleActions(type: string, px: number, py: number, event: Event) {
    // handle views events
  }

  public handleResize(size: Group, event: Event) {
    this.resizedBoundX = size.p2.x
    this.resizedBoundY = size.p2.y
    this.calculateParams()
    this.initViews()
  }

  private createViews() {
    this.ctrl.options.stages.forEach((stage) => {
      this.stages.push(new Stage(this))
    })
    this.ctrl.options.conversions.forEach(() => {
      this.conversions.push(new Conversion(this))
    })
    this.ctrl.options.stages.forEach(() => {
      this.stageLabels.push(new StageLabel(this))
    })
    this.ctrl.options.stages.forEach(() => {
      this.stageIndicators.push(new StageIndicator(this))
    })
    this.ctrl.options.conversions.forEach(() => {
      this.conversionLabels.push(new Typography(this))
    })
  }

  private calculateParams() {
    this.conversionHeight =
      this.boundY /
      (this.stages.length * STAGE_TO_CONVERSION_HEIGHT +
        this.conversions.length)
    this.stageHeight = this.conversionHeight * STAGE_TO_CONVERSION_HEIGHT
    this.pivot = (this.boundX * FUNNEL_TO_CANVAS_WIDTH) / 2
  }

  private initViews() {
    this.initStages()
    this.initConversions()
    this.initStageLabels()
    this.initStageIndicators()
    this.initConversionLabels()
  }

  private initStages() {
    let prevStageWidthRatio = 0
    this.stages.forEach((stage, index) => {
      let stageWidthRatio = FUNNEL_TO_CANVAS_WIDTH - index * 0.27
      if (stageWidthRatio < 0) {
        stageWidthRatio = prevStageWidthRatio * 0.5
      }
      const stageWidth = this.boundX * stageWidthRatio
      const x0 = this.pivot - stageWidth / 2
      const y0 = this.stageHeight * index + this.conversionHeight * index
      stage.init(
        [x0, y0],
        stageWidth,
        this.stageHeight,
        this.ctrl.options.stages[index].value > 0
      )
      prevStageWidthRatio = stageWidthRatio
    })
  }

  private initConversions() {
    this.stages.slice(0, -1).forEach((stage, index) => {
      const x0 = stage.layout.p1.x
      const y0 = stage.layout.p2.y
      const x1 = stage.layout.p2.x
      const y1 = stage.layout.p2.y
      const x2 = this.stages[index + 1].layout.p2.x
      const y2 = this.stages[index + 1].layout.p1.y
      const x3 = this.stages[index + 1].layout.p1.x
      const y3 = this.stages[index + 1].layout.p1.y
      this.conversions[index].init([x0, y0], [x1, y1], [x2, y2], [x3, y3])
    })
  }

  private initStageLabels() {
    const x0 = this.boundX * (1 - STAGE_LABEL_TO_CANVAS_WIDTH)
    this.stages.forEach((stage, index) => {
      const y0 = stage.layout.p1.y
      const width = this.boundX * STAGE_LABEL_TO_CANVAS_WIDTH
      const height = stage.layout.p2.y - stage.layout.p1.y
      const label1 = String(this.ctrl.options.stages[index].value)
      const label2 = this.ctrl.options.stages[index].label
      this.stageLabels[index].init([x0, y0], width, height, label1, label2)
    })
  }

  private initStageIndicators() {
    const padding = this.boundX * STAGE_INDICATOR_PADDING_TO_CANVAS_WIDTH
    this.stages.forEach((stage, index) => {
      const x0 = stage.layout.p2.x + padding
      const y0 = stage.layout.p1.y + (stage.layout.p2.y - stage.layout.p1.y) / 2
      const x1 = this.stageLabels[index].label1Layout.p1.x - padding
      const y1 = y0
      this.stageIndicators[index].init([x0, y0], [x1, y1])
    })
  }

  private initConversionLabels() {
    const maxLabelWidth = this.boundX * FUNNEL_TO_CANVAS_WIDTH
    this.conversions.forEach((conversion, index) => {
      const { value, label } = this.ctrl.options.conversions[index]

      const text = [
        {
          value: value ? `${value}% ` : '',
          fontSize: '24',
          color: '#374766',
        },
      ]

      if (label) {
        text.push({
          value: label,
          fontSize: '14',
          color: '#374766',
        })
      }

      this.conversionLabels[index].init({
        topLeft: [0, conversion.layout.p1.y],
        width: maxLabelWidth,
        height: this.conversionHeight,
        text,
        verticalAlign: 'middle',
        align: 'center',
      })
    })
  }
}

export default ViewController

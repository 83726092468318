import type { CalendarInputValue } from '@clain/core/ui-kit'
import type { Amount } from '../../AmountInput'

export enum FIELDS {
  selected = 'selected',
  created = 'created',
  someSearch = 'someSearch',
  severity = 'severity',
  rule = 'rule',
  riskAmount = 'riskAmount',
}

interface StaticFiltersState {
  [FIELDS.selected]: Array<string>
  [FIELDS.created]: CalendarInputValue
  [FIELDS.someSearch]: string | null
}

interface SelectableFiltersState {
  [FIELDS.severity]: Array<string> | null
  [FIELDS.riskAmount]: Amount | null
  [FIELDS.rule]: string | null
}

export type FiltersState = StaticFiltersState & SelectableFiltersState

export interface FiltersProps {
  defaultFiltersState: FiltersState
  initialValues: FiltersState
  onChange: (value: FiltersState) => void
}

export interface FieldProps<T> {
  value: T | null
  onChange: (value: T | null) => void
}

type FieldType = keyof SelectableFiltersState

export type FieldTypes = {
  [k in FieldType]: React.ComponentType<FieldProps<any>>
}

type FieldOption = {
  label: string
  value: FieldType
}

export type FieldOptions = Array<FieldOption>

import React from 'react'
import classnames from 'classnames/bind'
import { startOfDay } from 'date-fns'

import { useTranslateDate } from '../../../hooks'
import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'
import Portlet from '@clain/core/Portlet'
import { Typography } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'

import {
  DashboardClientScoresData,
  DashboardClientScoresApiParams,
} from '../../../types/Dashboard'
import ScoreBars from '../../Chart/scoreBars'
import Period, { PeriodValue, getDateFromPeriod } from '../Filters/Period'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

interface ClientsBlockProps {
  className?: string
}

const ClientsBlock: React.FC<ClientsBlockProps> = ({ className }) => {
  const [period, setPeriod] = React.useState<PeriodValue>('week')

  const translateDate = useTranslateDate()

  const prevApiParams = React.useMemo(
    (): DashboardClientScoresApiParams => ({
      date: translateDate(startOfDay(getDateFromPeriod(period))).toISOString(),
    }),
    [period]
  )

  const { data: previous, isFirstLoading: isPreviousLoading } =
    useHttp<DashboardClientScoresData>(
      buildUrl`${getConfig()?.API}/api/dash/scores?${prevApiParams}`
    )

  const { data: current, isFirstLoading: isCurrentLoading } =
    useHttp<DashboardClientScoresData>(
      buildUrl`${getConfig()?.API}/api/dash/scores`
    )

  const isFirstLoading = isPreviousLoading || isCurrentLoading

  // Пока данных нет имитируем прошлые значения скора
  const chartData = React.useMemo(
    () => ({
      previous: previous || [],
      current: current || [],
    }),
    [previous, current]
  )

  return (
    <Portlet variant="card" className={className}>
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Client Score
          </Typography>
        ),
        toolbar: (
          <Period label="current vs" value={period} onChange={setPeriod} />
        ),
        body: isFirstLoading ? (
          <Stub className={cx('ClientScorePortletStub')} isActive={true} />
        ) : (
          <ScoreBars data={chartData} period="Last 7 day" />
        ),
      }}
    </Portlet>
  )
}

export default observer(ClientsBlock)

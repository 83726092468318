import React from 'react'

import { ReactComponent as ClientIcon } from '@clain/core/assets/user.svg'
import { Score } from '@clain/core/ui-kit'

import BaseSearchCard from './BaseSearchCard'

import { ClientDetails } from '../../../../../apiServices/Client/ClientService.types'

interface ClientSearchCardProps {
  client: ClientDetails
  selected: boolean
}

const ClientSearchCard: React.FC<ClientSearchCardProps> = ({
  client,
  selected,
}) => (
  <BaseSearchCard
    icon={<ClientIcon />}
    id={client.id}
    href={`/clients/${client.id}`}
    selected={selected}
  >
    {client.score ? <Score value={client.score} /> : null}
  </BaseSearchCard>
)

export default ClientSearchCard

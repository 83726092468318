import React from 'react'
import {
  AssetIcon,
  CopyCursorContainer,
  EllipsisTypography,
  Stack,
  Stub,
  Tooltip,
  TypographyNew,
} from '@clainio/web-platform'
import { formatMoney, FORMATS_WITHOUT_DECIMALS } from '@clain/core/utils/format'

import * as S from './TransactionInfoWidget.styles'
import {
  AmountTickerStyled,
  FoggyWrapperAmountStyled,
} from './TransactionInfoWidget.styles'
import { formatAmountValue, formatTokenValue } from './TransactionAmount.utils'
import { TransactionAmountProps } from './TransactionInfoWidget.types'

const AmountDisplay = ({ amount = '0' }: { amount: string }) => (
  <CopyCursorContainer
    value={formatAmountValue({
      value: amount,
      isShowCurrency: false,
      isShort: false,
    })}
  >
    <Tooltip
      content={formatAmountValue({
        value: amount,
        isShort: false,
        isShowCurrency: true,
      })}
    >
      <TypographyNew variant={'display100Accent'} color={'onBackgroundBase'}>
        {formatAmountValue({
          value: amount,
          isShort: true,
          isShowCurrency: true,
        })}
      </TypographyNew>
    </Tooltip>
  </CopyCursorContainer>
)

const TokenAmountDisplay = ({
  token,
  amount = '0',
}: {
  token: TransactionAmountProps['token']
  amount: string
}) => {
  const tokenAmountConfig = {
    formats: FORMATS_WITHOUT_DECIMALS,
    value: parseFloat(amount || '0'),
    currency: token?.blockchain?.toLowerCase() || 'eth',
  }
  return (
    <CopyCursorContainer
      value={formatMoney({
        ...tokenAmountConfig,
        precision: 64,
        code: '',
        minimumSignificantDigits: 1,
      })}
    >
      <Tooltip
        content={formatMoney({
          ...tokenAmountConfig,
          precision: 64,
          minimumSignificantDigits: 1,
          code: token.symbol,
        })}
      >
        <Stack gap={'md'} align="center">
          <EllipsisTypography
            variant={'body100NormalCode'}
            color={'onBackgroundBase'}
          >
            {formatTokenValue(tokenAmountConfig)}
          </EllipsisTypography>
          <AssetIcon
            icon={token?.icon}
            size={'xs'}
            id={token?.id}
            blockchain={token?.blockchain}
          />
          <FoggyWrapperAmountStyled $isActive={token?.symbol?.length > 6}>
            <AmountTickerStyled>
              <TypographyNew
                variant={'body100NormalCode'}
                color={'onBackgroundVariant1'}
              >
                {token?.symbol}
              </TypographyNew>
            </AmountTickerStyled>
          </FoggyWrapperAmountStyled>
        </Stack>
      </Tooltip>
    </CopyCursorContainer>
  )
}

export const TransactionAmount = ({
  amount,
  amountUSD,
  token,
  isLoading,
}: TransactionAmountProps) => {
  return (
    <Stack direction={'column'} gap={'xs'}>
      {isLoading ? (
        <Stub isActive type={'grey'} height={38.5} width={200} />
      ) : (
        <S.HiddenAmountBlock>
          <AmountDisplay amount={amountUSD} />
        </S.HiddenAmountBlock>
      )}

      {isLoading ? (
        <Stub isActive type={'grey'} height={20} width={110} />
      ) : (
        token && (
          <S.HiddenAmountBlock>
            <TokenAmountDisplay token={token} amount={amount} />
          </S.HiddenAmountBlock>
        )
      )}
    </Stack>
  )
}

import React from 'react'

import useHttp from '@clain/core/useHttp'
import { AssetsData } from '../../../types/Settings'

import baseAssets from './baseAssets'
import { AssetsSuggestCore } from '@clain/core/ui-kit'
import { CoinType } from '../../../types/coin'
import { getConfig } from '@clain/core/useConfig'

interface AssetProps {
  value?: string
  onChange?: (value: string | number | null, currency?: CoinType) => void
}

const AssetSuggest = ({ value, onChange }: AssetProps) => {
  const { data: source } = useHttp<AssetsData>(
    `${getConfig()?.API}/api/settings/assets`
  )

  const data = React.useMemo(
    () =>
      (source ? baseAssets.concat(source) : baseAssets).map((asset) => ({
        ...asset,
        blockchain: asset?.blockchain.toLowerCase() as CoinType,
      })),
    [source]
  )

  return (
    <AssetsSuggestCore
      availableAssets={data}
      value={value}
      onChange={onChange}
    />
  )
}

export default AssetSuggest

import React from 'react'

import { CommentCard } from '@clain/core/ui-kit'
import { EditorView } from '@clain/core/Editor'

import {
  FeedCommentActivity,
  FeedEvidenseActivity,
  FEED_ACTIVITY_TYPE,
} from '../../../../types/Case'
import { LightBox } from '@clain/core/ui-kit'
import { FilePreviewStub } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../hooks'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import { observer } from 'mobx-react-lite'
interface CaseCommentCardProps {
  activity:
    | SnakeToCamelCaseObject<FeedCommentActivity>
    | SnakeToCamelCaseObject<FeedEvidenseActivity>
  className?: string
}

const CaseCommentCard: React.FC<CaseCommentCardProps> = ({
  className,
  activity,
}) => {
  const formatDate = useFormatDate()

  const text =
    activity.type === FEED_ACTIVITY_TYPE.COMMENT_WITHOUT_ATTACH
      ? activity.activity.text
      : activity.activity.comment
  const attachments =
    activity.type === FEED_ACTIVITY_TYPE.COMMENT_WITHOUT_ATTACH
      ? undefined
      : activity.activity.files

  const [isActive, setIsActive] = React.useState(false)
  const [activeIndex, setActiveIndex] = React.useState(0)

  const lightBoxItems = attachments?.map((attachment) => {
    const extArr = attachment.name.split('.')
    const ext = extArr.length > 1 ? extArr[extArr.length - 1] : '?'

    return {
      title: attachment.name,
      content: /^image\/[png|jpg|jpeg]/gi.test(attachment.contentType) ? (
        <img src={attachment.file} alt={'Attached file'} />
      ) : (
        <FilePreviewStub ext={ext} />
      ),
      downloadLink: attachment.file,
    }
  })

  const handleClickOnAttachment = (index: number) => {
    setIsActive(true)
    setActiveIndex(index)
  }

  return (
    <React.Fragment>
      <CommentCard
        className={className}
        userName={activity.user.name}
        userAvatar={activity.user.avatar}
        dateText={formatDate(new Date(activity.insertedAt), 'time')}
        //@ts-expect-error TODO update CommentCard props in ui-kit
        attachments={normalizeCamelToSnakeCase(attachments)}
        handleClickOnAttachment={handleClickOnAttachment}
      >
        <EditorView value={text} />
      </CommentCard>
      <LightBox
        isActive={isActive}
        setIsActive={setIsActive}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        items={lightBoxItems}
      />
    </React.Fragment>
  )
}

export default observer(CaseCommentCard)

//TODO use notify from ui-kit
import { toast } from 'react-toastify'
import {
  IncidentMentionNotification,
  CaseMentionNotification,
  NewIncidentNotification,
  NewIncidentsNotification,
  NewCaseNotification,
  CaseReassignNotification,
  ClientBlockedNotification,
  CaseOverdueNotification,
} from '../components/generalNotificationCards'
import { NotificationService, Notification } from '../apiServices'
import { WebSocketWrapper } from '../utils/WebSocketWrapper'
import { SettingsController } from './SettingsController'

interface NotificationsControllerProps {
  webSocketState: typeof WebSocketWrapper
  settingsController: SettingsController
}

export class NotificationsController {
  private notificationService: NotificationService

  constructor({
    webSocketState,
    settingsController,
  }: NotificationsControllerProps) {
    const id = settingsController.getUserId()

    this.notificationService = new NotificationService({ webSocketState, id })
  }

  public init() {
    this.notificationService.subscribe(this.handleNewNotification)
  }

  private handleNewNotification = (notification: Notification) => {
    toast(({ closeToast }) => {
      switch (notification.type) {
        case 'incident_mention':
          return (
            <IncidentMentionNotification
              closeToast={closeToast}
              notification={notification}
            />
          )
        case 'case_mention':
          return (
            <CaseMentionNotification
              closeToast={closeToast}
              notification={notification}
            />
          )
        case 'new_incident':
          return (
            <NewIncidentNotification
              closeToast={closeToast}
              notification={notification}
            />
          )
        case 'new_incidents':
          return (
            <NewIncidentsNotification
              closeToast={closeToast}
              notification={notification}
            />
          )
        case 'new_case':
          return (
            <NewCaseNotification
              closeToast={closeToast}
              notification={notification}
            />
          )
        case 'case_reassign':
          return (
            <CaseReassignNotification
              closeToast={closeToast}
              notification={notification}
            />
          )
        case 'client_blocked':
          return (
            <ClientBlockedNotification
              closeToast={closeToast}
              notification={notification}
            />
          )
        case 'case_overdue':
          return (
            <CaseOverdueNotification
              closeToast={closeToast}
              notification={notification}
            />
          )
      }
    })
  }
}

import React from 'react'
import castArray from 'lodash/castArray'

import { formatPercent } from '@clain/core/utils/format'
import { roundToPrecision } from '@clain/core/utils/math'
import Chart from '@clain/core/Chart2'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'
import { getScoreColor } from '@clain/core/utils/getScoreColor'

type Score = number

interface ScoreData {
  current: Array<[Score, number]>
  previous: Array<[Score, number]>
}

const Tooltip = ({
  points: p,
  period = 'Previous period',
  previousTotal,
  currentTotal,
}) => {
  const points = p ? castArray(p) : []

  const previous = points.find((p) => p.seriesName === 'previous')
  const current = points.find((p) => p.seriesName === 'current')

  if (!current) {
    return null
  }

  const score = Number(current.axisValue)

  const showPrevAndDiff = Boolean(previous)

  const previousPercent = previous
    ? (previous.value * 100) / previousTotal
    : null
  const currentPercent = (current.value * 100) / currentTotal

  const diffPercent = previousPercent
    ? roundToPrecision(currentPercent, 1) - roundToPrecision(previousPercent, 1)
    : null
  const diffSign = previousPercent && diffPercent > 0 ? '+' : ''

  return (
    <Container gap={1}>
      <ColDeprecated>
        <RowDeprecated gap={0.5}>
          <Score value={score} />
          {showPrevAndDiff ? (
            <>
              <Typography color="black" variant="heading5">
                {diffSign}
                {formatPercent(diffPercent)}
              </Typography>
              <Typography color="grey3" variant="subheading1">
                {diffPercent > 0 ? 'growth' : 'decrease'}
              </Typography>
            </>
          ) : null}
        </RowDeprecated>
        <ColDeprecated gap={0}>
          <Typography color="black">
            <Typography variant="body2">{current.value}</Typography>{' '}
            <Typography variant="body1" color="grey1">
              ({formatPercent(currentPercent)}) Today
            </Typography>
          </Typography>
          {showPrevAndDiff ? (
            <Typography color="black">
              <Typography variant="body2">{previous.value}</Typography>{' '}
              <Typography variant="body1" color="grey1">
                ({formatPercent(previousPercent)}) {period}
              </Typography>
            </Typography>
          ) : null}
        </ColDeprecated>
      </ColDeprecated>
    </Container>
  )
}

interface ScoreBarsOptions {
  data: ScoreData
  period: string
}

function fillWithEmptyScores(scoreSet: [number, number][]) {
  const filledScoreSet = scoreSet
  if (scoreSet.length < 10) {
    const scoreMap = new Map(filledScoreSet)
    for (let i = 1; i <= 10; i++) {
      if (!(scoreMap.get(i) >= 0)) {
        filledScoreSet.push([i, undefined])
      }
    }
    filledScoreSet
  }
  return filledScoreSet
}

function getOptions({ data, period }: ScoreBarsOptions) {
  // sorted by score from 1 to 10
  const previous = fillWithEmptyScores(data.previous).sort(([a], [b]) => a - b)
  const current = fillWithEmptyScores(data.current).sort(([a], [b]) => a - b)
  const previousTotal = data.previous.reduce(
    (result, [, count]) => result + count,
    0
  )
  const currentTotal = data.current.reduce(
    (result, [, count]) => result + count,
    0
  )

  const highRiskTotal = data.current
    .filter(([score]) => score <= 4)
    .reduce((result, [, count]) => result + count, 0)

  const highRiskPercent = highRiskTotal
    ? Math.round((highRiskTotal * 100) / currentTotal)
    : 0

  const options = {
    xAxis: {
      type: 'category',
      data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      axisLabel: {
        fontSize: 14,
        fontFamily: 'Inter, sans-serif',
      },
      axisPointer: {
        type: 'none',
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#8D9EC1',
        },
      },
      z: 0,
    },
    yAxis: {
      type: 'value',
      show: false,
    },
    grid: {
      top: '15%',
      left: '3%',
      right: '4%',
      bottom: '10%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      padding: 0,
      showDelay: 0.001,
      hideDelay: 0,
      borderRadius: 8,
      transitionDuration: 0,
      formatter: createTooltipFormatter(
        (points) => ({ points, seriesData: data }),
        Tooltip,
        { period, previousTotal, currentTotal }
      ),
      backgroundColor: 'rgba(255, 255, 255, 1)',
      extraCssText:
        'box-shadow: 0px 4px 40px rgba(0, 17, 158, 0.25); z-index: 0; width: 220px;',
      appendToBody: true,
    },
    series: [
      {
        z: 1,
        name: 'previous',
        data: previous.map(([score, count]) => ({
          value: count,
          itemStyle: {
            color: '#E6EBF5',
          },
        })),
        type: 'bar',
        barGap: '-66%',
        barMinWidth: 12,
        barMaxWidth: 66,
        barCategoryGap: '10%',
        itemStyle: {
          borderRadius: 4,
        },
        emphasis: {
          itemStyle: {
            color: '#E6EBF5',
          },
        },
      },
      {
        z: 2,
        name: 'current',
        data: current.map(([score, count]) => ({
          value: count,
          itemStyle: {
            color: getScoreColor(score),
          },
        })),
        type: 'bar',
        barMinWidth: 12,
        barMaxWidth: 66,
        barCategoryGap: '10%',
        itemStyle: {
          borderRadius: 4,
        },
        label: {
          show: true,
          position: 'top',
          fontSize: 14,
          fontFamily: 'Inter, sans-serif',
        },
        markArea: {
          itemStyle: {
            color: '#FEF6F8',
          },
          data: [
            [
              {
                x: '5%',
                name: `High risk\n${highRiskPercent}%`,
              },
              {
                x: '43.5%',
              },
            ],
          ],
          silent: true,
          label: {
            show: true,
            position: 'insideTopLeft',
            padding: 5,
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 20,
            fontFamily: 'Inter, sans-serif',
            color: '#D4114A',
          },
        },
      },
    ],
  }

  return options
}

interface ScoreBarsProps extends ScoreBarsOptions {
  className?: string
  loading?: boolean
}

const ScoreBars = React.memo(
  ({ className, loading, data, period }: ScoreBarsProps) => {
    return (
      <Chart
        stub={!data}
        loading={loading}
        className={className}
        option={getOptions({ data, period })}
        style={{
          height: 200,
        }}
      />
    )
  }
)
ScoreBars.displayName = 'ScoreBars'
export default ScoreBars

import React from 'react'
import classnames from 'classnames/bind'

import Portlet from '@clain/core/Portlet'
import { RowDeprecated } from '@clain/core/ui-kit'
import { AddFilter } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { CalendarInput } from '@clain/core/ui-kit'
import Form, { Field } from '@clain/core/Form'

import { FiltersProps, FIELDS, FieldTypes, FieldOptions } from './types'
import { ScoreInput } from '@clain/core/ui-kit'
import AmountInput from '../../AmountInput'
import SearchInput from './SearchInput'
import AssetAmountInput from '../../Assets/AssetAmountInput'

import styles from './index.scss'

const cx = classnames.bind(styles)

const AmountUsd = (props) => <AmountInput label="USD Amount:" {...props} />

const filterType: FieldTypes = {
  assetAmount: AssetAmountInput,
  amountUsd: AmountUsd,
  cpScore: ScoreInput,
}

const filters: FieldOptions = [
  {
    label: 'Transaction amount',
    value: FIELDS.amountUsd,
  },
  {
    label: 'Asset / Amount',
    value: FIELDS.assetAmount,
  },
  {
    label: 'Counterparty score',
    value: FIELDS.cpScore,
  },
]

const CaseFilters = ({
  defaultFiltersState,
  initialValues,
  onChange,
}: FiltersProps) => {
  const formRef = React.useRef(null)

  React.useEffect(() => {
    const unsubscribe = formRef.current?.form.subscribe(
      ({ values: { selected } }) => {
        if (selected) {
          filters
            .filter(({ value }) => !selected.includes(value))
            .forEach(({ value }) => {
              formRef.current?.form.change(value, defaultFiltersState[value])
            })
        }
      },
      { values: true }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Portlet>
      {{
        content: (
          <Form initialValues={initialValues} onChange={onChange} ref={formRef}>
            {({ form }) => {
              const formState = form.getState()
              const selected = formState.values.selected || []

              return (
                <MagicGrid>
                  <RowDeprecated>
                    <Field
                      as={AddFilter}
                      options={filters}
                      name={FIELDS.selected}
                    />
                    <Field
                      clearable
                      as={CalendarInput}
                      variant="outline"
                      name={FIELDS.created}
                      label="Created:"
                      placeholder="Select date"
                    />
                    <Field as={SearchInput} name={FIELDS.search} />
                  </RowDeprecated>
                  {selected.length > 0 && (
                    <RowDeprecated wrap className={cx('Filters')}>
                      {selected.map((field) => (
                        <Field
                          key={field}
                          name={field}
                          as={filterType[field]}
                        />
                      ))}
                    </RowDeprecated>
                  )}
                </MagicGrid>
              )
            }}
          </Form>
        ),
      }}
    </Portlet>
  )
}

export default CaseFilters
export type { FiltersState } from './types'

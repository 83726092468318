import React from 'react'
import classnames from 'classnames/bind'

import buildUrl from '@clain/core/utils/buildUrl'
import { formatDurationLifetime } from '@clain/core/utils/formatDuration'
import Portlet from '@clain/core/Portlet'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import AutoCounter from '../AutoCounter'
import useHttp from '@clain/core/useHttp'

import {
  DashboardAverageTimeToProcessCaseData,
  DashboardAverageTimeToProcessCaseApiParams,
} from '../../../types/Dashboard'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'

const cx = classnames.bind(styles)

const formatTime = (value: number) => {
  return formatDurationLifetime(value * 1000, {
    round: false,
    largest: 1,
    maxDecimalPoints: 1,
  })
}
interface AverageTimeToProcessCaseBlockProps {
  className?: string
  assignee?: number
}

const AverageTimeToProcessCaseBlock: React.FC<
  AverageTimeToProcessCaseBlockProps
> = ({ className, assignee }) => {
  const apiParams = React.useMemo(
    (): DashboardAverageTimeToProcessCaseApiParams => ({
      assignee_id: assignee,
    }),
    [assignee]
  )

  const { data, isFirstLoading } =
    useHttp<DashboardAverageTimeToProcessCaseData>(
      buildUrl`${getConfig()?.API}/api/dash/avg_time_to_close?${apiParams}`
    )

  return (
    <Portlet variant="card" className={className}>
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Case average processing time
          </Typography>
        ),
        body: isFirstLoading ? (
          <Stub
            className={cx('AverageTimeToProcessCasePortletStub')}
            isActive={true}
          />
        ) : (
          <Container className={cx('CountersContainer')} gap={2}>
            <ColDeprecated gap={1.5} className={cx('Counter')}>
              <Typography variant="heading4" color="magenta">
                High
              </Typography>
              <ColDeprecated className={cx('CounterValue')}>
                <AutoCounter
                  value={data?.HIGH || 'N/A'}
                  format={formatTime}
                  minFontSize={18}
                  maxFontSize={28}
                />
              </ColDeprecated>
            </ColDeprecated>
            <ColDeprecated gap={1.5} className={cx('Counter')}>
              <Typography variant="heading4" color="yellow">
                Medium
              </Typography>
              <ColDeprecated className={cx('CounterValue')}>
                <AutoCounter
                  value={data?.MEDIUM || 'N/A'}
                  format={formatTime}
                  minFontSize={18}
                  maxFontSize={28}
                />
              </ColDeprecated>
            </ColDeprecated>
            <ColDeprecated gap={1.5} className={cx('Counter')}>
              <Typography variant="heading4" color="blue">
                Low
              </Typography>
              <ColDeprecated className={cx('CounterValue')}>
                <AutoCounter
                  value={data?.LOW || 'N/A'}
                  format={formatTime}
                  minFontSize={18}
                  maxFontSize={28}
                />
              </ColDeprecated>
            </ColDeprecated>
          </Container>
        ),
      }}
    </Portlet>
  )
}

export default AverageTimeToProcessCaseBlock

import React from 'react'

import { MagicGrid } from '@clain/core/ui-kit'

import RelatedIncident from '../RelatedIncidentCard'
import { IncidentDataResponseNormalized } from '@compliance/apiServices'

interface ClientIncidentsListProps {
  incidents?: IncidentDataResponseNormalized[]
}

const ClientIncidentsList: React.FC<ClientIncidentsListProps> = ({
  incidents,
}) => (
  <MagicGrid>
    {incidents?.map(
      ({
        rule: {
          usdAmount: riskAmount,
          scoreAmount: score,
          percentage,
          type,
          severity,
          name,
        },
        transaction: { amountUsd: amount },
        cases,
        incident: { id, insertedAt, status },
      }) => {
        return (
          <RelatedIncident
            key={id}
            type={type}
            id={id}
            severity={severity}
            name={name}
            amount={amount}
            riskAmount={riskAmount}
            percentage={percentage}
            score={score}
            cases={cases}
            status={status}
            createdAt={insertedAt}
          />
        )
      }
    )}
  </MagicGrid>
)

export default ClientIncidentsList

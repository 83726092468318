import React from 'react'
import { Rule } from '../../types/Rule'
import { TagSize, TagVariant } from '@clainio/web-platform'
import * as S from './SeverityTag.styles'

export interface SeverityTag {
  value: Lowercase<Rule['severity']> | Rule['severity']
  size?: TagSize
  children?: React.ReactNode
  width?: 'short' | 'auto' | 'full'
  customWidth?: number
}

const tagVariantMap: Record<Lowercase<Rule['severity']>, TagVariant> = {
  high: 'critical',
  medium: 'warning',
  low: 'accent2',
}

const widthMap: Record<SeverityTag['width'], string> = {
  short: '82px',
  auto: 'auto',
  full: '100%',
}

export const SeverityTag = React.forwardRef<HTMLSpanElement, SeverityTag>(
  (
    { value = 'high', size = 'md', width = 'short', customWidth, children },
    ref
  ) => (
    <S.SeverityTag
      $width={widthMap[width]}
      $minWidth={customWidth}
      ref={ref}
      variant={tagVariantMap[value.toLowerCase()]}
      size={size}
      type="solid"
    >
      {children}
    </S.SeverityTag>
  )
)
SeverityTag.displayName = 'SeverityTag'
export default SeverityTag

import type { CalendarInputValue } from '@clain/core/ui-kit'
import type { ScoreRange } from '@clain/core/types/ScoreRange'

export interface Volume {
  from?: string
  to?: string
}

export enum FIELDS {
  selected = 'selected',
  created = 'created',
  clientId = 'clientId',
  score = 'score',
  transfers = 'transfers',
  incoming = 'incoming',
  outgoing = 'outgoing',
}

interface StaticFiltersState {
  [FIELDS.selected]: Array<string>
  [FIELDS.created]: CalendarInputValue
  [FIELDS.clientId]: string | null
}

interface SelectableFiltersState {
  [FIELDS.score]: ScoreRange | null
  [FIELDS.transfers]: Volume | null
  [FIELDS.incoming]: Volume | null
  [FIELDS.outgoing]: Volume | null
}

export type FiltersState = StaticFiltersState & SelectableFiltersState

export interface FiltersProps {
  defaultFiltersState: FiltersState
  initialValues: FiltersState
  onChange: (value: FiltersState) => void
}

export interface FieldProps<T> {
  value: T | null
  onChange: (value: T | null) => void
}

type FieldType = keyof SelectableFiltersState

export type FieldTypes = {
  [k in FieldType]: React.ComponentType<FieldProps<any>>
}

type FieldOption = {
  label: string
  value: FieldType
}

export type FieldOptions = Array<FieldOption>

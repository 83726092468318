import { useCountUp } from 'use-count-up'

const useCount = (value = 0, ms = 1000) => {
  const { value: count } = useCountUp({
    isCounting: true,
    end: value,
    duration: ms / 1000,
  })

  return Number(count)
}

export default useCount

import { CanvasForm, CanvasSpace, Group } from 'pts'
import ViewController from './ViewController'
import { FunnelOptions } from '../types'
import StyleConroller from '../../core/controllers/StyleController'
import { ICoreController } from '../../core/types'
import DevUtilsController from '../../core/controllers/DevUtilsController'

class CoreController implements ICoreController {
  public space: CanvasSpace
  public form: CanvasForm
  public options?: FunnelOptions

  public styleController = new StyleConroller(this)
  public devUtilsController = new DevUtilsController(this)

  public viewController = new ViewController(this)

  public init(space: CanvasSpace, form: CanvasForm, options: FunnelOptions) {
    this.space = space
    this.form = form
    this.options = options

    this.styleController.init()
    this.devUtilsController.init()

    this.viewController.init()
  }

  public render(time: number, ftime: number) {
    this.viewController.render()

    this.devUtilsController.render(time, ftime)
  }

  public handleActions(type: string, px: number, py: number, event: Event) {
    this.viewController.handleActions(type, px, py, event)
  }

  public handleResize(size: Group, event: Event) {
    this.viewController.handleResize(size, event)
  }
}

export default CoreController

import React from 'react'

import Portlet from '@clain/core/Portlet'
import { RowDeprecated } from '@clain/core/ui-kit'
import { AddFilter } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { CalendarInput } from '@clain/core/ui-kit'
import Form, { Field } from '@clain/core/Form'

import AssigneeInput from '../../AssigneeInput'
import SeverityInput from './SeverityInput'
import CaseSearchInput from './SearchInput'
import ClosureStatusInput from './ClosureStatusInput'
import ClientIdInput from './ClientIdInput'

import { FiltersProps, FIELDS, FieldTypes, FieldOptions } from './types'

const filterType: FieldTypes = {
  severity: SeverityInput,
  closureStatus: ClosureStatusInput,
  clientId: ClientIdInput,
}

const casesFitlers: FieldOptions = [
  {
    label: 'Severity',
    value: FIELDS.severity,
  },
  {
    label: 'Resolved',
    value: FIELDS.closureStatus,
  },
  {
    label: 'Client ID',
    value: FIELDS.clientId,
  },
]

const CaseFilters = ({
  defaultFiltersState,
  initialValues,
  disableAssignee,
  disableAssigneeText,
  onChange,
}: FiltersProps & {
  disableAssignee?: boolean
  disableAssigneeText?: string
}) => {
  const formRef = React.useRef(null)

  React.useEffect(() => {
    const unsubscribe = formRef.current?.form.subscribe(
      ({ values: { selected } }) => {
        if (selected) {
          casesFitlers
            .filter(({ value }) => !selected.includes(value))
            .forEach(({ value }) => {
              formRef.current?.form.change(value, defaultFiltersState[value])
            })
        }
      },
      { values: true }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Portlet>
      {{
        content: (
          <Form initialValues={initialValues} onChange={onChange} ref={formRef}>
            {({ form }) => {
              const formState = form.getState()
              const selected = formState.values.selected || []
              return (
                <MagicGrid>
                  <RowDeprecated>
                    <Field
                      as={AddFilter}
                      name="selected"
                      options={casesFitlers}
                    />
                    <Field
                      clearable
                      as={CalendarInput}
                      disableDaysAfterToday
                      variant="outline"
                      name="created"
                      label="Created:"
                      placeholder="Select date"
                    />
                    <Field as={CaseSearchInput} name="tag" />
                    <Field
                      as={AssigneeInput}
                      name="assignee"
                      label="Filter by assignee"
                      disabled={disableAssignee}
                      tooltip={disableAssigneeText}
                    />
                  </RowDeprecated>
                  {selected.length > 0 && (
                    <RowDeprecated wrap>
                      {selected.map((field) => (
                        <Field
                          as={filterType[field]}
                          key={field}
                          name={field}
                        />
                      ))}
                    </RowDeprecated>
                  )}
                </MagicGrid>
              )
            }}
          </Form>
        ),
      }}
    </Portlet>
  )
}

export default CaseFilters
export type { FiltersState } from './types'

import React from 'react'

import { CheckboxMenu } from '@clain/core/ui-kit'
import { InputDropdown } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'

import { FieldProps } from './types'

const options = [
  {
    label: <Typography color="magenta">High</Typography>,
    value: 'high' as const,
  },
  {
    label: <Typography color="yellow">Medium</Typography>,
    value: 'medium' as const,
  },
  {
    label: <Typography color="blue">Low</Typography>,
    value: 'low' as const,
  },
]

const Severity = ({ value, onChange }: FieldProps<Array<string>>) => {
  const handleInputClear = () => {
    onChange([])
  }

  const handleChange = (selected) => {
    onChange(selected.length ? selected : [])
  }

  const printValue =
    value.length > 0 ? value.map(capitalizeFirstLetter).join(' / ') : ''

  return (
    <InputDropdown
      clearable
      sameWidth
      padded={false}
      variant="outline"
      startAddon={{ label: 'Severity:' }}
      value={printValue}
      placeholder="Any"
      onClear={handleInputClear}
    >
      <Container gap={[1, 0]}>
        <CheckboxMenu options={options} value={value} onChange={handleChange} />
      </Container>
    </InputDropdown>
  )
}

export default Severity

import React from 'react'
import classnames from 'classnames/bind'
import { CSSTransition } from 'react-transition-group'

import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import useCountUp from '@clain/core/useCountUp'
import useMount from '@clain/core/useMount'
import cssVars from '@clain/core/styles/cssVars'
import { Container } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import SeverityDot from '../SeverityDot'

import styles from './index.scss'
import { formatPercent } from '../../../../core/utils/format'
import { PopoverBaseContainer } from '@clainio/web-platform'

const cx = classnames.bind(styles)

const ANIMATION_DURATION = 300 // ms

const transitions = {
  enter: cx('animate'),
}

const SeverityPanelTooltip = ({
  label,
  total = 0,
  high = 0,
  medium = 0,
  low = 0,
}) => {
  return (
    <Container>
      <ColDeprecated className={cx('SeverityPanelTooltip')}>
        <Typography variant="subheading1" color="grey1">
          <Typography variant="heading5" color="black">
            {total}
          </Typography>{' '}
          {label}
        </Typography>
        <ColDeprecated gap={0.5}>
          <RowDeprecated>
            <SeverityDot value="high" />
            <Typography variant="body1" color="grey1">
              <Typography variant="body2" color="black">
                {high}
              </Typography>{' '}
              High severity (
              {formatPercent(total ? (high * 100) / total : 0, 0)})
            </Typography>
          </RowDeprecated>
          <RowDeprecated>
            <SeverityDot value="medium" />
            <Typography variant="body1" color="grey1">
              <Typography variant="body2" color="black">
                {medium}
              </Typography>{' '}
              Medium severity (
              {formatPercent(total ? (medium * 100) / total : 0, 0)})
            </Typography>
          </RowDeprecated>
          <RowDeprecated>
            <SeverityDot value="low" />
            <Typography variant="body1" color="grey1">
              <Typography variant="body2" color="black">
                {low}
              </Typography>{' '}
              Low severity ({formatPercent(total ? (low * 100) / total : 0, 0)})
            </Typography>
          </RowDeprecated>
        </ColDeprecated>
      </ColDeprecated>
    </Container>
  )
}

const SeverityPanel = ({
  high = 0,
  medium = 0,
  low = 0,
  label,
  percent = 1,
}) => {
  const mounted = useMount()

  const total = high + medium + low

  const style = cssVars(
    {
      barWidth: percent * 100,
      lowPoint: (low / total) * 100,
      mediumPoint: ((low + medium) / total) * 100,
      animationDuration: `${ANIMATION_DURATION}ms`,
    },
    '%'
  )

  const totalCounting = useCountUp(total, ANIMATION_DURATION * 3)

  const tooltipContent = (
    <SeverityPanelTooltip
      label={label}
      total={total}
      high={high}
      medium={medium}
      low={low}
    />
  )

  return (
    <CSSTransition
      in={mounted}
      timeout={ANIMATION_DURATION}
      classNames={transitions}
    >
      <PopoverBaseContainer
        trigger="hover"
        className={cx('Dropdown')}
        content={tooltipContent}
        placement="top-start"
        anchor={
          <div className={cx('SeverityPanel', { empty: !total })} style={style}>
            <div className={cx('BarContainer')}>
              <div className={cx('Bar')} />
            </div>
            <ColDeprecated className={cx('Label')} align="right" gap={0.25}>
              <Typography variant="heading2">{totalCounting}</Typography>
              <Typography variant="body1">{label}</Typography>
            </ColDeprecated>
          </div>
        }
      />
    </CSSTransition>
  )
}

export default SeverityPanel

import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'
import pluralize from '@clain/core/pluralize'

import { Typography } from '@clain/core/ui-kit'
import { InfoCard, InfoCardStub } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import useConfig from '@clain/core/useConfig'
import { RowDeprecated } from '@clain/core/ui-kit'
import { TagDeprecated } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { CopyIcon } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { formatMoney, formatNumber } from '@clain/core/utils/format'
import { useFormatDate, useTimezone } from '../../hooks'
import { ActivityPeriod } from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import { Counterparty } from '../../types/Counterparty'

import styles from './index.scss'
import { CoinType } from '../../types/coin'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

const cx = classnames.bind(styles)

interface CounterpartyInfoCardProps {
  className?: string
  counterparty?: SnakeToCamelCaseObject<Counterparty['data']>
  currency: CoinType
  loading?: boolean
}

const CounterpartyInfoCard: React.FC<CounterpartyInfoCardProps> = ({
  loading,
  counterparty,
  className,
  currency,
}) => {
  const config = useConfig()
  const timezone = useTimezone()
  const formatDate = useFormatDate()

  if (loading) {
    return (
      <Container gap={1.25} className={cx('CounterpartyInnerCard')}>
        <InfoCardStub rows={7} />
      </Container>
    )
  }

  if (!counterparty) return null

  const activityPeriod = [
    fromUnixTime(counterparty.firstSeen),
    fromUnixTime(counterparty.lastSeen),
  ] as [Date, Date]
  const addressCount = counterparty?.size ?? 0

  return (
    <div className={cx('CounterpartyInfoCard', className)}>
      <Container gap={1.25} className={cx('CounterpartyInnerCard')}>
        <MagicGrid gap={2}>
          <div className={cx('CounterpartyInfoCardHeader')}>
            <RowDeprecated>
              <Link
                to={`${config.PLATFORM_URL}/${currency}/cluster/${counterparty.clusterId}`}
              >
                <Typography variant="heading3">
                  {counterparty.entity?.name || counterparty.clusterId}
                </Typography>
              </Link>
              <RowDeprecated gap={0.25}>
                <Score
                  className={cx('CounterpartyInfoCardScore')}
                  value={counterparty.score}
                />
                {counterparty.entity?.category && (
                  <TagDeprecated>{counterparty.entity?.category}</TagDeprecated>
                )}
              </RowDeprecated>
            </RowDeprecated>
          </div>
          <MagicGrid>
            <ActivityPeriod
              timeZone={timezone}
              today={formatDate(new Date(), 'date')}
              activityPeriod={activityPeriod}
              currency={currency}
            />
            <InfoCard
              details={[
                counterparty?.size && {
                  label: 'Size',
                  value: (
                    <Typography variant="body1" color="grey1">
                      {formatNumber(addressCount, 0)}{' '}
                      {pluralize('address', addressCount)}
                    </Typography>
                  ),
                },
              ].filter(Boolean)}
            />
          </MagicGrid>
        </MagicGrid>
      </Container>
      <Container gap={1.25} className={cx('CounterpartyInnerCard', 'right')}>
        <InfoCard
          details={[
            {
              label: (
                <>
                  Transfers
                  <Typography variant="caption1">
                    (
                    {formatNumber(
                      (counterparty.trxCount.in ?? 0) +
                        (counterparty.trxCount.out ?? 0),
                      0
                    )}
                    )
                  </Typography>
                </>
              ),
              value: (
                <RowDeprecated gap={0.5}>
                  <Typography>
                    {formatMoney({
                      value:
                        (counterparty.balanceUsd.totalIn ?? 0) +
                        (counterparty.balanceUsd.totalOut ?? 0),
                      currency: 'usd',
                      precision: 0,
                      code: '',
                    })}
                  </Typography>
                  <CopyIcon
                    value={
                      (counterparty.balanceUsd.totalIn ?? 0) +
                      (counterparty.balanceUsd.totalOut ?? 0)
                    }
                  />
                </RowDeprecated>
              ),
            },
            {
              label: 'Total received',
              value: (
                <RowDeprecated gap={0.5}>
                  <Typography>
                    {formatMoney({
                      value: counterparty.balanceUsd.totalIn ?? 0,
                      currency: 'usd',
                      precision: 0,
                      code: '',
                    })}
                  </Typography>
                  <CopyIcon value={counterparty.balanceUsd.totalIn ?? 0} />
                </RowDeprecated>
              ),
            },
            {
              label: 'Total sent',
              value: (
                <RowDeprecated gap={0.5}>
                  <Typography>
                    {formatMoney({
                      value: counterparty.balanceUsd.totalOut ?? 0,
                      currency: 'usd',
                      precision: 0,
                      code: '',
                    })}
                  </Typography>
                  <CopyIcon value={counterparty.balanceUsd.totalOut ?? 0} />
                </RowDeprecated>
              ),
            },
            {
              label: 'Balance',
              value: (
                <RowDeprecated gap={0.5}>
                  <Typography>
                    {formatMoney({
                      value: counterparty.balanceUsd.balance ?? 0,
                      currency: 'usd',
                      precision: 0,
                      code: '',
                    })}
                  </Typography>
                  <CopyIcon value={counterparty.balanceUsd.balance ?? 0} />
                </RowDeprecated>
              ),
            },
          ]}
        />
      </Container>
    </div>
  )
}

export default observer(CounterpartyInfoCard)

import React from 'react'
import classnames from 'classnames/bind'

import { Notification } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'

import { IncidentMentionNotification as N } from '../../apiServices'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface IncidentMentionNotificationProps {
  notification: N
  closeToast: () => void
}

export const IncidentMentionNotification: React.FC<
  IncidentMentionNotificationProps
> = ({ notification: { id, user }, closeToast }) => (
  <Notification.Body closeToast={closeToast} type="default">
    <Typography variant="body1" color="grey1">
      <Link to="/incidents/:id" className={cx('Bold')} params={{ id }}>
        Mention:
      </Link>
      <Typography variant="body3" color="grey1">
        {' '}
        {user}{' '}
      </Typography>
      mentioned you in note for the incident{' '}
      <Link to="/incidents/:id" params={{ id }}>
        Incident #{id}
      </Link>
    </Typography>
  </Notification.Body>
)

import React from 'react'
import * as S from '../../Layout/IncidentPageLayout.styles'
import { AIChatWidget } from '../../Widgets/AIChatWidget'
import { observer } from 'mobx-react-lite'
import { incidentViewModelFacade } from '@compliance/di/containers/IncidentViewModelFacade.container'
import { toJS } from 'mobx'
import { useSettings } from '@compliance/hooks'

export const IncidentSidebar = observer(() => {
  const { user } = useSettings()

  const {
    sendAiChatMessage,
    useMessageInReport,
    chatData,
    isChatDisabled,
    isSummaryReportCantBeModifiedInChat,
    isSummaryReportToProcess,
    isSummaryReportProcessing,
    retrySendAiChatMessage,
  } = incidentViewModelFacade
  return (
    <S.Sidebar>
      <AIChatWidget
        retrySendAiChatMessage={retrySendAiChatMessage}
        error={chatData.error}
        userId={user?.id}
        chatMessagesUsedInSummary={toJS(chatData.chatMessagesUsedInSummary)}
        onSubmitMessage={sendAiChatMessage}
        onUseMessageInReport={useMessageInReport}
        chatMessages={toJS(chatData.messages)}
        chatMessage={toJS(chatData.aiResponseMessage)}
        userMessage={toJS(chatData.userMessage)}
        chatStatus={toJS(chatData.status)}
        isChatDisabled={toJS(isChatDisabled)}
        showToProcessInfoBlock={toJS(isSummaryReportToProcess)}
        isSummaryReportProcessing={toJS(isSummaryReportProcessing)}
        isSummaryReportCantBeModified={toJS(
          isSummaryReportCantBeModifiedInChat
        )}
        showUseInReportButton={false}
      />
    </S.Sidebar>
  )
})

import React from 'react'

import { TextField } from '@clain/core/ui-kit'

import { FieldProps } from './types'

const SearchInput = ({ value, onChange }: FieldProps<string>) => {
  const handleChange = (v) => {
    onChange?.(v)
  }

  const handleClear = () => {
    onChange?.('')
  }

  return (
    <TextField
      fullWidth
      clearable
      value={value}
      autoComplete="off"
      variant="outline"
      placeholder="Search by Transaction hash"
      startAddon={{ iconVariant: 'Search' }}
      onChange={handleChange}
      onClear={handleClear}
    />
  )
}

export default SearchInput

import React from 'react'

import { RadioMenu } from '@clain/core/ui-kit'
import { InputDropdown } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'

import { FieldProps } from './types'

const options = [
  {
    label: 'In',
    value: 'in' as const,
  },
  {
    label: 'Out',
    value: 'out' as const,
  },
  {
    label: 'Both (In/ Out)',
    value: 'both' as const,
  },
]

const TransactionDirection = ({ value, onChange }: FieldProps<string>) => {
  const handleInputClear = () => {
    onChange('')
  }

  const handleChange = (selected) => {
    onChange(selected)
  }

  const printValue = options.find(({ value: v }) => v === value)?.label || ''

  return (
    <InputDropdown
      clearable
      sameWidth
      padded={false}
      variant="outline"
      startAddon={{ label: 'Transaction direction:' }}
      placeholder="Any"
      value={printValue}
      onClear={handleInputClear}
    >
      <Container gap={[1, 0]}>
        <RadioMenu options={options} value={value} onChange={handleChange} />
      </Container>
    </InputDropdown>
  )
}

export default TransactionDirection

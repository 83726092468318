import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as AlertCircleIcon } from '@clain/core/assets/alert_circle.svg'

import useHttp from '@clain/core/useHttp'
import { InputDropdown } from '@clain/core/ui-kit'
import { Menu } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import useFuse from '@clain/core/useFuse'

import { Rule, RulesData } from '../../../../types/Rule'
import SeverityTag from '../../../SeverityTag'
import { FieldProps } from '../types'

import styles from './index.scss'
import { Container } from '@clain/core/ui-kit'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'

const cx = classnames.bind(styles)
interface IncidentRuleItemProps {
  rule: Rule
}

const IncidentRuleItem: React.FC<IncidentRuleItemProps> = ({ rule }) => {
  let direction = ''

  if (rule.direction === 'both') {
    direction = 'In / Out'
  }
  if (rule.direction === 'in') {
    direction = 'In'
  }
  if (rule.direction === 'out') {
    direction = 'Out'
  }

  return (
    <div className={cx('IncidentRuleItem')}>
      <RowDeprecated className={cx('SeverityName')}>
        <SeverityTag value={rule.severity}>{rule.severity}</SeverityTag>
        <Typography className={cx('Name')} color="grey1">
          {rule.name}
        </Typography>
      </RowDeprecated>
      <Typography color="grey4" transform="capitalize" className={cx('Type')}>
        {rule.type}
      </Typography>
      <Typography color="grey4" className={cx('RiskAmount')}>
        {rule.usd_amount ? `≥ $${rule.usd_amount}` : 'Any'}
      </Typography>
      <Typography className={cx('Direction')} color="grey4">
        {direction}
      </Typography>
    </div>
  )
}

const IncidentRuleFilter: React.FC<FieldProps<string>> = ({
  value,
  onChange,
}) => {
  const [inputValue, setInputValue] = React.useState('')

  const { data } = useHttp<RulesData>(buildUrl`${getConfig()?.API}/api/rules`)

  const rules = data?.rules || []

  React.useEffect(() => {
    if (value) {
      const rule = rules.find((rule) => rule.id == value)

      if (rule) {
        setInputValue(rule.name)
      }
    }
  }, [data, rules, value])

  const handleInputClear = () => {
    onChange(null)
  }

  const handleChange = (ruleId) => {
    const rule = rules.find((rule) => rule.id == ruleId)

    setInputValue(rule.name)
    onChange(ruleId)
  }

  const getRules = useFuse(rules, {
    keys: ['name', 'type'],
    threshold: 0.4,
  })

  const options = React.useMemo(
    () =>
      getRules(inputValue).map((rule) => ({
        value: rule.id,
        label: <IncidentRuleItem rule={rule} />,
        selected: `${rule.id}` === value,
      })),
    [getRules, inputValue, value]
  )

  return (
    <InputDropdown
      clearable
      readOnly={false}
      variant="outline"
      startAddon={{ label: 'Rule:' }}
      value={inputValue}
      placeholder="Type a rule name"
      onClear={handleInputClear}
      padded={false}
      className={cx('RuleFilter')}
      contentClassName={cx('RuleFilterContent')}
      onChange={setInputValue}
    >
      <MagicGrid>
        {options.length ? (
          <div>
            <div className={cx('Header')}>
              <Typography
                className={cx('SeverityTitle')}
                variant="body3"
                color="grey3"
              >
                Severity
              </Typography>
              <Typography
                className={cx('NameTitle')}
                variant="body3"
                color="grey3"
              >
                Name
              </Typography>
              <Typography
                className={cx('TypeTitle')}
                variant="body3"
                color="grey3"
              >
                Type
              </Typography>
              <Typography
                className={cx('RiskAmountTitle')}
                variant="body3"
                color="grey3"
              >
                Amount
              </Typography>
              <Typography
                className={cx('DirectionTitle')}
                variant="body3"
                color="grey3"
              >
                Direction
              </Typography>
            </div>
            <Menu padded={false} options={options} onChange={handleChange} />
          </div>
        ) : (
          <Container gap={[0.5, 1]}>
            <RowDeprecated gap={0.5}>
              <Typography color="grey4">
                <AlertCircleIcon />
              </Typography>
              <Typography color="grey3">
                There are no matching rules. Try another name.
              </Typography>
            </RowDeprecated>
          </Container>
        )}
      </MagicGrid>
    </InputDropdown>
  )
}

export default IncidentRuleFilter

import React, { useState } from 'react'
import { Button, RadioButtonGroup } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import Editor, { extractMentions } from '@clain/core/Editor'
import { Label } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import Form, {
  FORM_ERROR,
  Field,
  FieldError,
  FormActionsVertical,
  validators,
} from '@clain/core/Form'
import { Typography } from '@clain/core/ui-kit'
import { CaseAssignee } from '../../../types/Case'
import { SnakeToCamelCaseArray } from '@clain/core/utilsTypes'
import { useCaseActions } from '../CaseActions.context'

interface ReviewFormProps {
  caseId: number
  onSubmitSuccess: () => void
  onCancel: () => void
  assignees: SnakeToCamelCaseArray<CaseAssignee>
}

const ReviewForm: React.FC<ReviewFormProps> = ({
  caseId,
  onSubmitSuccess,
  onCancel,
  assignees,
}) => {
  const { setCaseToPending } = useCaseActions()

  const tagRef = React.useRef(null)

  const handleSubmit = async (data: { comment: string; closeAs: string }) => {
    const mentions = extractMentions(data.comment)
    try {
      await setCaseToPending(caseId, { ...data, mentions })
      onSubmitSuccess()
    } catch (e) {
      return {
        [FORM_ERROR]: e.message,
      }
    } finally {
    }
  }

  const optionLabels = {
    true: <Typography color="magenta">True Positive</Typography>,
    false: <Typography color="blue">False Positive</Typography>,
  }

  const options = [
    {
      value: 'true',
      label: <>Recommended as {optionLabels.true}</>,
    },
    {
      value: 'false',
      label: <>Recommended as {optionLabels.false}</>,
    },
    {
      value: '',
      label: 'With no recommended opinion',
    },
  ]

  return (
    <Form onSubmit={handleSubmit} initialValues={{ close_as: 'true' }}>
      {({ form }) => {
        const { validating, invalid, pristine, submitting, submitError } =
          form.getState()

        return (
          <>
            <MagicGrid gap={2}>
              <Typography variant="heading5" color="black">
                Submit for review
              </Typography>

              <MagicGrid>
                <Field
                  as={RadioButtonGroup}
                  name="close_as"
                  options={options}
                />

                <Label
                  size="lg"
                  content={
                    <>
                      The case will be sent to review. <br />
                      Leave the reasoning below and CONFIRM.
                    </>
                  }
                >
                  <Field
                    ref={tagRef}
                    fullWidth
                    as={Editor}
                    mentionOptions={assignees}
                    name="comment"
                    variant="outline"
                    validate={validators.required()}
                  />
                  <FieldError name="comment" />
                </Label>
              </MagicGrid>

              <FormActionsVertical>
                {submitError && (
                  <Typography color="magenta">{submitError}</Typography>
                )}
                <RowDeprecated gap={0.5}>
                  <Button
                    type="button"
                    size="lg"
                    variant="outline"
                    color="secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={submitting}
                    type="submit"
                    size="lg"
                    // pristine докидываем, чтобы не моргало при первом рендере
                    // т.к. знаем что форму полюбому надо заполнить
                    disabled={pristine || validating || invalid || submitting}
                    variant="solid"
                    color="primary"
                  >
                    Confirm
                  </Button>
                </RowDeprecated>
              </FormActionsVertical>
            </MagicGrid>
          </>
        )
      }}
    </Form>
  )
}

export default ReviewForm

import React from 'react'
import classnames from 'classnames/bind'

import { ColDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { ReactComponent as LockClosedIcon } from '@clain/core/assets/lock_closed.svg'

import styles from './index.scss'

const cx = classnames.bind(styles)

const NoAccess: React.FC = () => (
  <div className={cx('NoAccess')}>
    <ColDeprecated align="center">
      <LockClosedIcon className={cx('LockClosedIcon')} />
      <Typography variant="display1" color="black">
        You have no access to this service
      </Typography>
      <Typography variant="heading4" color="grey2">
        Seems that you are not subscribed.
        <br />
        Reach out our sales manager or your system administrator.
      </Typography>
    </ColDeprecated>
  </div>
)

export default NoAccess

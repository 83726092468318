import { API_ENDPOINT } from '../utils'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import http from '@clain/core/http'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import {
  ClientApiParams,
  ClientDataResponse,
  IClientService,
} from './ClientService.types'
import { injectable } from 'inversify'

@injectable()
export class ClientService implements IClientService {
  private apiEndpoint = `${API_ENDPOINT}/clients`

  public getClientById = ({ clientId }: ClientApiParams) => {
    return new Promise<SnakeToCamelCaseObject<ClientDataResponse>>(
      (resolve, reject) => {
        http
          .get<ClientDataResponse>(`${this.apiEndpoint}/${clientId}`)
          .then((result) => {
            resolve(normalizeSnakeToCamelCase(result.data))
          })
          .catch(reject)
      }
    )
  }
}

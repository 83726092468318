import type { CalendarInputValue } from '@clain/core/ui-kit'

export enum FIELDS {
  selected = 'selected',
  created = 'created',
  assignee = 'assignee',
  tag = 'tag',
  clientId = 'clientId',
  closureStatus = 'closureStatus',
  severity = 'severity',
}

interface StaticFiltersState {
  [FIELDS.selected]: Array<string>
  [FIELDS.created]: CalendarInputValue
  [FIELDS.tag]: string | null
  [FIELDS.assignee]: string | null
}

interface SelectableFiltersState {
  [FIELDS.clientId]: string | null
  [FIELDS.closureStatus]: 'true' | 'false' | 'both'
  [FIELDS.severity]: Array<'high' | 'medium' | 'low'>
}

export type FiltersState = StaticFiltersState & SelectableFiltersState

export interface FiltersProps {
  defaultFiltersState: FiltersState
  initialValues: FiltersState
  onChange: (value: FiltersState) => void
}

export interface FieldProps<T> {
  value: T | null
  onChange: (value: T | null) => void
}

type FieldType = keyof SelectableFiltersState

export type FieldTypes = {
  [k in FieldType]: React.ComponentType<FieldProps<any>>
}

type FieldOption = {
  label: string
  value: FieldType
}

export type FieldOptions = Array<FieldOption>

import React from 'react'
import { Button } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { Alert } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import Form, {
  FORM_ERROR,
  Field,
  FormActionsVertical,
  validators,
} from '@clain/core/Form'
import { Container } from '@clain/core/ui-kit'
import { useIncidentActions } from '../IncidentActions.context'

export default function IncidentConvertForm({
  incidentId,
  onSubmitSuccess,
  onCancel,
}) {
  const tagRef = React.useRef(null)
  const { openCase } = useIncidentActions()

  React.useEffect(() => {
    tagRef.current.focus()
  }, [])

  const handleSubmit = async (data: { tag: string }) => {
    try {
      const response = await openCase(incidentId, data)
      onSubmitSuccess(response.case.caseId)
    } catch (e) {
      return {
        [FORM_ERROR]: e.message,
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({ form }) => {
        const { validating, invalid, pristine, submitting, submitError } =
          form.getState()

        return (
          <Container gap={0.5}>
            <MagicGrid gap={2}>
              <Typography variant="heading5" color="black">
                Open new case
              </Typography>

              <Label content="Label the case before you start it" size="lg">
                <Field
                  ref={tagRef}
                  fullWidth
                  as={TextField}
                  name="tag"
                  variant="outline"
                  validate={validators.required()}
                />
              </Label>

              <Alert variant="warning">
                Starting the case you won’t be able to cancel its creation or
                delete it later
              </Alert>

              <FormActionsVertical>
                {submitError && (
                  <Typography color="magenta">{submitError}</Typography>
                )}
                <MagicGrid cols={2} gap={1 / 2}>
                  <Button
                    type="button"
                    variant="outline"
                    color="secondary"
                    onClick={onCancel}
                    size="lg"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    // pristine докидываем, чтобы не моргало при первом рендере
                    // т.к. знаем что форму полюбому надо заполнить
                    disabled={pristine || validating || invalid || submitting}
                    variant="solid"
                    color="primary"
                    size="lg"
                  >
                    Start case
                  </Button>
                </MagicGrid>
              </FormActionsVertical>
            </MagicGrid>
          </Container>
        )
      }}
    </Form>
  )
}

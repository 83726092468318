import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'

import buildUrl from '@clain/core/utils/buildUrl'
import useHttp from '@clain/core/useHttp'
import { generatePath, useMatch, useNavigate } from '@clain/core/Router/router'
import { Stub } from '@clain/core/ui-kit'
import Portlet from '@clain/core/Portlet'
import { CounterTabs, CounterTabPropsOption } from '@clain/core/ui-kit'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { useFormatDate } from '../../hooks'
import { ActivityPeriod } from '@clain/core/ui-kit'

import ActivitySummaryInfoCard from '../InfoCard/ActivitySummaryInfoCard'
import ClientScoreTimeline from '../ClientScoreTimeline'
import ClientHeader from '../ClientHeader'
import ExposureTab from './ExposureTab'
import DepositTab from './DepositTab'
import WithdrawalTab from './WithdrawalTab'
import TransfersTab from './TransfersTab'
import IncidentsTab from './IncidentsTab'
import CasesTab from './CasesTab'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'
import { useSettings } from '../../hooks'
import { ClientDataResponse } from '../../apiServices/Client/ClientService.types'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

interface ClientProps {
  id?: string
}

type TabType =
  | 'exposure'
  | 'transfers'
  | 'incidents'
  | 'cases'
  | 'deposit'
  | 'withdrawal'

const Client: React.FC<ClientProps> = ({ id }) => {
  const formatDate = useFormatDate()

  const { firstTransaction: firstTransactionSettings, settings } = useSettings()
  const navigate = useNavigate()

  const match = useMatch('/clients/:id/:tab')

  const tab = match ? (match.params.tab as TabType) : 'exposure'

  const handleUpdateTab = (tab) => {
    navigate(generatePath('/clients/:id/:tab', { id, tab }))
  }

  const [counters, setCounters] = React.useState({
    incidents: 0,
    cases: 0,
    transfers: 0,
    deposit: 0,
    withdrawals: 0,
  })

  const { data, isLoading } = useHttp<ClientDataResponse>(
    buildUrl`${getConfig()?.API}/api/clients/${id}`
  )

  React.useEffect(() => {
    setCounters((prev) => ({
      ...prev,
      incidents: data?.client.total_incidents || 0,
      cases: data?.client.total_cases || 0,
      transfers: data?.client.transactions || 0,
      deposit: data?.client.total_deposits || 0,
      withdrawals: data?.client.total_withdrawals || 0,
    }))
  }, [data])

  HeaderSlot.useContent(
    () => <ClientHeader withActions id={id} data={data?.client} />,
    [data]
  )

  const tabs: CounterTabPropsOption[] = [
    {
      value: 'exposure',
      children: 'exposure',
    },
    {
      value: 'transfers',
      children: 'transfers',
      counterValue: counters.transfers,
      isShowZeroValue: true,
    },
    {
      value: 'incidents',
      children: 'incidents',
      counterValue: counters.incidents,
      isShowZeroValue: true,
    },
    {
      value: 'cases',
      children: 'cases',
      counterValue: counters.cases,
      isShowZeroValue: true,
    },
    {
      value: 'deposit',
      children: 'deposit addr.',
      counterValue: counters.deposit,
      isShowZeroValue: true,
    },
    {
      value: 'withdrawal',
      children: 'withdrawal addr.',
      counterValue: counters.withdrawals,
      isShowZeroValue: true,
    },
  ]

  const firstTransaction = firstTransactionSettings
    ? fromUnixTime(firstTransactionSettings)
    : null

  const tabToView = {
    exposure: (
      <ExposureTab
        exposure={
          data?.exposure ? normalizeSnakeToCamelCase(data?.exposure) : undefined
        }
      />
    ),
    transfers: <TransfersTab clientId={id} flow={data?.flow} />,
    incidents: <IncidentsTab clientId={id} />,
    cases: <CasesTab clientId={id} />,
    deposit: <DepositTab clientId={id} />,
    withdrawal: <WithdrawalTab clientId={id} />,
  }

  const firstSeen = data?.client?.first_seen
    ? new Date(data?.client.first_seen * 1000)
    : null

  const lastSeen = data?.client?.last_seen
    ? new Date(data?.client.last_seen * 1000)
    : null

  const activityPeriod = firstSeen
    ? ([firstSeen, lastSeen] as [Date, Date])
    : null

  return (
    <div className={cx('ClientGrid')}>
      <Portlet variant="card">
        {{
          body: (
            <div className={cx('ClientMainInfo')}>
              <Container gap={1.5} className={cx('ActivitySummaryInfoCard')}>
                <MagicGrid gap={2}>
                  <Typography variant="heading3" color="grey1">
                    Activity summary
                  </Typography>
                  <ActivityPeriod
                    timeZone={settings?.timeZone}
                    activityPeriodStart={firstTransaction}
                    today={formatDate(new Date(), 'date')}
                    activityPeriod={activityPeriod}
                  />
                  <ActivitySummaryInfoCard
                    loading={isLoading}
                    client={data?.client}
                  />
                </MagicGrid>
              </Container>
              <ClientScoreTimeline
                data={data?.score_history}
                className={cx('ClientScoreTimeline')}
              />
            </div>
          ),
        }}
      </Portlet>
      <Portlet variant="card" className={cx('TabsPortlet')}>
        {{
          head: (
            <CounterTabs
              className={cx('ClientTabs', { disabled: isLoading })}
              options={tabs}
              value={!isLoading ? tab : ''}
              disabled={isLoading}
              onChange={handleUpdateTab}
            />
          ),
          body: !isLoading ? (
            tabToView[tab]
          ) : (
            <Stub className={cx('PortletBodyStub')} isActive={true} />
          ),
        }}
      </Portlet>
    </div>
  )
}

export default observer(Client)

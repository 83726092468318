import React, { useEffect } from 'react'

import ProfileSettings from '../components/Settings/ProfileSettings'
import { useAppLayoutResetter } from '@clain/core/state'

export const PageProfileSettings = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])
  return <ProfileSettings />
}

export default PageProfileSettings

import React from 'react'
import classnames from 'classnames/bind'

import http from '@clain/core/http'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { CopyIcon } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { ReactComponent as RefreshIcon } from '@clain/core/assets/refresh.svg'
import { notify } from '@clain/core/ui-kit'
import Form, { Field } from '@clain/core/Form'
import { Textarea } from '@clain/core/ui-kit'

import {
  SettingsBlockUrlApiData,
  SettingsWhitelistApiData,
} from '../../../types/Settings'
import BlockClientAPIExample from './BlockClientAPIExample'

import styles from './index.scss'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'

const cx = classnames.bind(styles)

const BlockClientForm = ({ whiteList, blockUrl, keys, onUpdate }) => {
  const handleRegerateBlockClientAPI = async () => {
    try {
      const response = await http.post(
        buildUrl`${getConfig()?.API}/api/settings/regenerate_api_keys`,
        { type: false }
      )

      if (response.data.status) {
        onUpdate(response.data.data)
        notify('API keys successfully regerated', { type: 'success' })
      } else {
        throw new Error(response.data.msg)
      }
    } catch (e) {
      const errorText = 'An error occured while regerating keys'

      notify(e.message ? `${errorText}: ${e.message}` : errorText, {
        type: 'fail',
      })
    }
  }

  const formRef = React.useRef(null)

  const handleUpdateForm = async (data: {
    whiteList: string
    blockUrl: string
  }) => {
    try {
      const apiData: Partial<SettingsWhitelistApiData> = {
        white_list: data.whiteList || '',
      }
      const response = await http.post(
        buildUrl`${getConfig()?.API}/api/settings/ip_white_list`,
        apiData
      )

      if (response.data.white_list) {
        formRef.current.form.change('whiteList', response.data.white_list)
      }

      if (response.data.status) {
        onUpdate(response.data.data)
        notify('Whitelist successfully updated', { type: 'success' })
      } else {
        throw new Error(response.data.msg)
      }
    } catch (e) {
      const errorText = 'An error occured while saving whitelist IP addresses'

      notify(e.message ? `${errorText}: ${e.message}` : errorText, {
        type: 'fail',
      })

      return { whiteList: e.message }
    }

    try {
      const apiData: Partial<SettingsBlockUrlApiData> = {
        block_url: data.blockUrl || '',
      }
      const response = await http.post(
        buildUrl`${getConfig()?.API}/api/settings/client_block_url`,
        apiData
      )

      if (response.data.status) {
        onUpdate(response.data.data)
        notify('Client block endpoint successfully updated', {
          type: 'success',
        })
      } else {
        throw new Error(response.data.msg)
      }
    } catch (e) {
      const errorText = 'An error occured while saving сlient block endpoint'

      notify(e.message ? `${errorText}: ${e.message}` : errorText, {
        type: 'fail',
      })

      return { blockUrl: e.message }
    }
  }

  return (
    <Container className={`${cx('Card')} sentry-mask`} gap={[2, 1.5]}>
      <MagicGrid gap={2}>
        <RowDeprecated>
          <MagicGrid className={cx('Keys')}>
            <RowDeprecated gap={2} className={cx('KeysRow')}>
              <Typography className={cx('Label')} variant="body3" color="grey4">
                Public key
              </Typography>
              <div className={cx('KeyBlock')}>
                <Typography className={cx('Key')} variant="body1" color="black">
                  {keys.public}
                </Typography>
                <CopyIcon className={cx('CopyIcon')} value={keys.public} />
              </div>
            </RowDeprecated>
            <RowDeprecated gap={2} className={cx('KeysRow')}>
              <Typography className={cx('Label')} variant="body3" color="grey4">
                Private key
              </Typography>
              <div className={cx('KeyBlock')}>
                <Typography className={cx('Key')} variant="body1" color="black">
                  {keys.private}
                </Typography>
                <CopyIcon className={cx('CopyIcon')} value={keys.private} />
              </div>
            </RowDeprecated>
          </MagicGrid>
          <div className={cx('RefreshConnector')} />
          <Button
            className={cx('RegenerateButton')}
            variant="solid"
            color="primary"
            size="lg"
            endCustomIcon={RefreshIcon}
            onClick={handleRegerateBlockClientAPI}
          >
            Regenerate
          </Button>
        </RowDeprecated>
        <Form
          initialValues={{ whiteList, blockUrl }}
          ref={formRef}
          onSubmit={handleUpdateForm}
        >
          {({ form }) => {
            const { dirty } = form.getState()

            return (
              <MagicGrid gap={2}>
                <RowDeprecated className={cx('IpAddressesWrapper')}>
                  <RowDeprecated gap={2} className={cx('IpAddresses')}>
                    <Typography
                      className={cx('Label')}
                      variant="body3"
                      color="grey4"
                    >
                      Whitelist IP addresses
                    </Typography>
                    <Field
                      as={Textarea}
                      name="whiteList"
                      className={cx('IpAddressesInput')}
                      variant="outline"
                      rows={3}
                    />
                  </RowDeprecated>
                </RowDeprecated>
                <RowDeprecated className={cx('EndpointWrapper')}>
                  <RowDeprecated gap={2} className={cx('Endpoint')}>
                    <Typography
                      className={cx('Label')}
                      variant="body3"
                      color="grey4"
                    >
                      Client block endpoint
                    </Typography>
                    <Field
                      as={TextField}
                      name="blockUrl"
                      label="POST:"
                      className={cx('EndpointInput')}
                      placeholder="Input an endpoint for blocking clients"
                      variant="outline"
                    />
                  </RowDeprecated>
                </RowDeprecated>
                <Button
                  className={cx('SaveButton')}
                  type="submit"
                  variant="solid"
                  color="primary"
                  size="lg"
                  disabled={!dirty}
                >
                  Save
                </Button>
              </MagicGrid>
            )
          }}
        </Form>
      </MagicGrid>
      <div className={cx('ExampleWrapper')}>
        <BlockClientAPIExample url={blockUrl} />
      </div>
    </Container>
  )
}

export default BlockClientForm

import React from 'react'

import StatusDot from '../StatusDot'
import { RowDeprecated } from '@clain/core/ui-kit'

import BlockClient from './BlockClient'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'

export type ClientStatusType = 'blocked' | 'active'

interface ClientStatusProps {
  status: ClientStatusType
}

export const ClientStatus = ({ status }: ClientStatusProps) => {
  const statusText = capitalizeFirstLetter(status)

  return (
    <RowDeprecated gap={0.5}>
      <StatusDot status={status} />
      {statusText}
    </RowDeprecated>
  )
}

interface ClientActionsProps {
  id?: string
  status?: ClientStatusType | undefined | null
  showStatus?: boolean
  onStatusChange?: (status: ClientStatusType) => void
}

const ClientActions = ({
  id,
  status,
  showStatus = false,
  onStatusChange,
}: ClientActionsProps) => {
  const [currentStatus, setCurrentStatus] =
    React.useState<ClientStatusType>(status)

  React.useEffect(() => {
    setCurrentStatus(status)
  }, [status])

  const handleBlock = async () => {
    setCurrentStatus('blocked')
    onStatusChange?.('blocked')
  }

  let actions = null

  if (currentStatus === 'active') {
    actions = <BlockClient id={id} onSubmitSuccess={handleBlock} />
  }

  if (currentStatus === 'blocked') {
    // actions = <ActivateClient id={id} onSubmitSuccess={handleActivate} />
  }

  if (showStatus) {
    return (
      <RowDeprecated>
        <ClientStatus status={currentStatus} />
        {actions}
      </RowDeprecated>
    )
  }

  return actions
}

export default ClientActions

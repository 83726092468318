import { Channel, WebSocketWrapper } from '../../utils/WebSocketWrapper'
import { injectable, unmanaged } from 'inversify'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { normalizeCamelToSnakeCase } from '@clain/core/utils/normalizeCamelToSnakeCase'
import { ISocketService } from '@compliance/apiServices/shared/Chat.types'

@injectable()
export class IncidentCaseSocketService<
  SocketDataResponse extends object,
  ChatSocketChannelSubscriptions extends Record<string, object>
> implements ISocketService<SocketDataResponse>
{
  private channel: Channel
  private socketChannelTopic: string
  private subscriptions: (keyof ChatSocketChannelSubscriptions)[]

  constructor(
    @unmanaged() private CHANNEL_KEY: 'incident' | 'case',
    @unmanaged() subscriptions: (keyof ChatSocketChannelSubscriptions)[]
  ) {
    this.subscriptions = subscriptions
  }

  public initializeSocketChannel = async ({ id }: { id: number }) => {
    this.socketChannelTopic = `${this.CHANNEL_KEY}:${id}`
    this.channel = WebSocketWrapper.channel(this.socketChannelTopic)
    const rawResult = await this.channel.join<SocketDataResponse>()
    return normalizeSnakeToCamelCase(rawResult)
  }

  public closeSocketChannel = () => {
    this.unsubscribeFromAllSocketChannels()
    WebSocketWrapper.clear(this.socketChannelTopic)
  }

  public sendAiChatMessage = (payload: {
    message: string
    fileIds: string[]
  }) => {
    this.channel?.push('message', normalizeCamelToSnakeCase(payload))
  }

  public retrySendAiChatMessage = async () => {
    await this.channel?.push('retry')
  }

  public regenerateReport = async () => {
    await this.channel?.push('regenerate_report')
  }

  public useMessageInReport = async (payload: { messageId: string }) => {
    await this.channel?.push(
      'add_to_report',
      normalizeCamelToSnakeCase(payload)
    )
  }

  private manageSocketChannel = <U extends object>(
    action: 'subscribe' | 'unsubscribe',
    event: keyof ChatSocketChannelSubscriptions,
    callback?: (res: U) => void
  ) => {
    if (action === 'subscribe' && callback) {
      this.channel?.subscribe<U>(event as string, callback)
    } else {
      this.channel?.unsubscribe(event as string)
    }
  }

  protected subscribeToSocketChannel = <U extends object>(
    event: keyof ChatSocketChannelSubscriptions,
    callback: (res: U) => void
  ) => {
    this.manageSocketChannel('subscribe', event, callback)
  }

  private unsubscribeFromSocketChannel = (
    event: keyof ChatSocketChannelSubscriptions
  ) => {
    this.manageSocketChannel('unsubscribe', event)
  }

  private unsubscribeFromAllSocketChannels = () => {
    this.subscriptions.forEach((event) => {
      this.unsubscribeFromSocketChannel(event)
    })
  }
}

import React from 'react'
import { WebSocketWrapper } from '../utils/WebSocketWrapper'
import { UserAccessState } from '@clain/core/states/UserAcessState'
import { NotificationsController } from '../controllers'
import { settingsController } from '../controllers/SettingsController'
import { settingsState } from '../states'

interface AppCtxInit {
  token: string
  wss: string
  refreshToken: () => Promise<string>
}

export class AppCtx {
  public wsState = WebSocketWrapper
  public userState = new UserAccessState()
  public settingsController = settingsController
  public settingsState = settingsState

  public init = async ({ token, wss, refreshToken }: AppCtxInit) => {
    this.wsState.init({ token, wss, userState: this.userState, refreshToken })
    await this.settingsController.loadAndStoreSettingsProfile()
    const notificationsController = new NotificationsController({
      webSocketState: this.wsState,
      settingsController: this.settingsController,
    })
    notificationsController.init()
  }
  public reconnectWithNewToken = async (token: string) => {
    await this.wsState.reconnectWithNewToken(token)
  }
}

export const ctx = new AppCtx()
export const createAppCtx = React.createContext(ctx)
export const useAppCtx = () => React.useContext(createAppCtx)

import React from 'react'
import classnames from 'classnames/bind'

import { Container } from '@clain/core/ui-kit'
import Form, { Field, validators } from '@clain/core/Form'
import { MagicGrid } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import { RadioButtonGroup } from '@clain/core/ui-kit'
import { CheckboxSwitch } from '@clain/core/ui-kit'
import { Checkbox } from '@clain/core/ui-kit'

import RuleEntityFilter from './RuleEntityFilter'
import RuleSeverityFilter from './RuleSeverityFilter'
import RuleRiskAmountFilter from './RuleRiskAmountFilter'
import CreateRuleConfirmationButton from '../CreateRuleConfirmationButton'

import { NewRuleApiParams } from '../../../../types/Rule'

import styles from './index.scss'

const cx = classnames.bind(styles)

const directionOptions = [
  { value: 'both', label: 'In / Out' },
  { value: 'in', label: 'Incoming' },
  { value: 'out', label: 'Outgoing' },
]

const initialFormValues = {
  type: 'entity',
  direction: 'both',
  identityId: '',
  riskAmount: '',
  severity: 'HIGH',
  autoBlock: false,
  retrospectivelyApply: false,
  retrospectivelyBlock: false,
  usd: false,
}

interface CreateEntityRuleFormProps {
  onSubmit: (data) => Promise<any>
  onSubmitModalOpen: (isOpen: boolean) => void
}

const CreateEntityRuleForm: React.FC<CreateEntityRuleFormProps> = ({
  onSubmit,
  onSubmitModalOpen,
}) => {
  const formRef = React.useRef(null)

  const handleSubmit = (data) => {
    const submitData: NewRuleApiParams = {
      type: data.type,
      severity: data.severity,
      identity_id: data.identityId,
      direction: data.direction,
      usd: data.usd,
      usd_amount: data.riskAmount,
      block_client: data.autoBlock,
      run_retrospectivly: data.retrospectivelyApply,
      block_old_clients: data.retrospectivelyBlock,
    }

    return onSubmit(submitData).then(() => formRef.current.form.restart())
  }

  React.useEffect(() => {
    const unsubscribe = formRef.current?.form.subscribe(
      ({ values: { retrospectivelyApply } }) => {
        if (!retrospectivelyApply) {
          formRef.current.form.change('retrospectivelyBlock', false)
        }
      },
      { values: true }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      ref={formRef}
    >
      {({ form }) => {
        const { values, valid } = form.getState()
        const { usd, retrospectivelyApply } = values

        return (
          <Container gap={[1, 0]}>
            <MagicGrid gap={2.5}>
              <Label content="Transaction direction">
                <Field
                  name="direction"
                  as={RadioButtonGroup}
                  options={directionOptions}
                  fullWidth
                />
              </Label>
              <Label content="Entity name (required)">
                <Field
                  name="identityId"
                  as={RuleEntityFilter}
                  validate={validators.required()}
                />
              </Label>
              <Label
                as={'div'}
                content={<Field as={Checkbox} name="usd" label="Risk amount" />}
              >
                <Field
                  name="riskAmount"
                  disabled={!usd}
                  validate={(value, allValues, meta) => {
                    if (allValues.usd)
                      return validators.required()(value, allValues, meta)
                  }}
                  as={RuleRiskAmountFilter}
                />
              </Label>
              <Label content="Severity">
                <Field name="severity" as={RuleSeverityFilter} />
              </Label>
              <Container gap={[1.5, 1.5]} className={cx('SwitchGroup')}>
                <MagicGrid>
                  <Field
                    name="autoBlock"
                    as={CheckboxSwitch}
                    label="Automatically block client when the rule is triggered"
                  />
                  <Field
                    name="retrospectivelyApply"
                    as={CheckboxSwitch}
                    label="Apply the rule retrospectively"
                  />
                  <Field
                    name="retrospectivelyBlock"
                    as={CheckboxSwitch}
                    label="Block client when the rule is triggered in retrospect"
                    disabled={!retrospectivelyApply}
                  />
                </MagicGrid>
              </Container>
              <CreateRuleConfirmationButton
                onModalOpen={onSubmitModalOpen}
                values={values}
                valid={valid}
                submit={() => form.submit()}
              />
            </MagicGrid>
          </Container>
        )
      }}
    </Form>
  )
}

export default CreateEntityRuleForm

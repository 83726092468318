import React from 'react'
import classnames from 'classnames/bind'

import {
  Table2 as Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  createColumns,
  HandleChangeSort,
  capitalizeFirstLetter,
  Icon,
} from '@clain/core/ui-kit'
import buildUrl from '@clain/core/utils/buildUrl'
import { RowDeprecated } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../hooks'
import { CopyIcon } from '@clain/core/ui-kit'
import useHttp from '@clain/core/useHttp'
import Pagination from '@clain/core/Pagination'
import { observer } from 'mobx-react-lite'
import {
  DepositData,
  DepositDataItem,
  DepositApiParams,
} from '../../../types/Client'
import VoidText from '../../VoidText'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'

const cx = classnames.bind(styles)

const DEFAULT_PAGINATION_SIZE = 10

interface DepositAddressTableProps {
  items: Array<DepositDataItem>
  loading: boolean
  onChangeSort: HandleChangeSort
}

const DepositAddressTable = observer(
  ({ items, loading, onChangeSort }: DepositAddressTableProps) => {
    const formatDate = useFormatDate()

    const { columns, columnsData } = createColumns<DepositDataItem>(
      items,
      [
        {
          title: 'address',
          field: 'address',
          sortable: false,
          render: ({ address, currency }) => {
            return (
              <RowDeprecated>
                <RowDeprecated>
                  <Icon variant={capitalizeFirstLetter(currency)} />
                  <Link
                    to="%PLATFORM_URL%/:coin/address/:id"
                    params={{ coin: currency, id: address }}
                    className={cx('Address')}
                  >
                    <Typography variant="code1">{address}</Typography>
                  </Link>
                </RowDeprecated>
                <CopyIcon value={address} />
              </RowDeprecated>
            )
          },
        },
        {
          title: 'address creation date',
          field: 'createdAt',
          type: 'number',
          sortable: false,
          render: ({ inserted_at }) =>
            inserted_at ? formatDate(new Date(inserted_at)) : '',
        },
      ],
      {
        stubHeight: 70,
        stubAmount: DEFAULT_PAGINATION_SIZE,
      }
    )

    return (
      <Table loading={loading}>
        <Thead>
          <Tr>
            {columns.map(({ title, field, type, sortable }) => (
              <Th
                key={field}
                className={cx(field)}
                type={type}
                sortable={sortable}
                name={field}
                onChangeSort={onChangeSort}
              >
                {title}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {columnsData.map((item, index) => {
            return (
              <Tr key={`columnsData_${index}`}>
                {columns.map(({ field, render, type }) => (
                  <Td key={field} className={cx(field)} type={type}>
                    {render(item)}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    )
  }
)

interface DepositTabProps {
  clientId: string | number
}

const DepositTab = ({ clientId }: DepositTabProps) => {
  const [page, setPage] = React.useState(1)
  const [sortBy, setSortBy] = React.useState('')
  const [order, setOrder] = React.useState<'asc' | 'desc' | ''>('')

  const handleChangeSort = (sortBy, order) => {
    setSortBy(sortBy)
    setOrder(order)
  }

  const apiParams = React.useMemo(
    (): DepositApiParams => ({
      page,
      sort_by: sortBy,
      order,
    }),
    [page, sortBy, order]
  )

  const { data, isLoading } = useHttp<DepositData>(
    buildUrl`${getConfig()?.API}/api/clients/${clientId}/deposits?${apiParams}`
  )

  if (data?.addresses?.length === 0) {
    return <VoidText>No deposits or withdrawals yet</VoidText>
  }

  return (
    <>
      <DepositAddressTable
        items={data?.addresses}
        loading={isLoading}
        onChangeSort={handleChangeSort}
      />
      <Pagination
        className={cx('Pagination')}
        totalPages={data?.total_pages}
        value={page}
        onChange={setPage}
      />
    </>
  )
}

export default DepositTab

import React from 'react'

import Chart from '@clain/core/Chart2'

interface SeverityDonutData {
  high: number
  medium: number
  low: number
  total: number
  title: string
}

interface SeverityDonutOptions {
  data: [SeverityDonutData, SeverityDonutData]
}

function getOptions({ data }: SeverityDonutOptions) {
  const options = {
    tooltip: {
      show: false,
    },
    title: [
      {
        text: data[0].total,
        subtext: data[0].title,

        left: '25%',
        y: 115,

        itemGap: 8,

        textAlign: 'center',
        textStyle: {
          fontSize: 24,
          fontWeight: 'normal',
        },
        subtextStyle: {
          fontSize: 16,
        },
      },
      {
        text: data[1].total,
        subtext: data[1].title,

        left: '75%',
        y: 115,

        itemGap: 8,

        textAlign: 'center',
        textStyle: {
          fontSize: 24,
          fontWeight: 'normal',
        },
        subtextStyle: {
          fontSize: 16,
        },
      },
    ],
    legend: {
      show: true,
      bottom: 32,
      selectedMode: false,
      data: [
        data[0].high && {
          name: 'High',
          icon: 'circle',
        },
        data[0].medium && {
          name: 'Medium',
          icon: 'circle',
        },
        data[0].low && {
          name: 'Low',
          icon: 'circle',
        },
      ].filter(Boolean),
      x: 'center',
      y: 'bottom',
      itemGap: 24,
      padding: [24, 0, 24, 0],
      orient: 'horizontal',
      selected: {},
      textStyle: {
        fontSize: 14,
      },
    },
    series: [
      {
        name: data[0].title,
        type: 'pie',
        radius: ['45%', '70%'], // 70 * 0.65 ~ 45% // 0.65 — отношение внетруннего круга к внешнему
        center: ['26%', '42%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 4,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        silent: true,
        data: [
          data[0].total !== 0 && {
            value: data[0].high,
            name: 'High',
            itemStyle: {
              color: '#D4114A',
            },
          },
          data[0].total !== 0 && {
            value: data[0].medium,
            name: 'Medium',
            itemStyle: {
              color: '#FFA800',
            },
          },
          data[0].total !== 0 && {
            value: data[0].low,
            name: 'Low',
            itemStyle: {
              color: '#2173FF',
            },
          },
          data[0].total === 0 && {
            value: 0,
            name: 'Empty',
            itemStyle: {
              color: '#F1F3F9',
            },
          },
        ].filter(Boolean),
      },
      {
        name: data[1].title,
        type: 'pie',
        radius: ['45%', '70%'],
        center: ['75%', '42%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 4,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        silent: true,
        data: [
          data[1].total !== 0 && {
            value: data[1].high,
            name: 'High',
            itemStyle: {
              color: '#D4114A',
            },
          },
          data[1].total !== 0 && {
            value: data[1].medium,
            name: 'Medium',
            itemStyle: {
              color: '#FFA800',
            },
          },
          data[1].total !== 0 && {
            value: data[1].low,
            name: 'Low',
            itemStyle: {
              color: '#2173FF',
            },
          },
          data[1].total === 0 && {
            value: 0,
            name: 'Empty',
            itemStyle: {
              color: '#F1F3F9',
            },
          },
        ].filter(Boolean),
      },
    ],
  }

  return options
}

interface SeverityDonutProps extends SeverityDonutOptions {
  className?: string
  loading?: boolean
}

const SeverityDonut = React.memo(
  ({ className, loading, data }: SeverityDonutProps) => {
    return (
      <Chart
        stub={!data}
        loading={loading}
        className={className}
        option={getOptions({ data })}
        style={{
          height: 330,
        }}
      />
    )
  }
)
SeverityDonut.displayName = 'SeverityDonut'
export default SeverityDonut

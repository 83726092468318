import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'

import { capitalizeFirstLetter, Icon, Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../hooks'
import { formatMoney } from '@clain/core/utils/format'

import { ColDeprecated } from '@clain/core/ui-kit'
import StatusDot from '../../StatusDot'
import { Tooltip } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { TagDeprecated } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { LoaderOverlay } from '@clain/core/ui-kit'
import {
  Table2 as Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  createColumns,
} from '@clain/core/ui-kit'

import { TransactionNewAPIFormat } from '../../../types/Transaction'
import TransactionHash from '../../TransactionHash'
import { observer } from 'mobx-react-lite'
import styles from './index.scss'

const cx = classnames.bind(styles)

const DEFAULT_PAGINATION_SIZE = 10

interface TransactionTableProps {
  items: Array<TransactionNewAPIFormat>
  loading: boolean
  sortable?: boolean
  showClientId?: boolean
  sortBy?: string
  order?: '' | 'asc' | 'desc'
  onChangeSort?: (sortBy: string, order: 'asc' | 'desc') => void
}

const TransactionTable = ({
  items,
  loading = false,
  showClientId = true,
  sortBy,
  order,
  sortable: tableSortable = false,
  onChangeSort,
}: TransactionTableProps) => {
  const formatDate = useFormatDate()

  const { columns, columnsData } = createColumns<TransactionNewAPIFormat>(
    items,
    [
      {
        title: 'Counterparty',
        field: 'cp',
        render(item) {
          return (
            <RowDeprecated>
              {item.score ? <Score value={item.score} /> : null}
              <Link
                to="%PLATFORM_URL%/:coin/cluster/:id"
                params={{
                  id: item.counterparty.root_id,
                  coin: item.currency,
                }}
              >
                {item.counterparty?.name || item.counterparty.root_id}
              </Link>
            </RowDeprecated>
          )
        },
      },
      {
        title: 'Hash',
        field: 'trx',
        render(item) {
          const coin = item.currency
          const direction = item.incoming ? 'in' : 'out'
          const directionTooltip = item.incoming ? 'INCOMING' : 'OUTGOING'
          return (
            <RowDeprecated gap={0.5}>
              <Icon variant={capitalizeFirstLetter(coin)} />
              <Link
                to="%PLATFORM_URL%/:coin/explorer/transaction/:id"
                params={{ coin, id: item.hash }}
              >
                <TransactionHash value={item.hash} />
              </Link>
              <Tooltip content={directionTooltip}>
                <TagDeprecated>{direction}</TagDeprecated>
              </Tooltip>
            </RowDeprecated>
          )
        },
      },
      {
        title: 'Amount',
        field: 'amount',
        sortable: true,
        render(item) {
          const coin = item.currency
          const token = item.token
          return (
            <ColDeprecated gap={0}>
              <Typography>
                {formatMoney({ value: item.amount_usd, currency: 'usd' })}
              </Typography>
              <Typography color="grey4" variant="caption1">
                {formatMoney({
                  value: Number(item.amount),
                  currency: token?.symbol || coin,
                  precision: token?.symbol === 'USDT' ? 2 : 8,
                  decimals: 0,
                })}
              </Typography>
            </ColDeprecated>
          )
        },
      },
      showClientId && {
        title: 'Client',
        field: 'client',
        render(item) {
          return (
            <RowDeprecated gap={0.5}>
              <StatusDot
                status={item.client_id.blocked ? 'blocked' : 'active'}
              />
              <Link
                className={cx('ClientIdLink')}
                to="/clients/:id"
                params={{ id: item.client_id.id }}
              >
                {item.client_id.id}
              </Link>
            </RowDeprecated>
          )
        },
      },
      {
        title: 'Date',
        field: 'time',
        type: 'number',
        sortable: true,
        render(item) {
          const date = formatDate(fromUnixTime(item.time), {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          })
          const time = formatDate(fromUnixTime(item.time), {
            hour: 'numeric',
            minute: 'numeric',
          })

          return (
            <ColDeprecated gap={0}>
              <Typography>{date}</Typography>
              <Typography color="grey4" variant="caption1">
                {time}
              </Typography>
            </ColDeprecated>
          )
        },
      },
    ],
    {
      stubHeight: 74,
      stubAmount: DEFAULT_PAGINATION_SIZE,
    }
  )

  return (
    <>
      <LoaderOverlay loading={loading}>
        <Table>
          <Thead>
            <Tr>
              {columns.map(({ title, field, type, sortable }) => (
                <Th
                  key={field}
                  className={cx(field)}
                  name={field}
                  type={type}
                  sortable={tableSortable && sortable}
                  order={order as 'desc' | 'asc'}
                  sortBy={sortBy}
                  onChangeSort={onChangeSort}
                >
                  {title}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {columnsData.map((item, columnsDataIndex) => (
              <Tr key={`columnsData-${columnsDataIndex}`}>
                {columns.map(({ field, render, type }) => (
                  <Td key={field} className={cx(field)} type={type}>
                    {render(item)}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </LoaderOverlay>
    </>
  )
}

export default observer(TransactionTable)

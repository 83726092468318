import React, { useEffect } from 'react'
import Transactions from '../components/Transactions'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageTransactions = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: 'Transfers', fixedHeader: true })
  }, [])
  return <Transactions />
}

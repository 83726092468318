import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ReactComponent as BlockIcon } from '@clain/core/assets/block.svg'

import CaseActivityCard from './index'
import { FeedClientBlockActivity } from '../../../../types/Case'

import styles from './index.scss'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

const cx = classnames.bind(styles)

interface CaseClientBlockActivityCardProps {
  activity: SnakeToCamelCaseObject<FeedClientBlockActivity>
  userName?: string
  className?: string
}

const CaseClientBlockActivityCard: React.FC<
  CaseClientBlockActivityCardProps
> = ({ activity, className }) => {
  return (
    <CaseActivityCard
      className={className}
      icon={<BlockIcon />}
      title="Client block"
      main={
        <Typography variant="body3" color="grey2">
          <RowDeprecated gap={0.5}>
            {activity.user.name}
            <Typography color="grey2">blocked the client.</Typography>
          </RowDeprecated>
        </Typography>
      }
      date={new Date(activity.insertedAt)}
    >
      {activity.activity.comment && (
        <div className={cx('Comment')}>
          <Typography>{activity.activity.comment}</Typography>
        </div>
      )}
    </CaseActivityCard>
  )
}

export default CaseClientBlockActivityCard

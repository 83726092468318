import React, { memo } from 'react'
import { ProgressBar } from '@clain/core/ui-kit'
import { ProgressBarItemSubtitle, Stack } from '@clainio/web-platform'
import { ProgressBarProps } from '@clainio/web-platform/dist/components/ProgressBar/ProgressBar.types'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'

interface CaseStatusProgressBarProps {
  status?: 'investigation' | 'pending' | 'closed'
  loading?: boolean
  reviewDays: number
  investigationDays: number
}

const getItems = ({
  status,
  investigationDays,
  reviewDays,
}: Omit<CaseStatusProgressBarProps, 'loading'>): ProgressBarProps['items'] => [
  {
    title: 'INVESTIGATION',
    subtitle: (variant) => (
      <Stack gap={'xs'}>
        <ProgressBarItemSubtitle variant={variant}>
          Total spent
        </ProgressBarItemSubtitle>
        <ProgressBarItemSubtitle variant={variant} type={'2'}>
          {`${investigationDays}`}
        </ProgressBarItemSubtitle>
        <ProgressBarItemSubtitle variant={variant}>
          days
        </ProgressBarItemSubtitle>
      </Stack>
    ),
    active: status === 'investigation',
  },
  {
    title: 'REVIEW',
    subtitle: (variant) => (
      <Stack gap={'xs'}>
        <ProgressBarItemSubtitle variant={variant}>
          Total spent
        </ProgressBarItemSubtitle>
        <ProgressBarItemSubtitle variant={variant} type={'2'}>
          {`${reviewDays}`}
        </ProgressBarItemSubtitle>
        <ProgressBarItemSubtitle variant={variant}>
          days
        </ProgressBarItemSubtitle>
      </Stack>
    ),
    active: status === 'pending',
  },
  {
    title: 'CLOSED',
    active: status === 'closed',
  },
]

export const CaseStatusProgressBar: React.FC<CaseStatusProgressBarProps> = memo(
  ({ status, reviewDays = 0, investigationDays = 0, loading }) => {
    return (
      <ErrorBoundaryContainer>
        <ProgressBar
          key={status}
          stubType={'grey'}
          items={getItems({ status, reviewDays, investigationDays })}
          loading={loading}
        />
      </ErrorBoundaryContainer>
    )
  }
)
CaseStatusProgressBar.displayName = 'CaseStatusProgressBar'

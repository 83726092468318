import React, { useState } from 'react'
import * as S from '../../Layout/IncidentPageLayout.styles'
import { AIChatWidget } from '../../Widgets/AIChatWidget'
import { TimelineWidget } from '../../Widgets/TimelineWidget'
import * as SL from './CaseSidebar.styles'
import { Feed } from '../../../types/Case'
import CaseActivity from '../CaseActivity'
import { SnakeToCamelCaseMapping } from '@clain/core/utilsTypes'
import { CasesAssigneesDataResponse } from '../../../apiServices/Case'
import { caseViewModelFacade } from '@compliance/di/containers/CaseViewModelFacade.container'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { useSettings } from '@compliance/hooks'

const tabsOptions = [
  { value: 'chat', children: 'Query AI' },
  { value: 'timeline', children: 'Timeline' },
]

interface CaseSidebarProps {
  assignees: SnakeToCamelCaseMapping<CasesAssigneesDataResponse>
  feed: SnakeToCamelCaseMapping<Feed>
}

export const CaseSidebar = observer(({ assignees, feed }: CaseSidebarProps) => {
  const { user } = useSettings()

  const {
    sendAiChatMessage,
    useMessageInReport,
    chatData,
    attachFilesToChatMessage,
    removeChatFile,
    isChatDisabled,
    isSummaryReportCantBeModifiedInChat,
    isSummaryReportToProcess,
    isSummaryReportProcessing,
    retrySendAiChatMessage
  } = caseViewModelFacade
  const [activeTab, setActiveTab] =
    useState<(typeof tabsOptions)[number]['value']>('chat')
  return (
    <S.Sidebar>
      <SL.Tabs
        options={tabsOptions}
        value={activeTab}
        onChange={setActiveTab}
      />
      {activeTab === 'chat' && (
        <AIChatWidget
          retrySendAiChatMessage={retrySendAiChatMessage}
          error={chatData.error}
          userId={user?.id}
          attachFilesToChatMessage={attachFilesToChatMessage}
          chatMessagesUsedInSummary={toJS(chatData.chatMessagesUsedInSummary)}
          removeChatFile={removeChatFile}
          onSubmitMessage={sendAiChatMessage}
          onUseMessageInReport={useMessageInReport}
          chatMessages={toJS(chatData.messages)}
          chatMessage={toJS(chatData.aiResponseMessage)}
          userMessage={toJS(chatData.userMessage)}
          chatStatus={toJS(chatData.status)}
          chatFilesInProcessing={toJS(chatData.chatFilesInProcessing)}
          isChatDisabled={toJS(isChatDisabled)}
          showToProcessInfoBlock={toJS(isSummaryReportToProcess)}
          isSummaryReportProcessing={toJS(isSummaryReportProcessing)}
          isSummaryReportCantBeModified={toJS(
            isSummaryReportCantBeModifiedInChat
          )}
        />
      )}
      {activeTab === 'timeline' && (
        <TimelineWidget>
          <CaseActivity feed={feed} assignees={assignees} />
        </TimelineWidget>
      )}
    </S.Sidebar>
  )
})

import { DateISOString } from '@clain/core/types'

import { ClientRef } from './Client'
import { CaseDataAssignee } from '../apiServices/Case'

export enum CASE_STATUS {
  NEW = 'new',
  INVESTIGATION = 'investigation',
  PENDING = 'pending', // to review
  CLOSED = 'closed',
}

export enum CASE_SEVERITY {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export interface ActivityUser {
  email: string
  name: string
  avatar: string
}

export interface ActivityFile {
  content_type: string
  file: string
  size: number
  name: string
  uploaded_at: string
}

export type ActivityFiles = Array<ActivityFile>

export interface CaseAssignee {
  id: number
  name: string
  avatar: string
}

//////////////////////////////////////////

interface CommentActivity {
  activity_type: 'comment'
  text: string
}

interface EvidenseActivity {
  comment: string
  files: ActivityFiles
}

interface AssignActivity {
  assigned_to: number
  due_date: string
}

interface StatusChangedActivity {
  comment: string
  status_from: string
  status_to: string
}

interface StatusClosedActivity {
  comment: string
  closed_as: 'true' | 'false'
  status_from: string
  status_to: string
}

interface DueDateChangedActivity {
  due_date: string
}

interface RuleUpdateActivity {
  text: string
}

interface StartCaseActivity {
  tag: string
}

interface ClientBlockActivity {
  comment: string
}

interface LabelUpdateActivity {
  new: string
  old: string
}

//////////////////////////////////////////

//  0:comment
//  1:start_investigation
//  2:case_updated_by_incident
//  3:case_moved_to_pending
//  4:case_closed
//  5:re_opened
//  6:due_date
//  7:attach
//  8:reassigned
//  9:start_case
// 10:update_tag

export enum FEED_ACTIVITY_TYPE {
  COMMENT_WITHOUT_ATTACH = 0,
  ASSIGN = 1,
  RULE_UPDATE = 2,
  TO_PENDING = 3,
  TO_CLOSED = 4,
  TO_OPEN = 5,
  DUE_DATE = 6,
  COMMENT_WITH_ATTACH = 7,
  REASSIGNED = 8,
  START_CASE = 9,
  LABEL_UPDATE = 10,
  CLIENT_BLOCK = 11,
}

//////////////////////////////////////////

export interface FeedCommentActivity {
  user: ActivityUser
  inserted_at: string
  activity: CommentActivity
  type: FEED_ACTIVITY_TYPE.COMMENT_WITHOUT_ATTACH
}

export interface FeedEvidenseActivity {
  user: ActivityUser
  inserted_at: string
  activity: EvidenseActivity
  type: FEED_ACTIVITY_TYPE.COMMENT_WITH_ATTACH
}

export interface FeedAssignActivity {
  user: ActivityUser
  inserted_at: string
  activity: AssignActivity
  type: FEED_ACTIVITY_TYPE.ASSIGN
}

export interface FeedReassignedActivity {
  user: ActivityUser
  inserted_at: string
  activity: AssignActivity
  type: FEED_ACTIVITY_TYPE.REASSIGNED
}

export interface FeedStatusChangedActivity {
  user: ActivityUser
  inserted_at: string
  activity: StatusChangedActivity
  type: FEED_ACTIVITY_TYPE.TO_PENDING | FEED_ACTIVITY_TYPE.TO_OPEN
}

export interface FeedStatusClosedActivity {
  user: ActivityUser
  inserted_at: string
  activity: StatusClosedActivity
  type: FEED_ACTIVITY_TYPE.TO_CLOSED
}

export interface FeedDueDateChangedActivity {
  user: ActivityUser
  inserted_at: string
  activity: DueDateChangedActivity
  type: FEED_ACTIVITY_TYPE.DUE_DATE
}

export interface FeedRuleUpdateActivity {
  inserted_at: string
  activity: RuleUpdateActivity
  type: FEED_ACTIVITY_TYPE.RULE_UPDATE
}

export interface FeedStartCaseActivity {
  user: ActivityUser
  inserted_at: string
  activity: StartCaseActivity
  type: FEED_ACTIVITY_TYPE.START_CASE
}

export interface FeedClientBlockActivity {
  user: ActivityUser
  inserted_at: string
  activity: ClientBlockActivity
  type: FEED_ACTIVITY_TYPE.CLIENT_BLOCK
}

export interface FeedLabelUpdateActivity {
  user: ActivityUser
  inserted_at: string
  activity: LabelUpdateActivity
  type: FEED_ACTIVITY_TYPE.LABEL_UPDATE
}

//////////////////////////////////////////

export type FeedActivity =
  | FeedCommentActivity
  | FeedEvidenseActivity
  | FeedAssignActivity
  | FeedReassignedActivity
  | FeedStatusChangedActivity
  | FeedStatusClosedActivity
  | FeedRuleUpdateActivity
  | FeedDueDateChangedActivity
  | FeedStartCaseActivity
  | FeedClientBlockActivity
  | FeedLabelUpdateActivity

export type Feed = Array<FeedActivity>

//////////////////////////////////////////

// TODO: blocked не должен быть в ClientRef?
interface CaseClient extends ClientRef {
  blocked: boolean
}

export interface CasesDataItem {
  assignee: CaseDataAssignee | null
  client: CaseClient
  created_at: DateISOString
  due_date: DateISOString | null
  id: number
  // TODO: severity to string
  severity: CASE_SEVERITY
  status: CASE_STATUS
  tag: string
  updated_at: DateISOString

  // TODO: ждем этого в апи
  recommend_close_as?: boolean
  closed_as?: boolean
}

// /api/cases

export interface CaseCounters {
  closed: number
  investigation: number
  my: number
  new: number
  pending: number
  overdue: number
}

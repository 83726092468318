import React from 'react'
import classnames from 'classnames/bind'
import { fromUnixTime } from 'date-fns'

import { useNavigate, generatePath } from '@clain/core/Router/router'
import { useFormatDate } from '../../../hooks'
import { formatMoney } from '@clain/core/utils/format'
import { capitalizeFirstLetter, Icon, Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { TagDeprecated } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import StatusDot from '../../StatusDot'
import { Tooltip } from '@clain/core/ui-kit'
import {
  Table2 as Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  createColumns,
  HandleChangeSort,
} from '@clain/core/ui-kit'
import { observer } from 'mobx-react-lite'
import { IncidentsDataItem } from '../../../types/Incident'
import IncidentStatusTag from '../../IncidentStatusTag'
import TransactionHash from '../../TransactionHash'
import SeverityTag from '../../SeverityTag'
import { IncidentActions } from '../../IncidentActions'

import styles from './index.scss'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'

const cx = classnames.bind(styles)

const DEFAULT_PAGINATION_SIZE = 10

const currencyToBlockchain = {
  eth: 'Ethereum',
  btc: 'Bitcoin',
}

interface IncidentTableProps {
  items: Array<IncidentsDataItem>
  sortBy?: string
  order?: 'asc' | 'desc'
  showActions?: boolean
  showStatuses?: boolean
  loading: boolean
  showClientId?: boolean
  onChangeSort?: HandleChangeSort
  onIgnoreSuccess?: () => void
}

const IncidentTable = ({
  items,
  sortBy,
  order,
  showActions = true,
  loading = false,
  showClientId = true,
  onChangeSort,
  onIgnoreSuccess = () => {},
  showStatuses = false,
}: IncidentTableProps) => {
  const nav = useNavigate()

  const formatDate = useFormatDate()

  const { columns, columnsData } = createColumns<IncidentsDataItem>(
    items,
    [
      {
        title: 'id',
        field: 'id',
        sortable: false,
        //TODO remove default {} when api response will be updated
        render: ({ incident: { id, status } = {} }) => {
          return (
            <RowDeprecated gap={0.5}>
              {showStatuses ? <IncidentStatusTag value={status} /> : null}
              <Link to="/incidents/:id" params={{ id }}>
                {id}
              </Link>
            </RowDeprecated>
          )
        },
      },

      {
        title: 'created',
        field: 'date',
        type: 'number',
        sortable: true,
        render: ({ incident: { inserted_at } = {} }) => {
          if (!inserted_at) return null

          const date = formatDate(new Date(inserted_at), 'date')
          const time = formatDate(new Date(inserted_at), 'time')

          return (
            <ColDeprecated gap={0}>
              <Typography className={cx('nowrap')}>{date}</Typography>
              <Typography variant="caption1" color="grey3">
                {time}
              </Typography>
            </ColDeprecated>
          )
        },
      },

      {
        title: 'severity',
        field: 'severity',
        sortable: false,
        type: 'label',
        render: ({ rule: { severity } = {} }) => {
          return <SeverityTag value={severity}>{severity}</SeverityTag>
        },
      },

      {
        title: 'rule/trigger',
        field: 'rule_trigger',
        sortable: false,
        render: ({ rule: { name, type } = {} }) => {
          return (
            <ColDeprecated gap={0}>
              <Typography transform="capitalize">{type}</Typography>
              <Typography variant="caption1" color="grey3">
                {name}
              </Typography>
            </ColDeprecated>
          )
        },
      },

      {
        title: 'amount/risk',
        field: 'amount_usd',
        sortable: true,
        type: 'number',
        render: ({ rule, transaction }) => {
          const amount = transaction.amount_usd
            ? formatMoney({
                value: transaction.amount_usd,
                currency: 'usd',
                code: '',
              })
            : ''

          let riskAmount = rule.usd_amount
            ? formatMoney({
                precision: 0,
                value: rule.usd_amount,
                currency: 'usd',
                code: '',
              })
            : null

          riskAmount = riskAmount ? `≥ ${riskAmount}` : 'Any'

          return (
            <ColDeprecated gap={0}>
              <Typography>{amount}</Typography>
              <Typography variant="caption1" color="grey3">
                {riskAmount}
              </Typography>
            </ColDeprecated>
          )
        },
      },

      {
        title: 'transaction',
        sortable: false,
        field: 'trx',
        render: ({ transaction }) => {
          const dir = transaction.is_deposit ? 'IN' : 'OUT'

          return (
            <RowDeprecated gap={0.5}>
              <Tooltip
                content={
                  transaction?.token?.name ||
                  currencyToBlockchain[transaction?.currency]
                }
              >
                <span>
                  <Icon variant={capitalizeFirstLetter(transaction.currency)} />
                </span>
              </Tooltip>
              <Link
                to="%PLATFORM_URL%/:coin/explorer/transaction/:id"
                params={{ coin: transaction.currency, id: transaction.hash }}
                onClick={(event) => event.stopPropagation()}
              >
                <TransactionHash value={transaction.hash} />
              </Link>
              <TagDeprecated>{dir}</TagDeprecated>
            </RowDeprecated>
          )
        },
      },
      {
        title: 'time',
        sortable: true,
        field: 'transaction_time',
        type: 'number',
        render: ({ transaction }) => {
          const transactionTime = transaction.time

          if (!transactionTime) return null

          const date = formatDate(fromUnixTime(transactionTime), 'date')
          const time = formatDate(fromUnixTime(transactionTime), 'time')

          return (
            <ColDeprecated gap={0}>
              <Typography className={cx('nowrap')}>{date}</Typography>
              <Typography variant="caption1" color="grey3">
                {time}
              </Typography>
            </ColDeprecated>
          )
        },
      },

      showClientId && {
        title: 'client',
        field: 'client',
        sortable: false,
        render: ({ client }) => {
          return (
            <RowDeprecated gap={0.5}>
              <StatusDot status={client.blocked ? 'blocked' : 'active'} />
              <Link
                className={cx('ClientIdLink')}
                to="/clients/:id"
                params={{ id: client.id }}
              >
                {client.id}
              </Link>
            </RowDeprecated>
          )
        },
      },

      showActions && {
        title: 'actions',
        field: 'actions',
        sortable: false,
        render: (item) => (
          <IncidentActions
            incident={normalizeSnakeToCamelCase(item)}
            className={cx('Actions')}
            onSubmitSuccess={onIgnoreSuccess}
          />
        ),
        type: 'label',
      },
    ],
    {
      stubHeight: 75,
      stubAmount: DEFAULT_PAGINATION_SIZE,
    }
  )

  return (
    <>
      <Table loading={loading}>
        <Thead>
          <Tr>
            {columns.map(({ title, field, sortable, type }) => (
              <Th
                key={field}
                className={cx(field)}
                sortable={sortable}
                order={order as 'desc' | 'asc'}
                name={field}
                sortBy={sortBy}
                onChangeSort={onChangeSort}
                type={type}
              >
                {title}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {columnsData.map((item, index) => {
            const navigate = (e) => {
              if (
                e.target?.tagName === 'A' ||
                window.getSelection().type === 'Range'
              ) {
                return
              }

              const { target, currentTarget } = e

              if (currentTarget.contains(target)) {
                nav(generatePath('/incidents/:id', { id: item.incident.id }))
              }
            }

            return (
              <Tr key={index} className={cx('ClickableRow')} onClick={navigate}>
                {columns.map(({ field, render, type }) => (
                  <Td key={field} className={cx('col', field)} type={type}>
                    {render(item)}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </>
  )
}

export default observer(IncidentTable)

import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const Profile = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0()

  return (
    <div>
      <button onClick={() => getAccessTokenSilently()}>
        getAccessTokenSilently
      </button>
      {isLoading && <div>Loading ...</div>}
      {isAuthenticated && (
        <div className={'sentry-block sentry-mask'}>
          <img src={user.picture} alt={user.name} />
          <h2>{user.name}</h2>
          <p>{user.email}</p>
        </div>
      )}
    </div>
  )
}

export default Profile

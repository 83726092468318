import React from 'react'
import { compareDesc, startOfDay } from 'date-fns'
import { useFormatDate } from '../../../hooks'
import {
  CaseAssignee,
  Feed,
  FeedActivity,
  FEED_ACTIVITY_TYPE,
} from '../../../types/Case'
import CaseCommentCard from './CaseCommentCard'
import CaseAssigneeActivityCard from './CaseActivityCard/CaseAssigneeActivityCard'
import CaseStageActivityCard from './CaseActivityCard/CaseStageActivityCard'
import CaseCloseActivityCard from './CaseActivityCard/CaseCloseActivityCard'
import CaseDueDateActivityCard from './CaseActivityCard/CaseDueDateActivityCard'
import CaseRuleUpdateActivityCard from './CaseActivityCard/CaseRuleUpdateActivityCard'
import CaseStartActivityCard from './CaseActivityCard/CaseStartActivityCard'
import CaseClientBlockActivityCard from './CaseActivityCard/CaseClientBlockActivityCard'
import CaseLabelUpdateActivityCard from './CaseActivityCard/CaseLabelUpdateActivityCard'
import { Stack, TypographyNew } from '@clainio/web-platform'
import {
  SnakeToCamelCaseArray,
  SnakeToCamelCaseMapping,
} from '@clain/core/utilsTypes'
import { CasesAssigneesDataResponse } from '../../../apiServices/Case'
import { observer } from 'mobx-react-lite'
interface ActivityCardProps {
  activity: SnakeToCamelCaseMapping<FeedActivity>
  assignees: SnakeToCamelCaseArray<CaseAssignee>
}

const ActivityCard: React.FC<ActivityCardProps> = ({ activity, assignees }) => {
  const idToUserName = React.useMemo(() => {
    return assignees.reduce((accum, { id, name }) => {
      return { ...accum, [id]: name }
    }, {})
  }, [assignees])

  if (
    activity.type === FEED_ACTIVITY_TYPE.COMMENT_WITHOUT_ATTACH ||
    activity.type === FEED_ACTIVITY_TYPE.COMMENT_WITH_ATTACH
  ) {
    return <CaseCommentCard activity={activity} />
  }

  if (
    activity.type === FEED_ACTIVITY_TYPE.ASSIGN ||
    activity.type === FEED_ACTIVITY_TYPE.REASSIGNED
  ) {
    return (
      <CaseAssigneeActivityCard
        activity={activity}
        userName={idToUserName[activity.activity.assignedTo]}
      />
    )
  }

  if (
    activity.type === FEED_ACTIVITY_TYPE.TO_PENDING ||
    activity.type === FEED_ACTIVITY_TYPE.TO_OPEN
  ) {
    return <CaseStageActivityCard activity={activity} />
  }

  if (activity.type === FEED_ACTIVITY_TYPE.TO_CLOSED) {
    return <CaseCloseActivityCard activity={activity} />
  }

  if (activity.type === FEED_ACTIVITY_TYPE.DUE_DATE) {
    return <CaseDueDateActivityCard activity={activity} />
  }

  if (activity.type === FEED_ACTIVITY_TYPE.RULE_UPDATE) {
    return <CaseRuleUpdateActivityCard activity={activity} />
  }

  if (activity.type === FEED_ACTIVITY_TYPE.START_CASE) {
    return <CaseStartActivityCard activity={activity} />
  }

  if (activity.type === FEED_ACTIVITY_TYPE.CLIENT_BLOCK) {
    return <CaseClientBlockActivityCard activity={activity} />
  }

  if (activity.type === FEED_ACTIVITY_TYPE.LABEL_UPDATE) {
    return <CaseLabelUpdateActivityCard activity={activity} />
  }

  return null
}

interface CaseActivityProps {
  assignees: SnakeToCamelCaseMapping<CasesAssigneesDataResponse>
  feed: SnakeToCamelCaseMapping<Feed>
  isLoading?: boolean
}

const CaseActivity: React.FC<CaseActivityProps> = ({
  feed = [],
  assignees,
  isLoading,
}) => {
  const formatDate = useFormatDate()

  const sortedFeed = React.useMemo(() => {
    const newFeed = [...feed]

    return newFeed.sort(({ insertedAt: a }, { insertedAt: b }) => {
      return compareDesc(new Date(a), new Date(b))
    })
  }, [feed])

  const dateGroupedFeed: (typeof feed)[] = React.useMemo(() => {
    let buffer: typeof feed = []
    let day = 0

    const groupedFeed: (typeof feed)[] = sortedFeed.reduce((result, feed) => {
      const curr = startOfDay(new Date(feed.insertedAt)).getTime()

      if (day === curr) {
        buffer.push(feed)
        day = curr

        return result
      } else {
        if (buffer.length) {
          const newResult = [...result, buffer]
          buffer = [feed]
          day = curr

          return newResult
        } else {
          buffer = [feed]
          day = curr

          return result
        }
      }
    }, [])

    if (buffer.length) {
      groupedFeed.push(buffer)
    }

    return groupedFeed
  }, [sortedFeed])
  return (
    <div>
      {dateGroupedFeed.map((feed, index) => {
        return (
          <Stack
            key={index}
            direction={'column'}
            gap={'xl'}
            padding={['xl', 'none']}
          >
            <TypographyNew
              variant={'body200Normal'}
              color={'onBackgroundVariant2'}
            >
              {formatDate(new Date(feed[0].insertedAt), {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </TypographyNew>
            <Stack direction={'column'} gap={'xl'}>
              {feed.map((activity, index) => (
                <ActivityCard
                  activity={activity}
                  assignees={assignees}
                  key={index}
                />
              ))}
            </Stack>
          </Stack>
        )
      })}
    </div>
  )
}

export default observer(CaseActivity)

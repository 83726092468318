import React from 'react'
import classnames from 'classnames/bind'
import { getConfig } from '@clain/core/useConfig'

import { formatNumberProgressive } from '@clain/core/utils/format'
import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'
import Portlet from '@clain/core/Portlet'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'
import AutoCounter from '../AutoCounter'
import Link from '@clain/core/Link'
import { ColDeprecated } from '@clain/core/ui-kit'
import { PeriodValue } from '../Filters/Period'

import {
  DashboardClientsData,
  DashboardClientsApiParams,
} from '../../../types/Dashboard'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface ClientsBlockedBlockProps {
  className?: string
}

const ClientsBlockedBlock: React.FC<ClientsBlockedBlockProps> = ({
  className,
}) => {
  const [period] = React.useState<PeriodValue>('week')

  const apiParams = React.useMemo(
    (): DashboardClientsApiParams => ({
      // date: translateDate(startOfDay(getDateFromPeriod(period))).toISOString(),
    }),
    [period]
  )

  const { data, isFirstLoading } = useHttp<DashboardClientsData>(
    buildUrl`${getConfig()?.API}/api/dash/clients?${apiParams}`
  )

  return (
    <Portlet variant="card" className={className}>
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Clients blocked
          </Typography>
        ),
        // toolbar: (
        //   <Period value={period} onChange={setPeriod} />
        // ),
        body: isFirstLoading ? (
          <Stub className={cx('ClientsBlockedPortletStub')} isActive={true} />
        ) : (
          <Container className={cx('CounterContainer')} gap={2}>
            <ColDeprecated className={cx('Counter')}>
              <AutoCounter
                value={data?.Blocked ?? 0}
                minFontSize={32}
                maxFontSize={48}
                format={formatNumberProgressive}
              />
              <Link to="/clients?status=blocked">see clients</Link>
            </ColDeprecated>
          </Container>
        ),
      }}
    </Portlet>
  )
}

export default ClientsBlockedBlock

import { AlertCaution, Spinner } from '@clainio/web-platform'
import React from 'react'

export const ReportToProcessInfoBlock = () => (
  <AlertCaution
    isMarkdown
    variant={'info'}
    leftSlotProps={{
      children: <Spinner color={'grey2'} size={'xxs'} />,
      align: 'start',
    }}
  >
    Messaging is paused while your report is being generated in the **Report
    Tab**.
  </AlertCaution>
)

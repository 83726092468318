import React from 'react'
import classnames from 'classnames/bind'

import { HeaderSlot } from '@clain/core/Layout/slots'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { CheckboxSwitch } from '@clain/core/ui-kit'
import Form, { Field, FormActionsVertical } from '@clain/core/Form'
import { notify } from '@clain/core/ui-kit'
import http from '@clain/core/http'

import { SettingsApiData } from '../../../types/Settings'
import SystemTimeDropdown from './SystemTimeDropdown'
import SettingsHeader from '../SettingsHeader'

import styles from './index.scss'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'
import { useSettings } from '../../../hooks'
import { useTimezones } from '@clain/core/hooks'

const cx = classnames.bind(styles)

const changesAplliedNotification = (
  <>
    Changes in the <b>Global Settings</b> section applied.
  </>
)

const GlobalSettings: React.FC = () => {
  const { settings, loadAndStoreSettingsProfile } = useSettings()
  const timezones = useTimezones()

  const handleSubmit = (data) => {
    const newSettings: typeof settings = {
      ...settings,
      ...data,
      offset: timezones.find((tz) => tz.name === data.timeZone).utcOffset,
    }

    const apiData: Partial<SettingsApiData> = {
      time_zone: newSettings.timeZone,
      offset: newSettings.offset,
      threshold: newSettings.threshold,
      deadline: newSettings.deadline,
      block_clients: newSettings.blockClients,
    }

    http
      .post(buildUrl`${getConfig()?.API}/api/settings/update`, apiData)
      .then(async () => {
        await loadAndStoreSettingsProfile()
        notify(changesAplliedNotification)
      })
  }

  HeaderSlot.useContent(() => <SettingsHeader />)

  const initialValues = {
    timeZone: settings?.timeZone,
    threshold: settings?.threshold,
    deadline: settings?.deadline,
    blockClients: settings?.blockClients,
  }

  return (
    <Form
      onSubmit={handleSubmit}
      className={cx('Form')}
      initialValues={initialValues}
    >
      {() => {
        return (
          <MagicGrid gap={2.5}>
            <Typography variant="heading2" color="black">
              Global settings
            </Typography>

            <Field
              as={SystemTimeDropdown}
              name="timeZone"
              variant="outline"
              timezones={timezones}
            />

            <MagicGrid>
              <MagicGrid gap={0.5}>
                <Typography variant="subheading2" color="grey2">
                  Default deadline
                </Typography>
                <Typography variant="caption1" color="grey3">
                  Number of days assigned by default for investigation once case
                  is opened
                </Typography>
              </MagicGrid>
              <Label content="Days">
                <Field
                  as={TextField}
                  name="deadline"
                  variant="outline"
                  type="number"
                  step={1}
                  min={1}
                  className={cx('Input', 'InputNumber')}
                />
              </Label>
            </MagicGrid>

            <MagicGrid gap={0.5}>
              <Field
                as={CheckboxSwitch}
                rtl
                name="blockClients"
                label={
                  <Typography variant="subheading2" color="grey2">
                    Allow automated client block
                  </Typography>
                }
              />
              <Typography variant="caption1" color="grey3">
                Global setting. Allowing automated client block if it is
                specified in rules.
              </Typography>
            </MagicGrid>

            <FormActionsVertical>
              <Button type="submit" variant="solid" color="primary" size="lg">
                Save settings
              </Button>
            </FormActionsVertical>
          </MagicGrid>
        )
      }}
    </Form>
  )
}

export default GlobalSettings

import { Channel, WebSocketWrapper } from '../utils/WebSocketWrapper'

const CHANNEL = 'notifications'
const EVENT = 'new_notification'

interface NotificationServiceProps {
  webSocketState: typeof WebSocketWrapper
  id: number
}

export interface IncidentMentionNotification {
  type: 'incident_mention'
  id: number
  user: string
}

export interface CaseMentionNotification {
  type: 'case_mention'
  id: number
  user: string
}

export interface NewIncidentNotification {
  type: 'new_incident'
  id: number
  severity: 'LOW' | 'MEDIUM' | 'HIGH'
}

export interface NewIncidentsNotification {
  type: 'new_incidents'
  amount: number
  low_amount?: number
  medium_amount?: number
  high_amount?: number
}

export interface CaseOverdueNotification {
  type: 'case_overdue'
  id: number
  due_date: string
}

export interface CaseReassignNotification {
  type: 'case_reassign'
  assigned_from: string | null
  assigned_to: string
  id: number
}

export interface NewCaseNotification {
  type: 'new_case'
  id: number
}

export interface ClientBlockedNotification {
  type: 'client_blocked'
  client_id: number
  id: number
}

export type Notification =
  | IncidentMentionNotification
  | CaseMentionNotification
  | NewIncidentNotification
  | NewIncidentsNotification
  | CaseOverdueNotification
  | CaseReassignNotification
  | NewCaseNotification
  | ClientBlockedNotification

export class NotificationService {
  private channel: Channel

  constructor({ webSocketState, id }: NotificationServiceProps) {
    this.channel = webSocketState.channel(`${CHANNEL}:${id}`)
    this.channel.join()
  }

  public subscribe(cb: (notification: Notification) => unknown): number {
    return this.channel.subscribe(EVENT, cb)
  }

  public unsubscribe(ref: number): void {
    this.channel.unsubscribe(EVENT, ref)
  }
}

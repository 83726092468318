import React from 'react'
import classnames from 'classnames/bind'

import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ReactComponent as TrashIcon } from '@clain/core/assets/trash.svg'

import RuleOptionsList from './RuleOptionsList'
import { Rule } from '../../../types/Rule'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface RemoveRuleConfirmationButtonProps {
  rule: Rule
  submit: () => void
  className?: string
}

const RemoveRuleConfirmationButton: React.FC<
  RemoveRuleConfirmationButtonProps
> = ({ rule, submit, className }) => {
  const target: ModalSlot = ({ show }) => {
    return (
      <TrashIcon className={cx('RemoveButton', className)} onClick={show} />
    )
  }

  const body: ModalSlot = ({ hide }) => (
    <Container gap={0.5}>
      <MagicGrid gap={2}>
        <Typography variant="heading5" color="black">
          Delete rule
        </Typography>
        <MagicGrid>
          <Typography variant="body1" color="black">
            Following rule will be removed to Archieved rules. You will be able
            to activate it again upon necessity
          </Typography>
          <RuleOptionsList
            options={{
              type: rule.type,
              category: rule.type === 'category' && rule.name,
              riskAmount: rule.usd_amount && String(rule.usd_amount),
              direction: rule.direction,
              identityId: rule.identity_id,
              severity: rule.severity && String(rule.severity),
              percentage: rule.percentage,
              scoreAmount: rule.score_amount && String(rule.score_amount),
              autoBlock: false, // waiting for API
              retrospectivelyApply: false, // waiting for API
              retrospectivelyBlock: false, // waiting for API
            }}
          />
          <RowDeprecated className={cx('RuleConfirmationButtons')}>
            <Button
              variant="outline"
              color="secondary"
              size="lg"
              onClick={hide}
            >
              cancel
            </Button>
            <Button
              variant="solid"
              color="primary"
              size="lg"
              type="submit"
              onClick={submit}
            >
              delete
            </Button>
          </RowDeprecated>
        </MagicGrid>
      </MagicGrid>
    </Container>
  )

  return (
    <Modal target={target} closable>
      {body}
    </Modal>
  )
}

export default RemoveRuleConfirmationButton

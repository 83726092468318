import { injectable } from 'inversify'
import { IncidentCaseSocketService } from '@compliance/apiServices/shared/IncidentCaseSocketService'
import {
  CaseChatSocketChannelSubscriptions,
  CaseSocketDataResponse,
  ICaseSocketService,
} from './CaseService.types'

@injectable()
export class CaseSocketService
  extends IncidentCaseSocketService<
    CaseSocketDataResponse,
    CaseChatSocketChannelSubscriptions
  >
  implements ICaseSocketService
{
  constructor() {
    super('case', [
      'stream',
      'user_message',
      'thread_messages',
      'summary',
      'case_status',
      'message_used_in_summary',
    ])
  }

  public subscribeToStreamSocketChannel = (
    callback: (res: CaseChatSocketChannelSubscriptions['stream']) => void
  ) => {
    this.subscribeToSocketChannel('stream', callback)
  }

  public subscribeToUserMessageSocketChannel = (
    callback: (res: CaseChatSocketChannelSubscriptions['user_message']) => void
  ) => {
    this.subscribeToSocketChannel('user_message', callback)
  }

  public subscribeToThreadMessageSocketChannel = (
    callback: (
      res: CaseChatSocketChannelSubscriptions['thread_messages']
    ) => void
  ) => {
    this.subscribeToSocketChannel('thread_messages', callback)
  }

  public subscribeToMessageUsedInSummarySocketChannel = (
    callback: (
      res: CaseChatSocketChannelSubscriptions['message_used_in_summary']
    ) => void
  ) => {
    this.subscribeToSocketChannel('message_used_in_summary', callback)
  }

  public subscribeToSummarySocketChannel = (
    callback: (res: CaseChatSocketChannelSubscriptions['summary']) => void
  ) => {
    this.subscribeToSocketChannel('summary', callback)
  }

  public subscribeToCaseStatusSocketChannel = (
    callback: (res: CaseChatSocketChannelSubscriptions['case_status']) => void
  ) => {
    this.subscribeToSocketChannel('case_status', callback)
  }
}

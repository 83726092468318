import { Group } from 'pts'
import { IController, IView } from '../../core/types'

const INDICATOR_WIDTH = 1
const INDICATOR_COLOR = '#BDC8DF'

class StageIndicator implements IView {
  public ctrl: IController

  get form() {
    return this.ctrl.form
  }

  get space() {
    return this.ctrl.space
  }

  public layout: Group

  constructor(ctrl: IController) {
    this.ctrl = ctrl
  }

  public init(from: [number, number], to: [number, number]) {
    this.layout = Group.fromArray([from, to])
  }

  public render() {
    this.form.strokeOnly(INDICATOR_COLOR, INDICATOR_WIDTH).line(this.layout)
  }
}

export default StageIndicator

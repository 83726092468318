import styled, { css } from 'styled-components'
import { createTokenKey, selectToken, Stack } from '@clainio/web-platform'
import { HEADER_HEIGHT } from '@clain/core/Layout/Header/Header.styles'

const SIDEBAR_WIDTH = 504

export const PageContainer = styled.div<{ $top?: number }>`
  position: absolute;
  right: 0;
  left: 0;

  ${({ theme, $top = 0 }) => {
    return css({
      top: $top === 0 ? 0 : $top - HEADER_HEIGHT,
      bottom: `-${selectToken(theme, createTokenKey(['padding', 'xl']))}`,
    })
  }}
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr ${SIDEBAR_WIDTH}px;
  height: 100%;
  ${({ theme }) => {
    return css({
      gap: selectToken(theme, createTokenKey(['gap', 'sm'])),
    })
  }}
`

export const MainContent = styled(Stack)`
  overflow-x: hidden;
  overflow-y: auto;
  ${({ theme }) => {
    return css({
      paddingRight: selectToken(theme, createTokenKey(['padding', 'xxxxl'])),
      paddingLeft: selectToken(theme, createTokenKey(['padding', 'xxxxl'])),
      paddingBottom: selectToken(theme, createTokenKey(['padding', 'xl'])),
    })
  }}
    /* Hide scrollbar by default */
  &::-webkit-scrollbar {
    width: 7px;
    background: transparent;
  }

  /* Show scrollbar on hover */
  &:hover {
    &::-webkit-scrollbar {
      width: 7px;
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
`

export const Sidebar = styled.aside`
  display: flex;
  flex-flow: column;
  overflow: hidden;
  position: relative;

  ${({ theme }) => {
    return css({
      paddingRight: selectToken(theme, createTokenKey(['padding', 'xxxxl'])),
    })
  }}
`

export const Section = styled.div`
  flex: 1;
`

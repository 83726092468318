import { inject, injectable } from 'inversify'
import { IncidentViewModel } from '@compliance/modules/IncidentViewModel'
import { makeAutoObservable } from 'mobx'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'

@injectable()
export class IncidentViewModelFacade {
  constructor(
    @inject(DY_TYPES.IncidentViewModel)
    private viewModel: IncidentViewModel
  ) {
    makeAutoObservable(this)
  }

  init = (incidentId: number) => {
    return this.viewModel.init(incidentId)
  }

  get incidentActionsController() {
    return this.viewModel.incidentActionsController
  }

  get isInitialized() {
    return this.viewModel.store.isInitialized
  }

  get incidentData() {
    return this.viewModel.store.incidentData
  }

  get aiSummary() {
    return this.viewModel.store.chatDataStore.data.aiSummary
  }

  get aiSummaryStatus() {
    return this.viewModel.store.chatDataStore.data.aiSummaryStatus
  }

  get isSummaryReportCantBeModified() {
    return (
      this.viewModel.store.incidentData?.incident?.status === 'case' ||
      this.isSummaryReportToProcess ||
      this.isSummaryReportNew
    )
  }

  get isSummaryReportCantBeModifiedInChat() {
    return this.isSummaryReportCantBeModified || this.isSummaryReportProcessing
  }

  get isChatDisabled() {
    return this.isSummaryReportCantBeModified
  }

  get isSummaryReportProcessing() {
    return this.aiSummaryStatus === 'in_process'
  }

  get isSummaryReportNew() {
    return this.aiSummaryStatus === 'new'
  }

  get isSummaryReportToProcess() {
    return this.aiSummaryStatus === 'to_process'
  }

  get chatData() {
    return {
      messages: this.viewModel.store.chatDataStore.data.chatMessages,
      status: this.viewModel.store.chatDataStore.data.chatStatus,
      userMessage: this.viewModel.store.chatDataStore.data.chatUserMessage,
      aiResponseMessage:
        this.viewModel.store.chatDataStore.data.chatSystemMessageResponse,
      chatFilesInProcessing:
        this.viewModel.store.chatDataStore.data.filesInProcessing,
      chatMessagesUsedInSummary:
        this.viewModel.store.chatDataStore.data.chatMessagesUsedInSummary,
      error: this.viewModel.store.chatDataStore.data.error,
    }
  }

  get clientData() {
    return this.viewModel.store.clientData
  }

  get relatedIncidents() {
    return this.viewModel.store.relatedIncidents
  }

  get rules() {
    return this.viewModel.store.rules
  }

  get isFirstLoading() {
    return this.viewModel.store.isFirstLoading
  }

  get isClientDataFirstLoading() {
    return this.viewModel.store.isClientDataFirstLoading
  }

  get relatedCases() {
    return this.viewModel.store.relatedCases
  }

  sendAiChatMessage = (message: string, retry = false) => {
    return this.viewModel.sendAiChatMessage({ message })
  }

  retrySendAiChatMessage = () => {
    return this.viewModel.retrySendAiChatMessage()
  }

  regenerateReport = () => {
    return this.viewModel.regenerateReport()
  }

  useMessageInReport = (messageId: string) => {
    return this.viewModel.useMessageInReport({ messageId })
  }

  clear = () => {
    return this.viewModel.clear()
  }
}

import React from 'react'
import { useObjectFormattedSearchParams } from '@clain/core/Router/router'
import classnames from 'classnames/bind'
import download from 'downloadjs'
import { formatDate } from 'date-fns'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { getConfig } from '@clain/core/useConfig'

import { ReactComponent as TrxIcon } from '@clain/core/assets/transaction.svg'
import { ReactComponent as CSVIcon } from '@clain/core/assets/csv.svg'

import useHttp from '@clain/core/useHttp'
import type { ScoreRange } from '@clain/core/types/ScoreRange'
import { useTranslateDate } from '../../hooks'
import { Typography } from '@clain/core/ui-kit'
import buildUrl, { clearParams } from '@clain/core/utils/buildUrl'
import evolve from '@clain/core/utils/evolve'

import Header from '@clain/core/Layout/Header'
import Portlet from '@clain/core/Portlet'
import { RowDeprecated } from '@clain/core/ui-kit'
import { TabPropsOption, Tabs } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import Pagination from '@clain/core/Pagination'
import PaginationSize from '../PaginationSize'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { useDebounceValue } from '@clain/core/useDebounce'
import http from '@clain/core/http'
import { observer } from 'mobx-react-lite'
import {
  TransactionsData,
  TransactionsApiParams,
} from '../../types/Transaction'
import Filters, { FiltersState } from './Filters'
import { Amount, AssetAmount } from './Filters/types'
import TransactionTable from './Table'
import VoidText from '../VoidText'

import styles from './index.scss'
import { CoinType } from '../../types/coin'

const cx = classnames.bind(styles)

const DEFAULT_PAGINATION_SIZE = 10
const DEBOUNCE_IN_MS = 500

const tabStub = {
  both: 'No transfers yet.',
  in: 'No incoming transfers.',
  out: 'No outgoing transfers.',
}

type DirectionVariant = 'both' | 'in' | 'out'

const directionOptions: TabPropsOption<DirectionVariant>[] = [
  {
    value: 'both',
    children: 'all',
  },
  {
    value: 'in',
    children: 'in',
  },
  {
    value: 'out',
    children: 'out',
  },
]

const defaultFiltersState: FiltersState = {
  selected: [],
  created: [],
  search: null,
  amountUsd: {},
  assetAmount: {},
  cpScore: [1, 10],
}

const unpackQuery = evolve({
  created: (created) => created.map((c) => new Date(c)),
  cpScore: (cpScore) =>
    !cpScore ? [1, 10] : cpScore.map((score) => Number(score)),
})

const compactQuery = evolve({
  created: (created) => created.map((c) => c && String(c)),
  // не добавляем в кверю стандартные значения фильтров
  cpScore: (cpScore) =>
    isEqual(cpScore, defaultFiltersState.cpScore) ? [] : cpScore,
  page: (page) => (page !== 1 ? page : undefined),
})

type TransactionsQuery = {
  page: string
  sortBy: string
  order: 'desc' | 'asc'
  direction: DirectionVariant
  selected: Array<string>
  created: [] | [string, string]
  search: string
  amountUsd: Amount
  assetAmount: AssetAmount
  cpScore: [string, string]
}

type TransactionsUnpackedQuery = TransactionsQuery & {
  created: [] | [Date, Date]
  cpScore: ScoreRange
}

const Transactions = ({
  title,
  clientId,
}: {
  title?: string
  clientId?: string | number
}) => {
  const translateDate = useTranslateDate()

  const [query, setQuery] = useObjectFormattedSearchParams<TransactionsQuery>()
  const uppackedQuery = React.useMemo(
    () => unpackQuery(query),
    [query]
  ) as TransactionsUnpackedQuery

  const [page, setPage] = React.useState(Number(uppackedQuery.page) || 1)
  const [sortBy, setSortBy] = React.useState(uppackedQuery.sortBy || '')
  const [order, setOrder] = React.useState<'asc' | 'desc'>(uppackedQuery.order)
  const [direction, setDirection] = React.useState<DirectionVariant>(
    uppackedQuery.direction || 'both'
  )
  const [filters, setFilters] = React.useState<FiltersState>({
    ...defaultFiltersState,
    selected: uppackedQuery.selected || defaultFiltersState.selected,
    created: uppackedQuery.created || defaultFiltersState.created,
    search: uppackedQuery.search || defaultFiltersState.search,
    amountUsd: uppackedQuery.amountUsd || defaultFiltersState.amountUsd,
    assetAmount: uppackedQuery.assetAmount || defaultFiltersState.assetAmount,
    cpScore: uppackedQuery.cpScore || defaultFiltersState.cpScore,
  })

  const handleChangeFilters = (newFilters: FiltersState) => {
    setPage(1)
    setFilters(newFilters)
  }
  const handleChangeDirections = (direction: DirectionVariant) => {
    setPage(1)
    setDirection(direction)
  }
  const handleChangeSort = (sortBy, order) => {
    setSortBy(sortBy)
    setOrder(order)
  }

  const debouncedCpScore = useDebounceValue(filters.cpScore, DEBOUNCE_IN_MS)
  const debouncedAmountUsd = useDebounceValue(filters.amountUsd, DEBOUNCE_IN_MS)
  const debouncedSearch = useDebounceValue(filters.search, DEBOUNCE_IN_MS)
  const debouncedAssetAmount = useDebounceValue(
    filters.assetAmount,
    DEBOUNCE_IN_MS
  )

  const apiParams = React.useMemo(
    (): TransactionsApiParams => ({
      page: String(page),
      sort_by: sortBy,
      sort_order: order,
      client_id: clientId,
      direction: direction === 'both' ? undefined : direction,
      from: filters.created?.[0]
        ? translateDate(filters.created?.[0]).toISOString()
        : undefined,
      to: filters.created?.[1]
        ? translateDate(filters.created?.[1]).toISOString()
        : undefined,
      score_from: debouncedCpScore?.[0] || undefined,
      score_to: debouncedCpScore?.[1] || undefined,
      amount_usd_from: debouncedAmountUsd?.from,
      amount_usd_to: debouncedAmountUsd?.to,
      hash: debouncedSearch,
      blockchain: debouncedAssetAmount?.blockchain?.toLowerCase() as CoinType,
      asset_id: debouncedAssetAmount?.asset_id,
      amount_from: debouncedAssetAmount?.from,
      amount_to: debouncedAssetAmount?.to,
    }),
    [
      page,
      sortBy,
      order,
      clientId,
      direction,
      filters?.created,
      translateDate,
      debouncedCpScore,
      debouncedAmountUsd?.from,
      debouncedAmountUsd?.to,
      debouncedSearch,
      debouncedAssetAmount?.blockchain,
      debouncedAssetAmount?.asset_id,
      debouncedAssetAmount?.from,
      debouncedAssetAmount?.to,
    ]
  )

  React.useEffect(() => {
    const debouncedUpdateQuery = debounce(() => {
      setQuery(
        compactQuery(
          clearParams({
            ...filters,
            page,
            sortBy,
            order,
            direction,
          })
        ) as TransactionsQuery
      )
    }, DEBOUNCE_IN_MS)
    debouncedUpdateQuery()
    return () => {
      debouncedUpdateQuery.cancel()
    }
  }, [filters, page, sortBy, order, direction, setQuery])

  const { data, isLoading } = useHttp<TransactionsData>(
    buildUrl`${getConfig()?.API}/api/transactions?${apiParams}`
  )

  const withFilters = React.useMemo(() => {
    return Boolean(
      filters.created?.length ||
        debouncedCpScore ||
        debouncedAmountUsd ||
        debouncedSearch ||
        debouncedAssetAmount
    )
  }, [
    filters,
    debouncedCpScore,
    debouncedAmountUsd,
    debouncedSearch,
    debouncedAssetAmount,
  ])

  const downloadTransactions = () => {
    http
      .get(
        buildUrl`${getConfig()?.API}/api/transactions/csv?${{
          ...apiParams,
          page: undefined,
        }}`
      )
      .then((data) => {
        if (data?.data) {
          download(
            data.data,
            `transactions - ${formatDate(new Date(), 'yyyy-MM-dd-HH-mm')}.csv`,
            'text/csv'
          )
        }
      })
  }

  const [paginationSize, setPaginationSize] = React.useState(0)

  React.useEffect(() => {
    if (data?.total_entries) {
      setPaginationSize(
        DEFAULT_PAGINATION_SIZE < data.total_entries
          ? DEFAULT_PAGINATION_SIZE
          : data.total_entries
      )
    }
  }, [data])

  HeaderSlot.useContent(() => (
    <Header
      title="Transfers"
      icon={<TrxIcon />}
      actions={
        <RowDeprecated
          className={cx('ExportAction')}
          onClick={downloadTransactions}
        >
          <Typography>Export data</Typography>
          <Typography color="grey3">
            <CSVIcon />
          </Typography>
        </RowDeprecated>
      }
    />
  ))

  return (
    <MagicGrid gap={2}>
      <Filters
        defaultFiltersState={defaultFiltersState}
        initialValues={filters}
        onChange={handleChangeFilters}
      />
      <div>
        <div className={cx('TableHead')}>
          <Tabs
            className={cx('DirectionTabs')}
            options={directionOptions}
            value={direction}
            onChange={handleChangeDirections}
          />
          <PaginationSize
            total={data?.total_entries}
            value={String(paginationSize)}
            onChange={setPaginationSize}
            disabled
          />
        </div>
        <Portlet variant="card">
          {{
            title,
            body: (
              <TransactionTable
                sortable
                showClientId={!clientId}
                loading={isLoading}
                items={data?.data}
                order={order}
                sortBy={sortBy}
                onChangeSort={handleChangeSort}
              />
            ),
          }}
        </Portlet>
        {data?.data.length === 0 && !isLoading && (
          <VoidText>
            {tabStub[direction]}
            <br />
            {withFilters &&
              'Try to change filters and/or time range restrictions'}
          </VoidText>
        )}
        <Pagination
          className={cx('Pagination')}
          totalPages={data?.total_pages}
          value={page}
          onChange={setPage}
        />
      </div>
    </MagicGrid>
  )
}

export default observer(Transactions)
export { TransactionTable }

import { inject, injectable } from 'inversify'
import { IReactionDisposer, reaction } from 'mobx'
import {
  IIncidentService,
  IClientService,
  ICaseService,
} from '@compliance/apiServices'
import * as Sentry from '@sentry/react'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { REJECT_REASON } from '@clain/core/utils/WebSocket'
import {
  IIncidentActionsController,
  IncidentActionsController,
} from '@compliance/components/IncidentActions/IncidentActionsController'
import { Notification } from '@clain/core/ui-kit'
import { notificationToastOptions } from '@compliance/utils/Notifications'
import { settingsController } from '@compliance/controllers/SettingsController'
import {
  convertToUnixTimestamp,
  getCurrentTimezone,
} from '@clain/core/utils/date'
import { convertFileToChatFile } from '@compliance/utils/convertFileToChatFile'
import { IncidentStore } from '@compliance/modules/IncidentStore'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'
import { prop, uniqBy } from 'ramda'

@injectable()
export class IncidentViewModel {
  private reactionDisposers: Array<IReactionDisposer> = []
  public incidentActionsController: IIncidentActionsController

  constructor(
    @inject(DY_TYPES.IncidentStore) private incidentStore: IncidentStore,
    @inject(DY_TYPES.IncidentService)
    private incidentAPIService: IIncidentService,
    @inject(DY_TYPES.ClientService)
    private clientAPIService: IClientService,
    @inject(DY_TYPES.CaseService) private caseAPIService: ICaseService
  ) {
    this.incidentActionsController = new IncidentActionsController({
      ignoreIncidentCallback: this.fetchIncidentData,
      openCaseCallback: this.fetchIncidentData,
    })
  }

  public get store() {
    return this.incidentStore
  }

  public init = async (incidentId: number) => {
    try {
      this.initReactions()
      this.store.apiServicesStateFacade.initApiParamsStateByService('incident')(
        {
          id: incidentId,
        }
      )
      this.store.commonData.initState({
        incidentId,
      })
      this.fetchIncidentData()
      await this.initSocketChannel(incidentId)
      this.store.setInitialized(true)
    } catch (error) {
      this.handleError(error, 'Failed to initialize incident page')
    }
  }

  private showErrorNotification = (error: string) => {
    Notification.notify(error, { type: 'warning' }, notificationToastOptions)
  }

  public sendAiChatMessage = async ({ message }: { message: string }) => {
    try {
      const user = settingsController.getUser()
      const createdAt = convertToUnixTimestamp(new Date(), getCurrentTimezone())
      this.store.chatDataStore.actions.updateTemporaryData({
        chatUserMessage: normalizeSnakeToCamelCase({
          role: 'user',
          id: createdAt.toString(),
          used_in_report: false,
          files: this.store.chatDataStore.data?.filePreviews?.map(
            convertFileToChatFile
          ),
          content: message,
          created_at: createdAt,
          user: {
            id: user.id,
            name: user.name,
            avatar: user.avatar,
          },
        }),
      })

      this.incidentAPIService.socket.sendAiChatMessage({
        message,
        fileIds: this.store.chatDataStore.data.fileIds,
      })
    } catch (e) {
      this.handleError(e, 'Failed to send chat message')
    }
  }

  public retrySendAiChatMessage = () => {
    try {
      this.store.chatDataStore.actions.updateTemporaryData({
        error: null,
      })
      this.incidentAPIService.socket.retrySendAiChatMessage()
    } catch (e) {
      this.handleError(e, 'Failed to retry send chat message')
    }
  }

  public regenerateReport = async () => {
    try {
      await this.incidentAPIService.socket.regenerateReport()
    } catch (e) {
      this.showErrorNotification(e?.reason || 'Failed to regenerate report')
      this.handleError(e, 'Failed to regenerate report')
    }
  }

  public useMessageInReport = async ({ messageId }: { messageId: string }) => {
    try {
      await this.incidentAPIService.socket.useMessageInReport({
        messageId,
      })
    } catch (e) {
      this.showErrorNotification(e?.reason || 'Failed to use chat message')
      this.handleError(e, 'Failed to use chat message in report')
    }
  }

  public clear = () => {
    this.reactionDisposers.forEach((disposer) => disposer())
    this.reactionDisposers = []
    this.store.clear()
    this.incidentAPIService.socket.closeSocketChannel()
  }

  private fetchIncidentData = () => {
    this.store.apiServicesStateFacade.injectRequestMethodByService('incident')(
      this.incidentAPIService.rest.getIncidentById
    )
    this.store.apiServicesStateFacade.initDataLoadingReaction('incident')
  }

  private initSocketChannel = async (incidentId: number) => {
    try {
      const aiChatResult =
        await this.incidentAPIService.socket.initializeSocketChannel({
          id: incidentId,
        })

      this.store.chatDataStore.actions.initData(aiChatResult)
      this.subscribeToAIChatMessageStream()
    } catch (error) {
      this.handleError(error, 'Failed to initialize socket channel')
    }
  }

  private subscribeToAIChatMessageStream = () => {
    this.incidentAPIService.socket.subscribeToStreamSocketChannel((res) => {
      if ('data' in res) {
        this.store.chatDataStore.data.chatSystemMessageResponseArray.push(
          res.data
        )
      }
      if ('chat_status' in res) {
        this.store.chatDataStore.actions.updateData({
          chatStatus: res.chat_status,
        })
      }
      if ('error' in res) {
        this.handleError(res?.error, 'Failed to send chat message')
        this.store.chatDataStore.actions.updateTemporaryData({
          error: res.error,
        })
      }
    })

    this.incidentAPIService.socket.subscribeToSummarySocketChannel((res) => {
      if ('error' in res) {
        this.showErrorNotification(res.error)
      } else {
        this.store.chatDataStore.actions.updateData({
          aiSummary: res.summary,
        })
      }
    })

    this.incidentAPIService.socket.subscribeToIncidentStatusSocketChannel(
      (res) => {
        if ('error' in res) {
          this.showErrorNotification(res.error)
        } else {
          this.store.chatDataStore.actions.updateData({
            aiStatus: res.ai_status,
          })
        }
      }
    )

    this.incidentAPIService.socket.subscribeToMessageUsedInSummarySocketChannel(
      (res) => {
        if ('error' in res) {
          this.showErrorNotification(res.error)
        } else {
          this.store.chatDataStore.actions.updateTemporaryData((prevState) => ({
            ...prevState,
            chatMessagesUsedInSummary: [
              ...prevState.chatMessagesUsedInSummary,
              res.message_id,
            ],
          }))
        }
      }
    )

    this.incidentAPIService.socket.subscribeToThreadMessageSocketChannel(
      (res) => {
        if ('error' in res) {
          this.showErrorNotification(res.error)
        } else {
          this.store.chatDataStore.actions.resetTemporaryData()
          this.store.chatDataStore.actions.updateData((prevState) => ({
            ...prevState,
            chatMessages: uniqBy(prop('id'), [
              ...prevState.chatMessages,
              ...normalizeSnakeToCamelCase(res.messages),
            ]),
          }))
        }
      }
    )
  }

  private initReactions = () => {
    this.reactionDisposers.push(
      reaction(
        () => this.store.incidentData?.client?.id,
        (clientId) => {
          if (clientId != null) {
            this.fetchClientData(clientId)
            this.fetchClientCases(clientId)
            this.fetchClientIncidents(clientId)
          }
        }
      )
    )
  }

  private fetchClientData = (clientId: string) => {
    this.store.apiServicesStateFacade.initApiParamsStateByService('client')({
      clientId,
    })
    this.store.apiServicesStateFacade.injectRequestMethodByService('client')(
      this.clientAPIService.getClientById
    )
    this.store.apiServicesStateFacade.initDataLoadingReaction('client')
  }

  private fetchClientCases = (clientId: string) => {
    this.store.apiServicesStateFacade.initApiParamsStateByService('cases')({
      client_id: clientId,
    })
    this.store.apiServicesStateFacade.injectRequestMethodByService('cases')(
      this.caseAPIService.rest.getCases
    )
    this.store.apiServicesStateFacade.initDataLoadingReaction('cases')
  }

  private fetchClientIncidents = (clientId: string) => {
    this.store.apiServicesStateFacade.initApiParamsStateByService('incidents')({
      client_id: clientId,
      status: 'all',
    })
    this.store.apiServicesStateFacade.injectRequestMethodByService('incidents')(
      this.incidentAPIService.rest.getIncidents
    )
    this.store.apiServicesStateFacade.initDataLoadingReaction('incidents')
  }

  private handleError = (error: any, message: string) => {
    if (error?.message === REJECT_REASON.offline) {
      return
    }
    Sentry.captureException(error, { extra: { message } })
    console.error(message, error)
  }
}

import React from 'react'
import * as echarts from 'echarts'

import { ReactComponent as ArrowRightIcon } from '@clain/core/assets/arrow_right_solid.svg'

import { UnixTimestamp } from '@clain/core/types'
import { useFormatDate } from '../../hooks'
import Chart from '@clain/core/Chart2'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import createTooltipFormatter from '@clain/core/Chart2/createTooltipFormatter'
import { getScoreColor } from '@clain/core/utils/getScoreColor'
import { useLeftSidebarState, useRightSidebarState } from '@clain/core/state'
import { observer } from 'mobx-react-lite'
const ScoreTooltip = ({ points, seriesData, formatDate }) => {
  const point = points?.[0]

  if (!point || !point.data) {
    return null
  }

  const [time, score] = point.value

  const currentIndex = point.dataIndex
  const prevScore = currentIndex ? seriesData[currentIndex - 1][1] : 0

  const showPrev = prevScore && prevScore !== score

  return (
    <Container gap={1}>
      <ColDeprecated>
        <RowDeprecated>
          {showPrev ? <Score value={prevScore} /> : null}
          {showPrev ? (
            <Typography color="grey4">
              <ArrowRightIcon />
            </Typography>
          ) : null}
          <Score value={score} />
        </RowDeprecated>
        <Typography color="black">
          {formatDate(new Date(time), 'date')}
        </Typography>
        {/* <ColDeprecated gap={0}>
          <Typography color='black'>
            Withdrawal: <Typography variant='body2'>{amount}</Typography>
          </Typography>
          <Typography color='black'>
            Amount in USD: <Typography variant='body2'>{amountUsd}</Typography>
          </Typography>
        </ColDeprecated> */}
      </ColDeprecated>
    </Container>
  )
}

interface ScoreHistoryChartOptions {
  data: Array<[UnixTimestamp, number]>
}

interface ScoreHistoryConfigOptions {
  formatDate: ReturnType<typeof useFormatDate>
}

// сколько точек изначально показываем на графике
const DEFAULT_NAVIGATOR_POINTS_COUNT = 10

function getOptions({
  data,
  formatDate,
}: ScoreHistoryChartOptions & ScoreHistoryConfigOptions) {
  const dataMin = Math.floor(
    data.reduce((result, [time, score]) => Math.min(result, score), +Infinity)
  )
  const dataMax = Math.ceil(
    data.reduce((result, [time, score]) => Math.max(result, score), 0)
  )

  const possibleMin = dataMin - 1
  const possibleMax = dataMax + 1

  const showOnlyPartOfData = data.length >= DEFAULT_NAVIGATOR_POINTS_COUNT

  const startValue = showOnlyPartOfData
    ? data[data.length - DEFAULT_NAVIGATOR_POINTS_COUNT][0]
    : data[0]?.[0]
  const endValue = showOnlyPartOfData
    ? data[data.length - 1][0]
    : data[data.length]?.[0]

  const options = {
    tooltip: {
      trigger: 'axis',
      // appendToBody: true,
      padding: 0,
      showDelay: 0.001,
      hideDelay: 0,
      borderRadius: 8,
      transitionDuration: 0,
      // TODO: https://github.com/apache/echarts/blob/master/src/component/tooltip/TooltipView.ts#L777
      formatter: createTooltipFormatter(
        (points) => ({ points, seriesData: data }),
        ScoreTooltip,
        { formatDate }
      ),
      backgroundColor: 'rgba(255, 255, 255, 1)',
      extraCssText:
        'box-shadow: 0px 4px 40px rgba(0, 17, 158, 0.25); width: 300px; z-index: 0; width: 220px',
      appendToBody: true,
    },
    xAxis: {
      type: 'time',
    },
    yAxis: {
      type: 'value',
      min: possibleMin > 1 ? possibleMin : 1,
      max: possibleMax < 10 ? possibleMax : 10,
      minInterval: 1,
      maxInterval: 1,
    },
    grid: {
      left: 0,
      top: 36,
      right: 10,
      bottom: 60,
      containLabel: true,
    },
    dataZoom: [
      {
        type: 'slider',
        show: true,
        brushSelect: false,
        zoomLock: false,
        xAxisIndex: [0],

        startValue,
        endValue,

        // Всегда ренедерим все данные
        filterMode: 'none',

        // отключаем лейблы слева и справа
        showDetail: false,
      },
    ],
    series: [
      {
        data: data.map((item) => ({
          value: item,
          itemStyle: {
            color: getScoreColor(item[1]),
          },
        })),
        type: 'line',
        step: 'end',
        lineStyle: {
          color: '#808DFF',
        },
      },
    ],
  }

  return options
}

interface ScoreHistoryChartProps extends ScoreHistoryChartOptions {
  height?: number
}

const ScoreHistoryChart = ({ data, height = 300 }: ScoreHistoryChartProps) => {
  const formatDate = useFormatDate()

  const ref = React.useRef()

  const [isLeftSidebarOpen] = useLeftSidebarState()
  const [isRightSidebarOpen] = useRightSidebarState()

  /*
   * To resize chart after sidebars toggling
   */
  React.useEffect(() => {
    if (ref.current) {
      // @ts-expect-error
      const instance = echarts.getInstanceByDom(ref.current.ele)

      setTimeout(() => {
        instance?.resize()
      }, 200)
    }
  }, [isLeftSidebarOpen, isRightSidebarOpen])

  if (!data) return null

  return (
    <Chart
      option={getOptions({ data, formatDate })}
      style={{ height }}
      ref={ref}
    />
  )
}

export default observer(ScoreHistoryChart)

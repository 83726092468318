import React from 'react'
import classnames from 'classnames/bind'

import { Notification } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { RowDeprecated } from '@clain/core/ui-kit'

import { NewIncidentNotification as N } from '../../apiServices'
import SeverityTag from '../SeverityTag'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface NewIncidentNotificationProps {
  notification: N
  closeToast: () => void
}

export const NewIncidentNotification: React.FC<
  NewIncidentNotificationProps
> = ({ notification: { id, severity }, closeToast }) => (
  <Notification.Body closeToast={closeToast} type="warning">
    <RowDeprecated>
      <Typography variant="body1" color="grey1">
        <Link to="/incidents/:id" className={cx('Bold')} params={{ id }}>
          Incident #{id}:{' '}
        </Link>
        New incident triggered
      </Typography>
      <SeverityTag value={severity}>{severity}</SeverityTag>
    </RowDeprecated>
  </Notification.Body>
)

import React, { useEffect } from 'react'
import Incident from '../components/Incident'
import { useParams } from '@clain/core/Router/router'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageIncident = () => {
  const { id } = useParams<{ id: string }>()
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: `Incident #${id}`, fixedHeader: true })
  }, [])
  return <Incident id={Number(id)} />
}

import React from 'react'

import { RowDeprecated } from '@clain/core/ui-kit'
import ButtonLink from '@clain/core/Link/ButtonLink'
import { ReactComponent as OpenInNewIcon } from '@clain/core/assets/open_in_new.svg'
import { IncidentStatus } from './components/IncidentStatus'
import { IgnoreIncidentButton } from './components/IgnoreIncidentButton'
import { OpenCaseButton } from './components/OpenCaseButton'
import { IncidentsDataItemNormalized } from '@compliance/types/Incident'

interface IncidentActionsProps {
  incident: IncidentsDataItemNormalized
  className?: string
  onSubmitSuccess?: (id: number) => void
  variant?: 'list' | 'page'
}

export const IncidentActions = ({
  incident,
  className,
  onSubmitSuccess,
  variant = 'list',
}: IncidentActionsProps) => {
  if (!incident) {
    return null
  }
  // primary отображается на стр. инцидента,
  // и там не надо отображать Dismissed и Case...
  if (variant === 'page') {
    //TODO remove ? when api will be updated
    if (incident.incident?.status === 'new') {
      return (
        <RowDeprecated gap={0.5} className={className}>
          <IgnoreIncidentButton
            incidentId={incident.incident?.id}
            size="lg"
            onSubmitSuccess={onSubmitSuccess}
          />
          <OpenCaseButton
            variant="solid"
            color="primary"
            size="lg"
            incidentId={incident.incident?.id}
            showIcon={false}
          />
        </RowDeprecated>
      )
    }
    //TODO remove ? when api will be updated
    if (incident.incident?.status === 'case' && incident.cases.length) {
      return (
        <ButtonLink
          size="lg"
          target="_blank"
          variant="outline"
          color="secondary"
          to="/cases/:id"
          params={{ id: incident.cases[0].id }}
          endCustomIcon={OpenInNewIcon}
        >
          case # {incident.cases[0].id}
        </ButtonLink>
      )
    }

    return null
  }

  if (incident.incident?.status === 'new') {
    return (
      <RowDeprecated gap={0.5} className={className}>
        <IgnoreIncidentButton
          incidentId={incident.incident?.id}
          onSubmitSuccess={onSubmitSuccess}
        />
        <OpenCaseButton incidentId={incident.incident?.id} />
      </RowDeprecated>
    )
  }

  return (
    <IncidentStatus
      cases={incident.cases}
      status={incident.incident?.status}
      className={className}
    />
  )
}

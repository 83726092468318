import React from 'react'
import classnames from 'classnames/bind'

import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { usePrevious } from '@clain/core/usePrevious'
import { generatePath, useNavigate } from '@clain/core/Router/router'
import { ReactComponent as SearchIcon } from '@clain/core/assets/search_new.svg'
import { Loader } from '@clain/core/ui-kit'

import ClientSearchCard from './cards/ClientSearchCard'
import IncidentSearchCard from './cards/IncidentSearchCard'
import CaseSearchCard from './cards/CaseSearchCard'
import { SearchResult, SearchResults } from '../../types'

import styles from './index.scss'
import { IncidentDataResponse } from '../../../../apiServices'
import { CaseDataResponse } from '../../../../apiServices/Case'
import { ClientDetails } from '@compliance/apiServices/Client'

const cx = classnames.bind(styles)

const MAX_SEARCH_RESULTS = 10

const isIncidentResult = (
  result: SearchResult
): result is IncidentDataResponse => {
  return (result as IncidentDataResponse)?.incident?.inserted_at !== undefined
}

const isCaseResult = (result: SearchResult): result is CaseDataResponse => {
  return (result as CaseDataResponse).tag !== undefined
}

const isClientResult = (result: SearchResult): result is ClientDetails => {
  return (result as ClientDetails).score !== undefined
}

interface GlobalSearchResultsProps {
  results: SearchResults
  isLoading: boolean
  hideModal: () => void
}

const GlobalSearchResults: React.FC<GlobalSearchResultsProps> = ({
  results,
  isLoading,
  hideModal,
}) => {
  const nav = useNavigate()

  const prevResults = usePrevious(results, !results)

  const [selected, _setSelected] = React.useState(0)
  const selectedRef = React.useRef(selected)
  const setSelected = (selectedIndex: number) => {
    selectedRef.current = selectedIndex
    _setSelected(selectedIndex)
  }

  React.useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (!prevResults.current?.length) return

      if (event.code === 'ArrowUp') {
        setSelected(
          selectedRef.current <= 0
            ? prevResults.current.length - 1
            : selectedRef.current - 1
        )
      }

      if (event.code === 'ArrowDown') {
        setSelected(
          selectedRef.current >= prevResults.current.length - 1
            ? 0
            : selectedRef.current + 1
        )
      }

      if (event.code === 'Enter') {
        const item = prevResults.current[selectedRef.current]

        if (isIncidentResult(item)) {
          nav(
            generatePath('/incidents/:id', {
              id: item?.incident?.id != null ? String(item?.incident?.id) : '',
            })
          )
        }
        if (isCaseResult(item)) {
          nav(
            generatePath('/cases/:id', {
              id: item?.id != null ? String(item?.id) : '',
            })
          )
        }
        if (isClientResult(item)) {
          nav(
            generatePath('/clients/:id', {
              id: item?.id != null ? String(item?.id) : '',
            })
          )
        }
        hideModal()
      }
    }

    document.addEventListener('keydown', handler)

    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [])

  /* Запрос не введен */
  if (!prevResults.current) {
    return null
  }

  /* По введенному запросу ничего не найдено */
  if (isLoading) {
    return <LoadingResults />
  }

  /* По введенному запросу ничего не найдено */
  if (
    (!prevResults.current.length && !isLoading) ||
    (!results?.length && isLoading)
  ) {
    return <NoSearchResults />
  }

  /* Маппинг результатов */
  return (
    <Container gap={[1, 0]} className={cx('GlobalSearchResults')}>
      {prevResults.current.slice(0, MAX_SEARCH_RESULTS).map((result, index) => {
        const isSelected = selected === index

        if (isIncidentResult(result)) {
          return (
            <IncidentSearchCard
              key={`IncidentSearchCard_${index}`}
              incident={result}
              selected={isSelected}
            />
          )
        }

        if (isCaseResult(result)) {
          return (
            <CaseSearchCard
              key={`CaseSearchCard_${index}`}
              caseItem={result}
              selected={isSelected}
            />
          )
        }

        if (isClientResult(result)) {
          return (
            <ClientSearchCard
              key={`ClientSearchCard_${index}`}
              client={result}
              selected={isSelected}
            />
          )
        }
      })}
    </Container>
  )
}

const LoadingResults = () => (
  <Container gap={[2, 0]} className={cx('GlobalSearchResults', 'container')}>
    <Loader />
  </Container>
)

const NoSearchResults = () => (
  <Container gap={[2, 0]} className={cx('GlobalSearchResults', 'container')}>
    <SearchIcon className={cx('NoResultsSearchIcon')} />
    <Typography color="grey3">No matches so far</Typography>
  </Container>
)

export default GlobalSearchResults

import React from 'react'

import Chart from '@clain/core/Chart2'
import { formatMoney, formatNumberShort } from '@clain/core/utils/format'

interface VolumeDonutData {
  in: number
  out: number
  volume: number
}

interface VolumeDonutOptions {
  data: VolumeDonutData
}

function getOptions({ data }: VolumeDonutOptions) {
  const legendValues = {
    Received: data.in,
    Sent: data.out,
  }

  const options = {
    title: [
      {
        text: `$${data.volume ? formatNumberShort({ value: data.volume }) : 0}`,
        subtext: 'Transfers\nVolume',

        x: 'center',
        y: 150,

        itemGap: 8,

        textAlign: 'left',
        textStyle: {
          fontSize: 24,
          fontWeight: 'normal',
          height: 30,
        },
        subtextStyle: {
          fontSize: 16,
        },
      },
    ],
    legend: {
      show: true,
      selectedMode: false,
      data: [
        {
          name: 'Received',
          icon: 'circle',
        },
        {
          name: 'Sent',
          icon: 'circle',
        },
      ],
      x: 'center',
      y: 'bottom',
      orient: 'vertical',
      selected: {},
      formatter: function (name) {
        const value = formatMoney({
          value: legendValues[name],
          currency: 'usd',
          precision: 0,
          code: '',
        })

        return `${value} ${name}`
      },
      textStyle: {
        fontSize: 14,
      },
    },
    series: [
      {
        name: 'Transfers Volume',
        type: 'pie',
        radius: ['45%', '70%'], // 70 * 0.65 ~ 45% // 0.65 — отношение внетруннего круга к внешнему
        center: ['50%', '42%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 4,
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        silent: true,
        data: [
          {
            value: data.in,
            name: 'Received',
            itemStyle: {
              color: '#808DFF',
            },
          },
          {
            value: data.out,
            name: 'Sent',
            itemStyle: {
              color: '#BDC4FF',
            },
          },
        ],
      },
    ],
  }

  return options
}

interface VolumeDonutProps extends VolumeDonutOptions {
  className?: string
  loading?: boolean
}

const VolumeDonut = React.memo(
  ({ className, loading, data }: VolumeDonutProps) => {
    return (
      <Chart
        stub={!data}
        loading={loading}
        className={className}
        option={getOptions({ data })}
        style={{
          height: 430,
        }}
      />
    )
  }
)
VolumeDonut.displayName = 'VolumeDonut'
export default VolumeDonut

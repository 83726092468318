import React from 'react'
import classnames from 'classnames/bind'

import { Notification } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'

import { CaseReassignNotification as N } from '../../apiServices'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface CaseReassignNotificationProps {
  notification: N
  closeToast: () => void
}

export const CaseReassignNotification: React.FC<
  CaseReassignNotificationProps
> = ({ notification: { assigned_from, assigned_to, id }, closeToast }) => (
  <Notification.Body closeToast={closeToast} type="default">
    <Typography variant="body1" color="grey1">
      <Link to="/cases/:id" className={cx('Bold')} params={{ id }}>
        Case #{id}:
      </Link>
      {assigned_from ? (
        <>
          {' '}
          The assignee for this case was changed from
          <Typography variant="body3" color="grey1">
            {' '}
            {assigned_from}{' '}
          </Typography>
          to
          <Typography variant="body3" color="grey1">
            {' '}
            {assigned_to}{' '}
          </Typography>
        </>
      ) : (
        <>
          {' '}
          The case assigned to
          <Typography variant="body3" color="grey1">
            {' '}
            {assigned_to}{' '}
          </Typography>
        </>
      )}
    </Typography>
  </Notification.Body>
)

import React from 'react'
import classnames from 'classnames/bind'
import { useNavigate, generatePath } from '@clain/core/Router/router'

import SeverityTag from '../../SeverityTag'
import Link from '@clain/core/Link'
import { Avatar } from '@clain/core/ui-kit'
import {
  Table2 as Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  createColumns,
} from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../hooks'
import StatusDot from '../../StatusDot'

import CaseStatusTag from '../../CaseStatusTag'
import CaseDueDate from '../../CaseDueDate'
import { CasesDataItem } from '../../../types/Case'
import { observer } from 'mobx-react-lite'
import styles from './index.scss'

const cx = classnames.bind(styles)

const DEFAULT_PAGINATION_SIZE = 10

interface CaseTableProps {
  items?: Array<CasesDataItem>
  showClientId?: boolean
  sortBy?: string
  order?: 'asc' | 'desc'
  loading?: boolean
  onChangeSort?: (sortBy: string, order: 'asc' | 'desc') => void
}

const CaseTable = ({
  items,
  showClientId = true,
  sortBy,
  order,
  loading,
  onChangeSort,
}: CaseTableProps) => {
  const nav = useNavigate()

  const formatDate = useFormatDate()

  const { columns, columnsData } = createColumns<CasesDataItem>(
    items,
    [
      {
        title: 'case id',
        field: 'id',
        sortable: false,
        render: ({ id, status, recommend_close_as, closed_as }) => {
          const rightBadge = (
            <CaseStatusTag
              value={status}
              closedAs={closed_as}
              recommendAs={recommend_close_as}
              className={cx('IdRightBadge')}
            />
          )

          return (
            <RowDeprecated>
              <Link
                to={status === 'new' ? '/cases/:id/start' : '/cases/:id'}
                params={{ id }}
              >
                {id}
              </Link>
              {rightBadge}
            </RowDeprecated>
          )
        },
      },
      {
        title: 'label',
        field: 'label',
        sortable: false,
        render: ({ tag }) => <Typography>{tag}</Typography>,
      },
      {
        title: 'severity',
        field: 'severity',
        sortable: false,
        render: (item) => (
          <SeverityTag value={item.severity}>{item.severity}</SeverityTag>
        ),
      },
      {
        title: 'created',
        field: 'created_at',
        sortable: true,
        render: ({ created_at }) =>
          created_at ? formatDate(new Date(created_at), 'date') : '',
      },
      {
        title: 'due date',
        field: 'due_date',
        sortable: true,
        render: ({ due_date, status }) => (
          <CaseDueDate
            date={due_date ? new Date(due_date) : null}
            status={status}
          />
        ),
      },
      showClientId && {
        title: 'client',
        field: 'client',
        sortable: false,
        render: (item) => (
          <RowDeprecated gap={0.5}>
            <StatusDot status={item.client.blocked ? 'blocked' : 'active'} />
            <Link
              className={cx('ClientIdLink')}
              to="/clients/:id"
              params={{ id: item.client.id }}
            >
              {item.client.id}
            </Link>
          </RowDeprecated>
        ),
      },
      {
        title: 'assignee',
        field: 'assignee',
        sortable: false,
        type: 'label',
        render: ({ assignee }) => {
          return (
            <Avatar
              className={cx('AssigneeAvatar')}
              src={assignee?.avatar}
              name={assignee?.name}
              size="sm"
            />
          )
        },
      },
    ],
    {
      stubHeight: 70,
      stubAmount: DEFAULT_PAGINATION_SIZE,
    }
  )

  return (
    <Table loading={loading}>
      <Thead>
        <Tr>
          {columns.map(({ title, field, type, sortable }) => (
            <Th
              key={field}
              className={cx(field)}
              type={type}
              sortable={sortable}
              order={order}
              name={field}
              sortBy={sortBy}
              onChangeSort={onChangeSort}
            >
              {title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {columnsData.map((item, index) => {
          const navigate = (e) => {
            if (
              e.target?.tagName === 'A' ||
              window.getSelection().type === 'Range'
            )
              return

            if (item.status === 'new') {
              nav(generatePath('/cases/:id/start', { id: item.id }))
            } else {
              nav(generatePath('/cases/:id', { id: item.id }))
            }
          }

          return (
            <Tr
              key={`columnsData_${index}`}
              className={cx('ClickableRow')}
              onClick={navigate}
            >
              {columns.map(({ field, render, type }) => (
                <Td key={field} className={cx('col', field)} type={type}>
                  {render(item)}
                </Td>
              ))}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default observer(CaseTable)

import React from 'react'

import { Modal, ModalSlot } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Button, ButtonSize } from '@clain/core/ui-kit'
import ButtonLink from '@clain/core/Link/ButtonLink'
import CaseSubmitForReviewForm from './ReviewForm'
import CaseResolveCaseForm from './ResolveForm'
import CaseRevertToInvestigationForm from './ReopenForm'

import { CaseAssignee, CASE_STATUS } from '../../../types/Case'
import { SnakeToCamelCaseArray } from '@clain/core/utilsTypes'

interface CaseModalProps {
  id: number
  assignees: SnakeToCamelCaseArray<CaseAssignee>
}

const InvestigateCase = ({ id }: CaseModalProps) => {
  return (
    <ButtonLink
      variant="solid"
      color="primary"
      size="lg"
      to="/cases/:id/start"
      params={{ id }}
    >
      Investigate
    </ButtonLink>
  )
}

const PendingReview = ({ id, assignees }: CaseModalProps) => {
  const target: ModalSlot = ({ show }) => (
    <Button variant="solid" color="primary" size="lg" onClick={show}>
      Send to review
    </Button>
  )

  const body: ModalSlot = ({ hide }) => {
    return (
      <CaseSubmitForReviewForm
        caseId={id}
        onSubmitSuccess={hide}
        onCancel={hide}
        assignees={assignees}
      />
    )
  }

  return (
    <Modal closable size="lg" target={target}>
      {body}
    </Modal>
  )
}

const Close = ({ id, assignees }: CaseModalProps) => {
  const target: ModalSlot = ({ show }) => {
    return (
      <Button variant="solid" color="primary" size="lg" onClick={show}>
        Resolve case
      </Button>
    )
  }

  const body: ModalSlot = ({ hide }) => {
    return (
      <CaseResolveCaseForm
        caseId={id}
        onSubmitSuccess={hide}
        onCancel={hide}
        assignees={assignees}
      />
    )
  }

  return (
    <Modal closable size="lg" target={target}>
      {body}
    </Modal>
  )
}

const Reopen = ({ id, assignees }: CaseModalProps) => {
  const target: ModalSlot = ({ show }) => {
    return (
      <Button variant="outline" color="secondary" size="lg" onClick={show}>
        Revert to investigation
      </Button>
    )
  }

  const body: ModalSlot = ({ hide }) => {
    return (
      <CaseRevertToInvestigationForm
        caseId={id}
        onSubmitSuccess={hide}
        onCancel={hide}
        assignees={assignees}
      />
    )
  }

  return (
    <Modal closable size="lg" target={target}>
      {body}
    </Modal>
  )
}

interface CaseActionsProps {
  id: number
  status: CASE_STATUS
  size?: ButtonSize
  className?: string
  assignees: SnakeToCamelCaseArray<CaseAssignee>
}

const CaseActions = ({
  id,
  status,
  className = '',
  assignees,
}: CaseActionsProps) => {
  if (!id) {
    return null
  }

  if (status === CASE_STATUS.NEW) {
    return (
      <RowDeprecated className={className}>
        <InvestigateCase id={id} assignees={assignees} />
      </RowDeprecated>
    )
  }

  if (status === CASE_STATUS.INVESTIGATION) {
    return (
      <RowDeprecated className={className}>
        <PendingReview id={id} assignees={assignees} />
      </RowDeprecated>
    )
  }

  if (status === CASE_STATUS.PENDING) {
    return (
      <RowDeprecated className={className}>
        <Reopen id={id} assignees={assignees} />
        <Close id={id} assignees={assignees} />
      </RowDeprecated>
    )
  }

  if (status === CASE_STATUS.CLOSED) {
    return (
      <RowDeprecated className={className}>
        <Reopen id={id} assignees={assignees} />
      </RowDeprecated>
    )
  }

  return null
}

export default CaseActions

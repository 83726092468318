import {
  formatMoney,
  formatMoneyShort,
  FORMATS_WITHOUT_DECIMALS,
} from '@clain/core/utils/format'

export const formatAmountValue = ({
  value,
  isShort,
  isShowCurrency,
}: {
  value: string
  isShort: boolean
  isShowCurrency: boolean
}) => {
  const formattedValue = parseFloat(value)
  return isShort && formattedValue >= 1e9
    ? formatMoneyShort({
        value,
        currency: isShowCurrency ? 'usd' : '',
        code: '',
      })
    : formatMoney({
        value,
        currency: isShowCurrency ? 'usd' : '',
        precision: 2,
        code: '',
      })
}
export const formatTokenValue = (tokenConfig: {
  value: number
  currency: string
  decimals?: number
}) => {
  const { value } = tokenConfig
  return value >= 1e12
    ? formatMoneyShort({
        ...tokenConfig,
        code: '',
      })
    : formatMoney({
        ...tokenConfig,
        code: '',
      })
}

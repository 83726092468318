import React from 'react'
import classnames from 'classnames/bind'

import { Header } from '@clain/core/Layout'
import { useNavigate, generatePath, useMatch } from '@clain/core/Router/router'
import { Tabs, TabPropsOption } from '@clain/core/ui-kit'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'

const cx = classnames.bind(styles)

const tabs: TabPropsOption<string>[] = [
  {
    value: 'global',
    children: 'GLOBAL SETTINGS',
  },
  {
    value: 'rules',
    children: 'AML/ CFT RULES',
  },
  {
    value: 'team',
    children: 'TEAM SETUP',
  },
  {
    value: 'notifications',
    children: 'NOTIFICATIONS',
  },
  {
    value: 'api',
    children: 'API INTEGRATION',
  },
  {
    value: 'profile',
    children: 'PROFILE',
  },
  ...(getConfig().ENV === 'production'
    ? []
    : [
        {
          value: 'reset',
          children: 'RESET',
        },
      ]),
]

type Page =
  | 'global'
  | 'rules'
  | 'team'
  | 'notifications'
  | 'api'
  | 'profile'
  | 'reset'

const SettingsHeader: React.FC = () => {
  const match = useMatch('/settings/:page')
  const page = match?.params?.page
  const nav = useNavigate()

  const onTabChange = (page: Page) => {
    nav(generatePath('/settings/:page', { page }))
  }

  return (
    <Header iconVariant="Settings" title="Settings">
      <Tabs
        className={cx('SettingsTabs')}
        options={tabs}
        value={page}
        onChange={onTabChange}
      />
    </Header>
  )
}

export default SettingsHeader

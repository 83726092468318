import {
  CaseAssignApiParamsData,
  CaseDueDateApiParamsData,
  caseService,
  CaseStartApiParamsData,
  CaseTagApiParamsData,
  ICaseApiService,
} from '../../apiServices/Case'
import { SubmitCaseForReviewParams } from './CaseActions/ReopenForm.types'
import { notify } from '@clain/core/ui-kit'

export interface ICaseActionsController {
  setCaseDueDate: (
    caseId: number,
    data: CaseDueDateApiParamsData
  ) => Promise<void>
  assignCase: (caseId: number, data: CaseAssignApiParamsData) => Promise<void>
  tagCase: (caseId: number, data: CaseTagApiParamsData) => Promise<void>
  startCase: (caseId: number, data: CaseStartApiParamsData) => Promise<void>
  reopenCase: (caseId: number, data: SubmitCaseForReviewParams) => Promise<void>
  setCaseToPending: (
    caseId: number,
    data: SubmitCaseForReviewParams
  ) => Promise<void>
  setCaseToClose: (
    caseId: number,
    data: SubmitCaseForReviewParams
  ) => Promise<void>
}

type CaseCallbacks = {
  caseDueDateCallback?: () => Promise<void> | void
  assignCaseCallback?: () => Promise<void> | void
  tagCaseCallback?: () => Promise<void> | void
  startCaseCallback?: () => Promise<void> | void
  reopenCaseCallback?: () => Promise<void> | void
  setCaseToPendingCallback?: () => Promise<void> | void
  setCaseToCloseCallback?: () => Promise<void> | void
}

export class CaseActionsController implements ICaseActionsController {
  private caseAPIService: ICaseApiService
  private callbacks: Partial<CaseCallbacks>

  constructor({
    caseAPIService,
    callbacks,
  }: {
    caseAPIService: ICaseApiService
    callbacks?: Partial<CaseCallbacks>
  }) {
    this.caseAPIService = caseAPIService
    this.callbacks = callbacks ?? {}
  }

  setCaseDueDate = async (caseId: number, data: CaseDueDateApiParamsData) => {
    try {
      await this.caseAPIService.postCaseDueDate(caseId, data)
      await this.callbacks?.caseDueDateCallback?.()
    } catch (e) {
      notify('An error occured while saving changes', { type: 'fail' })
      throw new Error(e)
    }
  }

  assignCase = async (caseId: number, data: CaseAssignApiParamsData) => {
    try {
      await this.caseAPIService.postCaseAssignee(caseId, data)
      await this.callbacks?.assignCaseCallback?.()
    } catch (e) {
      notify('An error occured while saving changes', { type: 'fail' })
      throw new Error(e)
    }
  }

  tagCase = async (caseId: number, data: CaseTagApiParamsData) => {
    try {
      await this.caseAPIService.postCaseTag(caseId, data)
      await this.callbacks?.tagCaseCallback?.()
      notify('The label was successfully changed', { type: 'success' })
    } catch (e) {
      notify('An error occured while saving changes', { type: 'fail' })
      throw new Error(e)
    }
  }

  startCase = async (caseId: number, data: CaseStartApiParamsData) => {
    try {
      await this.caseAPIService.postCaseStart(caseId, data)
      await this.callbacks?.startCaseCallback?.()
    } catch (e) {
      notify('An error occured while saving changes', { type: 'fail' })
      throw new Error(e)
    }
  }

  reopenCase = async (caseId: number, data: SubmitCaseForReviewParams) => {
    try {
      await this.caseAPIService.postCaseReOpen(caseId, data)
      await this.callbacks?.reopenCaseCallback?.()
    } catch (e) {
      notify('An error occured while saving changes', { type: 'fail' })
      throw new Error(e)
    }
  }

  setCaseToPending = async (
    caseId: number,
    data: SubmitCaseForReviewParams
  ) => {
    try {
      await this.caseAPIService.postCaseToPending(caseId, data)
      await this.callbacks?.setCaseToPendingCallback?.()
    } catch (e) {
      notify('An error occured while saving changes', { type: 'fail' })
      throw new Error(e)
    }
  }

  setCaseToClose = async (caseId: number, data: SubmitCaseForReviewParams) => {
    try {
      await this.caseAPIService.postCaseToClose(caseId, data)
      await this.callbacks?.setCaseToCloseCallback?.()
    } catch (e) {
      notify('An error occured while saving changes', { type: 'fail' })
      throw new Error(e)
    }
  }
}

export const defaultCaseActionsController = new CaseActionsController({
  caseAPIService: caseService.rest,
})

import React from 'react'
import classnames from 'classnames/bind'
import { startOfDay } from 'date-fns'
import { getConfig } from '@clain/core/useConfig'

import buildUrl from '@clain/core/utils/buildUrl'
import useHttp from '@clain/core/useHttp'
import { formatNumberProgressive } from '@clain/core/utils/format'
import { useTranslateDate } from '../../../hooks'
import Portlet from '@clain/core/Portlet'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { ColDeprecated } from '@clain/core/ui-kit'
import AutoCounter from '../AutoCounter'

import {
  DashboardIncidentsBySeverityData,
  DashboardClientScoresApiParams,
} from '../../../types/Dashboard'
import Period, { PeriodAllValue, getDateFromPeriod } from '../Filters/Period'
import { observer } from 'mobx-react-lite'
import styles from './index.scss'

const cx = classnames.bind(styles)

interface IncidentsBlockProps {
  className?: string
}

const IncidentsBlock: React.FC<IncidentsBlockProps> = ({ className }) => {
  const [period, setPeriod] = React.useState<PeriodAllValue>('week')

  const translateDate = useTranslateDate()

  const apiParams = React.useMemo(
    (): DashboardClientScoresApiParams => ({
      date:
        period !== 'all'
          ? translateDate(startOfDay(getDateFromPeriod(period))).toISOString()
          : null,
    }),
    [period]
  )

  const { data, isFirstLoading } = useHttp<DashboardIncidentsBySeverityData>(
    buildUrl`${getConfig()?.API}/api/dash/incidents_by_severity?${apiParams}`
  )

  return (
    <Portlet variant="card" className={className}>
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Incidents
          </Typography>
        ),
        toolbar: <Period preset="all" value={period} onChange={setPeriod} />,
        body: isFirstLoading ? (
          <Stub className={cx('IncidentsPortletStub')} isActive={true} />
        ) : (
          <Container className={cx('CountersContainer')} gap={2}>
            <ColDeprecated gap={1.5} className={cx('Counter')}>
              <Typography variant="heading4" color="magenta">
                High
              </Typography>
              <ColDeprecated className={cx('CounterValue')}>
                <AutoCounter
                  value={data?.HIGH?.total ?? 0}
                  minFontSize={32}
                  maxFontSize={48}
                  format={formatNumberProgressive}
                />
                <Link to="/incidents?selected[]=severity&severity[]=high">
                  see <b>{data?.LOW?.new ?? 0}</b> new
                </Link>
              </ColDeprecated>
            </ColDeprecated>
            <ColDeprecated gap={1.5} className={cx('Counter')}>
              <Typography variant="heading4" color="yellow">
                Medium
              </Typography>
              <ColDeprecated className={cx('CounterValue')}>
                <AutoCounter
                  value={data?.MEDIUM?.total ?? 0}
                  minFontSize={32}
                  maxFontSize={48}
                  format={formatNumberProgressive}
                />
                <Link to="/incidents?selected[]=severity&severity[]=medium">
                  see <b>{data?.MEDIUM?.new ?? 0}</b> new
                </Link>
              </ColDeprecated>
            </ColDeprecated>
            <ColDeprecated gap={1.5} className={cx('Counter')}>
              <Typography variant="heading4" color="blue">
                Low
              </Typography>
              <ColDeprecated className={cx('CounterValue')}>
                <AutoCounter
                  value={data?.LOW?.total ?? 0}
                  minFontSize={32}
                  maxFontSize={48}
                  format={formatNumberProgressive}
                />
                <Link to="/incidents?selected[]=severity&severity[]=low">
                  see <b>{data?.LOW?.new ?? 0}</b> new
                </Link>
              </ColDeprecated>
            </ColDeprecated>
          </Container>
        ),
      }}
    </Portlet>
  )
}

export default observer(IncidentsBlock)

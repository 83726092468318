/**
 * Represents the status of an case summary.
 */
export enum CaseSummaryStatus {
  /**
   * In the queue to generate the report.
   */
  ToProcess = 'to_process',
  /**
   * Generating report, should be ready soon.
   */
  InProcess = 'in_process',
  /**
   * Report has been generated.
   */
  Done = 'done',
}

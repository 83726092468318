import React from 'react'

import Portlet from '@clain/core/Portlet'
import { RowDeprecated } from '@clain/core/ui-kit'
import { AddFilter } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { CalendarInput } from '@clain/core/ui-kit'
import Form, { Field } from '@clain/core/Form'

import AmountInput from '../../AmountInput'
import SeverityInput from './SeverityInput'
import SearchInput from './SearchInput'
import IncidentRuleInput from './IncidentRuleInput'
import { FiltersProps, FIELDS, FieldTypes, FieldOptions } from './types'

const RiskAmountInput = (props) => <AmountInput label="Amount:" {...props} />

const fieldTypes: FieldTypes = {
  rule: IncidentRuleInput,
  severity: SeverityInput,
  riskAmount: RiskAmountInput,
}

const fieldOptions: FieldOptions = [
  {
    label: 'Severity',
    value: FIELDS.severity,
  },
  {
    label: 'Amount',
    value: FIELDS.riskAmount,
  },
  {
    label: 'Rule',
    value: FIELDS.rule,
  },
]

const IncidentFilters = ({
  defaultFiltersState,
  initialValues,
  onChange,
}: FiltersProps) => {
  const formRef = React.useRef(null)

  React.useEffect(() => {
    const unsubscribe = formRef.current?.form.subscribe(
      ({ values: { selected } }) => {
        if (selected) {
          fieldOptions
            .filter(({ value }) => !selected.includes(value))
            .forEach(({ value }) => {
              formRef.current?.form.change(value, defaultFiltersState[value])
            })
        }
      },
      { values: true }
    )

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <Portlet>
      {{
        content: (
          <Form initialValues={initialValues} onChange={onChange} ref={formRef}>
            {({ form }) => {
              const formState = form.getState()
              const selected = formState.values.selected || []
              return (
                <MagicGrid>
                  <RowDeprecated>
                    <Field
                      as={AddFilter}
                      name={FIELDS.selected}
                      options={fieldOptions}
                    />
                    <Field
                      clearable
                      as={CalendarInput}
                      name={FIELDS.created}
                      variant="outline"
                      label="Created:"
                      placeholder="Select date"
                    />
                    <Field as={SearchInput} name={FIELDS.someSearch} />
                  </RowDeprecated>
                  {selected.length > 0 && (
                    <RowDeprecated wrap>
                      {selected.map((control) => (
                        <Field
                          as={fieldTypes[control]}
                          key={control}
                          name={control}
                        />
                      ))}
                    </RowDeprecated>
                  )}
                </MagicGrid>
              )
            }}
          </Form>
        ),
      }}
    </Portlet>
  )
}

export default IncidentFilters
export type { FiltersState } from './types'

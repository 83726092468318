import React from 'react'

import { TextField } from '@clain/core/ui-kit'

interface RuleRiskAmountFilterProps {
  value: string
  onChange: (value: string) => unknown
  disabled: boolean
  error?: boolean
}

const RuleRiskAmountFilter: React.FC<RuleRiskAmountFilterProps> = ({
  value,
  onChange,
  disabled,
  error,
}) => {
  const [isFocused, setIsFocused] = React.useState(false)

  React.useEffect(() => {
    if (disabled) {
      onChange('')
    }
  }, [disabled])

  return (
    <TextField
      mask="number"
      variant="outline"
      startAddon={{ label: isFocused || value ? 'More or equal to, $' : 'Any' }}
      value={value}
      onChange={onChange}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      disabled={disabled}
      error={error}
    />
  )
}

export default RuleRiskAmountFilter

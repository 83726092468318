import React, { useEffect } from 'react'
import FullReset from '../components/Settings/FullReset'
import { useAppLayoutResetter } from '@clain/core/state'

export const PageFullReset = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])
  return <FullReset />
}

export default PageFullReset

import React from 'react'
import classnames from 'classnames/bind'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface StatusDotProps {
  status?: 'active' | 'blocked'
}

const StatusDot: React.FC<StatusDotProps> = ({ status }) => (
  <div className={cx('StatusDot', status)} />
)

export default StatusDot

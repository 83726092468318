import { CASE_STATUS, CaseAssignee } from '../../../types/Case'
import { useFormatDate } from '../../../hooks'
import { endOfDay } from 'date-fns'
import { Stack, SurfaceContainer } from '@clainio/web-platform'
import { Stub, TypographyNew } from '@clain/core/ui-kit'
import { CalendarPopup } from './CalendarPopup'
import { Assignee } from './Assignee'
import React from 'react'
import { CaseDataAssignee } from '../../../apiServices/Case'
import { useCaseActions } from '../CaseActions.context'
import { observer } from 'mobx-react-lite'
export const CaseActionsComponent = observer(
  ({
    id,
    dueDate,
    isLoading,
    status,
    assignees = [],
    assignee,
  }: {
    id: number
    dueDate: Date
    isLoading: boolean
    status: Exclude<CASE_STATUS, CASE_STATUS.NEW>
    assignees: CaseAssignee[]
    assignee: CaseDataAssignee
  }) => {
    const formatDate = useFormatDate()
    const { setCaseDueDate, assignCase } = useCaseActions()
    const dueDateText = dueDate ? formatDate(dueDate, 'date') : '-'

    const isOverdue =
      dueDate && dueDate.getTime() <= endOfDay(new Date()).getTime()

    const handleSubmitDueDate = async (dueDate: Date) => {
      await setCaseDueDate(id, { dueDate: dueDate.toISOString() })
    }

    const handleChangeAssignee = async (userId: number) => {
      await assignCase(id, { userId })
    }

    return (
      <Stack align={'center'} gap={'xl'} justify={'end'}>
        <Stack align={'center'} gap={'sm'} justify={'end'}>
          <TypographyNew
            variant={'body100Normal'}
            color={'onBackgroundVariant1'}
          >
            Due date
          </TypographyNew>
          {!isLoading ? (
            <CalendarPopup date={dueDate} onSubmit={handleSubmitDueDate}>
              <SurfaceContainer
                interactive
                spaces={['xs', 'xs']}
                borderRadius={'md'}
              >
                <TypographyNew
                  variant={'body100Accent'}
                  color={
                    isOverdue
                      ? 'accent1Base'
                      : status === 'closed'
                        ? 'onBackgroundVariant1'
                        : 'onBackgroundBase'
                  }
                >
                  {dueDateText}
                </TypographyNew>
              </SurfaceContainer>
            </CalendarPopup>
          ) : (
            <Stub isActive width={134} height={32} />
          )}
        </Stack>
        <Assignee
          assignees={assignees}
          assignee={assignee}
          onChangeAssignee={handleChangeAssignee}
        />
      </Stack>
    )
  }
)

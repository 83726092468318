import { injectable } from 'inversify'
import {
  IIncidentSocketService,
  IncidentChatSocketChannelSubscriptions,
  IncidentSocketDataResponse,
} from './IncidentService.types'
import { IncidentCaseSocketService } from '@compliance/apiServices/shared/IncidentCaseSocketService'

@injectable()
export class IncidentSocketService
  extends IncidentCaseSocketService<
    IncidentSocketDataResponse,
    IncidentChatSocketChannelSubscriptions
  >
  implements IIncidentSocketService
{
  constructor() {
    super('incident', [
      'stream',
      'user_message',
      'thread_messages',
      'message_used_in_summary',
      'summary',
      'incident_status',
    ])
  }

  public subscribeToStreamSocketChannel = (
    callback: (res: IncidentChatSocketChannelSubscriptions['stream']) => void
  ) => {
    this.subscribeToSocketChannel('stream', callback)
  }

  public subscribeToUserMessageSocketChannel = (
    callback: (
      res: IncidentChatSocketChannelSubscriptions['user_message']
    ) => void
  ) => {
    this.subscribeToSocketChannel('user_message', callback)
  }

  public subscribeToThreadMessageSocketChannel = (
    callback: (
      res: IncidentChatSocketChannelSubscriptions['thread_messages']
    ) => void
  ) => {
    this.subscribeToSocketChannel('thread_messages', callback)
  }

  public subscribeToMessageUsedInSummarySocketChannel = (
    callback: (
      res: IncidentChatSocketChannelSubscriptions['message_used_in_summary']
    ) => void
  ) => {
    this.subscribeToSocketChannel('message_used_in_summary', callback)
  }

  public subscribeToSummarySocketChannel = (
    callback: (res: IncidentChatSocketChannelSubscriptions['summary']) => void
  ) => {
    this.subscribeToSocketChannel('summary', callback)
  }

  public subscribeToIncidentStatusSocketChannel = (
    callback: (
      res: IncidentChatSocketChannelSubscriptions['incident_status']
    ) => void
  ) => {
    this.subscribeToSocketChannel('incident_status', callback)
  }
}

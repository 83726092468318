import React from 'react'
import classnames from 'classnames/bind'
import { addDays } from 'date-fns'
import { generatePath, useNavigate } from '@clain/core/Router/router'

import { DateISOString } from '@clain/core/types'
import http from '@clain/core/http'
import buildUrl from '@clain/core/utils/buildUrl'
import { useFormatDate } from '../../hooks'
import { formatDays } from '@clain/core/utils/formatDuration'
import { formatMoney } from '@clain/core/utils/format'
import useHttp from '@clain/core/useHttp'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { Calendar } from '@clain/core/ui-kit'
import UsersGrid from '@clain/core/UsersGrid'
import Portlet from '@clain/core/Portlet'
import { Button } from '@clain/core/ui-kit'
import { InlineInfoLabel } from '@clain/core/ui-kit'
import Form, { FORM_ERROR, Field } from '@clain/core/Form'
import { KeyValue } from '@clain/core/ui-kit'

import { CASE_STATUS } from '../../types/Case'
import { CaseHeader } from '../Case/CaseHeader'
import SeverityTag from '../SeverityTag'

import styles from './index.scss'
import { tokenIdToCurrency } from '@clain/core/utils'
import { useSettings } from '../../hooks'
import { getConfig } from '@clain/core/useConfig'
import { CaseDataResponse } from '../../apiServices/Case'
import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import { CaseActionsProvider } from '../Case/CaseActions.context'
import { defaultCaseActionsController } from '../Case/CaseActionsController'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

interface CaseStartPostData {
  id: number
  due_date: DateISOString
  user_id: number
}

const startInvestigation = ({ id, ...data }: CaseStartPostData) =>
  http.post(buildUrl`${getConfig()?.API}/api/cases/${id}/start`, data)

const OpenCase = ({ id }) => {
  const nav = useNavigate()
  const { user, settings } = useSettings()
  const {
    data: caseData,
    mutate,
    isLoading,
  } = useHttp<CaseDataResponse>(buildUrl`${getConfig()?.API}/api/cases/${id}`)

  // на случай если зайдут по прямой ссылке
  React.useEffect(() => {
    if (caseData && caseData.status !== 'new') {
      nav(generatePath('/cases/:id', { id }))
    }
  }, [caseData, id])

  const { data: assignees = [] } = useHttp(
    buildUrl`${getConfig()?.API}/api/cases/assignees`
  )

  const modifyTitle = async (tag: string) => {
    await mutate(
      {
        ...caseData,
        tag,
      },
      true
    )
  }

  HeaderSlot.useContent(
    () => (
      <CaseActionsProvider viewModel={defaultCaseActionsController}>
        <CaseHeader
          id={id}
          data={normalizeSnakeToCamelCase(caseData)}
          onTitleChange={modifyTitle}
        />
      </CaseActionsProvider>
    ),
    [id, caseData]
  )

  const handleStartInvestigation = async (data) => {
    const [, end] = data.periodToDueDate

    const response = await startInvestigation({
      ...data,
      due_date: end,
    })

    if (!response.status) {
      return {
        [FORM_ERROR]: response.data.message,
      }
    }

    mutate({ ...caseData, status: CASE_STATUS.INVESTIGATION }, true)
    nav(generatePath('/cases/:id', { id }))
  }

  const assigneesWithMark = React.useMemo(() => {
    return assignees
      .map((assignee) => ({
        ...assignee,
        mark: assignee.id === user?.id ? 'you' : undefined,
      }))
      .sort((a, b) => (a.mark ? -1 : b.mark ? 1 : 0))
  }, [assignees, user?.id])

  const initialValues = React.useMemo(
    () => ({
      id,
      user_id: assigneesWithMark[0]?.id,
      periodToDueDate: [
        new Date(),
        addDays(new Date(), settings?.deadline || 30),
      ],
    }),
    [assigneesWithMark, id, settings?.deadline]
  )

  const formatDate = useFormatDate()

  return (
    <Form onSubmit={handleStartInvestigation} initialValues={initialValues}>
      {({ form }) => {
        const { values } = form.getState()

        const [start, end] = values.periodToDueDate
        const dueDate = end ? formatDate(end, 'date') : 'N/A'
        const assignee = assignees.find((a) => a.id === values.user_id)

        const assigneeName = assignee?.name || 'N/A'
        const assigneeMark = assignee?.mark

        const duration =
          start && end ? formatDays(end.getTime() - start.getTime()) : ''

        const durationText = `(${duration} from now)`

        return (
          <>
            <MagicGrid gap={2} className={cx('OpenCase')}>
              <Portlet variant="blue">
                {{
                  content: (
                    <KeyValue
                      data={caseData}
                      loading={isLoading}
                      maxColumns={4}
                      keyWidth={100}
                    >
                      {[
                        {
                          key: 'Incident ID',
                          value: ({ incidents }) => {
                            const incidentId = incidents[0]?.id

                            return (
                              <Link
                                to="/incidents/:id"
                                params={{ id: incidentId }}
                              >
                                {incidentId}
                              </Link>
                            )
                          },
                        },
                        {
                          key: 'Date / time',
                          value: ({ created_at }) =>
                            formatDate(new Date(created_at)),
                        },
                        {
                          key: 'Client',
                          value: ({ client }) => (
                            <Link to="/clients/:id" params={{ id: client.id }}>
                              #{client.id}
                            </Link>
                          ),
                        },

                        {
                          key: 'Counterparty',
                          value: ({ incidents }) => {
                            const transaction = incidents[0].transaction
                            //@ts-expect-error TODO API should be updated
                            const counterparty = transaction.counterparty

                            return (
                              <RowDeprecated gap={0.5}>
                                <Score value={counterparty.score} />
                                <Link
                                  to="%PLATFORM_URL%/:coin/cluster/:id"
                                  params={{
                                    id: counterparty.cluster_id,
                                    coin: transaction.currency,
                                  }}
                                >
                                  {counterparty.entity?.name ||
                                    counterparty.cluster_id}
                                </Link>
                              </RowDeprecated>
                            )
                          },
                        },
                        {
                          key: 'Severity',
                          value: ({ severity }) => {
                            return (
                              <SeverityTag value={severity}>
                                {severity}
                              </SeverityTag>
                            )
                          },
                        },

                        {
                          key: 'Category',
                          value: ({ incidents }) => {
                            const rule = incidents[0].rule

                            return rule.name
                          },
                        },
                        {
                          key: 'Amount',
                          value: ({ incidents }) => {
                            const transaction = incidents[0].transaction

                            return formatMoney({
                              currency: tokenIdToCurrency[transaction.currency],

                              value: transaction.amount,
                            })
                          },
                        },
                        caseData?.incidents[0].rule.usd_amount && {
                          key: 'Risk Amount',
                          value: ({ incidents }) => {
                            const rule = incidents[0].rule

                            return formatMoney({
                              currency: 'USD',
                              value: rule.usd_amount,
                            })
                          },
                        },
                      ]}
                    </KeyValue>
                  ),
                }}
              </Portlet>
              <div className={cx('TopHeader')}>
                Assign a due date and responsible team member <br /> to start
                investigation
              </div>
              <div className={cx('TopHeaderCaption')}>
                Or leave defaults and change later upon necessity
              </div>
              <MagicGrid>
                <Typography variant="heading3" color="black">
                  Due date
                </Typography>
                <Portlet>
                  {{
                    content: (
                      <Field
                        name="periodToDueDate"
                        as={Calendar}
                        numberOfMonths={3}
                        className={cx('Calendar')}
                        disableDaysBeforeSelected
                        freezeFrom
                      />
                    ),
                  }}
                </Portlet>
              </MagicGrid>
              <MagicGrid>
                <Typography variant="heading3" color="black">
                  Assignee
                </Typography>
                <Portlet>
                  {{
                    content: (
                      <Field
                        name="user_id"
                        users={assigneesWithMark}
                        as={UsersGrid}
                      />
                    ),
                  }}
                </Portlet>
              </MagicGrid>
              <div className={cx('Footer')}>
                <MagicGrid gap={1 / 2}>
                  <InlineInfoLabel
                    label="Due date:"
                    value={dueDate}
                    help={durationText}
                  />
                  <InlineInfoLabel
                    label="Assignee:"
                    value={assigneeName}
                    help={assigneeMark}
                  />
                </MagicGrid>
                <Button
                  type="submit"
                  variant="solid"
                  color="primary"
                  size="lg"
                  className={cx('Action')}
                >
                  Start Investigation
                </Button>
              </div>
            </MagicGrid>
          </>
        )
      }}
    </Form>
  )
}

export default observer(OpenCase)

import React from 'react'
import { TypographyNew } from '@clainio/web-platform'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
import thumbnailImage from './graph-thumbnail.webp'
import * as S from './GraphThumbnailWidget.styles'
import { GraphThumbnailWidgetProps } from './GraphThumbnailWidget.types'

export const GraphThumbnailWidget = ({
  probeId,
}: GraphThumbnailWidgetProps) => {
  return (
    <S.GraphThumbnailWidgetWrapperContainer borderRadius={'md'}>
      <ErrorBoundaryContainer>
        <S.GraphThumbnailWidgetWrapper>
          <S.ThumbnailImageContainer $imageUrl={thumbnailImage}>
            <S.Overlay className="overlay">
              <S.StyledLink
                to="%PLATFORM_URL%/probes/:id"
                params={{
                  id: probeId,
                }}
              >
                <TypographyNew
                  variant={'heading200Accent'}
                  color={'onPrimaryBase'}
                >
                  Open visualisation
                </TypographyNew>
              </S.StyledLink>
            </S.Overlay>
          </S.ThumbnailImageContainer>
        </S.GraphThumbnailWidgetWrapper>
      </ErrorBoundaryContainer>
    </S.GraphThumbnailWidgetWrapperContainer>
  )
}

import type { CalendarInputValue } from '@clain/core/ui-kit'
import type { ScoreRange } from '@clain/core/types/ScoreRange'
import { BlockchainType } from '../../../types/coin'

export interface Amount {
  from?: string
  to?: string
}

export interface AssetAmount {
  blockchain?: BlockchainType
  asset_id?: string
  from?: string
  to?: string
}

export enum FIELDS {
  // Static
  selected = 'selected',
  created = 'created',
  search = 'search',

  // Selectable
  amountUsd = 'amountUsd',
  assetAmount = 'assetAmount',
  cpScore = 'cpScore', // Counterparty score
}

interface StaticFiltersState {
  [FIELDS.selected]: Array<string>
  [FIELDS.created]: CalendarInputValue
  [FIELDS.search]: string | null
}

interface SelectableFiltersState {
  [FIELDS.amountUsd]: Amount | null
  [FIELDS.assetAmount]: AssetAmount | null
  [FIELDS.cpScore]: ScoreRange | null
}

export type FiltersState = StaticFiltersState & SelectableFiltersState

export interface FiltersProps {
  defaultFiltersState: FiltersState
  initialValues: FiltersState
  onChange: (value: FiltersState) => void
}

export interface FieldProps<T> {
  value: T | null
  onChange: (value: T | null) => void
}

type FieldType = keyof SelectableFiltersState

export type FieldTypes = {
  [k in FieldType]: React.ComponentType<FieldProps<any>>
}

type FieldOption = {
  label: string
  value: FieldType
}

export type FieldOptions = Array<FieldOption>

import React from 'react'
import classnames from 'classnames/bind'

import styles from './index.scss'

const cx = classnames.bind(styles)

const VoidText = ({ children }) => {
  return <div className={cx('VoidText')}>{children}</div>
}

export default VoidText

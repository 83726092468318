import React from 'react'
import classnames from 'classnames/bind'
import { startOfDay, fromUnixTime } from 'date-fns'

import { formatPercent } from '@clain/core/utils/format'
import { roundToPrecision } from '@clain/core/utils/math'
import { useTranslateDate } from '../../../hooks'
import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'
import Portlet from '@clain/core/Portlet'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import Funnel from '@clain/graphics/src/funnel'
import { RowDeprecated } from '@clain/core/ui-kit'

import SeverityPanel from '../../SeverityPanel'
import Period, { getDateFromPeriod, PeriodAllValue } from '../Filters/Period'
import { FunnelData, FunnelApiParams } from '../../../types/Dashboard'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'
import { useSettings } from '../../../hooks'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

const TransfersIncidentsCasesBlock = () => {
  const { firstTransaction: firstTransactionSettingsSettings } = useSettings()

  const firstTransaction = firstTransactionSettingsSettings
    ? fromUnixTime(firstTransactionSettingsSettings)
    : new Date()

  const [period, setPeriod] = React.useState<PeriodAllValue>('all')

  const translateDate = useTranslateDate()

  const prevApiParams = React.useMemo(
    (): FunnelApiParams => ({
      date: translateDate(
        startOfDay(
          period === 'all' ? firstTransaction : getDateFromPeriod(period)
        )
      ).toISOString(),
    }),
    [period]
  )

  const { data: d, isFirstLoading } = useHttp<FunnelData>(
    buildUrl`${getConfig()?.API}/api/dash/funnel?${prevApiParams}`
  )

  const data = React.useMemo(
    () => ({
      incidents: {
        case: {
          high: d?.incidents.case_incidents.HIGH ?? 0,
          medium: d?.incidents.case_incidents.MEDIUM ?? 0,
          low: d?.incidents.case_incidents.LOW ?? 0,
        },
        ignored: {
          high: d?.incidents.dismissed_incidents.HIGH ?? 0,
          medium: d?.incidents.dismissed_incidents.MEDIUM ?? 0,
          low: d?.incidents.dismissed_incidents.LOW ?? 0,
        },
      },
      cases: {
        true: {
          high: d?.cases.cases_true_closed.HIGH ?? 0,
          medium: d?.cases.cases_true_closed.MEDIUM ?? 0,
          low: d?.cases.cases_true_closed.LOW ?? 0,
        },
        false: {
          high: d?.cases.cases_false_closed.HIGH ?? 0,
          medium: d?.cases.cases_false_closed.MEDIUM ?? 0,
          low: d?.cases.cases_false_closed.LOW ?? 0,
        },
      },
    }),
    [d]
  )

  const totalConvertedIncidents = Object.values(data.incidents.case).reduce(
    (a, b) => a + b,
    0
  )
  const totalIgnoredIncidents = Object.values(data.incidents.ignored).reduce(
    (a, b) => a + b,
    0
  )

  const maxIncidents = Math.max(totalConvertedIncidents, totalIgnoredIncidents)

  const totalTrueCases = Object.values(data.cases.true).reduce(
    (a, b) => a + b,
    0
  )
  const totalFalseCases = Object.values(data.cases.false).reduce(
    (a, b) => a + b,
    0
  )

  const maxCases = Math.max(totalTrueCases, totalFalseCases)

  const funnelData = React.useMemo(() => {
    return {
      transfers: d?.transactions ?? 0,
      incidents: d?.incidents.total ?? 0,
      cases: d?.cases.total ?? 0,
      true_closed_cases: totalTrueCases,
    }
  }, [d?.cases?.total, d?.incidents?.total, d?.transactions, totalTrueCases])

  const prepearedFunnelData = React.useMemo(() => {
    const incidentsPersent = funnelData.transfers
      ? (funnelData.incidents / funnelData.transfers) * 100
      : 0
    const casesPersent = funnelData.incidents
      ? (funnelData.cases / funnelData.incidents) * 100
      : 0
    const truePositivePersent = funnelData.cases
      ? (funnelData.true_closed_cases / funnelData.cases) * 100
      : 0
    const casesPersentAbs = funnelData.transfers
      ? (funnelData.cases / funnelData.transfers) * 100
      : 0
    const truePositivePersentAbs = funnelData.transfers
      ? (funnelData.true_closed_cases / funnelData.transfers) * 100
      : 0

    return {
      stages: [
        {
          value: roundToPrecision(funnelData.transfers, 1),
          label: 'Transfers',
        },
        {
          value: roundToPrecision(funnelData.incidents, 1),
          label: 'Incidents',
        },
        {
          value: roundToPrecision(funnelData.cases, 1),
          label: 'Cases',
        },
        {
          value: roundToPrecision(funnelData.true_closed_cases, 1),
          label: 'True positive',
        },
      ],
      conversions: [
        {
          value: roundToPrecision(incidentsPersent, 1),
        },
        {
          value: roundToPrecision(casesPersent, 1),
          label: casesPersentAbs
            ? `(${formatPercent(casesPersentAbs)} abs)`
            : '',
        },
        {
          value: roundToPrecision(truePositivePersent, 1),
          label: truePositivePersentAbs
            ? `(${formatPercent(truePositivePersentAbs)} abs)`
            : '',
        },
      ],
    }
  }, [funnelData])

  return (
    <Portlet variant="card">
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Risk funnel
          </Typography>
        ),
        toolbar: <Period preset="all" value={period} onChange={setPeriod} />,
        body: isFirstLoading ? (
          <Stub
            className={cx('TransfersIncidentsCasesPortletStub')}
            isActive={true}
          />
        ) : (
          <RowDeprecated>
            <Container gap={2} className={cx('FunnelGraph')}>
              <Funnel options={prepearedFunnelData} />
            </Container>
            <Container gap={2} className={cx('IncidentsBySeverity')}>
              <ColDeprecated gap={3}>
                <ColDeprecated gap={2}>
                  <Typography variant="heading4" color="grey3">
                    Incidents
                  </Typography>
                  <ColDeprecated>
                    <SeverityPanel
                      high={data.incidents.case.high}
                      medium={data.incidents.case.medium}
                      low={data.incidents.case.low}
                      percent={totalConvertedIncidents / maxIncidents}
                      label="Converted"
                    />
                    <SeverityPanel
                      high={data.incidents.ignored.high}
                      medium={data.incidents.ignored.medium}
                      low={data.incidents.ignored.low}
                      percent={totalIgnoredIncidents / maxIncidents}
                      label="Dismissed"
                    />
                  </ColDeprecated>
                </ColDeprecated>
                <ColDeprecated gap={2}>
                  <Typography variant="heading4" color="grey3">
                    Cases
                  </Typography>
                  <ColDeprecated>
                    <SeverityPanel
                      high={data.cases.true.high}
                      medium={data.cases.true.medium}
                      low={data.cases.true.low}
                      percent={totalTrueCases / maxCases}
                      label="True Positive"
                    />
                    <SeverityPanel
                      high={data.cases.false.high}
                      medium={data.cases.false.medium}
                      low={data.cases.false.low}
                      percent={totalFalseCases / maxCases}
                      label="False Positive"
                    />
                  </ColDeprecated>
                </ColDeprecated>
              </ColDeprecated>
            </Container>
          </RowDeprecated>
        ),
      }}
    </Portlet>
  )
}

export default observer(TransfersIncidentsCasesBlock)

import { ChatFile } from '@compliance/apiServices/shared/Chat.types'

export const convertFileToChatFile = (file: File): ChatFile => {
  return {
    content_type: file.type,
    file: URL.createObjectURL(file),
    name: file.name,
    size: file.size,
    uploaded_at: new Date().toISOString(),
  }
}

import { Button, ButtonProps, Modal, ModalSlot } from '@clain/core/ui-kit'
import { generatePath, useNavigate } from '@clain/core/Router/router'
import { ReactComponent as FolderOpenIcon } from '@clain/core/assets/folder_open.svg'

import ConvertForm from './ConvertForm'
import React from 'react'

interface OpenCaseProps extends ButtonProps {
  incidentId: number
  showIcon?: boolean
}

export const OpenCaseButton = ({
  variant = 'solid',
  color = 'primary',
  size = 'md',
  incidentId,
  showIcon = true,
}: OpenCaseProps) => {
  const nav = useNavigate()

  const target: ModalSlot = ({ show }) => {
    const onClick = (e) => {
      e.stopPropagation()
      show()
    }
    return (
      <Button
        variant={variant}
        color={color}
        size={size}
        onClick={onClick}
        endCustomIcon={showIcon ? FolderOpenIcon : null}
      >
        Open case
      </Button>
    )
  }

  const body: ModalSlot = ({ hide }) => {
    const handleSubmitSuccess = (caseId: number) => {
      hide()

      nav(generatePath('/cases/:id/start', { id: `${caseId}` }))
    }

    return (
      <ConvertForm
        incidentId={incidentId}
        onSubmitSuccess={handleSubmitSuccess}
        onCancel={hide}
      />
    )
  }

  return (
    <Modal closable target={target}>
      {body}
    </Modal>
  )
}

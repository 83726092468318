import { AlertCaution, Button } from '@clainio/web-platform'
import React from 'react'

export const ErrorInfoBlock = ({ onRetry }: { onRetry: () => void }) => {
  const message = `An error occurred.
  Please try to regenerate the reply.`
  return (
    <AlertCaution
      isMarkdown
      variant={'error'}
      leftSlotProps={{
        children: null,
      }}
      rightSlotProps={{
        children: (
          <Button
            endIcon={'Renew'}
            variant={'outline'}
            color={'critical'}
            onClick={onRetry}
          >
            Regenerate
          </Button>
        ),
        align: 'center',
      }}
    >
      {message}
    </AlertCaution>
  )
}

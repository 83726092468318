import React from 'react'
import classnames from 'classnames/bind'

import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface RelatedItemContainerProps {
  title?: string
  items: Array<{ name: string; value: React.ReactNode }>
}

const RelatedItemContainer: React.FC<RelatedItemContainerProps> = ({
  title,
  items,
}) => (
  <Container gap={[0.5, 1]} className={cx('RelatedItemContainer')}>
    {title && (
      <div className={cx('RelatedContainerTitle')}>
        <Typography variant="subheading2" color="grey1">
          {title}
        </Typography>
      </div>
    )}
    {items.map(({ name, value }) => (
      <div key={name} className={cx('RelatedContainerItem')}>
        <Typography variant="body3" color="grey3">
          {name}
        </Typography>
        {value}
      </div>
    ))}
  </Container>
)

export default RelatedItemContainer

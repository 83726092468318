import { ColDeprecated, RowDeprecated, Typography } from '@clain/core/ui-kit'
import React from 'react'
import Link from '@clain/core/Link'
import classnames from 'classnames/bind'
import styles from '../index.scss'

interface IncidenStatusProps {
  cases?: Array<{ id: number; tag: string }>
  status: 'ignored' | 'case' | 'new'
  variant?: 'default' | 'inline'
  className?: string
}

const cx = classnames.bind(styles)

export const IncidentStatus = ({
  cases,
  status,
  variant = 'default',
  className,
}: IncidenStatusProps) => {
  if (status === 'new') {
    return (
      <RowDeprecated gap={0.5} className={className}>
        <Typography variant="body1" color="grey3">
          New
        </Typography>
      </RowDeprecated>
    )
  }

  if (cases?.length > 0 && variant === 'inline') {
    return (
      <Typography>
        {cases.map((c, i) => {
          const id = c?.id

          const isLast = i === cases.length - 1

          return (
            <React.Fragment key={id}>
              <Link to={`/cases/${id}`}>Case #{id}</Link>
              {!isLast ? ', ' : ''}
            </React.Fragment>
          )
        })}
      </Typography>
    )
  }

  if (cases?.length > 0) {
    return (
      <RowDeprecated gap={0.5} className={className}>
        {cases.map((c) => {
          const id = c?.id
          const text = c?.tag || c?.id

          return (
            <ColDeprecated gap={0} align="left" key={id}>
              <Link to={`/cases/${id}`}>Case #{id}</Link>
              <Typography color="grey3" className={cx('Tag')}>
                {text}
              </Typography>
            </ColDeprecated>
          )
        })}
      </RowDeprecated>
    )
  }

  if (status === 'case') {
    return (
      <RowDeprecated gap={0.5} className={className}>
        <Typography variant="body1" color="grey3">
          Case
        </Typography>
      </RowDeprecated>
    )
  }

  if (status === 'ignored') {
    return (
      <RowDeprecated gap={0.5} className={className}>
        <Typography variant="body1" color="grey3">
          Dismissed
        </Typography>
      </RowDeprecated>
    )
  }

  return null
}

import styled, { css } from 'styled-components'
import {
  createTokenKey,
  selectToken,
  Tabs as UITabs,
} from '@clainio/web-platform'

export const Tabs = styled(UITabs)`
  ${({ theme }) => {
    return css({
      borderTopLeftRadius: selectToken(
        theme,
        createTokenKey(['border', 'radius', 'md'])
      ),
      borderTopRightRadius: selectToken(
        theme,
        createTokenKey(['border', 'radius', 'md'])
      ),
      borderBottom: `1px solid ${selectToken(
        theme,
        createTokenKey(['grey400'])
      )}`,
      backgroundColor: selectToken(theme, createTokenKey(['white'])),
    })
  }}
`

import React from 'react'

import ClientExposure from '../../Exposure/ClientExposure'
import VoidText from '../../VoidText'
import { ClientExposure as ClientExposureType } from '../../../apiServices/Client/ClientService.types'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

interface Exposure {
  in: ClientExposureType
  out: ClientExposureType
}

interface ExposureTabProps {
  exposure?: SnakeToCamelCaseObject<Exposure>
}

const Exposure: React.FC<ExposureTabProps> = ({ exposure }) => {
  if (exposure?.in?.exposure?.length || exposure?.out?.exposure?.length) {
    return <ClientExposure data={exposure} />
  }

  return (
    <VoidText>
      Your client has no exposure
      <br />
      untill the first transaction will be processed
    </VoidText>
  )
}

export default Exposure

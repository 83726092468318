import React from 'react'

import { TagDeprecated, TagVariantDeprecated } from '@clain/core/ui-kit'

import { IncidentStatus } from '@compliance/apiServices'

interface IncidentStatusTagProps {
  size?: 'md' | 'lg'
  value: IncidentStatus | undefined | null
  className?: string
}

const IncidentStatusTag = ({
  value,
  className,
  size,
}: IncidentStatusTagProps) => {
  let variant: TagVariantDeprecated = 'default'

  if (!value) {
    return null
  }

  if (value === 'new') {
    variant = 'alternate'
  }

  return (
    <TagDeprecated variant={variant} size={size} className={className}>
      {value === 'ignored' ? 'dismissed' : value}
    </TagDeprecated>
  )
}

export default IncidentStatusTag

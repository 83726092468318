import { Container } from 'inversify'
import { IncidentRestApiService } from './IncidentRestApiService'
import { IncidentSocketService } from './IncidentSocketService'
import { IncidentService } from './IncidentService'
import {
  IIncidentSocketService,
  IIncidentApiService,
  IIncidentService,
} from './IncidentService.types'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'

const container = new Container({ defaultScope: 'Singleton' })

container
  .bind<IIncidentApiService>(DY_TYPES.IncidentApiService)
  .to(IncidentRestApiService)
container
  .bind<IIncidentSocketService>(DY_TYPES.IncidentSocketService)
  .to(IncidentSocketService)
container.bind<IIncidentService>(DY_TYPES.IncidentService).to(IncidentService)

export const incidentService = container.get<IIncidentService>(
  DY_TYPES.IncidentService
)

export { IncidentService } from './IncidentService'

export * from './IncidentService.types'
export { IncidentSummaryStatus } from '@compliance/apiServices/Incident/IncidentService.constants'

import { SettingsService } from '../apiServices'
import { settingsState, SettingsState } from '../states'
import { Notification } from '@clain/core/ui-kit'
import { notificationToastOptions } from '../utils/Notifications'
import { ERROR } from '../constants'
import * as Sentry from '@sentry/react'
export class SettingsController {
  private static instance: SettingsController
  private settingsService: SettingsService
  private settingsState: SettingsState

  public constructor(
    settingsService: SettingsService,
    settingsState: SettingsState
  ) {
    this.settingsService = settingsService
    this.settingsState = settingsState
  }

  public static getInstance(): SettingsController {
    if (!SettingsController.instance) {
      SettingsController.instance = new SettingsController(
        new SettingsService(),
        settingsState
      )
    }
    return SettingsController.instance
  }

  public loadAndStoreSettingsProfile = async () => {
    try {
      const settingsProfile = await this.settingsService.getSettingsProfile()
      Sentry.setUser({
        id: settingsProfile.user.id,
        companyId: settingsProfile.user.companyId,
      })
      const settings = await this.settingsService.getSettings()
      this.settingsState.setSettingsProfile(settingsProfile)
      this.settingsState.setSettings(settings)
    } catch (error) {
      Notification.notify(
        ERROR.serverIssues,
        { type: 'warning' },
        notificationToastOptions
      )
    }
  }

  public getSettingsProfile = () => {
    return this.settingsState.getSettingsProfile
  }

  public getUser = () => {
    return this.settingsState.getSettingsProfile.user
  }

  public getUserId = () => {
    return this.settingsState.getSettingsProfile.user?.id || null
  }
}

export const settingsController = SettingsController.getInstance()

import {
  ApiServicesStateReactionFacade,
  APIServiceStateFacade,
  APIServiceStateViewModel,
  StateViewModel,
} from '@clain/core/utils/mobxUtils'
import {
  IIncidentService,
  IncidentApiParams,
  IncidentDataResponseNormalized,
  IncidentsApiParams,
  IncidentsDataResponse,
} from '@compliance/apiServices'
import {
  ClientApiParams,
  ClientDataResponse,
  IClientService,
} from '@compliance/apiServices/Client'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import {
  CasesApiParams,
  CasesDataResponse,
  ICaseApiService,
} from '@compliance/apiServices/Case'
import { inject, injectable } from 'inversify'
import { action, computed, makeObservable, observable } from 'mobx'
import { mergeDeepRight } from 'ramda'
import { ChatUserMessage } from '@compliance/apiServices/shared/Chat.types'
import {
  IAiChartReportData,
  IncidentCaseChatStore,
} from '@compliance/modules/IncidentCaseChatStore'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'

const apiServicesStateFacade = new ApiServicesStateReactionFacade({
  apiServiceStates: {
    incident: new APIServiceStateFacade<
      Parameters<IIncidentService['rest']['getIncidentById']>,
      IncidentDataResponseNormalized
    >(new APIServiceStateViewModel()),
    client: new APIServiceStateFacade<
      Parameters<IClientService['getClientById']>,
      SnakeToCamelCaseObject<ClientDataResponse>
    >(new APIServiceStateViewModel()),
    cases: new APIServiceStateFacade<
      Parameters<ICaseApiService['getCases']>,
      SnakeToCamelCaseObject<CasesDataResponse>
    >(new APIServiceStateViewModel()),
    incidents: new APIServiceStateFacade<
      Parameters<IIncidentService['rest']['getIncidents']>,
      SnakeToCamelCaseObject<IncidentsDataResponse>
    >(new APIServiceStateViewModel()),
  },
  apiParams: {
    incident: new StateViewModel<IncidentApiParams>(),
    client: new StateViewModel<ClientApiParams>(),
    cases: new StateViewModel<CasesApiParams>(),
    incidents: new StateViewModel<IncidentsApiParams>(),
  },
})

const commonData = new StateViewModel<{
  incidentId: number
}>({
  incidentId: null,
})

@injectable()
export class IncidentStore {
  public apiServicesStateFacade = apiServicesStateFacade
  public commonData = commonData

  @observable public isInitialized = false

  constructor(
    @inject(DY_TYPES.IncidentCaseChatStore)
    public chatDataStore: IncidentCaseChatStore<
      IAiChartReportData,
      SnakeToCamelCaseObject<ChatUserMessage>
    >
  ) {
    makeObservable(this)
  }

  @action
  public setInitialized = (isInitialized: boolean) => {
    this.isInitialized = isInitialized
  }

  @computed
  public get isFirstLoading() {
    return this.apiServicesStateFacade.getIsFirstLoadingByService('incident')
  }

  @computed
  public get incidentData() {
    const incidentRestApiData =
      this.apiServicesStateFacade.getStateByService('incident')
    if (!incidentRestApiData) {
      return null
    }

    const { chatMessages, chatStatus, aiSummary, aiSummaryStatus } =
      this.chatDataStore.data
    return mergeDeepRight(
      incidentRestApiData || ({} as typeof incidentRestApiData),
      { chatMessages, chatStatus, aiSummary, aiSummaryStatus }
    )
  }

  @computed
  public get clientData() {
    return this.apiServicesStateFacade.getStateByService('client')
  }

  @computed
  public get isClientDataFirstLoading() {
    return this.apiServicesStateFacade.getIsFirstLoadingByService('client')
  }

  @computed
  public get relatedIncidents() {
    const relatedIncidentsData =
      this.apiServicesStateFacade.getStateByService('incidents')
    return relatedIncidentsData?.data
      ? relatedIncidentsData.data.filter(
          (related) => related.incident?.id !== this.commonData.state.incidentId
        )
      : []
  }

  @computed
  public get relatedCases() {
    const relatedCasesData =
      this.apiServicesStateFacade.getStateByService('cases')
    return relatedCasesData?.cases
      ? relatedCasesData.cases.filter(
          (related) => related.id !== this.commonData.state.incidentId
        )
      : []
  }

  @computed
  public get rules() {
    if (!this.incidentData) {
      return []
    }
    return [this.incidentData.rule, ...this.incidentData.otherRules]
  }

  @action
  public clear = () => {
    this.commonData.clearState()
    this.chatDataStore.clear()
    this.apiServicesStateFacade.clear()
    this.isInitialized = false
  }
}

import React from 'react'
import classnames from 'classnames/bind'

import { Notification } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Link from '@clain/core/Link'

import { NewIncidentsNotification as N } from '../../apiServices'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface NewIncidentsNotificationProps {
  notification: N
  closeToast: () => void
}

export const NewIncidentsNotification: React.FC<
  NewIncidentsNotificationProps
> = ({
  notification: { amount, high_amount, medium_amount, low_amount },
  closeToast,
}) => {
  const bySeverity = []

  if (low_amount) bySeverity.push({ severity: 'Low', amount: low_amount })
  if (medium_amount)
    bySeverity.push({ severity: 'Medium', amount: medium_amount })
  if (high_amount) bySeverity.push({ severity: 'High', amount: high_amount })

  return (
    <Notification.Body closeToast={closeToast} type="warning">
      <Typography variant="body1" color="grey1">
        <Link to="/incidents" className={cx('Bold')}>
          Incidents:{' '}
        </Link>
        {amount} new incidents triggered. Severity:{' '}
        {bySeverity.map(({ severity, amount }, index) => (
          <>
            <Typography variant="body3" className={cx(severity)}>
              {severity}
            </Typography>
            {' - '}
            {amount}
            {index < bySeverity.length - 1 && ', '}
          </>
        ))}
      </Typography>
    </Notification.Body>
  )
}

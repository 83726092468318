import React from 'react'
import classnames from 'classnames/bind'

import { ReactComponent as CloseIcon } from '@clain/core/assets/close_alt.svg'

import { Typography } from '@clain/core/ui-kit'

import { CASE_STATUS } from '../../types/Case'

import { ReactComponent as ReviewIcon } from './review.svg'

import styles from './index.scss'
import { EditorView } from '@clain/core/Editor'

const cx = classnames.bind(styles)

const statusTexts = {
  [CASE_STATUS.PENDING]: 'Pending',
}

interface CaseStatusAlertProps {
  name?: string
  status?: CASE_STATUS.PENDING
  recommendation?: boolean
  comment?: string
}

const CaseStatusAlert = ({
  name,
  status,
  recommendation,
  comment,
}: CaseStatusAlertProps) => {
  const [visible, setVisible] = React.useState(true)

  const handleClose = () => {
    setVisible(false)
  }

  const recommendationColor = recommendation ? 'magenta' : 'blue'
  const recommendationText = recommendation ? 'True Positive' : 'False Positive'
  const statusText = statusTexts[status]

  if (!statusText || !visible) {
    return null
  }

  return (
    <div className={cx('CaseStatusAlert')}>
      <div className={cx('Header')}>
        <ReviewIcon className={cx('ReviewIcon')} />
        <div className={cx('Title')}>
          <div>
            <Typography color="grey2" variant="body3">
              {name}
            </Typography>
            {' changed status to '}
            <Typography color="grey2" variant="body3">
              {statusText}
            </Typography>
          </div>
          <div>
            {'Recommended as '}
            <Typography variant="body3" color={recommendationColor}>
              {recommendationText}
            </Typography>
          </div>
        </div>
      </div>
      <div className={cx('Comment')}>
        <EditorView value={comment} />
      </div>
      <div className={cx('Close')} onClick={handleClose}>
        <Typography>
          <CloseIcon />
        </Typography>
      </div>
    </div>
  )
}

export default CaseStatusAlert

import React from 'react'
import { useField } from 'react-final-form'

import { Typography } from '@clain/core/ui-kit'

interface FieldErrorProps {
  name: string
}

const FieldError = ({ name }: FieldErrorProps) => {
  const { touched, error, modified } = useField(name).meta

  const interected = touched || modified

  return interected && Boolean(error) ? (
    <Typography color="magenta">{error}</Typography>
  ) : null
}

export default FieldError

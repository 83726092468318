import React from 'react'

import Link from '@clain/core/Link'
import { useFormatDate } from '../../hooks'

import RelatedItemContainer from '../RelatedItemContainer'
import CaseStatusTag from '../CaseStatusTag'
import SeverityTag from '../SeverityTag'
import { CasesDataItem } from '../../types/Case'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import { observer } from 'mobx-react-lite'
interface RelatedCaseCardProps {
  case: SnakeToCamelCaseObject<CasesDataItem>
}

const RelatedCaseCard: React.FC<RelatedCaseCardProps> = ({
  case: { tag, id, severity, status, createdAt, closedAs, recommendCloseAs },
}) => {
  const formatDate = useFormatDate()

  return (
    <RelatedItemContainer
      title={tag}
      items={[
        {
          name: 'ID',
          value: (
            <Link to="/cases/:id" params={{ id }}>
              {id}
            </Link>
          ),
        },
        {
          name: 'Severity',
          value: <SeverityTag value={severity}>{severity}</SeverityTag>,
        },
        {
          name: 'Status',
          value: (
            <CaseStatusTag
              value={status}
              closedAs={closedAs}
              recommendAs={recommendCloseAs}
            />
          ),
        },
        {
          name: 'Created',
          value: createdAt ? formatDate(new Date(createdAt), 'date') : '',
        },
      ]}
    />
  )
}

export default observer(RelatedCaseCard)

import { selectToken } from '@clainio/web-platform'
import styled from 'styled-components'

export const FooterProgress = styled.div`
  width: 80%;
  max-width: 1200px;
`

export const EditableTag = styled.input`
  font: ${({ theme }) => selectToken(theme, 'typographyHeading100NormalFont')};
  color: ${({ theme }) =>
    selectToken(theme, 'typographyBaseOnBackgroundBaseColor')};
  padding: 0;
  outline: none;
  background: transparent;
`

import React from 'react'
import { PtsCanvas } from 'react-pts-canvas'
import { CanvasSpace, Bound, Group } from 'pts'

import CoreController from './controllers/CoreController'
import { FunnelOptions } from './types'

class FunnelPTS extends PtsCanvas {
  ctrl = new CoreController()

  start(bound: Bound, space: CanvasSpace) {
    // @ts-expect-error
    this.ctrl.init(this.space, this.form, this.props.options)
  }

  componentDidUpdate(prevProps) {
    // @ts-expect-error
    if (prevProps.options !== this.props.options) {
      // @ts-expect-error
      this.ctrl.init(this.space, this.form, this.props.options)
    }
  }

  animate(time: number, ftime: number) {
    this.ctrl.render(time, ftime)
  }

  action(type: string, px: number, py: number, event: Event) {
    this.ctrl.handleActions(type, px, py, event)
  }

  resize(size: Group, event: Event) {
    this.ctrl.handleResize(size, event)
  }
}

interface FunnelProps {
  options: FunnelOptions
}

const Funnel: React.FC<FunnelProps> = React.memo(({ options }) => {
  return (
    <FunnelPTS
      // @ts-expect-error
      options={options}
      style={{ width: '100%', height: '100%' }}
      canvasStyle={{ width: '100%', height: '100%' }}
      background='white'
    />
  )
})

export default Funnel

import React from 'react'
import classnames from 'classnames/bind'

import { Typography } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ReactComponent as CheckCircleIcon } from '@clain/core/assets/check_circle.svg'

import styles from './index.scss'

const cx = classnames.bind(styles)

export interface RuleOptions {
  type: 'entity' | 'category' | 'exposure' | 'score'
  category?: string
  riskAmount?: string
  direction?: 'in' | 'out' | 'both'
  identityId?: string
  severity?: string
  percentage?: number
  scoreAmount?: string
  autoBlock?: boolean
  retrospectivelyApply?: boolean
  retrospectivelyBlock?: boolean
}

interface RuleOptionsListProps {
  options: RuleOptions
}

const RuleOptionsList: React.FC<RuleOptionsListProps> = ({
  options: {
    type,
    category,
    riskAmount,
    direction,
    identityId,
    severity,
    percentage,
    scoreAmount,
    autoBlock,
    retrospectivelyApply,
    retrospectivelyBlock,
  },
}) => (
  <MagicGrid gap={0.5}>
    <Typography variant="body1" color="black">
      Type:{' '}
      <Typography variant="body3" transform="capitalize">
        {type}
      </Typography>
    </Typography>
    {category && (
      <Typography variant="body1" color="black">
        Category:{' '}
        <Typography variant="body3" transform="capitalize">
          {category}
        </Typography>
      </Typography>
    )}
    {riskAmount && (
      <Typography variant="body1" color="black">
        Risk amount: <Typography variant="body3">≥ $ {riskAmount}</Typography>
      </Typography>
    )}
    {direction && (
      <Typography variant="body1" color="black">
        Direction:{' '}
        <Typography variant="body3" transform="capitalize">
          {direction === 'both' ? 'In/Out' : direction}
        </Typography>
      </Typography>
    )}
    {identityId && (
      <Typography variant="body1" color="black">
        Entity ID: <Typography variant="body3">{identityId}</Typography>
      </Typography>
    )}
    {severity && (
      <Typography variant="body1" color="black">
        Severity:{' '}
        <Typography variant="body3" transform="capitalize">
          {severity?.toLowerCase()}
        </Typography>
      </Typography>
    )}
    {percentage && percentage >= 0 && (
      <Typography variant="body1" color="black">
        Exposure: <Typography variant="body3">≥ {percentage}%</Typography>
      </Typography>
    )}
    {scoreAmount && (
      <Typography variant="body1" color="black">
        Score amount: <Typography variant="body3">≤ {scoreAmount}%</Typography>
      </Typography>
    )}

    {autoBlock && (
      <RowDeprecated gap={0.5}>
        <CheckCircleIcon className={cx('CheckCircleIcon')} />
        <Typography variant="body1" color="black">
          Automatically block client when the rule is triggered
        </Typography>
      </RowDeprecated>
    )}
    {retrospectivelyApply && (
      <RowDeprecated gap={0.5}>
        <CheckCircleIcon className={cx('CheckCircleIcon')} />
        <Typography variant="body1" color="black">
          Apply the rule retrospectively
        </Typography>
      </RowDeprecated>
    )}
    {retrospectivelyBlock && (
      <RowDeprecated gap={0.5}>
        <CheckCircleIcon className={cx('CheckCircleIcon')} />
        <Typography variant="body1" color="black">
          Block client when the rule is triggered in retrospect
        </Typography>
      </RowDeprecated>
    )}
  </MagicGrid>
)

export default RuleOptionsList

import React from 'react'

import {
  ButtonSelect,
  Container,
  MagicGrid,
  notify,
  RowDeprecated,
  Typography,
} from '@clain/core/ui-kit'
import http from '@clain/core/http'
import { ReactComponent as CloseIcon } from '@clain/core/assets/close.svg'
import { ReactComponent as ShieldExclamationIcon } from '@clain/core/assets/shield_exclamation.svg'
import { Rule } from '../../../../types/Rule'

import CreateEntityRuleForm from './CreateEntityRuleForm'
import CreateCategoryRuleForm from './CreateCategoryRuleForm'
import CreateExposureRuleForm from './CreateExposureRuleForm'
import CreateFallbackRuleForm from './CreateFallbackRuleForm'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'
import {
  cx,
  RULES_RIGHT_OVERLAP_SIDEBAR_ID,
  ruleTypeOptions,
} from './RulesRightOverlapSidebar.constants'
import { RightOverlapSidebar } from '@clain/core/Layout/RightOverlapSidebar'

const renderCreateRuleForm = (
  type: string,
  onSubmit: (data) => Promise<any>,
  onSubmitModalOpen: (isOpen: boolean) => void
) => {
  switch (type) {
    case 'entity':
      return (
        <CreateEntityRuleForm
          onSubmit={onSubmit}
          onSubmitModalOpen={onSubmitModalOpen}
        />
      )
    case 'category':
      return (
        <CreateCategoryRuleForm
          onSubmit={onSubmit}
          onSubmitModalOpen={onSubmitModalOpen}
        />
      )
    case 'exposure':
      return (
        <CreateExposureRuleForm
          onSubmit={onSubmit}
          onSubmitModalOpen={onSubmitModalOpen}
        />
      )
    case 'fallback':
      return (
        <CreateFallbackRuleForm
          onSubmit={onSubmit}
          onSubmitModalOpen={onSubmitModalOpen}
        />
      )
    default:
      return null
  }
}

const renderRuleTypeDescription = (type: string) => {
  switch (type) {
    case 'entity':
      return 'Entity-based rule is a type of rule that screens blockchain transaction for a direct exposure to known counterparty’s identity name. As an additional option, a risk amount threshold can be applied.'
    case 'category':
      return 'Category-based rule is a type of rule that screens blockchain transactions for direct exposure to known counterparty’s category. As an additional option, a risk amount along with a score threshold amounts can be applied.'
    case 'exposure':
      return 'Exposure-based rule is a type of rule that screens blockchain transactions for both direct and indirect exposure for unknown counterparties with specified thresholds of selected categories and risk amount.'
    case 'fallback':
      return 'Score-based rule is a type of rule that screens blockchain transactions for both direct and indirect exposure for any counterparties with specified thresholds on entity’s score and transaction amount.'
    default:
      return null
  }
}

interface RulesRightOverlapSidebarProps {
  toggle: () => void
  isOpen: boolean
  onCreateRuleSuccess: (data: Rule) => void
}

const RulesRightOverlapSidebar: React.FC<RulesRightOverlapSidebarProps> = ({
  toggle,
  isOpen,
  onCreateRuleSuccess,
}) => {
  const [ruleType, setRuleType] = React.useState('entity')
  const [isSubmitModalOpen, setIsSubmitModalOpen] = React.useState(false)

  const createRule = React.useCallback(
    (data) => {
      return http
        .post(buildUrl`${getConfig()?.API}/api/rules/new`, data)
        .then((response) => {
          return response.data.rule
        })
        .catch((e) => {
          console.error(e)
          notify('An error occured while sending the request to server', {
            type: 'fail',
          })
        })
        .finally(() => {
          if (isOpen) {
            toggle()
          }
        })
    },
    [onCreateRuleSuccess, toggle, isOpen]
  )

  return (
    <RightOverlapSidebar
      toggle={toggle}
      isOpen={isOpen}
      clickOutside={!isSubmitModalOpen}
    >
      <Container gap={[1.25, 1.5]} id={RULES_RIGHT_OVERLAP_SIDEBAR_ID}>
        <div className={cx('HeadBlock')}>
          <CloseIcon className={cx('CloseIcon')} onClick={toggle} />
          <RowDeprecated className={cx('Title')} gap={0.5}>
            <ShieldExclamationIcon className={cx('ShieldExclamationIcon')} />
            <Typography variant="heading3" color="grey1">
              Rule setup
            </Typography>
          </RowDeprecated>
        </div>
        <Container gap={[2, 1.5]} className={cx('TypeSelectionBlock')}>
          <MagicGrid>
            <ButtonSelect
              fullWidth
              options={ruleTypeOptions}
              value={ruleType}
              onChange={setRuleType}
            />
            <Typography variant="caption1" color="grey3">
              {renderRuleTypeDescription(ruleType)}
            </Typography>
          </MagicGrid>
        </Container>
        {renderCreateRuleForm(ruleType, createRule, setIsSubmitModalOpen)}
      </Container>
    </RightOverlapSidebar>
  )
}

export default RulesRightOverlapSidebar

import castArray from 'lodash/castArray'

const format = (u, v) =>
  castArray(v)
    .map((value) => (typeof value === 'number' ? `${value}${u}` : value))
    .join(' ')

// делаем примерна как в реакте когда пишешь значение числом
const cssVars = (vars, unit = 'px') =>
  Object.entries(vars).reduce((acc, [name, value]) => {
    const key = `--${name}`

    if (typeof value !== 'undefined') {
      acc[key] = format(unit, value)
    }

    return acc
  }, {})

export default cssVars

import React from 'react'
import classnames from 'classnames/bind'

import { Header } from '@clain/core/Layout'
import { Typography } from '@clain/core/ui-kit'
import { Tabs, TabPropsOption } from '@clain/core/ui-kit'
import { ReactComponent as DashboardIcon } from '@clain/core/assets/dashboard_new.svg'

import AssigneeInput from '../../AssigneeInput'

import styles from './index.scss'

const cx = classnames.bind(styles)

export type DashboardTabType = 'enterprise' | 'officer'

const tabsOptions: TabPropsOption<DashboardTabType>[] = [
  {
    value: 'enterprise',
    children: 'enterprise',
  },
  {
    value: 'officer',
    children: 'officer',
  },
]

interface DashboardHeaderProps {
  tab: DashboardTabType
  onTabChange: (tab: DashboardTabType) => void
  assignee: number
  onAssigneeChange: (assignee: number) => void
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({
  tab,
  onTabChange,
  assignee,
  onAssigneeChange,
}) => {
  return (
    <Header
      icon={<DashboardIcon />}
      title={
        <Typography variant="heading1" color="black">
          Dashboard
        </Typography>
      }
      actions={
        tab === 'officer' && (
          <AssigneeInput
            label="Show by assignee"
            value={assignee}
            onChange={onAssigneeChange}
          />
        )
      }
    >
      <Tabs
        options={tabsOptions}
        value={tab}
        onChange={onTabChange}
        className={cx('DashboardTabs')}
      />
    </Header>
  )
}

export default DashboardHeader

import React, { useEffect } from 'react'
import GlobalSettings from '../components/Settings/GlobalSettings'
import { useAppLayoutResetter } from '@clain/core/state'

export const PageGlobalSettings = () => {
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])
  return <GlobalSettings />
}

export default PageGlobalSettings

import React from 'react'

import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ReactComponent as AccountIcon } from '@clain/core/assets/account.svg'

import CaseActivityCard from './index'
import {
  FeedAssignActivity,
  FeedReassignedActivity,
} from '../../../../types/Case'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

interface CaseAssigneeActivityCardProps {
  activity:
    | SnakeToCamelCaseObject<FeedAssignActivity>
    | SnakeToCamelCaseObject<FeedReassignedActivity>
  userName?: string
  className?: string
}

const CaseAssigneeActivityCard: React.FC<CaseAssigneeActivityCardProps> = ({
  activity,
  userName,
  className,
}) => {
  return (
    <CaseActivityCard
      className={className}
      icon={<AccountIcon />}
      title="Assignee"
      main={
        <Typography variant="body3" color="grey2">
          <RowDeprecated gap={0.5}>
            {activity.user.name}
            <Typography color="grey2">reassigned the case to</Typography>
            {userName}
          </RowDeprecated>
        </Typography>
      }
      date={new Date(activity.insertedAt)}
    />
  )
}

export default CaseAssigneeActivityCard

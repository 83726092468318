import React from 'react'
import { Button, RadioButtonGroup } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import Editor, { extractMentions } from '@clain/core/Editor'
import { Label } from '@clain/core/ui-kit'
import Form, {
  FORM_ERROR,
  Field,
  FieldError,
  FormActionsVertical,
} from '@clain/core/Form'
import { Typography } from '@clain/core/ui-kit'
import { CaseAssignee } from '../../../types/Case'
import { useCaseActions } from '../CaseActions.context'

interface ResolveFormProps {
  caseId: number
  onSubmitSuccess: () => void
  onCancel: () => void
  assignees: Array<CaseAssignee>
}

const ResolveForm: React.FC<ResolveFormProps> = ({
  caseId,
  onSubmitSuccess,
  onCancel,
  assignees,
}) => {
  const { setCaseToClose } = useCaseActions()
  const handleSubmit = async (data: { comment: string }) => {
    const mentions = extractMentions(data.comment)
    try {
      await setCaseToClose(caseId, { ...data, mentions })
      onSubmitSuccess()
    } catch (e) {
      return {
        [FORM_ERROR]: e.message,
      }
    }
  }

  const optionLabels = {
    true: <Typography color="magenta">True Positive</Typography>,
    false: <Typography color="blue">False Positive</Typography>,
  }

  const optionTextLabels = {
    true: 'True Positive',
    false: 'False Positive',
  }

  const options = [
    {
      value: 'true',
      label: <>Close as {optionLabels.true}</>,
    },
    {
      value: 'false',
      label: <>Close as {optionLabels.false}</>,
    },
  ]

  return (
    <Form onSubmit={handleSubmit} initialValues={{ close_as: 'true' }}>
      {({ form }) => {
        const {
          validating,
          invalid,
          pristine,
          submitting,
          values,
          submitError,
        } = form.getState()

        const closeAsValue = optionTextLabels[values.close_as]

        return (
          <>
            <MagicGrid gap={2}>
              <Typography variant="heading5">Resolve case</Typography>

              <MagicGrid>
                <Field
                  as={RadioButtonGroup}
                  name="close_as"
                  options={options}
                />

                <Label
                  size="lg"
                  content={
                    <>
                      The case will be closed as {closeAsValue}. <br />
                      Leave the reasoning below and CONFIRM.
                    </>
                  }
                >
                  <Field
                    as={Editor}
                    name="comment"
                    mentionOptions={assignees}
                  />
                  <FieldError name="comment" />
                </Label>
              </MagicGrid>

              <FormActionsVertical>
                {submitError && (
                  <Typography color="magenta">{submitError}</Typography>
                )}
                <RowDeprecated gap={0.5}>
                  <Button
                    type="button"
                    size="lg"
                    variant="outline"
                    color="secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={submitting}
                    type="submit"
                    size="lg"
                    // pristine докидываем, чтобы не моргало при первом рендере
                    // т.к. знаем что форму полюбому надо заполнить
                    disabled={pristine || validating || invalid || submitting}
                    variant="solid"
                    color="primary"
                  >
                    Confirm
                  </Button>
                </RowDeprecated>
              </FormActionsVertical>
            </MagicGrid>
          </>
        )
      }}
    </Form>
  )
}

export default ResolveForm

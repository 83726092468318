import React from 'react'
import classnames from 'classnames/bind'
import { getConfig } from '@clain/core/useConfig'

import Portlet from '@clain/core/Portlet'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'

import SeverityPanel from '../../SeverityPanel'

import {
  DashboardOngoingCasesData,
  DashboardOngoingCasesApiParams,
} from '../../../types/Dashboard'

import styles from './index.scss'

const cx = classnames.bind(styles)

interface OngoingCasesBlockProps {
  className?: string
  assignee?: number
}

const OngoingCasesBlock: React.FC<OngoingCasesBlockProps> = ({
  className,
  assignee,
}) => {
  const apiParams = React.useMemo(
    (): DashboardOngoingCasesApiParams => ({
      assignee_id: assignee,
    }),
    [assignee]
  )

  const { data, isFirstLoading } = useHttp<DashboardOngoingCasesData>(
    buildUrl`${getConfig()?.API}/api/dash/ongoing_cases?${apiParams}`
  )

  const totalInvestigation = React.useMemo(() => {
    if (data?.investigation) {
      return Object.values(data.investigation).reduce((a, b) => a + b, 0)
    }
    return 0
  }, [data])

  const totalPending = React.useMemo(() => {
    if (data?.pending) {
      return Object.values(data.pending).reduce((a, b) => a + b, 0)
    }
    return 0
  }, [data])

  const maxIncidents = Math.max(totalInvestigation, totalPending)

  return (
    <Portlet variant="card" className={className}>
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Ongoing cases
          </Typography>
        ),
        body: isFirstLoading ? (
          <Stub className={cx('OngoingCasesPortletStub')} isActive={true} />
        ) : (
          <Container gap={2}>
            <ColDeprecated>
              <SeverityPanel
                high={data?.investigation?.HIGH}
                medium={data?.investigation?.MEDIUM}
                low={data?.investigation?.LOW}
                percent={totalInvestigation / maxIncidents}
                label="Investigation"
              />
              <SeverityPanel
                high={data?.pending?.HIGH}
                medium={data?.pending?.MEDIUM}
                low={data?.pending?.LOW}
                percent={totalPending / maxIncidents}
                label="Pending review"
              />
            </ColDeprecated>
          </Container>
        ),
      }}
    </Portlet>
  )
}

export default OngoingCasesBlock

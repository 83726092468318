import React from 'react'
import { DropdownMenu } from '@clain/core/ui-kit'
import { Avatar, AvatarSize } from '@clain/core/ui-kit'
interface UserDropdownProps {
  className?: string
  size?: AvatarSize
  email?: string
  logout: () => void
}

const UserDropdown = ({
  className,
  size,
  logout,
  email,
}: UserDropdownProps) => {
  const options = ['Sign out', 'Sign out (Auth0)'] as const

  const handleChange = () => {
    logout()
  }

  return (
    <DropdownMenu
      options={options}
      onChange={handleChange}
      placement="bottom-end"
    >
      <Avatar name={email} size={size} className={className} bordered />
    </DropdownMenu>
  )
}

export default UserDropdown

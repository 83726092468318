import React, { useEffect } from 'react'
import NoAccess from '../components/NoAccess'
import { useAppLayoutSetter } from '@clain/core/state'

const PageNoAccess = () => {
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ variant: 'clear' })
  }, [])
  return <NoAccess />
}

export default PageNoAccess

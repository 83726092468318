export type Amount = {
  from: string
  to: string
}

export type CommonSettings = {
  autoBlock?: boolean
  retrospectivelyApply?: boolean
  retrospectivelyBlock?: boolean
}

export enum FIELDS {
  selected = 'selected',
  severity = 'severity',
  riskAmount = 'riskAmount',
  direction = 'direction',
  ruleType = 'ruleType',
  commonSettings = 'commonSettings',
}

interface StaticFiltersState {
  [FIELDS.selected]: Array<string>
  [FIELDS.severity]: Array<string> | null
  [FIELDS.riskAmount]: Amount
  [FIELDS.direction]: 'both' | 'in' | 'out' | null
  [FIELDS.ruleType]: Array<string> | null
  [FIELDS.commonSettings]: CommonSettings | null
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SelectableFiltersState {}

export type FiltersState = StaticFiltersState & SelectableFiltersState

export interface FiltersProps {
  initialValues: FiltersState
  onChange: (value: FiltersState) => void
}

export interface FieldProps<T> {
  value: T | null
  onChange: (value: T | null) => void
}

type FieldType = keyof SelectableFiltersState

export type FieldTypes = {
  [k in FieldType]: React.ComponentType<FieldProps<any>>
}

type FieldOption = {
  label: string
  value: FieldType
}

export type FieldOptions = Array<FieldOption>

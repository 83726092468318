import React from 'react'
import classnames from 'classnames/bind'
import { useObjectFormattedSearchParams } from '@clain/core/Router/router'
import download from 'downloadjs'
import { formatDate } from 'date-fns'
import debounce from 'lodash/debounce'
import http from '@clain/core/http'
import isEmpty from 'lodash/isEmpty'

import { ReactComponent as FolderOpenIcon } from '@clain/core/assets/folder_open.svg'
import { ReactComponent as CSVIcon } from '@clain/core/assets/csv.svg'

import useHttp from '@clain/core/useHttp'
import { useDebounceValue } from '@clain/core/useDebounce'
import buildUrl, { clearParams } from '@clain/core/utils/buildUrl'
import evolve from '@clain/core/utils/evolve'
import { useTranslateDate } from '../../hooks'

import Portlet from '@clain/core/Portlet'
import Pagination from '@clain/core/Pagination'
import { HeaderSlot } from '@clain/core/Layout/slots'
import PaginationSize from '../PaginationSize'
import Header from '@clain/core/Layout/Header'
import { MagicGrid } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { CounterTabs, CounterTabPropsOption } from '@clain/core/ui-kit'

import CaseFilters, { FiltersState } from './Filters'
import CaseTable from './Table'
import VoidText from '../VoidText'
import { CaseCounters } from '../../types/Case'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'
import { CasesApiParams, CasesDataResponse } from '../../apiServices/Case'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

const DEFAULT_PAGINATION_SIZE = 10
const DEBOUNCE_IN_MS = 500

const tabStub = {
  my: 'You have no assigned cases.',
  all: 'Not a single case is open.',
  new: 'No new cases.',
  investigation: 'No cases under investigation.',
  pending: 'No cases pending review.',
  closed: 'No closed cases.',
  overdue: 'No cases overdue.',
}

type CaseTab =
  | 'my'
  | 'all'
  | 'new'
  | 'investigation'
  | 'pending'
  | 'closed'
  | 'overdue'

const unpackQuery = evolve({
  created: (created) => created.map((c) => new Date(c)),
})

const compactQuery = evolve({
  created: (created) => created.map((c) => c && c.toISOString()),
  page: (page) => (page !== 1 ? page : undefined),
})

const defaultFiltersState: FiltersState = {
  created: [],
  tag: null,
  assignee: null,
  clientId: null,
  selected: [],
  closureStatus: null,
  severity: [],
}

type CaseQuery = {
  page: string
  sortBy: string
  order: 'asc' | 'desc'
  selected: Array<string>
  created: [] | [string, string]
  tag: string
  assignee: string
  clientId: string
  closureStatus: 'true' | 'false' | 'both'
  severity: Array<'high' | 'medium' | 'low'>
}

type CaseUnpackedQuery = CaseQuery & {
  created: [] | [Date, Date]
}

const calcAllCases = (counters: Partial<CaseCounters>) =>
  !isEmpty(counters)
    ? Object.values(counters).reduce((a, v) => a + v, 0) -
      counters.overdue -
      counters.my
    : 0

const Cases = () => {
  const translateDate = useTranslateDate()
  const [query, setQuery] = useObjectFormattedSearchParams<CaseQuery>()
  const uppackedQuery = React.useMemo(
    () => unpackQuery(query),
    [query]
  ) as CaseUnpackedQuery

  const [page, setPage] = React.useState(Number(uppackedQuery.page) || 1)
  const [sortBy, setSortBy] = React.useState(uppackedQuery.sortBy || '')
  const [order, setOrder] = React.useState<'asc' | 'desc'>(uppackedQuery.order)
  const [filters, setFilters] = React.useState<FiltersState>({
    ...defaultFiltersState,
    selected: uppackedQuery.selected || defaultFiltersState.selected,
    created: uppackedQuery.created || defaultFiltersState.created,
    tag: uppackedQuery.tag || defaultFiltersState.tag,
    assignee: uppackedQuery.assignee || defaultFiltersState.assignee,
    clientId: uppackedQuery.clientId || defaultFiltersState.clientId,
    closureStatus:
      uppackedQuery.closureStatus || defaultFiltersState.closureStatus,
    severity: uppackedQuery.severity || defaultFiltersState.severity,
  })

  const handleChangeFilters = (newFilters: FiltersState) => {
    setPage(1)
    setFilters(newFilters)
  }
  const handleChangeSort = (sortBy, order) => {
    setSortBy(sortBy)
    setOrder(order)
  }

  const [counters, setCounters] = React.useState<Partial<CaseCounters>>({})

  const [currentTab, setCurrentTab] = React.useState<CaseTab>('all')

  const handleChangeCurrentTab = (tab: CaseTab) => {
    setPage(1)
    setCurrentTab(tab)
  }

  const tabs = React.useMemo(
    (): Array<CounterTabPropsOption> => [
      {
        value: 'my',
        children: 'my cases',
        counterValue: counters?.my ?? 0,
        isShowZeroValue: true,
      },
      {
        value: 'all',
        children: 'all',
        counterValue: calcAllCases(counters),
        isShowZeroValue: true,
      },
      {
        value: 'new',
        children: 'new',
        counterValue: counters?.new ?? 0,
        counterVariant: counters?.new > 0 ? 'important' : 'default',
        isShowZeroValue: true,
      },
      {
        value: 'investigation',
        children: 'investigation',
        counterValue: counters?.investigation ?? 0,
        isShowZeroValue: true,
      },
      {
        value: 'pending',
        children: 'review',
        counterValue: counters?.pending ?? 0,
        isShowZeroValue: true,
      },
      {
        value: 'closed',
        children: 'resolved',
        counterValue: counters?.closed ?? 0,
        isShowZeroValue: true,
      },
      {
        value: 'overdue',
        children: 'overdue',
        counterValue: counters?.overdue ?? 0,
        counterVariant: counters?.overdue > 0 ? 'important' : 'default',
        isShowZeroValue: true,
      },
    ],
    [counters]
  )

  // 3. Запрос данных

  const debouncedClientId = useDebounceValue(filters.clientId, DEBOUNCE_IN_MS)
  const debouncedTag = useDebounceValue(filters.tag, DEBOUNCE_IN_MS)

  const apiParams = React.useMemo<CasesApiParams>(() => {
    const params: CasesApiParams = {
      page: String(page),
      sort_by: sortBy,
      sort_order: order,
      client_id: debouncedClientId,
      assignee: filters.assignee,
      tag: debouncedTag,
      created_from: filters.created?.[0]
        ? translateDate(filters.created[0]).toISOString()
        : null,
      created_to: filters.created?.[1]
        ? translateDate(filters.created[1]).toISOString()
        : null,
      severity: filters.severity,
      closure_status: filters.closureStatus,
    }

    if (currentTab === 'overdue') {
      params.overdue = true
    } else if (currentTab === 'my') {
      // запрашиваем сразу на вкладке /cases/my
    } else if (currentTab !== 'all') {
      params.status = [currentTab]
    }

    return params
  }, [
    filters,
    page,
    currentTab,
    sortBy,
    order,
    debouncedClientId,
    debouncedTag,
  ])

  React.useEffect(() => {
    const debouncedUpdateQuery = debounce(() => {
      setQuery(
        compactQuery(
          clearParams({
            ...filters,
            page,
            sortBy,
            order,
          })
        ) as CaseQuery
      )
    }, DEBOUNCE_IN_MS)
    debouncedUpdateQuery()
    return () => {
      debouncedUpdateQuery.cancel()
    }
  }, [filters, page, sortBy, order, setQuery])

  // имеет смысл отправлять запрос, только когда определились с вкладкой
  const { data, isLoading: isCasesDataLoading } = useHttp<CasesDataResponse>(
    currentTab === 'my'
      ? buildUrl`${getConfig()?.API}/api/cases/my?${apiParams}`
      : buildUrl`${getConfig()?.API}/api/cases?${apiParams}`
  )

  React.useEffect(() => {
    if (data) {
      setCounters(data.counters)
    }
  }, [data])

  const withFilters = React.useMemo(() => {
    return Boolean(
      filters.assignee ||
        filters.closureStatus ||
        filters.created?.length ||
        filters.severity?.length ||
        debouncedClientId ||
        debouncedTag
    )
  }, [filters, debouncedClientId, debouncedTag])

  const downloadCases = () => {
    http
      .get(
        buildUrl`${getConfig()?.API}/api/cases/csv?${{
          ...apiParams,
          page: undefined,
        }}`
      )
      .then((data) => {
        if (data?.data) {
          download(
            data.data,
            `cases - ${formatDate(new Date(), 'yyyy-MM-dd-HH-mm')}.csv`,
            'text/csv'
          )
        }
      })
  }

  HeaderSlot.useContent(() => (
    <Header
      title="Cases"
      icon={<FolderOpenIcon />}
      actions={
        <RowDeprecated className={cx('ExportAction')} onClick={downloadCases}>
          <Typography>Export data</Typography>
          <Typography color="grey3">
            <CSVIcon />
          </Typography>
        </RowDeprecated>
      }
    />
  ))

  const cases = data?.cases || []

  const [paginationSize, setPaginationSize] = React.useState(0)

  React.useEffect(() => {
    if (data?.total_entries) {
      setPaginationSize(
        DEFAULT_PAGINATION_SIZE < data?.total_entries
          ? DEFAULT_PAGINATION_SIZE
          : data?.total_entries
      )
    }
  }, [data?.total_entries])

  // TODO: handle http error
  return (
    <MagicGrid gap={2.5}>
      <CaseFilters
        disableAssignee={['my', 'overdue'].includes(currentTab)}
        disableAssigneeText={
          currentTab === 'my'
            ? 'Can’t apply with My Cases tab'
            : 'Can’t apply with Overdue tab'
        }
        defaultFiltersState={defaultFiltersState}
        initialValues={filters}
        onChange={handleChangeFilters}
      />
      <div>
        <div className={cx('TableHead')}>
          <CounterTabs
            className={cx('Tabs')}
            options={tabs}
            value={currentTab}
            onChange={handleChangeCurrentTab}
          />
          <PaginationSize
            total={data?.total_entries}
            value={String(paginationSize)}
            onChange={setPaginationSize}
            disabled
          />
        </div>
        <Portlet variant="card">
          {{
            body: (
              <CaseTable
                items={cases}
                onChangeSort={handleChangeSort}
                sortBy={sortBy}
                order={order}
              />
            ),
          }}
        </Portlet>
        {cases.length === 0 && !isCasesDataLoading && (
          <VoidText>
            {tabStub[currentTab]}
            <br />
            {withFilters &&
              'Try to change filters and/or time range restrictions'}
          </VoidText>
        )}
        <Pagination
          className={cx('Pagination')}
          totalPages={data?.total_pages}
          value={page}
          onChange={setPage}
        />
      </div>
    </MagicGrid>
  )
}

export default observer(Cases)
export { CaseTable }

import React from 'react'

import { BaseLink, BaseLinkProps } from './index'

import { ButtonMarkup, ButtonMarkupProps } from '@clain/core/ui-kit'

export type ButtonLinkProps = Omit<ButtonMarkupProps, 'as' | 'onClick'> &
  BaseLinkProps & {
    onClick?: (e: React.SyntheticEvent<'click'>) => void | Promise<any>
    target?: string
  }

const ButtonLink = React.forwardRef<HTMLButtonElement, ButtonLinkProps>(
  (
    {
      size = 'md',
      variant = 'text',
      color = 'secondary',
      children,
      onClick,
      ...props
    },
    ref
  ) => {
    return (
      <ButtonMarkup
        {...props}
        as={BaseLink}
        ref={ref}
        size={size}
        variant={variant}
        color={color}
      >
        {children}
      </ButtonMarkup>
    )
  }
)

ButtonLink.displayName = 'ButtonLink'

export default ButtonLink

import React from 'react'
import classnames from 'classnames/bind'

import { Text } from '@clain/core/ui-kit'
import { InfoCard, InfoCardStub } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { CopyIcon } from '@clain/core/ui-kit'
import { formatMoney } from '@clain/core/utils/format'
import { AssetsListDetailed } from '@clain/core/ui-kit'

import styles from './index.scss'
import { BlockchainType } from '../../types/coin'
import { PopoverBaseContainer } from '@clainio/web-platform'
import { ClientDetails } from '../../apiServices/Client/ClientService.types'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

const cx = classnames.bind(styles)

interface ClientInfoCardProps {
  className?: string
  client?: SnakeToCamelCaseObject<ClientDetails>
  loading?: boolean
  relatedIncidentsCount?: number
  relatedCasesCount?: number
  onRelatedIncidentsClick?: () => void
  onRelatedCasesClick?: () => void
}

const ClientInfoCard: React.FC<ClientInfoCardProps> = ({
  client,
  className,
  loading,
  relatedIncidentsCount,
  relatedCasesCount,
  onRelatedIncidentsClick,
  onRelatedCasesClick,
}) => {
  const assets = React.useMemo(
    () =>
      client?.clientTokens.map(({ withdrawals, deposited, token }) => ({
        id: token.id,
        name: token.name,
        blockchain: token.blockchain.toUpperCase() as BlockchainType,
        receivedUsd: withdrawals,
        sentUsd: deposited,
      })),
    [client]
  )

  if (loading) {
    return <InfoCardStub rows={7} />
  }

  const incomingTransactions = client?.transactionDistribution?.find(
    (transactions) => transactions.type === 'incoming'
  )

  const outgoingTransactions = client?.transactionDistribution?.find(
    (transactions) => transactions.type === 'outgoing'
  )

  return (
    <InfoCard
      className={cx('ClientInfoCard', className)}
      details={[
        {
          label: (
            <Text>
              Transfers{' '}
              <Typography variant="caption1">
                ({client?.transactions ?? 0})
              </Typography>
            </Text>
          ),
          value: (
            <RowDeprecated gap={0.5}>
              <Text>
                {formatMoney({
                  value: client?.volume ?? 0,
                  currency: 'usd',
                  precision: 0,
                  code: '',
                })}
              </Text>
              <CopyIcon value={client?.volume ?? 0} />
            </RowDeprecated>
          ),
        },
        {
          label: (
            <Text>
              Total received{' '}
              <Typography variant="caption1">
                ({incomingTransactions?.total ?? 0})
              </Typography>
            </Text>
          ),
          value: (
            <RowDeprecated gap={0.5}>
              <Text>
                {formatMoney({
                  value: incomingTransactions?.totalUsd ?? 0,
                  currency: 'usd',
                  precision: 0,
                  code: '',
                })}
              </Text>
              <CopyIcon value={incomingTransactions?.totalUsd ?? 0} />
            </RowDeprecated>
          ),
        },
        {
          label: (
            <Text>
              Total sent{' '}
              <Typography variant="caption1">
                ({outgoingTransactions?.total ?? 0})
              </Typography>
            </Text>
          ),
          value: (
            <RowDeprecated gap={0.5}>
              <Text>
                {formatMoney({
                  value: outgoingTransactions?.totalUsd ?? 0,
                  currency: 'usd',
                  precision: 0,
                  code: '',
                })}
              </Text>
              <CopyIcon value={outgoingTransactions?.totalUsd ?? 0} />
            </RowDeprecated>
          ),
        },
        {
          label: 'Assets',
          value: (
            <>
              <Typography
                className={cx('CounterpartyTokens', {
                  active: client?.clientTokens?.length,
                })}
                variant="body2"
                color="grey1"
              >
                {client?.clientTokens?.length ?? 0}
              </Typography>
              {client?.clientTokens?.length > 0 && (
                <PopoverBaseContainer
                  content={
                    <MagicGrid>
                      <Container gap={[1, 1, 0, 1]}>
                        <Typography variant="body2" color="grey1">
                          The client has {client?.clientTokens?.length} assets
                        </Typography>
                      </Container>
                      <AssetsListDetailed
                        assets={assets}
                        className={cx('CounterpartyAssetsList')}
                      />
                    </MagicGrid>
                  }
                  anchor={<div className={cx('AssetsDropdownInner')} />}
                />
              )}
            </>
          ),
        },
        {
          label: 'Related incidents',
          value: (
            <>
              <Typography
                className={cx('RelatedIncidentsCount', {
                  active: relatedIncidentsCount,
                })}
                variant="body2"
                color="grey1"
              >
                {relatedIncidentsCount ?? 0}
              </Typography>
              <div
                className={cx('RelatedIncidentsClickable', {
                  active: relatedIncidentsCount > 0,
                })}
                onClick={onRelatedIncidentsClick}
              />
            </>
          ),
        },
        {
          label: 'Related cases',
          value: (
            <>
              <Typography
                className={cx('RelatedCasesCount', {
                  active: relatedIncidentsCount,
                })}
                variant="body2"
                color="grey1"
              >
                {relatedCasesCount ?? 0}
              </Typography>
              <div
                className={cx('RelatedCasesClickable', {
                  active: relatedCasesCount > 0,
                })}
                onClick={onRelatedCasesClick}
              />
            </>
          ),
        },
      ]}
    />
  )
}

export default ClientInfoCard

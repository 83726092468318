import React, { useEffect } from 'react'
import Client from '../components/Client'
import { useAppLayoutResetter } from '@clain/core/state'
import { useParams } from '@clain/core/Router/router'

export const PageClient = () => {
  const { id } = useParams<{ id: string }>()
  const resetLayout = useAppLayoutResetter()
  useEffect(() => {
    resetLayout()
  }, [])
  return <Client id={id} />
}

import React from 'react'
import { Button, Card, CopyIcon, Stack } from '@clainio/web-platform'
import { CheckboxSwitchDouble, Container, Tabs } from '@clain/core/ui-kit'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import * as S from './CaseDetailsTabs.styles'
import { ClientWidget } from '../../Widgets/ClientWidget'
import { CounterpartyWidget } from '../../Widgets/CounterpartyWidget'
import { ReportWidget } from '../../Widgets/ReportWidget'
import { RightOverlapSidebarSlot } from '@clain/core/Layout/slots'
import OverlapSidebar, { OverlapSidebarVariant } from '../../OverlapSidebar'
import { useRightOverlapSidebarStateSetter } from '@clain/core/state'
import { caseViewModelFacade } from '@compliance/di/containers/CaseViewModelFacade.container'

const tabs = [
  {
    children: 'Counterparty',
    value: 'cp',
  },
  {
    children: 'Client',
    value: 'client',
  },
  {
    children: 'Report',
    value: 'report',
  },
]

export const CaseDetailsTabs = observer(() => {
  const {
    incidentData: mobxIncidentData,
    isFirstLoading: isCaseFirstLoading,
    clientData: mobxClientData,
    isClientDataFirstLoading,
    relatedCases: mobxRelatedCases,
    relatedIncidents: mobxRelatedIncidents,
    aiSummary,
    aiSummaryStatus,
    isSummaryReportCantBeModified,
    isSummaryReportProcessing,
    regenerateReport,
  } = caseViewModelFacade
  const aiSummaryData = toJS(aiSummary)
  const aiSummaryReportStatus = toJS(aiSummaryStatus)
  const incident = toJS(mobxIncidentData)
  const clientData = toJS(mobxClientData)
  const relatedCases = toJS(mobxRelatedCases)
  const relatedIncidents = toJS(mobxRelatedIncidents)

  const [tab, setTab] = React.useState('cp')

  const isShowSummaryReportActions =
    tab === 'report' && !isSummaryReportCantBeModified

  const [currentView, setCurrentView] = React.useState<'snapshot' | 'current'>(
    'snapshot'
  )

  const setIsRightOverlapSidebarOpen = useRightOverlapSidebarStateSetter()

  const [overlapSidebarVariant, setOverlapSidebarVariant] =
    React.useState<OverlapSidebarVariant>('cases')

  const toggleRightOverlapSidebar = (variant: OverlapSidebarVariant) => {
    setOverlapSidebarVariant(variant)
    setIsRightOverlapSidebarOpen((prevState) => !prevState)
  }

  RightOverlapSidebarSlot.useContent(
    ({ toggle, isOpen }) => (
      <OverlapSidebar
        toggle={toggle}
        isOpen={isOpen}
        variant={overlapSidebarVariant}
        clientIncidents={relatedIncidents}
        clientCases={relatedCases}
      />
    ),
    [relatedIncidents, relatedCases, incident, overlapSidebarVariant]
  )

  const tabsContent = {
    client: (
      <ClientWidget
        data={clientData}
        isFirstLoading={isClientDataFirstLoading}
        relatedIncidentsCount={relatedIncidents?.length}
        relatedCasesCount={relatedCases?.length}
        onRelatedCasesClick={() => toggleRightOverlapSidebar('cases')}
        onRelatedIncidentsClick={() => toggleRightOverlapSidebar('incidents')}
      />
    ),
    cp: (
      <CounterpartyWidget
        view={currentView}
        loading={isCaseFirstLoading}
        counterparty={
          //@ts-expect-error TODO Waiting for API update
          incident?.counterparty || incident?.transaction?.counterparty
        }
        //@ts-expect-error TODO Waiting for API update
        exposure={incident?.exposure}
        //@ts-expect-error TODO Waiting for API update
        exposureSnapshot={incident?.snapshotExposure}
        currency={incident?.transaction?.currency}
      />
    ),
    report: (
      <ReportWidget data={aiSummaryData} status={aiSummaryReportStatus} />
    ),
  }
  return (
    <Card flex fullHeight borderRadius={'md'} withHeader={false}>
      <S.TabsWrapper>
        <Tabs options={tabs} value={tab} onChange={setTab} />
        {isShowSummaryReportActions && (
          <Container gap={[1, 1]}>
            <Stack gap={'md'} align={'center'} justify={'end'}>
              <Button
                isLoading={isSummaryReportProcessing}
                disabled={isSummaryReportProcessing}
                endIcon={'Renew'}
                size={'sm'}
                onClick={regenerateReport}
                variant={'outline'}
                color={'secondary'}
              >
                Regenerate
              </Button>
              <CopyIcon size={'sm'} value={aiSummaryData} variant={'outline'} />
            </Stack>
          </Container>
        )}
        {tab === 'cp' && (
          <Container gap={[1, 1]}>
            <CheckboxSwitchDouble
              value={currentView}
              onChange={setCurrentView}
              leftLabel="Snapshot"
              rightLabel="Current"
              leftValue="snapshot"
              rightValue="current"
            />
          </Container>
        )}
      </S.TabsWrapper>
      <ErrorBoundaryContainer>{tabsContent[tab]}</ErrorBoundaryContainer>
    </Card>
  )
})

import React from 'react'

import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ReactComponent as ShieldExclamationIcon } from '@clain/core/assets/shield_exclamation.svg'

import CaseActivityCard from './index'
import { FeedRuleUpdateActivity } from '../../../../types/Case'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

interface CaseRuleUpdateActivityCardProps {
  activity: SnakeToCamelCaseObject<FeedRuleUpdateActivity>
  className?: string
}

const CaseRuleUpdateActivityCard: React.FC<CaseRuleUpdateActivityCardProps> = ({
  activity,
  className,
}) => {
  return (
    <CaseActivityCard
      className={className}
      icon={<ShieldExclamationIcon />}
      title="Rule update"
      main={
        <Typography variant="body3" color="grey2">
          <RowDeprecated gap={0.5}>
            <Typography color="grey2">{activity.activity.text}</Typography>
          </RowDeprecated>
        </Typography>
      }
      date={new Date(activity.insertedAt)}
    />
  )
}

export default CaseRuleUpdateActivityCard

import React from 'react'

import { InputDropdown } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { RadioMenu } from '@clain/core/ui-kit'

interface TransferDirectionInputProps {
  value: string | null
  onChange: (value: string | null) => void
}

const options = [
  {
    label: 'In',
    value: 'in',
  },
  {
    label: 'Out',
    value: 'out',
  },
  {
    label: 'Both (In / Out)',
    value: 'both',
  },
]

const TransferDirectionInput = ({
  value,
  onChange,
}: TransferDirectionInputProps) => {
  const handleInputClear = () => {
    onChange(null)
  }

  const inputDropdownValue = value
    ? options.find((o) => o.value === value)?.label || ''
    : ''

  return (
    <InputDropdown
      clearable
      padded={false}
      variant="outline"
      startAddon={{ label: 'Transfer direction:' }}
      placeholder="Any"
      value={inputDropdownValue}
      onClear={handleInputClear}
      sameWidth
    >
      <Container gap={[1, 0]}>
        <RadioMenu value={value} options={options} onChange={onChange} />
      </Container>
    </InputDropdown>
  )
}

export default TransferDirectionInput

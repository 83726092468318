import { normalizeSnakeToCamelCase } from '@clain/core/utils/normalizeSnakeToCamelCase'
import http from '@clain/core/http'
import { SettingsProfileCRMRaw, SettingsRaw } from '@clain/api/endpoint'
import { API_ENDPOINT } from './utils'

export class SettingsService {
  public getSettingsProfile = async () => {
    const {
      data: { data },
    } = await http.get<{
      data: SettingsProfileCRMRaw
    }>(`${API_ENDPOINT}/settings/profile`)
    return normalizeSnakeToCamelCase(data)
  }
  public getSettings = async () => {
    const {
      data: { data },
    } = await http.get<{
      data: SettingsRaw
    }>(`${API_ENDPOINT}/settings`)
    return normalizeSnakeToCamelCase(data)
  }
}

import { isWithinInterval } from 'date-fns'

interface RowInterval {
  value: Array<Date>
}

type ReturnInterval<T, I> = I & {
  data: Array<T>
}

const groupDataByIntervals = <
  T extends [number | string, ...unknown[]],
  I extends RowInterval
>(
  data: Array<T>,
  intervals: Array<I>
): Array<ReturnInterval<T, I>> => {
  return intervals
    .map((interval) => ({
      ...interval,
      data: data?.filter(([date]) =>
        isWithinInterval(new Date(date), {
          start: interval.value[0],
          end: interval.value[1],
        })
      ),
    }))
    .filter((interval) => interval.data?.length)
}

export default groupDataByIntervals

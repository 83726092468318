import React from 'react'

import { InputDropdown } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { RadioMenu } from '@clain/core/ui-kit'

import { FieldProps } from './types'

const inputIncludesOptions = [
  {
    label: 'True Positive',
    value: 'true',
  },
  {
    label: 'False Positive',
    value: 'false',
  },
  {
    label: 'All resolved',
    value: 'both',
  },
]

const ClosureStatus = ({ value, onChange }: FieldProps<string | null>) => {
  const handleInputClear = () => {
    onChange(null)
  }

  const inputDropdownValue = value
    ? inputIncludesOptions.find((o) => o.value === value)?.label || ''
    : ''

  return (
    <InputDropdown
      clearable
      padded={false}
      variant="outline"
      startAddon={{ label: 'Resolved:' }}
      placeholder="Any"
      value={inputDropdownValue}
      onClear={handleInputClear}
      sameWidth
    >
      <Container gap={[1, 0]}>
        <RadioMenu
          value={value}
          options={inputIncludesOptions}
          onChange={onChange}
        />
      </Container>
    </InputDropdown>
  )
}

export default ClosureStatus

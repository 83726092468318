import React from 'react'
import { startOfDay, endOfDay, subDays, addDays } from 'date-fns'
import castArray from 'lodash/castArray'

import pluralize from '@clain/core/utils/pluralize'
import { ColDeprecated } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Chart, {
  groupDataByIntervals,
  createTooltipFormatter,
} from '@clain/core/Chart2'

type ProgressData = Array<[string, number]>

interface CaseProgressBarsOptions {
  data: ProgressData
}

interface Interval {
  data: ProgressData
  total: number
  name: string
  color: string
}

const Tooltip = ({ points: p = [] }) => {
  const points = p ? castArray(p) : []
  const point = points[0]

  if (!point) {
    return null
  }

  const total = point.value
  const name = point.axisValue

  if (!total) {
    return null
  }

  const match = name.match(/([0-9]+)-([0-9]+)/)

  let text = name

  if (name === '1 day') {
    text = 'with due date tomorrow'
  } else if (match && match.length === 3) {
    // [full, from, to]
    text = `with due date from ${match[1]} to ${match[2]} days`
  }

  return (
    <Container gap={1}>
      <ColDeprecated>
        <Typography color="black" variant="body3">
          {total} {pluralize('case', total)}
        </Typography>
        <Typography color="black" variant="body1">
          {text}
        </Typography>
      </ColDeprecated>
    </Container>
  )
}

const getBeforeDeadlineIntervals = () => {
  const today = new Date()

  return [
    {
      name: '30+ days',
      // Надеюсь года хватит
      value: [startOfDay(addDays(today, 31)), endOfDay(addDays(today, 365))],
      color: '#BDC4FF',
    },
    {
      name: '8-30 days',
      value: [startOfDay(addDays(today, 8)), endOfDay(addDays(today, 30))],
      color: '#BDC4FF',
    },
    {
      name: '4-7 days',
      value: [startOfDay(addDays(today, 4)), endOfDay(addDays(today, 7))],
      color: '#808DFF',
    },
    {
      name: '2-3 days',
      value: [startOfDay(addDays(today, 2)), endOfDay(addDays(today, 3))],
      color: '#808DFF',
    },
    {
      name: '1 day',
      value: [startOfDay(addDays(today, 1)), endOfDay(addDays(today, 1))],
      color: '#3349FF',
    },
  ]
}

const getTodayIntervals = () => {
  const today = new Date()

  return [
    {
      name: 'Today',
      value: [startOfDay(today), endOfDay(today)],
      color: '#FFA800',
    },
  ]
}

const getOverdueIntervals = () => {
  const today = new Date()

  return [
    {
      name: '1 day',
      value: [startOfDay(subDays(today, 1)), endOfDay(subDays(today, 1))],
      color: '#D4114A',
    },
    {
      name: '2-3 days',
      value: [startOfDay(subDays(today, 3)), endOfDay(subDays(today, 2))],
      color: '#D4114A',
    },
    {
      name: '4-7 days',
      value: [startOfDay(subDays(today, 7)), endOfDay(subDays(today, 4))],
      color: '#D4114A',
    },
    {
      name: '7+ days',
      value: [startOfDay(subDays(today, 365)), endOfDay(subDays(today, 8))],
      color: '#D4114A',
    },
  ]
}

function getOptions({ data }: CaseProgressBarsOptions) {
  let validIntervals: Array<Interval> = groupDataByIntervals(
    data,
    getBeforeDeadlineIntervals()
  ).map((interval) => ({
    ...interval,
    total: interval.data.reduce((sum, [, count]) => sum + count, 0),
  }))

  // тут он один будет, но для красоты сделано как везде массивом
  const todayIntervals: Array<Interval> = groupDataByIntervals(
    data,
    getTodayIntervals()
  ).map((interval) => ({
    ...interval,
    total: interval.data.reduce((sum, [, count]) => sum + count, 0),
  }))

  let overdueIntervals: Array<Interval> = groupDataByIntervals(
    data,
    getOverdueIntervals()
  ).map((interval) => ({
    ...interval,
    total: interval.data.reduce((sum, [, count]) => sum + count, 0),
  }))

  // если нет valid интервалов, то дабвляем один пустой
  if (validIntervals.length === 0) {
    validIntervals = [{ data: [], total: 0, name: '' } as Interval]
  }

  // если нет overdue интервалов, то дабвляем один пустой
  if (overdueIntervals.length === 0) {
    overdueIntervals = [{ data: [], total: 0, name: '' } as Interval]
  }

  const leftPaddingPercent = 3
  const rightPaddingPercent = 4

  const totalIntervals = validIntervals.length + overdueIntervals.length

  // размер одного бара с отступами (интервала)
  const intervalWidthPercent = 100 / totalIntervals

  // размер интервала просроченных баров
  const overdueIntervalWidthPercent =
    intervalWidthPercent * overdueIntervals.length

  const overdueIntervalStartPercent = 100 - overdueIntervalWidthPercent
  const overdueIntervalEndPercent = 100 - rightPaddingPercent

  const allIntervals = [
    ...validIntervals,
    ...todayIntervals,
    ...overdueIntervals,
  ]

  // Считаем сколько максимум может быть задач на любом из интервалов
  // Чтобы сделать потом markArea на один больше и полностью покрывала бары
  const maxTotal = allIntervals.reduce(
    (max, interval) => Math.max(max, interval.total),
    0
  )

  const xAsisData = allIntervals.map(({ name }) => name)

  const options = {
    xAxis: {
      show: true,
      type: 'category',
      data: xAsisData,
      axisLabel: {
        show: true,
        fontSize: 14,
        fontFamily: 'Inter, sans-serif',
      },
      axisPointer: {
        type: 'none',
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#8D9EC1',
        },
      },
      z: 1,
    },
    yAxis: {
      type: 'value',
      show: false,
      // чтобы markArea всегда была выше баров
      max: maxTotal + 2,
    },
    grid: {
      top: '10%',
      left: `${leftPaddingPercent}%`,
      right: `${rightPaddingPercent}%`,
      bottom: '3%',
      containLabel: true,
    },
    tooltip: {
      trigger: 'axis',
      padding: 0,
      showDelay: 0.001,
      hideDelay: 0,
      borderRadius: 8,
      transitionDuration: 0,
      formatter: createTooltipFormatter((points) => ({ points }), Tooltip),
      backgroundColor: 'rgba(255, 255, 255, 1)',
      extraCssText:
        'box-shadow: 0px 4px 40px rgba(0, 17, 158, 0.25); z-index: 0;',
      appendToBody: true,
    },
    series: [
      {
        type: 'bar',
        label: {
          show: true,
          position: 'top',
          fontSize: 14,
          fontFamily: 'Inter, sans-serif',
        },
        data: allIntervals.map((interval) => ({
          value: interval.total,
          label: {
            show: Boolean(interval.total),
          },
          itemStyle: {
            color: interval.color,
          },
        })),
        itemStyle: {
          borderRadius: 4,
        },
        barMinWidth: 36,
        barMaxWidth: 56,
        barCategoryGap: '10%',
        markArea: {
          data: [
            [
              {
                x: `${overdueIntervalStartPercent}%`,
                name: 'Overdue',
                itemStyle: {
                  color: '#FEF6F8',
                },
                label: {
                  show: true,
                  position: 'insideTopRight',
                  padding: 5,
                  fontWeight: 500,
                  fontSize: 14,
                  fontFamily: 'Inter, sans-serif',
                  color: '#D4114A',
                },
              },
              {
                x: `${overdueIntervalEndPercent}%`,
              },
            ],
          ],
          silent: true,
        },
      },
    ],
  }

  return options
}

interface CaseProgressBarsProps extends CaseProgressBarsOptions {
  className?: string
  loading?: boolean
}

const CaseProgressBars = React.memo(
  ({ className, loading, data }: CaseProgressBarsProps) => {
    return (
      <Chart
        stub={!data}
        loading={loading}
        className={className}
        option={getOptions({ data })}
        style={{
          height: 281,
        }}
      />
    )
  }
)
CaseProgressBars.displayName = 'CaseProgressBars'
export default CaseProgressBars

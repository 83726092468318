import React from 'react'
import { Button } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import Editor, { extractMentions } from '@clain/core/Editor'
import Form, {
  FORM_ERROR,
  Field,
  FieldError,
  FormActionsVertical,
  validators,
} from '@clain/core/Form'
import { CaseAssignee } from '../../../types/Case'

import { useCaseActions } from '../CaseActions.context'

interface ReopenProps {
  caseId: number
  onSubmitSuccess: () => void
  onCancel: () => void
  assignees: Array<CaseAssignee>
}

const Reopen: React.FC<ReopenProps> = ({
  caseId,
  onSubmitSuccess,
  onCancel,
  assignees,
}) => {
  const { reopenCase } = useCaseActions()

  const tagRef = React.useRef(null)

  const handleSubmit = async (data: { comment: string }) => {
    const mentions = extractMentions(data.comment)
    try {
      await reopenCase(caseId, { ...data, mentions })
      onSubmitSuccess()
    } catch (e) {
      return {
        [FORM_ERROR]: e.message,
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({ form }) => {
        const { validating, invalid, pristine, submitting, submitError } =
          form.getState()

        return (
          <>
            <MagicGrid gap={2}>
              <Typography variant="heading5" color="black">
                Revert to investigation
              </Typography>

              <Label
                size="lg"
                content={
                  <>
                    The case will be set to{' '}
                    <Typography variant="body3" color="black">
                      Investigation status.
                    </Typography>{' '}
                    Leave the reasoning below and CONFIRM.
                  </>
                }
              >
                <Field
                  ref={tagRef}
                  fullWidth
                  as={Editor}
                  mentionOptions={assignees}
                  name="comment"
                  variant="outline"
                  validate={validators.required()}
                />
                <FieldError name="comment" />
              </Label>

              <FormActionsVertical>
                {submitError && (
                  <Typography color="magenta">{submitError}</Typography>
                )}
                <RowDeprecated gap={0.5}>
                  <Button
                    size="lg"
                    type="button"
                    variant="outline"
                    color="secondary"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    isLoading={submitting}
                    type="submit"
                    size="lg"
                    // pristine докидываем, чтобы не моргало при первом рендере
                    // т.к. знаем что форму полюбому надо заполнить
                    disabled={pristine || validating || invalid || submitting}
                    variant="solid"
                    color="primary"
                  >
                    Confirm
                  </Button>
                </RowDeprecated>
              </FormActionsVertical>
            </MagicGrid>
          </>
        )
      }}
    </Form>
  )
}

export default Reopen

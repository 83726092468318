import React from 'react'
import {
  Card,
  Stack,
  Stub,
  TableList,
  TypographyNew,
} from '@clainio/web-platform'
import { Rule } from '../../../types/Rule'
import { SeverityTag } from '../../SeverityTag'
import { RulesTriggeredWidgetProps } from './RulesTriggeredWidget.types'
import { ErrorBoundaryContainer } from '@clain/core/ErrorBoundary'

const RULE_DIRECTION_MAP: Record<Rule['direction'], string> = {
  in: 'In',
  out: 'Out',
  both: 'In/Out',
}

const STUB_DATA = Array.from({ length: 5 }, (_, index) => [
  {
    width: '100%',
    component: (
      <Stub isActive={index % 2 === 0} height={32} type={'grey'} isFullWidth />
    ),
  },
])

export const RulesTriggeredWidget = ({
  rules,
  isLoading,
}: RulesTriggeredWidgetProps) => {
  return (
    <Card borderRadius={'md'} title={'Rules Triggered'}>
      <ErrorBoundaryContainer>
        <Stack padding={['xl', 'md']}>
          <TableList
            rowCellPadding={['none', 'lg']}
            data={
              isLoading
                ? STUB_DATA
                : rules.map((rule) => [
                    {
                      width: '40%',
                      component: (
                        <Stack gap={'lg'} align={'center'}>
                          <SeverityTag
                            size={'xs'}
                            width={'auto'}
                            customWidth={16}
                            value={rule.severity}
                          />
                          <Stack gap={'md'} align={'center'}>
                            <TypographyNew
                              variant={'body100Accent'}
                              color={'onBackgroundBase'}
                            >
                              {rule.name}
                            </TypographyNew>
                          </Stack>
                        </Stack>
                      ),
                    },
                    {
                      width: '20%',
                      component: (
                        <Stack align={'center'} justify={'center'}>
                          <TypographyNew
                            variant={'body100Normal'}
                            color={'onBackgroundBase'}
                          >
                            {RULE_DIRECTION_MAP[rule.direction]}
                          </TypographyNew>
                        </Stack>
                      ),
                    },
                    {
                      width: '40%',
                      component: (
                        <Stack gap={'md'} align={'center'} justify={'end'}>
                          <TypographyNew
                            variant={'body100Normal'}
                            color={'onBackgroundBase'}
                          >
                            Risk
                          </TypographyNew>
                          <TypographyNew
                            variant={'body100Accent'}
                            color={'onBackgroundBase'}
                          >
                            {rule.usdAmount ? `≥ $ ${rule.usdAmount}` : 'Any'}
                          </TypographyNew>
                        </Stack>
                      ),
                    },
                  ])
            }
            rowSize={'md'}
          />
        </Stack>
      </ErrorBoundaryContainer>
    </Card>
  )
}

import React from 'react'
import { ReactComponent as DasboardIcon } from '@clain/core/assets/dashboard_new.svg'
import { ReactComponent as TransactionsIcon } from '@clain/core/assets/transactions_new.svg'
import { ReactComponent as IncidentsIcon } from '@clain/core/assets/incidents_new.svg'
import { ReactComponent as CasesIcon } from '@clain/core/assets/cases_new.svg'
import { ReactComponent as ClientsIcon } from '@clain/core/assets/clients_new.svg'
import { NavMenuItem } from '@clain/core/Layout/NavMenu'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'
import GlobalSearch from './components/GlobalSearch'
import { Gear } from '@clain/core/ui-kit'
import { ProfileItemContainer } from './components/ProfileItemContaner'

export const navItems: NavMenuItem[] = [
  {
    label: 'Dashboard',
    to: '/dashboard',
    icon: <DasboardIcon />,
    placement: 'top',
  },
  {
    label: 'Transfers',
    to: '/transfers',
    icon: <TransactionsIcon />,
    placement: 'top',
  },
  {
    label: 'Incidents',
    to: '/incidents',
    icon: <IncidentsIcon />,
    counterUrl: buildUrl`${getConfig()?.API}/api/incidents/counter`,
    placement: 'top',
  },
  {
    label: 'Cases',
    to: '/cases',
    icon: <CasesIcon />,
    counterUrl: buildUrl`${getConfig()?.API}/api/cases/counter`,
    placement: 'top',
  },
  {
    label: 'Clients',
    to: '/clients',
    icon: <ClientsIcon />,
    placement: 'top',
  },
  {
    label: 'Search',
    component: GlobalSearch,
    placement: 'middle',
  },
  {
    label: 'Settings',
    to: '/settings',
    icon: <Gear />,
    placement: 'middle',
  },
  {
    label: 'Profile',
    component: ProfileItemContainer,
    to: '/settings/profile',
    placement: 'bottom',
  },
]

import { Tag } from '@clainio/web-platform'
import styled from 'styled-components'

export const SeverityTag = styled(Tag)<{
  $width?: string
  $minWidth?: number
}>`
  width: ${({ $width = 'auto' }) => $width};
  min-width: ${({ $minWidth }) =>
    $minWidth != null ? `${$minWidth}px` : 'auto'};
`

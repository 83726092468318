import React from 'react'

import { TextField } from '@clain/core/ui-kit'

import { FieldProps } from './types'

const ClientId = ({ value, onChange }: FieldProps<string>) => {
  return (
    <TextField
      clearable
      variant="outline"
      startAddon={{ label: 'Client ID:' }}
      placeholder="Input full Client ID"
      value={value}
      onChange={onChange}
    />
  )
}

export default ClientId

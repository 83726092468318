import React from 'react'

import Form, { Field, FORM_ERROR, validators } from '@clain/core/Form'
import { Container } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { Label } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import http from '@clain/core/http'
import buildUrl from '@clain/core/utils/buildUrl'
import { getConfig } from '@clain/core/useConfig'

type AddNewOfficerFormData = {
  email: string
  name: string
  position: string
}

const initialValues: AddNewOfficerFormData = {
  email: '',
  name: '',
  position: '',
}

interface AddNewOfficerFormProps {
  onSubmitSuccess: () => void
  onCancel: () => void
}

const AddNewOfficerForm: React.FC<AddNewOfficerFormProps> = ({
  onSubmitSuccess,
  onCancel,
}) => {
  const handleSubmit = (member: AddNewOfficerFormData) => {
    return http
      .post(buildUrl`${getConfig()?.API}/api/settings/users/create`, member)
      .then((response) => {
        if (response.data.msg) {
          return { [FORM_ERROR]: response.data.msg }
        }
        onSubmitSuccess()
      })
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {({ form }) => {
        const { valid, submitError } = form.getState()

        return (
          <Container>
            <MagicGrid gap={2.5}>
              <MagicGrid gap={1.5}>
                {submitError && (
                  <Typography variant="body2" color="magenta">
                    {submitError}
                  </Typography>
                )}
                <Label
                  content={
                    <Typography color="grey1">Corporate email</Typography>
                  }
                >
                  <Field
                    name="email"
                    as={TextField}
                    validate={validators.required()}
                    variant="outline"
                    placeholder="Input email"
                    fullWidth
                  />
                </Label>
                <Label
                  content={<Typography color="grey1">Full Name</Typography>}
                >
                  <Field
                    name="name"
                    as={TextField}
                    validate={validators.required()}
                    variant="outline"
                    placeholder="Input Full Name"
                    fullWidth
                  />
                </Label>
                <Label
                  content={<Typography color="grey1">Position</Typography>}
                >
                  <Field
                    name="position"
                    as={TextField}
                    validate={validators.required()}
                    variant="outline"
                    placeholder="Intercorporate role"
                    fullWidth
                  />
                </Label>
              </MagicGrid>
              <Typography variant="body1" color="black">
                New Officer will be added to your team in CRM.
                <br />
                This position will be counted free within your current
                subscription plan.
              </Typography>
              <RowDeprecated align="right">
                <Button
                  variant="outline"
                  color="secondary"
                  onClick={onCancel}
                  size="lg"
                >
                  cancel
                </Button>
                <Button
                  variant="solid"
                  color="primary"
                  type="submit"
                  size="lg"
                  disabled={!valid}
                >
                  send invite
                </Button>
              </RowDeprecated>
            </MagicGrid>
          </Container>
        )
      }}
    </Form>
  )
}

export default AddNewOfficerForm

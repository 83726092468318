import { Group, Pt } from 'pts'
import { IController, IView } from '../../core/types'

const BACKGROUND_COLOR = '#E6E8FF'

class Conversion implements IView {
  public ctrl: IController

  get form() {
    return this.ctrl.form
  }

  get space() {
    return this.ctrl.space
  }

  public layout: Group

  constructor(ctrl: IController) {
    this.ctrl = ctrl
  }

  public init(
    topLeft: [number, number],
    topRight: [number, number],
    bottomLeft: [number, number],
    bottomRight: [number, number]
  ) {
    this.layout = new Group(
      new Pt(topLeft),
      new Pt(topRight),
      new Pt(bottomLeft),
      new Pt(bottomRight)
    )
  }

  public render() {
    this.form.fill(BACKGROUND_COLOR).polygon(this.layout)
  }
}

export default Conversion

import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'
import {
  IncidentDataOpenCaseResponse,
  incidentService,
} from '../../apiServices'

export interface IIncidentActionsController {
  ignoreIncident: (
    id: number,
    data: { comment: string }
  ) => Promise<void> | void
  openCase: (
    id: number,
    data: { tag: string }
  ) => Promise<SnakeToCamelCaseObject<IncidentDataOpenCaseResponse>>
}

export class IncidentActionsController implements IIncidentActionsController {
  private incidentAPIService = incidentService
  private readonly ignoreIncidentCallback: () => void
  private readonly openCaseCallback: () => void

  constructor({
    ignoreIncidentCallback,
    openCaseCallback,
  }: {
    ignoreIncidentCallback?: () => void
    openCaseCallback?: () => void
  } = {}) {
    this.ignoreIncidentCallback = ignoreIncidentCallback
    this.openCaseCallback = openCaseCallback
  }

  ignoreIncident = async (id: number, data: { comment: string }) => {
    try {
      await this.incidentAPIService.rest.postIgnoreToIncident(id, data)
      this.ignoreIncidentCallback?.()
    } catch (e) {
      throw new Error(e)
    }
  }

  openCase = async (id: number, data: { tag: string }) => {
    try {
      const result = await this.incidentAPIService.rest.postCaseToIncident(
        id,
        data
      )
      this.openCaseCallback?.()
      return result
    } catch (e) {
      throw new Error(e)
    }
  }
}

export const defaultIncidentActionsController = new IncidentActionsController()

import React from 'react'

import { DateISOString } from '@clain/core/types'

import RelatedCategoryRuleIncidentCard from './RelatedCategoryRuleIncidentCard'
import RelatedExposureRuleIncidentCard from './RelatedExposureRuleIncidentCard'
import RelatedFallbackRuleIncidentCard from './RelatedFallbackRuleIncidentCard'
import RelatedEntityRuleIncidentCard from './RelatedEntityRuleIncidentCard'

interface RelatedIncidentProps {
  type: 'entity' | 'category' | 'exposure' | 'score'
  id: number
  name: string
  severity: 'LOW' | 'MEDIUM' | 'HIGH'
  amount: string
  riskAmount: number | null
  percentage: number | null
  score: number
  cases?: Array<{ id: number; tag: string }>
  status?: 'ignored' | 'case' | 'new'
  createdAt?: DateISOString
}

const RelatedIncident: React.FC<RelatedIncidentProps> = ({
  type,
  id,
  severity,
  name,
  amount,
  riskAmount,
  percentage,
  score,
  cases,
  status,
  createdAt,
}) => {
  switch (type) {
    case 'entity':
      return (
        <RelatedEntityRuleIncidentCard
          id={id}
          severity={severity}
          entity={name}
          amount={amount}
          cases={cases}
          status={status}
          createdAt={createdAt}
        />
      )
    case 'category':
      return (
        <RelatedCategoryRuleIncidentCard
          id={id}
          severity={severity}
          category={name}
          amount={amount}
          cases={cases}
          status={status}
          createdAt={createdAt}
        />
      )
    case 'exposure':
      return (
        <RelatedExposureRuleIncidentCard
          id={id}
          severity={severity}
          category={name}
          amount={amount}
          riskAmount={riskAmount}
          percentage={percentage}
          cases={cases}
          status={status}
          createdAt={createdAt}
        />
      )
    case 'score':
      return (
        <RelatedFallbackRuleIncidentCard
          id={id}
          severity={severity}
          score={score}
          amount={amount}
          cases={cases}
          status={status}
          createdAt={createdAt}
        />
      )
  }
}

export default RelatedIncident

import React from 'react'

import { ReactComponent as UserIcon } from '@clain/core/assets/user.svg'
import { Header } from '@clain/core/Layout'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Score } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'

import ClientActions, { ClientStatusType } from '../ClientActions'
import { ClientDetails } from '../../apiServices/Client/ClientService.types'

interface ClientHeaderProps {
  id: string
  data: ClientDetails
  withActions?: boolean
  onActionsSuccess?: (status: ClientStatusType) => void
}

const ClientHeader = ({
  id,
  data,
  withActions = false,
  onActionsSuccess,
}: ClientHeaderProps) => {
  const title = (
    <RowDeprecated>
      <Typography variant="heading1" color="black">
        Client
      </Typography>
      <Typography variant="heading2" color="grey1">
        #{id}
      </Typography>
      {data?.score ? <Score value={data?.score} /> : null}
    </RowDeprecated>
  )

  const status = data?.blocked ? 'blocked' : 'active'

  const actions = withActions ? (
    <ClientActions
      showStatus
      id={id}
      status={status}
      onStatusChange={onActionsSuccess}
    />
  ) : null

  return <Header icon={<UserIcon />} title={title} actions={actions} />
}

export default ClientHeader

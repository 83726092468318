import React from 'react'
import classnames from 'classnames/bind'
import { subMonths, startOfDay, endOfDay } from 'date-fns'

import { DateInterval } from '@clain/core/types'
import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'
import {
  normalizeSeriesDate,
  groupSeriesByWeek,
  groupSeriesByMonth,
  getSeriesExtremum,
} from '@clain/core/Chart2'
import { RowDeprecated } from '@clain/core/ui-kit'
import { ColDeprecated } from '@clain/core/ui-kit'
import Pagination from '@clain/core/Pagination'
import { CalendarInput } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import { ZoomRangeButtonSelect } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import Form, { Field } from '@clain/core/Form'

import TransfersFlowChart from '../../Chart/transfersFlow'
import { TransactionTable } from '../../Transactions'
import {
  TransactionsApiParams,
  TransactionsData,
} from '../../../types/Transaction'
import { GroupByInput, GroupByValue } from '@clain/core/ui-kit'
import { ScoreInput } from '@clain/core/ui-kit'
import AmountInput from '../../AmountInput'
import TransferDirectionInput from './TransferDirectionInput'
import VoidText from '../../VoidText'
import AssetAmountInput from '../../Assets/AssetAmountInput'

import styles from './index.scss'
import { CoinType } from '../../../types/coin'
import { getConfig } from '@clain/core/useConfig'
import { observer } from 'mobx-react-lite'
import { useTimezone } from '@compliance/hooks'
import { fromZonedTime } from 'date-fns-tz'
import { useTranslateDate } from '@clain/core/utils/date'

const cx = classnames.bind(styles)

// ------ yet another component  ------

const getDefaultZoomValue = (timeZone = 'UTC') => {
  return {
    start: startOfDay(fromZonedTime(subMonths(new Date(), 6), timeZone)),
    end: endOfDay(fromZonedTime(new Date(), timeZone)),
  }
}

interface TransfersFlowProps {
  flow?: Array<[string, number]>
}

const TransfersFlowViaState: React.FC<TransfersFlowProps> = observer(
  ({ flow }) => {
    const timeZone = useTimezone()

    const data = React.useMemo(() => {
      // @ts-expect-error там строка в данных, но normalizeSeriesDate приводит все к числу
      const normalized = normalizeSeriesDate(flow) as Array<[number, number]>
      const [min, max] = getSeriesExtremum(normalized)

      return {
        min,
        max,
        inflow: normalized.filter(([x, y]) => y >= 0),
        outflow: normalized.filter(([x, y]) => y < 0),
      }
    }, [flow])

    // атеншн! группировать inflow и outflow важно раздельно, иначе они взаимноуничтожатся
    const groupedData = React.useMemo(
      () => ({
        day: { inflow: data.inflow, outflow: data.outflow },
        week: {
          inflow: groupSeriesByWeek(data.inflow),
          outflow: groupSeriesByWeek(data.outflow),
        },
        month: {
          inflow: groupSeriesByMonth(data.inflow),
          outflow: groupSeriesByMonth(data.outflow),
        },
      }),
      [data]
    )

    const [groupBy, setGroupBy] = React.useState<GroupByValue>('week')

    function handleChangeGroupBy(groupByValue) {
      setGroupBy(groupByValue)
    }

    const [zoom, setZoomValue] = React.useState(getDefaultZoomValue(timeZone))
    const [calendar, setCalendar] = React.useState<[Date, Date] | []>([
      zoom.start,
      zoom.end,
    ])

    const handleChangeZoom = (zoomInterval: DateInterval) => {
      setZoomValue(zoomInterval)
      setCalendar([zoomInterval.start, zoomInterval.end])
    }

    const handleChangeCalendar = ([from, to]) => {
      const interval = {
        start: from,
        end: to,
      }

      setZoomValue(interval)
      setCalendar([from, to])
    }

    const handleChangeChartZoom = (chartInterval: DateInterval) => {
      setZoomValue(chartInterval)
      setCalendar([chartInterval.start, chartInterval.end])
    }

    return (
      <>
        <Container gap={[2, 2, 0, 2]}>
          <RowDeprecated align="between" wrap>
            <ZoomRangeButtonSelect
              min={data.min}
              max={data.max}
              value={zoom}
              onChange={handleChangeZoom}
              className={cx('Zoom')}
            />
            <CalendarInput
              variant="outline"
              placeholder="Select period"
              value={calendar}
              onChange={handleChangeCalendar}
            />
            {/* <AssetSuggest value={assetId} onChange={handleChageAsset} /> */}
            <GroupByInput
              variant="outline"
              value={groupBy}
              onChange={handleChangeGroupBy}
            />
          </RowDeprecated>
        </Container>
        <TransfersFlowChart
          // loading={isLoading}
          type="volume"
          data={groupedData[groupBy]}
          zoom={zoom}
          onZoomChange={handleChangeChartZoom}
        />
      </>
    )
  }
)

// ------ yet another component end  ------

interface TransfersFlowProps {
  flow?: Array<[string, number]>
}

interface TransfersTableProps {
  clientId: string | number
}

const TransfersTable: React.FC<TransfersTableProps> = observer(
  ({ clientId }) => {
    const [page, setPage] = React.useState(1)
    const translateDate = useTranslateDate()
    const initialFilters = React.useMemo(
      () => ({
        created: [],
        score: [1, 10],
        groupBy: 'day',
        mode: 'volume',
        direction: '' as 'both' | 'in' | 'out',
        amount: { from: '', to: '' },
        assetAmount: { blockchain: '', asset_id: '', from: '', to: '' },
      }),
      []
    )

    const [filters, setFilters] = React.useState(initialFilters)

    const apiParams: TransactionsApiParams = {
      client_id: clientId,
      from: filters.created?.[0]
        ? translateDate(filters.created[0]).toISOString()
        : null,
      to: filters.created?.[1]
        ? translateDate(filters.created[1]).toISOString()
        : null,
      page: String(page),
      score_from: filters.score[0],
      score_to: filters.score[1],
      direction: filters.direction === 'both' ? '' : filters.direction,
      amount_usd_from: filters.amount.from,
      amount_usd_to: filters.amount.to,
      blockchain: filters.assetAmount?.blockchain?.toLowerCase() as CoinType,
      asset_id: filters.assetAmount?.asset_id,
      amount_from: filters.assetAmount?.from,
      amount_to: filters.assetAmount?.to,
    }

    const { data, isLoading } = useHttp<TransactionsData>(
      buildUrl`${getConfig()?.API}/api/transactions?${apiParams}`
    )

    return (
      <ColDeprecated>
        <Container>
          <ColDeprecated>
            <Typography variant="heading3">Transfers log</Typography>
            <Form initialValues={initialFilters} onChange={setFilters}>
              {() => {
                return (
                  <RowDeprecated wrap>
                    <Field
                      as={CalendarInput}
                      clearable
                      variant="outline"
                      name="created"
                      placeholder="Select date"
                    />
                    {/* <Field
                    as={TextField}
                    clearable
                    variant='outline'
                    name='search'
                    placeholder='Search by Counterparty'
                    startAddon={{ iconVariant: 'Search' }}
                  /> */}
                    <Field as={ScoreInput} name="score" />
                    {/* <Field as={AssetSuggest} name='asset' /> */}
                    <Field as={AssetAmountInput} name="assetAmount" />
                    <Field as={AmountInput} name="amount" label="USD Amount:" />
                    <Field as={TransferDirectionInput} name="direction" />
                  </RowDeprecated>
                )
              }}
            </Form>
          </ColDeprecated>
        </Container>
        <TransactionTable
          loading={isLoading}
          items={data?.data}
          showClientId={false}
        />
        <Pagination
          className={cx('Pagination')}
          totalPages={data?.total_pages}
          value={page}
          onChange={setPage}
        />
      </ColDeprecated>
    )
  }
)

interface TransfersProps {
  clientId: string | number
  flow?: Array<[string, number]>
}

const Transfers: React.FC<TransfersProps> = ({ clientId, flow }) => {
  if (!flow || flow.length === 0) {
    return <VoidText>The client still don’t have any transactions</VoidText>
  }

  return (
    <ColDeprecated gap={1}>
      <TransfersFlowViaState flow={flow} />
      <TransfersTable clientId={clientId} />
    </ColDeprecated>
  )
}

export default Transfers

import React, { useEffect } from 'react'
import Case from '../components/Case'
import { useParams } from '@clain/core/Router/router'
import { useAppLayoutSetter } from '@clain/core/state'

export const PageCase = () => {
  const { id } = useParams<{ id: string }>()
  const setLayout = useAppLayoutSetter()
  useEffect(() => {
    setLayout({ title: `Case #${id}`, fixedHeader: true })
  }, [])
  return <Case id={Number(id)} />
}

import React from 'react'

import { useDebounceValue } from '@clain/core/useDebounce'

import GlobalSearchInput from './GlobalSearchInput'
import GlobalSearchResults from './GlobalSearchResults'
import useSearch from './useSearch'
import * as S from './GlobalSearchBody.styles'
const SEARCH_DEBOINS_IN_MS = 300

interface GlobalSearchBodyProps {
  hideModal: () => void
}

const GlobalSearchBody: React.FC<GlobalSearchBodyProps> = ({ hideModal }) => {
  const [searchValue, setSearchValue] = React.useState('')

  const onClear = () => {
    setSearchValue('')
  }

  const debouncedSearchValue = useDebounceValue(
    searchValue,
    SEARCH_DEBOINS_IN_MS
  )

  const { data, isLoading } = useSearch(debouncedSearchValue)

  return (
    <S.GlobalSearchBody>
      <GlobalSearchInput
        value={searchValue}
        onChange={setSearchValue}
        onClear={onClear}
      />
      <GlobalSearchResults
        results={data}
        isLoading={isLoading}
        hideModal={hideModal}
      />
    </S.GlobalSearchBody>
  )
}

export default GlobalSearchBody

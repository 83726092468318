import React from 'react'
import classnames from 'classnames/bind'

import http from '@clain/core/http'
import { DateISOString } from '@clain/core/types'
import { useFormatDate } from '../../../hooks'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { MagicGrid } from '@clain/core/ui-kit'
import Form, { Field } from '@clain/core/Form'
import { ColDeprecated } from '@clain/core/ui-kit'
import { TextField } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Button } from '@clain/core/ui-kit'
import { EditableAvatar } from '@clain/core/ui-kit'

import SettingsHeader from '../SettingsHeader'
import ChangePasswordCard from './ChangePasswordCard'
import styles from './index.scss'
import { useSettings } from '../../../hooks/useSettings/useSettings'
import { getConfig } from '@clain/core/useConfig'
import { observer } from 'mobx-react-lite'
const cx = classnames.bind(styles)

interface ProfileUser {
  id: number
  name: string
  role: 'supervisor' | 'officer'
  email: string
  position: string
  createdAt: DateISOString
  img: string
}

interface UserPatch {
  name: string
  email: string
  position: string
}

interface ProfileCardProps {
  user: ProfileUser
  onSubmit: (data: UserPatch) => void
  onPasswordChange: () => void
}

const FormRow = ({ name, label, disabled, onEdit, submit }) => {
  const ref = React.useRef(null)
  const [isEditing, setIsEditing] = React.useState(false)

  const handleEdit = () => {
    onEdit(true)
    setIsEditing(true)

    ref.current.focus()
  }

  const handleApply = () => {
    onEdit(false)
    setIsEditing(false)
    submit()
  }

  return (
    <div className={cx('FormRow', { isEditing, disabled })}>
      <Typography variant="body3" color="grey4" className={cx('FormRowLabel')}>
        {label}
      </Typography>
      <Field
        ref={ref}
        as={TextField}
        readOnly={!isEditing}
        name={name}
        variant={isEditing ? 'outline' : null}
        className={cx('FormInput')}
      />
      {isEditing ? (
        <Button
          key="apply"
          variant="solid"
          color="primary"
          size="lg"
          onClick={handleApply}
        >
          Apply
        </Button>
      ) : (
        <Button
          key="edit"
          size="lg"
          onClick={handleEdit}
          variant="outline"
          color="secondary"
        >
          Edit
        </Button>
      )}
    </div>
  )
}

const ProfileFormCard = observer(
  ({ user, onSubmit, onPasswordChange }: ProfileCardProps) => {
    const [isGlobalEdit, setIsGlobalEdit] = React.useState(false)

    const formatDate = useFormatDate()

    const handleLoadImage = async (file: File) => {
      const formData = new FormData()

      formData.append('avatar', file)

      await http.post(
        `${getConfig()?.API}/api/settings/profile/avatar`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      )
    }

    const handleRemoveImage = () => {
      http.post(`${getConfig()?.API}/api/settings/profile/avatar/remove`)
    }

    return (
      <div
        className={cx('ProfileCard', {
          supervisor: user.role === 'supervisor',
        })}
      >
        <RowDeprecated gap={3} className={cx('TopPanel')}>
          <ColDeprecated align="center" gap={0.5}>
            <EditableAvatar
              name={user.name}
              src={user.img}
              className={`${cx('Avatar')} sentry-block`}
              onLoad={handleLoadImage}
              onRemove={handleRemoveImage}
            />
            <Typography
              variant="caption1"
              transform="capitalize"
              color="purple"
              className={cx('Role')}
            >
              {user.role}
            </Typography>
            <div className={cx('Date')}>
              Since {formatDate(new Date(user.createdAt), 'date')}
            </div>
          </ColDeprecated>
          <Form
            className={`${cx('Form')} sentry-mask`}
            initialValues={user}
            onSubmit={onSubmit}
          >
            {({ form }) => {
              return (
                <ColDeprecated className={cx('FormGrid')}>
                  <FormRow
                    name="email"
                    label="Corporate email:"
                    disabled={isGlobalEdit}
                    onEdit={setIsGlobalEdit}
                    submit={() => form.submit()}
                  />
                  <FormRow
                    name="name"
                    label="Full name:"
                    disabled={isGlobalEdit}
                    onEdit={setIsGlobalEdit}
                    submit={() => form.submit()}
                  />
                  <FormRow
                    name="position"
                    label="Position:"
                    disabled={isGlobalEdit}
                    onEdit={setIsGlobalEdit}
                    submit={() => form.submit()}
                  />
                </ColDeprecated>
              )
            }}
          </Form>
        </RowDeprecated>
        <div className={cx('ChangePasswordPanel')}>
          <RowDeprecated gap={2}>
            <Button
              variant="solid"
              color="primary"
              size="lg"
              endIcon="Renew"
              onClick={onPasswordChange}
            >
              Change password
            </Button>
            <Typography variant="body1" color="grey3">
              We will send you an email with instructions for changing the
              password.
            </Typography>
          </RowDeprecated>
        </div>
      </div>
    )
  }
)

const ProfileSettings: React.FC = () => {
  const { user: profile, loadAndStoreSettingsProfile } = useSettings()

  const [isChangingPassword, setIsChangingPassword] = React.useState(false)

  const handleChangePassword = () => {
    http.post(`${getConfig().API}/api/settings/reset_password`).then(() => {
      setIsChangingPassword(true)
    })
  }

  const user = {
    id: profile.id,
    name: profile.name,
    role: 'officer' as const,
    email: profile.email,
    position: profile.position,
    createdAt: profile.createdAt,
    img: profile.avatar,
  }

  const handleSubmit = (newDate: {
    name: string
    email: string
    position: string
  }) => {
    return http
      .post(`${getConfig()?.API}/api/settings/profile`, newDate)
      .then(async () => {
        await loadAndStoreSettingsProfile()
      })
  }

  HeaderSlot.useContent(() => <SettingsHeader />)

  return isChangingPassword ? (
    <MagicGrid className={cx('Container')} gap={2}>
      <ChangePasswordCard />
      <Button
        className={cx('BackToProfileButton')}
        variant="outline"
        color="secondary"
        size="lg"
        onClick={() => setIsChangingPassword(false)}
      >
        back to profile
      </Button>
    </MagicGrid>
  ) : (
    <MagicGrid className={cx('Container')} gap={1.5}>
      <Typography variant="heading2">Profile</Typography>
      <ProfileFormCard
        user={user}
        onSubmit={handleSubmit}
        onPasswordChange={handleChangePassword}
      />
    </MagicGrid>
  )
}

export default ProfileSettings

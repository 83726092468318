import styled, { css } from 'styled-components'
import { createTokenKey, selectToken } from '@clainio/web-platform'

export const TabsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 57px;

  ${({ theme }) => {
    return css({
      borderBottom: `1px solid ${selectToken(
        theme,
        createTokenKey(['grey400'])
      )}`,
    })
  }}
`

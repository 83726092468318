import React, { FC } from 'react'
import { ChatMessageCardUser, FileList, Stack } from '@clainio/web-platform'
import { ChatMessageCardSystemProps } from '@compliance/components/Widgets/AIChatWidget/AIChatWidget.types'

export const ChatMessageCardUserSection: FC<ChatMessageCardSystemProps> = ({
  userName,
  userAvatar,
  text,
  createdAt,
  files,
  timeZone,
}) => (
  <div>
    <ChatMessageCardUser
      timeZone={timeZone}
      name={userName}
      avatar={{
        image: userAvatar,
      }}
      text={text}
      date={createdAt}
    />
    {files?.length > 0 && (
      <Stack padding={['none', 'lg', 'lg']}>
        <FileList files={files} interactive={false} size="sm" />
      </Stack>
    )}
  </div>
)

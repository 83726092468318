import {
  caseService,
  clientService,
  ICaseService,
  IClientService,
  IIncidentService,
  incidentService,
  IncidentSummaryStatus,
} from '@compliance/apiServices'
import { IAiChartReportData } from '@compliance/modules/IncidentCaseChatStore'
import { Container } from 'inversify'
import { incidentCaseChatStoreModule } from '@compliance/di/modules/IncidentCaseChatStore'
import { incidentStoreModule } from '@compliance/di/modules/incidentStore'
import { IncidentViewModelFacade } from '@compliance/modules/IncidentViewModelFacade'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'
import { incidentViewModelModule } from '@compliance/di/modules/IncidentViewModel'

const aiChatReportData = {
  aiStatus: IncidentSummaryStatus.New,
  aiSummary: '',
  chatMessages: [],
  chatStatus: 'idle',
} satisfies IAiChartReportData

const container = new Container({ defaultScope: 'Singleton' })

container.load(incidentCaseChatStoreModule(aiChatReportData))
container.load(incidentStoreModule())
container.load(incidentViewModelModule())

// Bind services
container
  .bind<IIncidentService>(DY_TYPES.IncidentService)
  .toConstantValue(incidentService)
container
  .bind<IClientService>(DY_TYPES.ClientService)
  .toConstantValue(clientService)
container.bind<ICaseService>(DY_TYPES.CaseService).toConstantValue(caseService)
// Bind store and view models
container
  .bind<IncidentViewModelFacade>(DY_TYPES.IncidentViewModelFacade)
  .to(IncidentViewModelFacade)

export const incidentViewModelFacade = container.get<IncidentViewModelFacade>(
  DY_TYPES.IncidentViewModelFacade
)

import classnames from 'classnames/bind'
import styles from './index.scss'

export const cx = classnames.bind(styles)
export const RULES_RIGHT_OVERLAP_SIDEBAR_ID = 'RULES_RIGHT_OVERLAP_SIDEBAR_ID'
export const ruleTypeOptions = [
  { value: 'entity' },
  { value: 'category' },
  { value: 'exposure' },
  { value: 'fallback' },
]

import React from 'react'
import classnames from 'classnames/bind'

import { Avatar } from '@clain/core/ui-kit'

import styles from './index.scss'

const cx = classnames.bind(styles)

type UserId = string | number

interface User {
  id?: UserId
  name?: string
  mark?: string
}

interface UsersGridProps {
  users: Array<User>
  onChange?: (id?: UserId) => void
  value?: UserId
}

const User = ({ selected, name, mark, onClick }) => {
  return (
    <div className={cx('User', { selected })} onClick={onClick}>
      <Avatar className={cx('UserAvatar')} name={name} size="sm" />
      <div className={cx('UserContent')}>
        {mark && <div className={cx('UserMark')}>{mark}</div>}
        <div className={cx('UserName')}>{name}</div>
      </div>
    </div>
  )
}

const UsersGrid = ({ users, value, onChange }: UsersGridProps) => {
  return (
    <div className={cx('UsersGrid')}>
      {users.map((u) => (
        <User
          key={u.id}
          selected={value === u.id}
          name={u.name}
          mark={u.mark}
          onClick={() => onChange?.(u.id)}
        />
      ))}
    </div>
  )
}

export default UsersGrid

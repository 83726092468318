import { injectable, inject } from 'inversify'
import {
  ICaseApiService,
  ICaseService,
  ICaseSocketService,
} from '@compliance/apiServices'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'

@injectable()
export class CaseService implements ICaseService {
  constructor(
    @inject(DY_TYPES.CaseApiService)
    public rest: ICaseApiService,
    @inject(DY_TYPES.CaseSocketService)
    public socket: ICaseSocketService
  ) {}
}

import { injectable, inject } from 'inversify'
import {
  IIncidentApiService,
  IIncidentService,
  IIncidentSocketService,
} from './IncidentService.types'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'

@injectable()
export class IncidentService implements IIncidentService {
  constructor(
    @inject(DY_TYPES.IncidentApiService)
    public rest: IIncidentApiService,
    @inject(DY_TYPES.IncidentSocketService)
    public socket: IIncidentSocketService
  ) {}
}

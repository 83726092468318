import React from 'react'

import { CheckboxMenu } from '@clain/core/ui-kit'
import { InputDropdown } from '@clain/core/ui-kit'
import { Container } from '@clain/core/ui-kit'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'

import { FieldProps } from './types'

const options = [
  {
    label: 'Entity',
    value: 'entity' as const,
  },
  {
    label: 'Category',
    value: 'category' as const,
  },
  {
    label: 'Exposure',
    value: 'exposure' as const,
  },
  {
    label: 'Fallback',
    value: 'score' as const,
  },
]

const Type = ({ value, onChange }: FieldProps<Array<string>>) => {
  const handleInputClear = () => {
    onChange([])
  }

  const handleChange = (selected) => {
    onChange(selected.length ? selected : [])
  }

  const printValue =
    value.length > 0 && value.length < 4
      ? value.map(capitalizeFirstLetter).join(' / ')
      : ''

  return (
    <InputDropdown
      clearable
      sameWidth
      padded={false}
      variant="outline"
      startAddon={{ label: 'Rule type:' }}
      placeholder="All"
      value={printValue}
      onClear={handleInputClear}
    >
      <Container gap={[1, 0]}>
        <CheckboxMenu options={options} value={value} onChange={handleChange} />
      </Container>
    </InputDropdown>
  )
}

export default Type

import { useEffect, useRef, useCallback } from 'react'

export const useAutoScroll = (dependencies: any[]) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const isScrolledToBottom = useRef(true)
  const timeoutId = useRef<number | null>(null)

  const onScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current!
    isScrolledToBottom.current = scrollTop + clientHeight >= scrollHeight - 2
  }, [])

  useEffect(() => {
    const el = containerRef.current
    if (el) {
      el.addEventListener('scroll', onScroll)
      return () => {
        el.removeEventListener('scroll', onScroll)
      }
    }
    return () => {
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current)
        timeoutId.current = null
      }
    }
  }, [onScroll])

  useEffect(() => {
    if (isScrolledToBottom.current) {
      // Delay the scroll update slightly to allow DOM updates to complete
      timeoutId.current = window.setTimeout(() => {
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight
        }
      }, 50) // Adjust the delay as needed
    }

  }, [...dependencies])

  return containerRef
}

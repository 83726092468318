import { Container } from 'inversify'
import {
  ICaseApiService,
  ICaseService,
  ICaseSocketService,
} from '@compliance/apiServices/Case/CaseService.types'
import { CaseApiService } from '@compliance/apiServices/Case/CaseApiService'
import { CaseSocketService } from '@compliance/apiServices/Case/CaseSocketService'
import { CaseService } from '@compliance/apiServices/Case/CaseService'
import { DY_TYPES } from '@compliance/di/serviceIdentifiers'

export * from './CaseService.types'

const container = new Container({ defaultScope: 'Singleton' })

container.bind<ICaseApiService>(DY_TYPES.CaseApiService).to(CaseApiService)
container
  .bind<ICaseSocketService>(DY_TYPES.CaseSocketService)
  .to(CaseSocketService)
container.bind<ICaseService>(DY_TYPES.CaseService).to(CaseService)

export const caseService = container.get<ICaseService>(DY_TYPES.CaseService)
export { CaseSummaryStatus } from '@compliance/apiServices/Case/CaseService.constants'

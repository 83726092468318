import React from 'react'
import classnames from 'classnames/bind'
import { subMonths, startOfDay, endOfDay } from 'date-fns'
import { UnixTimestamp, DateInterval } from '@clain/core/types'
import useHttp from '@clain/core/useHttp'
import buildUrl from '@clain/core/utils/buildUrl'
import {
  normalizeSeriesDate,
  groupSeriesByWeek,
  groupSeriesByMonth,
  getSeriesExtremum,
} from '@clain/core/Chart2'
import Portlet from '@clain/core/Portlet'
import { Container } from '@clain/core/ui-kit'
import { Typography } from '@clain/core/ui-kit'
import { Stub } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { MagicGrid } from '@clain/core/ui-kit'
import { ZoomRangeButtonSelect } from '@clain/core/ui-kit'
import { CalendarInput } from '@clain/core/ui-kit'

import {
  TransfersVolumeData,
  TransfersVolumeApiParams,
} from '../../../types/Dashboard'
import TransfersFlow from '../../Chart/transfersFlow'
import VolumeDonut from '../../Chart/volumeDonut'
import { GroupByInput, GroupByValue } from '@clain/core/ui-kit'
import AssetSuggest from '../../Assets/AssetsSuggest'

import styles from './index.scss'
import { getConfig } from '@clain/core/useConfig'
import { useTimezone } from '@compliance/hooks'
import { fromZonedTime } from 'date-fns-tz'

const cx = classnames.bind(styles)

const getDefaultZoomValue = (timeZone = 'UTC') => {
  return {
    start: startOfDay(fromZonedTime(subMonths(new Date(), 6), timeZone)),
    end: endOfDay(fromZonedTime(new Date(), timeZone)),
  }
}

const TransfersVolumeBlock: React.FC = () => {
  const [assetId, setAssetId] = React.useState(null)
  const [currency, setCurrency] = React.useState(null)

  const handleChageAsset = (id, currency) => {
    setAssetId(id)
    setCurrency(currency)
  }

  const apiParams: TransfersVolumeApiParams = {
    asset_id: assetId,
    currency,
  }

  const {
    data: source,
    isLoading,
    isFirstLoading,
  } = useHttp<TransfersVolumeData>(
    buildUrl`${getConfig()?.API}/api/dash/trxs?${apiParams}`
  )
  const timeZone = useTimezone()

  const data = React.useMemo(() => {
    const normalized = normalizeSeriesDate<[UnixTimestamp, number]>(
      source?.flow
    )
    const [min, max] = getSeriesExtremum(normalized)

    return {
      min,
      max,
      inflow: normalized.filter(([x, y]) => y >= 0),
      outflow: normalized.filter(([x, y]) => y < 0),
    }
  }, [source])

  const handleChangeZoom = (zoomInterval: DateInterval) => {
    setZoomValue({
      start: zoomInterval.start,
      end: zoomInterval.end,
    })
    setCalendar([zoomInterval.start, zoomInterval.end])
  }

  const handleChangeCalendar = ([from, to]: [Date, Date]) => {
    const interval = {
      start: from,
      end: to,
    }

    setZoomValue(interval)
    setCalendar([from, to])
  }

  const volumeData = React.useMemo(
    () => ({
      in: source?.in,
      out: source?.out,
      volume: source?.volume,
    }),
    [source]
  )

  // атеншн! группировать inflow и outflow важно раздельно, иначе они взаимноуничтожатся
  const groupedData = React.useMemo(
    () => ({
      day: { inflow: data.inflow, outflow: data.outflow },
      week: {
        inflow: groupSeriesByWeek(data.inflow),
        outflow: groupSeriesByWeek(data.outflow),
      },
      month: {
        inflow: groupSeriesByMonth(data.inflow),
        outflow: groupSeriesByMonth(data.outflow),
      },
    }),
    [data.inflow, data.outflow]
  )

  const [groupBy, setGroupBy] = React.useState<GroupByValue>('week')

  function handleChangeGroupBy(groupByValue) {
    setGroupBy(groupByValue)
  }

  const [zoom, setZoomValue] = React.useState(getDefaultZoomValue(timeZone))
  const [calendar, setCalendar] = React.useState<[Date, Date] | []>([
    zoom.start,
    zoom.end,
  ])

  const handleChangeChartZoom = (chartInterval: DateInterval) => {
    setZoomValue(chartInterval)
    setCalendar([chartInterval.start, chartInterval.end])
  }

  return (
    <Portlet variant="card">
      {{
        title: (
          <Typography variant="heading3" color="grey1">
            Transfers volume
          </Typography>
        ),
        body: isFirstLoading ? (
          <Stub className={cx('TransfersVolumePortletStub')} isActive={true} />
        ) : (
          <MagicGrid>
            <Container gap={[2, 2, 0, 2]}>
              <RowDeprecated align="between" wrap>
                <ZoomRangeButtonSelect
                  min={data.min}
                  max={data.max}
                  value={zoom}
                  onChange={handleChangeZoom}
                  className={cx('Zoom')}
                />
                <CalendarInput
                  variant="outline"
                  placeholder="Select period"
                  value={calendar}
                  onChange={handleChangeCalendar}
                />
                <AssetSuggest value={assetId} onChange={handleChageAsset} />
                <GroupByInput
                  variant="outline"
                  value={groupBy}
                  onChange={handleChangeGroupBy}
                />
              </RowDeprecated>
            </Container>
            <MagicGrid cols={[9, 3]} className={cx('Charts')}>
              <TransfersFlow
                loading={isLoading}
                type="volume"
                data={groupedData[groupBy]}
                zoom={zoom}
                onZoomChange={handleChangeChartZoom}
              />
              <VolumeDonut loading={isLoading} data={volumeData} />
            </MagicGrid>
          </MagicGrid>
        ),
      }}
    </Portlet>
  )
}

export default TransfersVolumeBlock

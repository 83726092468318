import React from 'react'
import { IconButton, Stack } from '@clainio/web-platform'
import * as S from './CaseHeader.styles'
import { Button, TypographyNew } from '@clain/core/ui-kit'
import { useKey } from 'react-use'

export const CaseHeaderSubtitle = ({
  text,
  onChangeSubmit,
}: {
  text: string
  onChangeSubmit: (text: string) => Promise<void>
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [subTitle, setSubTitle] = React.useState(text)
  const [isEditing, setIsEditing] = React.useState(false)
  const [isChangeTitleLoading, setIsChangeTitleLoading] = React.useState(false)
  const isEdited = subTitle !== text

  React.useEffect(() => {
    if (text) {
      setSubTitle(text)
    }
  }, [text])

  const handleClickOnEdit = React.useCallback(() => {
    setSubTitle(text)
    setIsEditing(true)
  }, [text])

  const handleClickOnCancel = React.useCallback(() => {
    setSubTitle(text)
    setIsEditing(false)
  }, [text])

  const handleClickOnApply = React.useCallback(() => {
    setIsChangeTitleLoading(true)
    onChangeSubmit(subTitle).then(() => {
      setIsChangeTitleLoading(false)
      setIsEditing(false)
    })
  }, [subTitle])

  useKey('Enter', handleClickOnApply, undefined, [
    setIsChangeTitleLoading,
    onChangeSubmit,
    subTitle,
    setIsEditing,
  ])

  if (isEditing) {
    return (
      <Stack gap={'md'} align={'center'}>
        <S.EditableTag
          autoFocus
          value={subTitle}
          onChange={(event) => setSubTitle(event.target.value)}
          ref={inputRef}
        />
        <Button
          variant="text"
          color="secondary"
          size="md"
          onClick={handleClickOnCancel}
        >
          cancel
        </Button>
        {isEdited && (
          <Button
            isLoading={isChangeTitleLoading}
            variant="solid"
            color="primary"
            size="md"
            onClick={handleClickOnApply}
          >
            apply
          </Button>
        )}
      </Stack>
    )
  }

  return (
    <Stack gap={'md'} align={'center'}>
      <TypographyNew variant={'heading100Normal'} color={'onBackgroundBase'}>
        {subTitle}
      </TypographyNew>
      {text && (
        <IconButton
          variant={'text'}
          iconVariant={'Edit'}
          onClick={handleClickOnEdit}
        />
      )}
    </Stack>
  )
}

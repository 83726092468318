import React from 'react'
import { observer } from 'mobx-react-lite'
import { HeaderSlot } from '@clain/core/Layout/slots'
import { IncidentHeader } from './IncidentHeader'
import { TransactionInfoWidget } from '../Widgets/TransactionInfoWidget'
import { GraphThumbnailWidget } from '../Widgets/GraphThumbnailWidget'
import * as S from '../Layout/IncidentPageLayout.styles'
import { Stack } from '@clainio/web-platform'
import { IncidentSidebar } from './IncidentSidebar'
import { RulesTriggeredWidget } from '../Widgets/RulesTriggeredWidget'
import { toJS } from 'mobx'
import { IncidentDetailsTabs } from './IncidentDetailsTabs'
import { incidentViewModelFacade } from '@compliance/di/containers/IncidentViewModelFacade.container'

const Incident = observer(({ id }: { id: number }) => {
  const {
    init,
    clear,
    isInitialized,
    incidentData: mobxData,
    isFirstLoading: isIncidentFirstLoading,
    rules,
  } = incidentViewModelFacade

  const data = toJS(mobxData)
  React.useEffect(() => {
    if (id != null) {
      ;(async () => init(id))()
      return () => {
        clear()
      }
    }
  }, [id])

  HeaderSlot.useContent(() => <IncidentHeader data={data} />, [data])

  if (!isInitialized) {
    return null
  }

  return (
    <S.PageContainer>
      <S.GridContainer>
        <S.MainContent gap={'xl'} direction={'column'}>
          <Stack gap={'xxl'}>
            <S.Section>
              <TransactionInfoWidget
                isLoading={isIncidentFirstLoading}
                token={data?.transaction?.token}
                type={data?.transaction?.isDeposit ? 'incoming' : 'outgoing'}
                amountUsd={data?.transaction?.amountUsd}
                amount={data?.transaction?.amount}
                time={data?.transaction?.time}
                rule={{
                  name: data?.rule?.name,
                  severity: data?.rule?.severity,
                  type: data?.rule?.type,
                  scoreAmount: data?.rule?.scoreAmount,
                  percentage: data?.rule?.percentage,
                }}
              />
            </S.Section>
            <S.Section>
              <GraphThumbnailWidget probeId={data?.probeId} />
            </S.Section>
          </Stack>
          <RulesTriggeredWidget
            isLoading={isIncidentFirstLoading}
            rules={rules}
          />
          <IncidentDetailsTabs />
        </S.MainContent>
        <IncidentSidebar />
      </S.GridContainer>
    </S.PageContainer>
  )
})

export default Incident

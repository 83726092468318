import React from 'react'
import { getScoreColor } from '@clain/core/utils/getScoreColor'
import { formatNumber } from '@clain/core/utils/format'

import { CounterpartyExposureProps } from './CounterpartyExposure.types'
import {
  Exposure as ExposureType,
  ExposureItem,
} from '@clain/core/Exposure/Exposure.types'
import Exposure from '@clain/core/Exposure/Exposure'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

const transformExposure = (
  exposure: SnakeToCamelCaseObject<ExposureType>
): Array<ExposureItem> =>
  Object.entries(exposure).map(([name, scoreFractions]) => {
    const totalFraction = scoreFractions.reduce(
      (result, [, fraction]) => result + fraction,
      0
    )
    const categoryScore = scoreFractions.reduce(
      (result, [score, fraction]) =>
        result + score * (fraction / totalFraction),
      0
    )

    return {
      name,
      value: totalFraction,
      color: getScoreColor(categoryScore),
      score: categoryScore,
      children: scoreFractions.map(([score, fraction]) => ({
        name: `${name} ${formatNumber((fraction * 100) / totalFraction, 1)}%`,
        color: getScoreColor(score),
        value: fraction,
        score,
        link: '',
      })),
    }
  })

const CounterpartyExposure: React.FC<CounterpartyExposureProps> = ({
  className,
  data,
  mode,
}) => {
  if (!data) return null

  const [source, destination] = data
  const preparedData = [
    transformExposure(source),
    transformExposure(destination),
  ] as [Array<ExposureItem>, Array<ExposureItem>]

  return <Exposure mode={mode} className={className} data={preparedData} />
}

export default CounterpartyExposure

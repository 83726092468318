import { Group, Rectangle } from 'pts'
import { IController, IView } from '../../core/types'

const LABELS_PADDING_PX = 4
const LABEL1_COLOR = '#0F131B'
const LABEL2_COLOR = '#374766'

class StageLabel implements IView {
  public ctrl: IController

  get form() {
    return this.ctrl.form
  }

  get space() {
    return this.ctrl.space
  }

  public label1Layout: Group
  public label2Layout: Group

  public label1Content: string
  public label2Content: string

  constructor(ctrl: IController) {
    this.ctrl = ctrl
  }

  public init(
    topLeft: [number, number],
    width: number,
    height: number,
    label1: string,
    label2: string
  ) {
    const labelHeight = height / 2 - LABELS_PADDING_PX / 2
    this.label1Layout = Rectangle.fromTopLeft(topLeft, width, labelHeight)
    this.label2Layout = Rectangle.fromTopLeft(
      [topLeft[0], topLeft[1] + height / 2 + LABELS_PADDING_PX / 2],
      width,
      labelHeight
    )
    this.label1Content = label1
    this.label2Content = label2
  }

  public render() {
    this.form
      .fill(LABEL1_COLOR)
      .font(24)
      .textBox(this.label1Layout, this.label1Content, 'bottom')
    this.form
      .fill(LABEL2_COLOR)
      .font(14)
      .textBox(this.label2Layout, this.label2Content, 'top')
  }
}

export default StageLabel

import React from 'react'

import { TextField } from '@clain/core/ui-kit'

const INPUT_PLACEHOLDER = 'Search Case ID, Label, Incident ID or Client ID'

interface GlobalSearchInputProps {
  value: string
  onChange: (value: string) => void
  onClear: () => void
}

const GlobalSearchInput: React.FC<GlobalSearchInputProps> = ({
  value,
  onChange,
  onClear,
}) => {
  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    ref.current.focus()
  }, [])

  return (
    <TextField
      ref={ref}
      startAddon={{ iconVariant: 'Search' }}
      placeholder={INPUT_PLACEHOLDER}
      value={value}
      onChange={onChange}
      onClear={onClear}
      fullWidth
      clearable
    />
  )
}

export default GlobalSearchInput

import { Group, Rectangle } from 'pts'
import { IController, IView } from '../../core/types'

const BACKGROUND_FILLED_COLOR = '#808DFF'
const BACKGROUND_COLOR = '#E6E8FF'

class Stage implements IView {
  public ctrl: IController

  get form() {
    return this.ctrl.form
  }

  get space() {
    return this.ctrl.space
  }

  public layout: Group
  public filled: boolean

  constructor(ctrl: IController) {
    this.ctrl = ctrl
  }

  public init(
    topLeft: [number, number],
    width: number,
    height: number,
    filled
  ) {
    this.layout = Rectangle.fromTopLeft(topLeft, width, height)
    this.filled = filled
  }

  public render() {
    this.form
      .fillOnly(this.filled ? BACKGROUND_FILLED_COLOR : BACKGROUND_COLOR)
      .rect(this.layout)
  }
}

export default Stage

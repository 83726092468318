import React from 'react'
import { CopyIcon, HighlightedHashProps } from '@clainio/web-platform'
import { HighlightedHash, Icon } from '@clain/core/ui-kit'
import capitalizeFirstLetter from '@clain/core/utils/capitalizeFirstLetter'
import { Link } from '@clain/core'
import { CoinType } from '../../types/coin'
import * as S from './TransactionHighlightedLink.styles'

interface TransactionHighlightedLinkProps {
  currency: CoinType
  hash: string
  typographyVariant?: HighlightedHashProps['variant']
}

export const TransactionHighlightedLink = ({
  currency,
  hash,
  typographyVariant,
}: TransactionHighlightedLinkProps) => {
  return (
    <S.HighlightedWrapper gap="md" align="center">
      <div>
        <Icon variant={capitalizeFirstLetter(currency)} />
      </div>
      <S.HighlightedWrapper align={'center'} gap={'xs'}>
        <S.HighlightedLinkWrapper>
          <Link
            to="%PLATFORM_URL%/:coin/explorer/transaction/:id"
            params={{
              id: hash,
              coin: currency,
            }}
          >
            <HighlightedHash
              enabledCopy={false}
              variant={typographyVariant}
              hash={hash || ''}
            />
          </Link>
        </S.HighlightedLinkWrapper>
        <CopyIcon value={hash} />
      </S.HighlightedWrapper>
    </S.HighlightedWrapper>
  )
}

import React from 'react'
import { observer } from 'mobx-react-lite'

import { Typography } from '@clain/core/ui-kit'
import { RowDeprecated } from '@clain/core/ui-kit'
import { useFormatDate } from '../../../../hooks'
import { ReactComponent as CalendarIcon } from '@clain/core/assets/calendar.svg'

import CaseActivityCard from './index'
import { FeedDueDateChangedActivity } from '../../../../types/Case'
import { SnakeToCamelCaseObject } from '@clain/core/utilsTypes'

interface CaseDueDateActivityCardProps {
  activity: SnakeToCamelCaseObject<FeedDueDateChangedActivity>
  firstInteract?: boolean
  className?: string
}

const CaseDueDateActivityCard: React.FC<CaseDueDateActivityCardProps> = ({
  activity,
  className,
  firstInteract,
}) => {
  const formatDate = useFormatDate()

  return (
    <CaseActivityCard
      className={className}
      icon={<CalendarIcon />}
      title="Due date"
      main={
        <Typography variant="body3" color="grey2">
          <RowDeprecated gap={0.5}>
            {activity.user.name}
            <Typography color="grey2">
              {firstInteract ? 'set Due Date till' : 'extended Due Date till'}
            </Typography>
            {formatDate(new Date(activity.activity.dueDate), {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </RowDeprecated>
        </Typography>
      }
      date={new Date(activity.insertedAt)}
    />
  )
}

export default observer(CaseDueDateActivityCard)
